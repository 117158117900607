import React,{useState,useEffect} from 'react'
import { Box,InputGroup,Input,InputRightElement,Button,Text,Select,Popover, PopoverTrigger, PopoverContent, PopoverHeader, PopoverBody, Portal } from '@chakra-ui/react'
import CustomTable from '../../../DynamicRenderer/customTable'
import './SilentInputs.scss'
import { IoSearch } from 'react-icons/io5';
import { AddIcon } from '@chakra-ui/icons';
import { BsInfoCircle } from 'react-icons/bs';
import axiosInstance,{axiosInstanceWithoutBaseUrl} from '../../../../utils/axios/axios';
import { showToastMessage } from '../../../TicketTemplateBuilder/utils/templateAPIUtils';

const SilentInputsTableWithSearch = ({tableData, columns, handleReset, isFetching, dropdownFields, selectedTemplate, silentInputsData,setSilentInputsData,qnaRecords,deletedFields,setDeletedFields}) => {
    const [filter, setFilter] = useState(silentInputsData);
    const [fieldValue, setFieldValue] = useState('');
    const [dropdownValue, setDropdownValue] = useState('');
    const [selectedQnaValue, setSelectedQnaValue] = useState({});
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const [descriptionPopover, setDescriptionPopover] = useState(false);
    const [crossRefPopover, setCrossRefPopover] = useState(false);

    const FIELDS_TOAST = {
      SUCCESS: 'Fields Updated Successfully',
      ERROR: 'Fields Not Updated'
    };
    const DescriptionHeaderRenderer = (text) => (
      <Popover placement="bottom-start" offset={[0, 10]} textTransform="none" isOpen={descriptionPopover} onOpen={() => setDescriptionPopover(true)} onClose={() => setDescriptionPopover(false)} closeOnBlur={false}>
        <PopoverTrigger>
          <Box display="flex" alignItems="center">
          <Text me="5px" color="#111827" fontSize="14px">{text}</Text>
          <BsInfoCircle color="#94A3B8" onMouseEnter={() => setDescriptionPopover(true)} onMouseLeave={() => setDescriptionPopover(false)} />
          </Box>
        </PopoverTrigger>
        <Portal>
          <PopoverContent zIndex="popover" position="absolute" onMouseEnter={() => setDescriptionPopover(true)} onMouseLeave={() => setDescriptionPopover(false)}>
            <PopoverHeader display="flex" gap="10px" p="10px" borderBottom="1px solid #E2E8F0" alignItems="center">
              <BsInfoCircle color="#E79B04" />
              <Text>Description For Bot:</Text>
            </PopoverHeader>
            <PopoverBody padding="10px" className="popover-body">
              <Text whiteSpace="normal" fontWeight="400" fontSize="14px" color="#111827">This field should contain a detailed explanation of the field's purpose and what kind of information it should contain.</Text>
              <Text whiteSpace="normal" fontWeight="400" fontSize="14px" mt="10px" color="#6B7280" className="popover-example">Eg. The email address of the customer, used for communication and account recovery.</Text>
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
    );
    const crossRefRenderer = (text) => (
      <Popover placement="bottom-start" offset={[0, 10]} textTransform="none" isOpen={crossRefPopover} onOpen={() => setCrossRefPopover(true)} onClose={() => setCrossRefPopover(false)} closeOnBlur={false}>
        <PopoverTrigger>
          <Box display="flex" alignItems="center">
          <Text me="5px" color="#111827" fontSize="14px">{text}</Text>
          <BsInfoCircle color="#94A3B8" onMouseEnter={() => setCrossRefPopover(true)} onMouseLeave={() => setCrossRefPopover(false)} />
          </Box>
        </PopoverTrigger>
        <Portal>
          <PopoverContent zIndex="popover" position="absolute" onMouseEnter={() => setCrossRefPopover(true)} onMouseLeave={() => setCrossRefPopover(false)}>
            <PopoverHeader display="flex" gap="10px" p="10px" borderBottom="1px solid #E2E8F0" alignItems="center">
              <BsInfoCircle color="#E79B04" />
              <Text>Cross Referenced Fields</Text>
            </PopoverHeader>
            <PopoverBody padding="10px" className="popover-body">
              <Text whiteSpace="normal" fontWeight="400" fontSize="14px" color="#111827">Specify the fields that bot should use to deduce the value for this field.</Text>
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
    );
    const columnsData=columns.map((item)=>{
      if (item.label === 'Description for Bot') {
        return { ...item, label: DescriptionHeaderRenderer(item?.label) };
      }
      if (item.label === 'Cross Referenced Fields') {
        return { ...item, label: crossRefRenderer(item?.label) };
      }
        return item
    });

    const handleChange = (e) => {
        const inputValue = e.target.value;
        const filteredData = silentInputsData.filter((item) =>
        item.name.toLowerCase().includes(inputValue.toLowerCase())
        );
        setFilter(filteredData.length > 0 ? filteredData : []);
    };

    useEffect(()=>{
      setFilter(silentInputsData)
    },[silentInputsData])

    useEffect(()=>{
      const flowName = selectedTemplate[0]?.name.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
      const response = qnaRecords.find((item) => item.metadata[0]?.value === flowName);
      setSelectedQnaValue(response)
    },[selectedTemplate])
    const fieldsSave=async(fieldsPayload)=>{
      console.log("fieldsPayload save",fieldsPayload)
      try {
        const response = await axiosInstance.patch(`templates/update?$filter=id%20eq%20${selectedTemplate[0]?.id}`, fieldsPayload);
        if (response) {
          // getFields();
          showToastMessage({
            title: 'Success',
            description: FIELDS_TOAST.SUCCESS,
            status: 'success',
          });
        }
      } catch (err) {
        console.log("err",err)
        showToastMessage({
          title: 'Error',
          description: FIELDS_TOAST.ERROR,
          status: 'error',
        });
        return [];
      }
    }
    const qnaSave=async(qna,values)=>{
      const isSilentInputs = qna.metadata.find(item => item.name === 'silentInputs');

        if (isSilentInputs) {
          qna.metadata = qna.metadata.map((item) => {
            if (item.name === 'silentInputs') {
              return {
                ...item,
                value: values, 
              };
            }
           
            return item;
          });
        } else {
          qna.metadata.push({ name: 'silentInputs', value: values });
        }
      const payload={questions:qna.questions,metadata:qna.metadata}
      try {
        const qnaRecordId = qna?.id;
        const response = await axiosInstanceWithoutBaseUrl.put(
          `/botmgmt-service/qna/update/${qnaRecordId}`, payload);
        if (response) {
          setDeletedFields([])
          return [];
        }
        return [];
      } catch (err) {
        setDeletedFields([])
        console.log("error occured in qna save",err)
        return [];
      }
    }

    const onSaveSilentInputs=async ()=>{
        const fields = selectedTemplate[0]?.fields
        const convertedFields = fields.map((item) => {
          const { silentInput, ...rest } = item;
          return {
            ...rest,
            type: item.type.replace(/ /g, '_').toUpperCase()
          };
        });
        const silentInputValues = convertedFields.map((item) => {
          const value = silentInputsData.find((element) => element?.id === item?.id);
          if (value && value !== undefined) {
            return {
              ...item,
              silentInput:true,
              botDescription: value?.botDescription,
              crossReferencedFields:value?.crossReferencedFields,
              selectedOffers:value?.selectedOffers
            };
          }
          return item;
        });
        const values = silentInputValues.map((item) => {
          const fieldsToBeDelete=deletedFields.find((element)=>element.id===item.id)
            if (fieldsToBeDelete) {
              return {
                ...item,
                silentInput: false,
                crossReferencedFields: [],
                selectedOffers:[],
                botDescription:''
              };
            }
            else {return item}
        });
        const fieldsPayload = { data: { fields: values } }
        await fieldsSave(fieldsPayload)
        await qnaSave(selectedQnaValue,values)
        
      }
      const onAddDropdownField = () => {
        const selectedField = dropdownFields.find((item) => item?.id.toString() === dropdownValue);
        const isExisting = filter.filter((item) => item?.id === selectedField?.id).length > 0;
        selectedField !== undefined && !isExisting && setFilter([...filter, selectedField]);
        selectedField !== undefined && !isExisting && setSilentInputsData([...silentInputsData, selectedField]);
        setIsDropdownVisible(false);
        setDropdownValue('');
      };
    return (
        <Box className="silent-inputs-section">
          <Box data-testid="search-section" className="search-section" display="flex" gap="10px" justifyContent="end" my="2">
              <InputGroup w="500px">
                  <Input placeholder="Search" value={fieldValue} onChange={handleChange} />
                  <InputRightElement><IoSearch /></InputRightElement>
              </InputGroup>
              {tableData && <Button data-testid="reset-button" onClick={handleReset}>Reset</Button>}
              {tableData && <Button data-testid="save-button" onClick={onSaveSilentInputs}>Save</Button>}
          </Box>
          <Box mt="20px">
              <CustomTable
                  data-testid="SilentInputsTable"
                  height="calc(100vh - 18rem)"
                  columns={columnsData}
                  tableData={filter}
                  onRowClick={() => {}}
                  botIntegrationTable="true"
                  isFetching={isFetching}
              />
          </Box>
          <Box className='add-field' data-testid="add-field" display="flex" justifyContent="flex-end" gap="20px" mt="20px" >
          {isDropdownVisible && <Select placeholder="Select field" onChange={(e) => setDropdownValue(e.target.value)} maxWidth="200px">
              {Array.isArray(dropdownFields) && dropdownFields.map((item) => {
                if (!silentInputsData.some((element) => element?.id === item?.id)) {
                    return (<option value={item?.id} key={item?.id}>{item?.label}</option>);
                  }
              })}
            </Select>}
            {!dropdownValue ? 
            <Button variant="outline" onClick={()=>{setIsDropdownVisible(true);}} disabled={dropdownFields?.length==0} >
                <AddIcon />
                <Text as='span' ms="10px">Add field</Text>
            </Button>
            :
            <Button onClick={onAddDropdownField}>
                <Text as='span' ms="10px">Add</Text>
            </Button>
            }
          </Box>
        </Box>
    )
}

export default SilentInputsTableWithSearch