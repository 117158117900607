const specialFieldList = [
  {
    meta_field_name: 'Submitted by',
    meta_field_id: 'c4e0b6e4-6c65-4d62-bf94-3c15dc7f645e',
    attributes: {},
    id: 'c4e0b6e4-6c65-4d62-bf94-3c15dc7f645e',
    label: 'Submitted by'
  },
  {
    meta_field_name: 'Submitted On',
    meta_field_id: '7f5607d0-cf9b-4cb1-8e63-72f8a4572d7f',
    attributes: {},
    id: '7f5607d0-cf9b-4cb1-8e63-72f8a4572d7f',
    label: 'Submitted On',
  },
  {
    meta_field_name: 'Case ID',
    meta_field_id: 'd821ee5b-03db-4f4c-9d67-5a96362b4db0',
    attributes: {},
    id: 'd821ee5b-03db-4f4c-9d67-5a96362b4db0',
    label: 'Case ID',
  },
];
export default specialFieldList;

export const mergeArrays = (firstArray, secondArray) => {
  const metaFieldIdsSet = new Set(firstArray.map((item) => item.meta_field_id));

  secondArray.forEach((item) => {
    if (!metaFieldIdsSet.has(item.meta_field_id)) {
      firstArray.push(item);
    }
  });

  return firstArray;
};
export const updateLabelWithKey = (arr1, arr2, checkMetaName = false, key = 'meta_field_id') => {
  // Create a lookup map for arr2 based on the specified key
  const lookup = arr2.reduce((acc, item2) => {
    acc[item2[key]] = item2.name;
    return acc;
  }, {});

  // Map arr1 and merge the label property if a match is found in the lookup
  if (checkMetaName) {
    return arr1.map((item1) => ({
      ...item1,
      label: lookup[item1[key]] || item1.label,
      meta_field_name: lookup[item1[key]] || item1.meta_field_name,
    }));
  }
  return arr1.map((item1) => ({
    ...item1,
    label: lookup[item1[key]] || item1.label
  }));
};
