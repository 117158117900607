/* eslint-disable camelcase */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { Box } from '@chakra-ui/react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { isEmpty } from 'lodash';
import TableViewWithSearch from '../../../components/Table/TableWithSearch';

import {
  fetchAllCards,
  selectAllCards,
} from '../../../redux/CardReducer/AllCardSlice';
import {
  enrichDataForEdit,
  generateOfferIdMap,
  generateOfferOptionsByTemplateId,
  generateTemplateIdMap,
  generateTemplateOptions,
} from './relation.helper';
import { columns } from './relation.constants';
import {
  deleteRelations,
  getRelations,
  saveRelation,
  updateRelation,
  bulkUpdateRelation
} from './relation.service';
import EditRelation from './EditRelation';
import axiosInstance from '../../../utils/axios/axios';
import { getHierarchies } from '../../../components/dynamicExpressionWithTags/dynamicExpressionWithTags.helper';

const Relations = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // eslint-disable-next-line camelcase
  const { ticket_template_fields_list_view } = useSelector(
    (state) => state.viewState
  );

  const allTemplates = useSelector(selectAllCards);

  const [showEdit, setShowEdit] = useState(false);
  const [relations, setRelations] = useState([]);
  const [offers, setOffers] = useState([]);
  const [isRelationLoading, setIsRelationLoading] = useState(false);
  const [hierarchies, setHierarchies] = useState([]);
  const { selectedTemplate: currentTemplate } = useSelector((state) => state.viewState || {});
  const currentTemplateId = currentTemplate?.id;

  // const isTemplateApiTriggered = useRef(false);
  const selectedRelation = useRef(null);

  const templateIdMapMemo = useMemo(() => generateTemplateIdMap(allTemplates), [allTemplates]);
  const templateOptionsMemo = useMemo(() => generateTemplateOptions(allTemplates), [allTemplates]);

  useEffect(() => {
    axiosInstance
      .get(`fieldDefaults/list?$select=*&$filter=offer_type ne 'email'`)
      .then((response) => {
        const allOffers = response?.data?.rows || [];
        setOffers(allOffers);
      });
    getHierarchies(setHierarchies);
  }, []);

  useEffect(() => {
  }, []);

  const offerIdMapMemo = useMemo(() => {
    const offerIdMap = {
      none: { name: 'None' }
    };
    if (offers?.length) {
      return {
        ...offerIdMap,
        ...generateOfferIdMap(offers)
      };
    }
    return offerIdMap;
  }, [offers]);

  // eslint-disable-next-line max-len
  const offerOptionsMemo = useMemo(() => generateOfferOptionsByTemplateId(offers, allTemplates), [offers, allTemplates]);

  const onCreateOrEdit = useCallback(
    (rowData) => {
      selectedRelation.current = rowData
        ? enrichDataForEdit({
          templateIdMap: templateIdMapMemo,
          data: rowData,
          currentTemplateId,
        })
        : {
          id: '',
          template_id_left: currentTemplateId,
          template_id_right: '',
          offer_id_left: 'none',
          offer_id_right: 'none',
          cardinality: '',
          customAttributes: [],
          similarities: [],
          similaritiesInverse: [],
          relations_list: [],
          default_relation: {
            left_to_right: '',
            right_to_left: '',
          },
        };
      setShowEdit(true);
    },
    [templateIdMapMemo, offerIdMapMemo, currentTemplateId]
  );

  const onDelete = useCallback((rowData) => {
    if (!rowData?.id) {
      return;
    }

    deleteRelations(rowData?.id).then((res) => {
      if (res) {
        setRelations((prev) => prev.filter((r) => r.id !== rowData?.id));
      }
    });
  }, []);

  const onSaveRelation = useCallback((payload, relationId) => {
    setIsRelationLoading(true);
    if (relationId) {
      updateRelation(relationId, payload).then((res) => {
        if (res) {
          setIsRelationLoading(false);
          setRelations((prev) =>
            prev.map((r) => {
              if (r?.id === relationId) {
                return res;
              }

              return r;
            })
          );
          setShowEdit(false);
        }
      });
    } else {
      saveRelation(payload).then((res) => {
        if (res) {
          setIsRelationLoading(false);
          setRelations((prev) => [...prev, res]);
          setShowEdit(false);
        }
      });
    }
  }, []);

  const navigateBack = useCallback(() => {
    setShowEdit(false);
  }, []);

  const getUpdateFields = (templateId) => {
    if (
      ticket_template_fields_list_view?.data?.length !==
      templateIdMapMemo?.[templateId]?.fields?.length
    ) {
      // eslint-disable-next-line max-len, no-unsafe-optional-chaining
      const updatedObject = {
        ...templateIdMapMemo[templateId],
        fields: [...(ticket_template_fields_list_view?.data || [])],
      };
      templateIdMapMemo[templateId] = updatedObject;
    }
  };
  // Fetching all templates, if the list is empty
  useEffect(() => {
    if (isEmpty(allTemplates)) {
      dispatch(fetchAllCards());
    }
  }, []);

  // Fetching all relations
  useEffect(() => {
    getRelations(currentTemplateId).then((res) => {
      setRelations(res);
    });
  }, [currentTemplateId]);

  useEffect(() => {
    if (ticket_template_fields_list_view?.data && templateIdMapMemo?.[currentTemplateId]?.fields) {
      getUpdateFields(currentTemplateId);
    }
  }, [
    ticket_template_fields_list_view?.data,
    templateIdMapMemo?.[currentTemplateId]?.fields,
    currentTemplateId,
  ]);

  // sortable drag & drop configs start
  const [isFilterSortable, setIsFilterSortable] = useState(false);
  const [reOrderedList, setReOrderList] = useState([]);
  const [isLoading, setIsloading] = useState(false);
  const onRelationOrderSave = useCallback(() => {
    setIsloading(true);
    const payload = reOrderedList.map((item) => {
      const { position, properties, ...rest } = item;
      return {
        ...rest,
        properties: {
          ...properties,
          position,
        }
      };
    });

    bulkUpdateRelation(payload).then((res) => {
      if (res) {
        setIsloading(false);
      }
    });
    //  API Call required to put the updated index in respective filter table
  }, [reOrderedList]);
  const sortableConfig = {
    isSortable: isFilterSortable,
    showReorder: Array.isArray(relations) && relations?.length > 0,
    onToggleSortable: setIsFilterSortable,
    onOrderChange: setReOrderList,
    onOrderSave: onRelationOrderSave,
    btnTitle: { toggleOff: 'Reorder', toggleOn: 'Save Order', isLoading },
  };
  // sortable drag & drop configs end

  return (
    <>
      {showEdit ? (
        <EditRelation
          relationData={selectedRelation.current}
          navigateBack={navigateBack}
          templateIdMap={templateIdMapMemo}
          templateOptions={templateOptionsMemo}
          offerIdMap={offerIdMapMemo}
          offerOptionsByTemplateId={offerOptionsMemo}
          onSaveRelation={onSaveRelation}
          isRelationLoading={isRelationLoading}
          currentTemplate={
            selectedRelation.current?.template_id_left
              ? templateIdMapMemo[selectedRelation.current?.template_id_left]
              : currentTemplate
          }
          hierarchies={hierarchies}
        />
      ) : null}

      {!showEdit ? (
        <Box py="15px" data-testid="RelationContainer">
          <TableViewWithSearch
            placeholder="Search"
            buttonText="Create New Relation"
            sortableConfig={sortableConfig}
            buttonVariant="solid"
            onCreateNew={() => {
              onCreateOrEdit(null);
            }}
            title="Relations"
            columns={columns({
              onEdit: onCreateOrEdit,
              onDelete,
              templateIdMap: templateIdMapMemo,
              offerIdMap: offerIdMapMemo,
              currentTemplateId,
              navigate,
            })}
            tableData={relations}
          />
        </Box>
      ) : null}
    </>
  );
};

export default Relations;
