import _, { isEmpty } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { FieldTypes } from '../../utils/enums/types';
import axiosInstance from '../../utils/axios/axios';
import { getQueueListForWholeTenant, getTitlesHierarchy } from '../dynamicExpressionWithTags/dynamicExpressionWithTags.helper';
import { FIELD_VS_INPUT_TYPE } from '../../utils/enums/labels';
import { getWholeOption } from '../../pages/TicketTemplateWorkflow/helper';
import {
  ruleCheckForBooleanOption,
  ruleCheckForSelectOptionV2,
  ruleCheckForTextNumberOption,
  ruleCheckForTextOptionV2,
} from '../../utils/enums/selectOption';
import { selectOptionsChannels } from '../../pages/DynamicRenderer/customTemplateModal/CustomTemplateModalLayout';
import { DROPDOWN_TYPES } from '../../pages/TenantFilters/filters.constants';
import { removeDuplicateItem } from '../../utils/helper/roleBasedAccess';

export const getDynamicValuesFromCurl = async (curl) => {
  try {
    const response = await axiosInstance.post('/v1/curl/execute', {
      data: {
        curl,
      },
    });
    return response?.data;
  } catch (error) {
    console.error('failed', error);
    return [];
  }
};

export const getTemplateById = async (id) => {
  const response = await axiosInstance.get(
    `/v1/templates/list?$top=1&$skip=0&$select=name,id,fields,workflow&$filter=id eq ${id}`
  );
  if (response.status === 200) {
    return response.data;
  }
  return [];
};

export const getTemplateFields = async (id) => {
  const response = await getTemplateById(id);
  return response?.rows[0]?.fields;
};

export const transformFieldIntoOptions = (fields, omitFields) => {
  const transformedFields = fields?.map((field) => {
    if (
      field?.type === FieldTypes.MEDIA ||
      omitFields.findIndex((x) => x === field?.type) > -1
    ) {
      return null;
    }

    if (!field) {
      return null;
    }

    if (omitFields.includes(field?.id)) {
      return null;
    }

    return { value: field.id, label: field.label };
  });
  return transformedFields?.filter((x) => x);
};

export const getSelectedField = (fields, expression) => {
  const field = fields?.find((f) => f?.id === expression?.leftOperand?.value);
  return field;
};

export const transformValuesIntoOptions = async (
  fields,
  expression,
  workflowData,
  templateHierarchy,
  fieldHierarchyMap) => {
  const field = getSelectedField(fields, expression);
  let values;
  if (field?.type === FieldTypes.DROPDOWN || field?.type === FieldTypes.TAGS) {
    const collectionType = _.get(
      field,
      'type_based_attributes.collection.collection_type'
    );
    const specialFieldType = _.get(
      field,
      'type_based_attributes.collection.specialField'
    );
    if (collectionType === 'DYNAMIC') {
      values = _.get(field, 'type_based_attributes.collection.dynamicCurl'); // curlKey name is change
      return getDynamicValuesFromCurl(values) || [];
      // return [
      //   { label: 'A', value: 'a' },
      //   { label: 'B', value: 'b' },
      // ];
    }
    if (collectionType === 'STATIC') {
      values = _.get(field, 'type_based_attributes.collection.staticValues');
      if (typeof values === 'string') {
        values = values?.split(/\r?\n|\r|\n/g);
        const options = values?.map((v) => {
          if (!v) return null;
          return { value: v, label: v };
        });
        return options?.filter((x) => x);
      }
      return values?.filter((x) => x.value && x.label) || [];
    }
    if (collectionType === 'SPECIAL_FIELD' && specialFieldType === 'queue') {
      const listFilter = _.get(field, 'type_based_attributes.collection.list');
      const isWhiteList = _.get(
        field,
        'type_based_attributes.collection.isWhiteList'
      );
      return getQueueListForWholeTenant(listFilter, isWhiteList);
    }
    if (
      collectionType === 'SPECIAL_FIELD' &&
      specialFieldType === 'workflowState'
    ) {
      return workflowData?.workFlowStates || [];
    }
    if (
      collectionType === 'SPECIAL_FIELD' &&
      specialFieldType === 'workflowCategory'
    ) {
      return workflowData?.workflowCategories || [];
    }
    if (collectionType === 'SPECIAL_FIELD' && specialFieldType === 'source') {
      return selectOptionsChannels;
    }
    if (collectionType === DROPDOWN_TYPES.DEPENDENT) {
      const titles = getTitlesHierarchy(templateHierarchy?.hierarchy || [], fieldHierarchyMap);
      if (titles[field.id]) {
        const result = [...(titles[field.id] || [])].map((item) => ({ value: item, label: item }));
        return removeDuplicateItem(result);
      }
      return [];
    }
    if (collectionType === 'SPECIAL_FIELD' && specialFieldType === 'actor') {
      return [{ value: 'amlan', label: 'Amlan' }];
    }
  }
  return [];
};

export const isEmptyNotEmptyOperator = (value) => {
  if (value === 'is empty' || value === 'is not empty') return true;
  return false;
};

export const getTypeOfField = (field, operand) => {
  if (!field) return FIELD_VS_INPUT_TYPE.TEXT;

  const collectionType = _.get(
    field,
    'type_based_attributes.collection.collection_type'
  );
  const specialFieldType = _.get(
    field,
    'type_based_attributes.collection.specialField'
  );
  if (collectionType === 'STATIC' || collectionType === 'DYNAMIC') {
    return FIELD_VS_INPUT_TYPE[field.type];
  }

  if (collectionType === 'SPECIAL_FIELD') {
    if (specialFieldType === 'source' || specialFieldType === 'queue' || specialFieldType === 'workflowState') {
      if (specialFieldType === 'queue' && operand === 'has') {
        return FIELD_VS_INPUT_TYPE.ACTOR;
      }
      return FIELD_VS_INPUT_TYPE[field.type];
    }

    if (specialFieldType === 'actor') {
      return FIELD_VS_INPUT_TYPE.TEXT;
    }
  }

  return FIELD_VS_INPUT_TYPE[field.type];
};

export const parserForOldComponentToNewConditionComponent = (values, fieldList) => {
  const fieldListOption = fieldList
    .map((item) => ({ value: item.id, label: item.label, type: item.type }));
  const operatorOptionList =
  [...ruleCheckForTextOptionV2,
    ...ruleCheckForBooleanOption,
    ...ruleCheckForSelectOptionV2,
    ...ruleCheckForTextNumberOption];
  const updateData = values.map((item) => {
    const leftOperand = getWholeOption(item.leftOperand, fieldListOption);
    const operator = getWholeOption(item.operator, operatorOptionList, 'label');
    let rightOperand = '';
    if (item.labelOfValue.length === item.rightOperand.length &&
      !isEmpty(item.labelOfValue) && !isEmpty(item.rightOperand) &&
      leftOperand.type === FieldTypes.DROPDOWN
    ) {
      rightOperand = item.rightOperand
        .map((val, index) => ({ value: val, label: item.labelOfValue[index] }));
    } else {
      rightOperand = item.rightOperand;
    }
    delete leftOperand.type;
    return { id: item.id, leftOperand, operator, rightOperand };
  });
  return [{
    or: [
      ...updateData,
    ],
    id: uuidv4(),
  }];
};
