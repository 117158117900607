/* eslint-disable no-tabs */
/* eslint-disable no-shadow */
// /* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Flex,
  Text,
  IconButton,
  useColorModeValue,
  Box,
} from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import _cloneDeep from 'lodash/cloneDeep';
import styles from './expression-styles.module.scss';
import { FIELD_VS_INPUT_TYPE } from '../../utils/enums/labels';
import {
  ruleCheckForBooleanOption,
  ruleCheckForDateOptions,
  ruleCheckForSelectOptionV2,
  ruleCheckForTextNumberOption,
  ruleCheckForTextOptionV2,
  ruleCheckForActorOption,
  roleSettingSelectOpt,
  ruleCheckForActorOptionNoTemplate,
  countOption,
} from '../../utils/enums/selectOption';
import { FieldTypes } from '../../utils/enums/types';
import {
  transformFieldIntoOptions,
  getSelectedField,
  transformValuesIntoOptions,
  isEmptyNotEmptyOperator,
  getTypeOfField,
} from './dynamicExpressionWithTags.helper';

import { LIGHT, DARK, COLORS } from '../../utils/enums/colors';
import CustomSelectBox from '../SelectBox/Select';
import CustomField from './CustomField';
import { availableUsersListInTenantLevel } from '../../utils/helper/roleBasedAccess';
import DynamicMutliSelectWithInputTags from '../dynamicSelectWithInputTags/DynamicSelectWithInputTags';
import { BASE_SETTINGS_KEYS } from '../../pages/TicketTemplateBuilder/utils/constants';

// const DATE_REGEX = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;

const DynamicExpression = ({
  containerLabel,
  omitFields,
  showRightOperand,
  fields,
  orBlock,
  andContainerId,
  orContainerId,
  andArray,
  setAndArray,
  workflowData,
  templateId,
  templateHierarchy,
  fieldHierarchyMap,
  orBlockList,
  actors,
}) => {
  const getNonMatchingElements = (fieldList, orExpressionList) => {
    // Extract the leftOperand values from arr2
    const leftOperandValues = orExpressionList.map(
      (item) => item.leftOperand.value
    );

    // Filter arr1 to include only those elements whose id is not in leftOperandValues
    return fieldList.filter((item) => !leftOperandValues.includes(item.value));
  };

  const [leftOperandOptions, setLeftOperandOptions] = useState();
  const [rightOperandOptions, setRightOperandOptions] = useState([]);
  const [operatorOptions, setOperatorOptions] = useState();
  const [tenantUsers, setTenantUsers] = useState([]);

  const handleChange = (value, andId, orId) => {
    const andBlock = andArray.find((item) => item.id === andId);
    const orBlock = andBlock.or.find((item) => item.id === orId);

    const newOrBlock = {
      ...orBlock,
      leftOperand: value?.leftOperand,
      operator: value?.operator,
      rightOperand: value?.rightOperand,
    };

    const newAndBlock = {
      ...andBlock,
      or: andBlock.or.map((item) => {
        if (item.id === orId) {
          return newOrBlock;
        }
        return item;
      }),
    };

    setAndArray((prevAndArray) =>
      prevAndArray.map((item) => {
        if (item.id === andId) {
          return newAndBlock;
        }
        return item;
      })
    );
  };

  const removeExpression = (andId, orId) => {
    const andBlock = andArray.find((item) => item.id === andId);
    // const currentOrBlock = andBlock.or.find((item) => item.id === orId);

    if (andArray?.length === 1 && andBlock.or.length === 1) {
      const newOrBlock = {
        ...orBlock,
        leftOperand: '',
        operator: '',
        rightOperand: '',
      };
      const newAndBlock = {
        ...andBlock,
        or: andBlock.or.map((item) => {
          if (item.id === orId) {
            return newOrBlock;
          }
          return item;
        }),
      };

      setAndArray((prevAndArray) =>
        prevAndArray.map((item) => {
          if (item.id === andId) {
            return newAndBlock;
          }
          return item;
        })
      );
      return;
    }
    const orBlockArray = andBlock.or.filter((item) => item.id !== orId);

    const newAndBlock = {
      ...andBlock,
      or: orBlockArray,
    };

    setAndArray((prevAndArray) =>
      prevAndArray
        .map((item) => {
          if (item.id === andId) {
            return newAndBlock;
          }
          return item;
        })
        .filter((item) => item.or.length > 0)
    );
  };

  const selectedField = getSelectedField(fields, orBlock);
  const updatedLeftOperand = {
    ...orBlock.leftOperand,
    label: selectedField?.label || '',
  };
  const { type: selectedFieldType } = selectedField || {};

  useEffect(() => {
    const andBlock = andArray.find((item) => item.id === andContainerId);
    const orBlock = andBlock.or.find((item) => item.id === orContainerId);
    if (selectedFieldType) {
      if (
        selectedFieldType === FieldTypes.TEXT ||
        selectedFieldType === FieldTypes.EMAIL ||
        selectedFieldType === FieldTypes.LOOKUP ||
        selectedFieldType === FieldTypes.RICH_TEXT ||
        selectedFieldType === FieldTypes.TEXT_AREA
      ) {
        setOperatorOptions(ruleCheckForTextOptionV2);
      } else if (selectedFieldType === 'COUNT') {
        setOperatorOptions(countOption);
      } else if (selectedFieldType === FieldTypes.NUMBER) {
        setOperatorOptions(ruleCheckForTextNumberOption);
      } else if (
        selectedFieldType === FieldTypes.DROPDOWN ||
        selectedFieldType === FieldTypes.BOOLEAN ||
        selectedFieldType === FieldTypes.TAGS
      ) {
        if (orBlock?.operator?.value) {
          if (orBlock?.operator?.value === 'has') {
            setRightOperandOptions([{ value: 'currentUser', label: 'Current User' }]);
          } else {
            transformValuesIntoOptions(
              fields,
              orBlock,
              workflowData,
              templateHierarchy,
              fieldHierarchyMap
            ).then((options) => {
              if (_isEmpty(options)) setRightOperandOptions([]);
              else setRightOperandOptions(options);
            });
          }
        }

        const specialFieldType = _get(
          selectedField,
          'type_based_attributes.collection.specialField'
        );

        if (selectedFieldType === FieldTypes.BOOLEAN) {
          setOperatorOptions(ruleCheckForBooleanOption);
        } else if (
          selectedFieldType === FieldTypes.DROPDOWN &&
          specialFieldType === 'actor'
        ) {
          setOperatorOptions(
            templateId
              ? ruleCheckForActorOption
              : ruleCheckForActorOptionNoTemplate
          );
        } else if (
          selectedFieldType === FieldTypes.DROPDOWN &&
          specialFieldType === 'queue') {
          setOperatorOptions([...ruleCheckForSelectOptionV2, { value: 'has', label: 'HAS' }]);
        } else {
          setOperatorOptions(ruleCheckForSelectOptionV2);
        }
      } else if (selectedFieldType === FieldTypes.DATE) {
        setOperatorOptions(ruleCheckForDateOptions);
      }
    }
  }, [orBlock?.leftOperand, orBlock?.operator?.value, fieldHierarchyMap]);

  useEffect(() => {
    setLeftOperandOptions(
      getNonMatchingElements(
        transformFieldIntoOptions(fields, omitFields),
        orBlockList
      )
    );
  }, [fields, omitFields, orBlockList, fieldHierarchyMap]);

  useEffect(() => {
    const users = availableUsersListInTenantLevel();
    if (users) {
      setTenantUsers(users);
    }
  }, []);

  const onChangeRightOperand = (value) => {
    const updatedExpression = {
      ...orBlock,
      rightOperand: Array.isArray(value) ? value : [value],
    };

    handleChange(updatedExpression, andContainerId, orContainerId);
  };

  const shouldRenderRightOperand = () => {
    if (showRightOperand) {
      return (
        !isEmptyNotEmptyOperator(orBlock?.operator?.value) &&
        selectedFieldType !== FieldTypes.BOOLEAN
      );
    }

    return showRightOperand;
  };

  const renderDateField = (isAllowTimeField) => {
    if (!orBlock?.leftOperand || !shouldRenderRightOperand()) {
      return null;
    }

    if (orBlock?.operator?.value === 'between') {
      return (
        <>
          <Box flexGrow={3}>
            <CustomField
              value={_get(orBlock, `rightOperand[0]`) || ''}
              type={FIELD_VS_INPUT_TYPE[FieldTypes.DATE]}
              onChange={(value) => {
                const updatedExpression = _cloneDeep(orBlock);

                const rightOperand = updatedExpression.rightOperand || [];
                rightOperand[0] = value;
                updatedExpression.rightOperand = rightOperand;
                handleChange(updatedExpression, andContainerId, orContainerId);
              }}
              showTime={isAllowTimeField}
            />
          </Box>
          <Box flexGrow={3} mt="8px">
            <CustomField
              value={_get(orBlock, `rightOperand[1]`) || ''}
              type={FIELD_VS_INPUT_TYPE[FieldTypes.DATE]}
              onChange={(value) => {
                const updatedExpression = _cloneDeep(orBlock);
                const rightOperand = updatedExpression.rightOperand || [];
                rightOperand[1] = value;
                updatedExpression.rightOperand = rightOperand;
                handleChange(updatedExpression, andContainerId, orContainerId);
              }}
              options={rightOperandOptions}
              showTime={isAllowTimeField}
            />
          </Box>
        </>
      );
    }

    if (orBlock?.operator?.value) {
      return (
        <Box flexGrow={3}>
          <CustomField
            value={_get(orBlock, `rightOperand[0]`) || ''}
            type={FIELD_VS_INPUT_TYPE[FieldTypes.DATE]}
            onChange={(value) => {
              const updatedExpression = _cloneDeep(orBlock);
              const rightOperand = updatedExpression.rightOperand || [];
              rightOperand[0] = value;
              updatedExpression.rightOperand = rightOperand;
              handleChange(updatedExpression, andContainerId, orContainerId);
            }}
            showTime={isAllowTimeField}
          />
        </Box>
      );
    }

    return null;
  };

  const rightOperandValues = useMemo(() => {
    const values = [...tenantUsers];
    if (orBlock?.operator?.value === 'is') {
      values.push({
        label: 'Current User',
        value: 'currentUser',
      });
    }
    return values;
  }, [tenantUsers, actors, orBlock]);

  const renderActorField = () => {
    if (
      orBlock?.leftOperand &&
      orBlock?.operator &&
      shouldRenderRightOperand()
    ) {
      if (orBlock?.operator?.value === 'is') {
        return (
          <Box flexGrow={3}>
            <CustomField
              testingId="custom-field"
              height="32px"
              value={orBlock?.rightOperand}
              type={FIELD_VS_INPUT_TYPE.ACTOR}
              onChange={onChangeRightOperand}
              options={rightOperandValues}
              placeholder="Value"
            />
          </Box>
        );
      }

      if (templateId) {
        return (
          <DynamicMutliSelectWithInputTags
            values={orBlock?.rightOperand[0]}
            onChange={onChangeRightOperand}
            selectOptions={roleSettingSelectOpt}
            showOnlyUserAttributes
          />
        );
      }

      return null;
    }

    return null;
  };
  const renderCountField = () => {
    if (
      orBlock?.leftOperand &&
      orBlock?.operator &&
      shouldRenderRightOperand()
    ) {
      if (orBlock?.operator?.value !== 'all') {
        return (
          <Box flexGrow={3} mt="5px">
            <CustomField
              testingId="custom-field"
              height="32px"
              value={orBlock?.rightOperand}
              type={FIELD_VS_INPUT_TYPE.NUMBER}
              onChange={onChangeRightOperand}
              // options={tenantUsers}
              placeholder="Value"
            />
          </Box>
        );
      }
      return null;
    }

    return null;
  };
  const renderOtherFields = () => {
    if (
      orBlock?.leftOperand &&
      orBlock?.operator &&
      shouldRenderRightOperand()
    ) {
      return (
        <Box flexGrow={3}>
          <CustomField
            testingId="custom-field"
            height="32px"
            value={orBlock?.rightOperand}
            type={getTypeOfField(selectedField, orBlock?.operator?.value)}
            onChange={onChangeRightOperand}
            options={rightOperandOptions}
            placeholder="Value"
          />
        </Box>
      );
    }

    return null;
  };

  const renderRightOperand = () => {
    if (selectedFieldType === FieldTypes.DATE) {
      const isAllowTimeField =
        selectedField[BASE_SETTINGS_KEYS.IS_ALLOW_TIME_FIELD] || false;

      return renderDateField(isAllowTimeField);
    }
    if (selectedFieldType === 'COUNT') {
      return renderCountField();
    }
    if (
      selectedFieldType === FieldTypes.DROPDOWN &&
      _get(selectedField, 'type_based_attributes.collection.specialField') ===
        'actor'
    ) {
      return renderActorField();
    }

    return renderOtherFields();
  };

  return (
    <Flex
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      gap="12px"
      marginTop="9px"
      data-id="dynamic-expression-container"
    >
      <Text
        // flex={1}
        className={styles.dynamic__expression__container__row__label}
        ml={containerLabel?.length === 0 ? '16px' : '0px'}
        color={useColorModeValue(LIGHT, DARK)}
      >
        {containerLabel}
      </Text>
      <Flex
        width="100%"
        direction="row"
        flexWrap="wrap"
        alignItems="center"
        gap={2}
      >
        {/* Field/Left Operand Renderer */}
        <Box flexGrow={4}>
          <CustomSelectBox
            id="left-operand-custom-select"
            options={leftOperandOptions}
            value={updatedLeftOperand}
            onChange={(value) => {
              const updatedExpression = {
                ...orBlock,
                leftOperand: value,
                operator: '',
                rightOperand: '',
              };
              handleChange(updatedExpression, andContainerId, orContainerId);
            }}
            placeholder="Select fields"
            // styles={customStylesSelectWithHeight32}
          />
        </Box>
        <Box height="8px" width="8px" />

        {/* Operator Renderer */}
        <Box flexGrow={2}>
          <CustomSelectBox
            id="operator-custom-select"
            height="32px"
            options={operatorOptions}
            value={orBlock?.operator}
            onChange={(value) => {
              const updatedExpression = {
                ...orBlock,
                operator: value,
                rightOperand: '',
              };
              handleChange(updatedExpression, andContainerId, orContainerId);
            }}
            placeholder=""
            // styles={customStylesSelectWithWidth114}
          />
        </Box>

        {/* Right Operand Renderer */}
        <Box width="100%">{renderRightOperand()}</Box>
      </Flex>

      <IconButton
        data-testid="remove-condition-button"
        onClick={() => {
          removeExpression(andContainerId, orContainerId);
        }}
        _hover={{
          backgroundColor: useColorModeValue(COLORS.LIGHT, COLORS.DARK),
        }}
        background="transparent"
        border={useColorModeValue(
          `1px solid ${COLORS.LIGHT_BORDER}`,
          `1px solid ${COLORS.RED}`
        )}
        icon={<DeleteIcon color={COLORS.DELETE_STATUS} />}
        className="mb-2"
      />
    </Flex>
  );
};

DynamicExpression.propTypes = {
  omitFields: PropTypes.array,
  andArray: PropTypes.array.isRequired,
  fields: PropTypes.array.isRequired,
  showRightOperand: PropTypes.bool,
  containerLabel: PropTypes.string,
  orBlock: PropTypes.array.isRequired,
  andContainerId: PropTypes.string.isRequired,
  orContainerId: PropTypes.string.isRequired,
  setAndArray: PropTypes.func.isRequired,
  workflowData: PropTypes.object,
  templateId: PropTypes.string,
  templateHierarchy: PropTypes.object,
  fieldHierarchyMap: PropTypes.object,
  orBlockList: PropTypes.array,
  actors: PropTypes.array,
};

DynamicExpression.defaultProps = {
  omitFields: [],
  showRightOperand: true,
  containerLabel: '',
  workflowData: {},
  templateId: '',
  templateHierarchy: {},
  fieldHierarchyMap: {},
  orBlockList: [],
  actors: [],
};

export default DynamicExpression;
