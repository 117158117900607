import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  Flex,
  Grid,
  GridItem,
  IconButton,
  Radio,
  RadioGroup,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { ArrowBackIcon, DeleteIcon } from '@chakra-ui/icons';
import { isEmpty } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { LABELS, SPECIAL_FIELDS_DROPDOWN } from '../../../utils/enums/labels';
import { COLORS } from '../../../utils/enums/colors';
import CustomButton from '../../../components/Button/SimpleButton';
import CustomSelectBox from '../../../components/SelectBox/Select';
import CirclePlusIcon from '../../../assets/icons/circlePlusIcon.svg';
import CustomInput from '../../../components/InputBox/Input';
import useFetchTemplate from './helper';
import Loader from '../../DynamicRenderer/Dashboard/Loader';
import {
  COLUMN_TYPE_OPTION,
  META_FIELD_KEY,
  createMetaFieldPayload,
  createMetaFieldValidation,
  createOrUpdateMetaFields,
  findMatchingObjectsForMetaField,
  removeDuplicateElement,
  removeMatchingOption,
  updateMetaFieldValidation,
} from './utils';
import {
  getStatusObject,
  processErrors,
} from '../../DynamicRenderer/AddEditSignatureDrawer/utils';
import CustomToast from '../../../components/Notification/Notification';
import {
  updateMetaFieldList,
  updateMultipleStatesOfMetaFieldList,
} from '../../../redux/MetaFieldsSlice/MetaFieldsReducer';
import radioStyles from '../../TicketTemplateBuilder/CreateEditTemplate/createtemplate.module.scss';
import { getWholeOption } from '../../TicketTemplateWorkflow/helper';
import { DROPDOWN_TYPES } from '../../TenantFilters/filters.constants';
import {
  PrimaryCustomButton,
  SecondaryCustomButton,
} from '../../../components/Button/PrimarySecondaryButton';
import axiosInstance from '../../../utils/axios/axios';
import { ASSOCIATION_ALLOWED_IDS, PRE_FIELDS_NO_ASSOCIATED } from '../../../redux/MetaFieldsSlice/MetaFieldsService';

const LinkedMetaFieldDrawer = ({
  selectedMetaFieldValue,
  openDrawer,
  setOpenDrawer,
  isCreateMetaField,
  isEditMetaFieldName,
}) => {
  const { addToast } = CustomToast();
  const [isLoading, setIsLoading] = useState(false);
  const [payload, setPayload] = useState(null);
  const [currentSelectedMetaFieldValue, setCurrentSelectedMetaFieldValue] =
    useState({ ...selectedMetaFieldValue });
  const fieldData = currentSelectedMetaFieldValue?.field_data || [];
  const lastField = fieldData[fieldData.length - 1];
  const isLastFieldIdPresent = lastField && lastField?.field_id?.trim() !== '';
  const [selectedTemplateAndFieldList, setSelectedTemplateFieldList] = useState(
    []
  );
  const [allHierarchyList, setAllHierarchyList] = useState([]);

  const fetchHierarchyList = async () => {
    const res = await axiosInstance.get('/hierarchies/list', {
      params: {
        $select: '*',
      },
    });
    return res?.data?.rows || [];
  };

  const {
    loading,
    fieldMapByTemplateId,
    templateOptionList,
    errorFromApi,
    slaMapByTemplateId,
    metricMapByTemplateId,
    hierarchyIdWithItem,
  } = useFetchTemplate();

  const [errorKeyList, setErrorKeyList] = useState({});
  const { metaFieldList, allMetaColumnAttributes } =
    useSelector((state) => state.metaFields);
  const dispatch = useDispatch();

  useEffect(() => {
    const defaultValue = {
      [META_FIELD_KEY.TEMPLATE_ID]: '',
      [META_FIELD_KEY.FIELD_ID]: '',
      [META_FIELD_KEY.META_UUID_COLUMN]: '',
    };
    let data = currentSelectedMetaFieldValue[META_FIELD_KEY.FIELD_DATA] || [];
    data = Array.isArray(data) ? [...data] : [];

    if (!data.length) {
      data.push(defaultValue);
    }
    setSelectedTemplateFieldList(data);
  }, [currentSelectedMetaFieldValue?.[META_FIELD_KEY.FIELD_DATA]]);

  useEffect(() => {
    const getData = async () => {
      const data = await fetchHierarchyList();
      const optionList = data.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setAllHierarchyList(optionList);
    };

    getData();
  }, []);

  const onDeleteSelectedTemplateAndFieldList = (index) => {
    let newStaticValue = [...selectedTemplateAndFieldList];
    if (newStaticValue.length === 1) {
      newStaticValue = [
        {
          [META_FIELD_KEY.TEMPLATE_ID]: '',
          [META_FIELD_KEY.FIELD_ID]: '',
          [META_FIELD_KEY.META_UUID_COLUMN]: '',
        },
      ];
    } else {
      newStaticValue.splice(index, 1);
    }
    setSelectedTemplateFieldList(newStaticValue);
    setCurrentSelectedMetaFieldValue((pre) => ({
      ...pre,
      [META_FIELD_KEY.FIELD_DATA]: newStaticValue,
    }));
  };

  const onAddSelectedMetaFieldValue = () => {
    // const IS_VALID_FIELD_LIST = selectedTemplateAndFieldList.every(
    //   (item) => item.template_id && item.field_id
    // );
    // if (!IS_VALID_FIELD_LIST) {
    //   const addmore = false;
    //   return;
    // }
    const defaultValue = {
      [META_FIELD_KEY.TEMPLATE_ID]: '',
      [META_FIELD_KEY.FIELD_ID]: '',
      [META_FIELD_KEY.META_UUID_COLUMN]: '',
    };
    const tempData = [...selectedTemplateAndFieldList, defaultValue];
    setSelectedTemplateFieldList(tempData);
    setCurrentSelectedMetaFieldValue((pre) => ({
      ...pre,
      [META_FIELD_KEY.FIELD_DATA]: tempData,
    }));
  };

  const onChangeSelectBox = (value, index) => {
    const DATA_STATE = [...selectedTemplateAndFieldList].map(
      (item, currentIndex) => {
        if (index === currentIndex) {
          return {
            ...item,
            [META_FIELD_KEY.FIELD_ID]: value,
          };
        }
        return item;
      }
    );
    setSelectedTemplateFieldList(DATA_STATE);
    setCurrentSelectedMetaFieldValue((pre) => ({
      ...pre,
      [META_FIELD_KEY.FIELD_DATA]: DATA_STATE,
    }));
    setErrorKeyList((pre) => ({ ...pre, [META_FIELD_KEY.FIELD_DATA]: false }));
  };
  const onChangeSelectBoxForTemplate = (valueOne, valueTwo, index) => {
    const DATA_STATE = [...selectedTemplateAndFieldList].map(
      (item, currentIndex) => {
        if (index === currentIndex) {
          return {
            [META_FIELD_KEY.TEMPLATE_ID]: valueOne,
            [META_FIELD_KEY.META_UUID_COLUMN]: valueTwo,
            [META_FIELD_KEY.FIELD_ID]: '',
          };
        }
        return item;
      }
    );
    setSelectedTemplateFieldList(DATA_STATE);
    setCurrentSelectedMetaFieldValue((pre) => ({
      ...pre,
      [META_FIELD_KEY.FIELD_DATA]: DATA_STATE,
    }));
    setErrorKeyList((pre) => ({ ...pre, [META_FIELD_KEY.FIELD_DATA]: false }));
  };
  const createNewMetaFields = async (createMFieldPayload) => {
    try {
      const result = findMatchingObjectsForMetaField(metaFieldList, createMFieldPayload);
      const res = await createOrUpdateMetaFields(
        createMFieldPayload,
        'Failed to create a new meta field'
      );

      if (res.error) {
        throw new Error(res.error);
      }

      const data = [];
      await Promise.all(
        result.map(async (item) => {
          try {
            const resForUpdate = await createOrUpdateMetaFields(
              item,
              'Failed to create a new meta field'
            );
            if (!resForUpdate.error) {
              data.push(resForUpdate.response);
            }
          } catch (error) {
            console.error(error);
            // Handle error if needed
          }
        })
      );

      const finalData = removeDuplicateElement([...data, ...metaFieldList]);
      const newMetaField = {
        meta_field_id: createMFieldPayload.meta_field_id,
        meta_field_name: createMFieldPayload.name,
        attributes: isEmpty(createMFieldPayload.attributes)
          ? {
            movable: true,
            searchable: true,
            sortable: true,
          }
          : createMFieldPayload.attributes,
        id: res.response.id,
        colSpan: createMFieldPayload.colSpan || 1,
        label: createMFieldPayload.name,
      };

      dispatch(updateMetaFieldList([...finalData, res.response]));
      dispatch(
        updateMultipleStatesOfMetaFieldList([
          {
            stateKey: 'allMetaColumnAttributes',
            value: [...allMetaColumnAttributes, newMetaField],
          },
          // {
          //   stateKey: 'metaColumnAttributes',
          //   value: [...metaColumnAttributes, newMetaField],
          // },
        ])
      );

      addToast({
        title: 'Success',
        message: 'Successfully created a new meta field',
        type: 'success',
      });
    } catch (error) {
      addToast({
        title: 'Something went wrong',
        message: error?.message || 'services does not have',
        type: 'error',
      });
    }
  };

  const updateMetaField = async (updateMFieldPayload) => {
    const selectedId = updateMFieldPayload[META_FIELD_KEY.META_FIELD_ID];
    const errorMessage = 'Failed to update the meta field';
    // payload[META_FIELD_KEY.FIELD_DATA]?.length === 0
    //   ? 'Failed to unlink the meta field'
    //   : 'Failed to update the meta field';
    const successMessage = 'Successfully updated the meta field';
    // payload[META_FIELD_KEY.FIELD_DATA]?.length === 0
    //   ? 'Successfully unlinked the meta field'
    //   : 'Successfully updated the meta field';
    const editedMetaFieldIndex = metaFieldList.findIndex(
      (field) => field[META_FIELD_KEY.META_FIELD_ID] === selectedId
    );

    if (editedMetaFieldIndex !== -1) {
      const res = await createOrUpdateMetaFields(updateMFieldPayload, errorMessage);
      if (!res.error) {
        const updatedFields = [...metaFieldList];
        updatedFields[editedMetaFieldIndex] = res.response;
        const removedSelected = [...updatedFields];
        removedSelected.splice(editedMetaFieldIndex, 1);
        const result = findMatchingObjectsForMetaField(
          removedSelected,
          updateMFieldPayload
        );
        const data = [];
        await Promise.all(
          result.map(async (item) => {
            try {
              const resForUpdate = await createOrUpdateMetaFields(
                item,
                'Failed to create a new meta field'
              );
              if (!resForUpdate.error) {
                data.push(resForUpdate.response);
              }
            } catch (error) {
              console.error(error);
              // Handle error if needed
            }
          })
        );
        dispatch(
          updateMetaFieldList([
            ...removeDuplicateElement([...data, ...updatedFields]),
          ])
        );
        addToast({
          title: 'Success',
          message: successMessage,
          type: 'success',
        });
      } else {
        addToast({
          title: 'Something went wrong',
          message: res.error,
          type: 'error',
        });
      }
    }
  };
  const showLabelForField = (index) => {
    if (index === 0) {
      if (
        currentSelectedMetaFieldValue[META_FIELD_KEY.META_FIELD_TYPE] === 'SLA'
      ) {
        return 'SLA';
      }
      return 'Field';
    }
    return '';
  };

  const getFieldValueByMetaFieldType = useMemo(
    () => (item) => {
      const metaFieldType =
        currentSelectedMetaFieldValue[META_FIELD_KEY.META_FIELD_TYPE];

      const metaFieldSpecial =
        currentSelectedMetaFieldValue[
          META_FIELD_KEY.META_FIELD_SPECIAL_FIELD_TYPE
        ];
      const templateId = item[META_FIELD_KEY.TEMPLATE_ID];
      let fieldValue;
      const level =
        currentSelectedMetaFieldValue[
          META_FIELD_KEY.META_FIELD_LEVEL_OF_FIELD_TYPE
        ];

      if (metaFieldType === 'METRICS') {
        fieldValue = metricMapByTemplateId?.[templateId];
      } else if (metaFieldType === 'SLA') {
        fieldValue = slaMapByTemplateId?.[templateId];
      } else if (metaFieldType === 'DROPDOWN' && metaFieldSpecial) {
        fieldValue = fieldMapByTemplateId?.[templateId]?.[metaFieldSpecial];
      } else if (
        currentSelectedMetaFieldValue[META_FIELD_KEY.META_FIELD_SUB_TYPE] ===
          'DEPENDENT' &&
        templateId
      ) {
        fieldValue =
          hierarchyIdWithItem.dependentFieldMapWithItem[
            parseInt(templateId, 10)
          ];
      } else {
        fieldValue = fieldMapByTemplateId?.[templateId]?.[metaFieldType];
      }
      if (
        hierarchyIdWithItem.dependentFieldMapWithItem[
          parseInt(templateId, 10)
        ] &&
        level
      ) {
        const { dependentFieldMapWithItem } = hierarchyIdWithItem;
        const option =
          dependentFieldMapWithItem[parseInt(templateId, 10)] || [];
        fieldValue = option[parseInt(level, 10) - 1]
          ? [option[parseInt(level, 10) - 1]]
          : [];
      }
      return fieldValue || [];
    },
    [
      currentSelectedMetaFieldValue,
      metricMapByTemplateId,
      slaMapByTemplateId,
      fieldMapByTemplateId,
      hierarchyIdWithItem.dependentFieldMapWithItem,
    ]
  );

  const getDisabledForItemType = () => {
    if (
      currentSelectedMetaFieldValue[META_FIELD_KEY.META_FIELD_SUB_TYPE] ===
      DROPDOWN_TYPES.SPECIAL_FIELD
    ) {
      return isEmpty(
        currentSelectedMetaFieldValue[
          META_FIELD_KEY.META_FIELD_SPECIAL_FIELD_TYPE
        ]
      );
    }
    return isEmpty(
      currentSelectedMetaFieldValue[META_FIELD_KEY.META_FIELD_TYPE]
    );
  };
  // const getDisabledForColumnType = () => {
  //   const indexValue = metaFieldList.findIndex(
  //     (item) => item.id === currentSelectedMetaFieldValue?.id
  //   );
  //   if (indexValue !== -1 && !isEmpty(metaFieldList[indexValue].field_data)) {
  //     return true;
  //   }
  //   return false;
  // };
  const handleAddClick = () => {
    setIsLoading(true);
    const noAssociatedFieldArray = [...PRE_FIELDS_NO_ASSOCIATED];
    const isNotAssociated = noAssociatedFieldArray.some(
      (field) => field.meta_field_id === currentSelectedMetaFieldValue.meta_field_id
    );
    const newPayload = createMetaFieldPayload(currentSelectedMetaFieldValue);
    if (isNotAssociated) {
      delete newPayload.id;
    }
    setPayload(newPayload);
  };

  const disablePreDefaultFields = () => {
    const noAssociatedFieldArray = [...PRE_FIELDS_NO_ASSOCIATED];
    const isNotAssociated = noAssociatedFieldArray.some(
      (field) => field.meta_field_id === currentSelectedMetaFieldValue.meta_field_id
    );
    return isNotAssociated;
  };

  useEffect(() => {
    if (payload) {
      const isAlreadyPresentNameList = metaFieldList?.map((item) => item?.name);
      let validationError;
      const data = { ...payload };
      delete data.id;

      if (isCreateMetaField) {
        const { error } = createMetaFieldValidation(
          isAlreadyPresentNameList
        ).validate(data, {
          abortEarly: false,
        });
        validationError = error;
      } else {
        const { error } = updateMetaFieldValidation.validate(payload, {
          abortEarly: false,
        });
        validationError = error;
      }

      if (!isEmpty(validationError)) {
        const tempError = processErrors(validationError.details);
        setErrorKeyList(tempError);
        setIsLoading(false);
        return;
      }

      if (isCreateMetaField) {
        createNewMetaFields(data);
      } else {
        const hardCodedMetaFieldIds = Object.keys(ASSOCIATION_ALLOWED_IDS);
        updateMetaField({
          ...payload,
          id: hardCodedMetaFieldIds.includes(payload.id) ? undefined : payload.id,
        });
      }
      setTimeout(() => {
        setOpenDrawer(false);
        setIsLoading(false);
      }, 1000);
    }
  }, [payload]);

  return (
    <Drawer
      size="lg"
      placement="right"
      onClose={setOpenDrawer}
      isOpen={openDrawer}
      closeOnOverlayClick={false}
    >
      <DrawerContent>
        <DrawerHeader>
          <Flex alignItems="center" gap="10px">
            <IconButton
              colorScheme="blue"
              aria-label="Close Drawer"
              size="sm"
              borderRadius="50%"
              minW="1.5rem"
              height="1.5rem"
              variant="outline"
              icon={<ArrowBackIcon />}
              onClick={() => setOpenDrawer(false)}
            />
            <Text fontSize="16px">
              {isCreateMetaField ? 'Add Meta Field' : 'Link Meta Field'}
            </Text>
          </Flex>
        </DrawerHeader>
        <DrawerBody>
          {loading && <Loader />}
          {!loading && !errorFromApi && (
            <>
              {isCreateMetaField || isEditMetaFieldName || disablePreDefaultFields() ? (
                <CustomInput
                  onChange={(e) => {
                    setCurrentSelectedMetaFieldValue((pre) => ({
                      ...pre,
                      [META_FIELD_KEY.NAME]: e.target.value,
                    }));
                    setErrorKeyList((pre) => ({
                      ...pre,
                      [META_FIELD_KEY.NAME]: false,
                    }));
                  }}
                  value={currentSelectedMetaFieldValue?.[META_FIELD_KEY.NAME]}
                  placeholder=""
                  isError={errorKeyList[META_FIELD_KEY.NAME] || false}
                  helperText=" "
                  label="Meta Field Name"
                  isMandatory
                  errorMessage={
                    currentSelectedMetaFieldValue[META_FIELD_KEY.NAME]
                      ? 'Meta field name already exists'
                      : 'Meta field is required'
                  }
                  data-testid="linkedMetaFieldDrawerMetaFieldName"
                />
              ) : (
                <>
                  <Text className="SCLabel">Meta Field Name</Text>
                  <Text
                    style={{
                      fontWeight: 'bold',
                      fontSize: '15px',
                      lineHeight: '19px',
                      marginTop: '10px',
                      marginBottom: '20px',
                    }}
                  >
                    {currentSelectedMetaFieldValue?.name}
                  </Text>
                </>
              )}
              <Box mb="10px">
                <CustomSelectBox
                  options={COLUMN_TYPE_OPTION}
                  value={getStatusObject(
                    currentSelectedMetaFieldValue?.[
                      META_FIELD_KEY.META_FIELD_TYPE
                    ],
                    COLUMN_TYPE_OPTION
                  )}
                  onChange={(e) => {
                    const defaultValue = {
                      [META_FIELD_KEY.TEMPLATE_ID]: '',
                      [META_FIELD_KEY.FIELD_ID]: '',
                      [META_FIELD_KEY.META_UUID_COLUMN]: '',
                    };
                    const tempData = [defaultValue];
                    setSelectedTemplateFieldList(tempData);
                    setCurrentSelectedMetaFieldValue((pre) => ({
                      ...pre,
                      [META_FIELD_KEY.META_FIELD_TYPE]: e.value,
                      [META_FIELD_KEY.META_FIELD_SUB_TYPE]:
                        e.value === 'DROPDOWN' ? DROPDOWN_TYPES.STATIC : null,
                      [META_FIELD_KEY.FIELD_DATA]: tempData,
                    }));
                    setErrorKeyList((pre) => ({
                      ...pre,
                      [META_FIELD_KEY.FIELD_DATA]: false,
                    }));
                  }}
                  label={
                    <>
                      Column Type
{' '}
<span style={{ color: '#E53E3E' }}>*</span>
                    </>
                  }
                  isError={
                    errorKeyList[META_FIELD_KEY.META_FIELD_TYPE] || false
                  }
                  errorMessage={
                    !currentSelectedMetaFieldValue[
                      META_FIELD_KEY.META_FIELD_TYPE
                    ]
                      ? 'Column Type is required'
                      : ''
                  }
                  aria-label="ColumnType"
                  isDisabled={disablePreDefaultFields()}
                  // isDisabled={getDisabledForColumnType()}
                />
              </Box>
              {currentSelectedMetaFieldValue?.[
                META_FIELD_KEY.META_FIELD_TYPE
              ] === 'DROPDOWN' && (
                <Grid>
                  <Text
                    pb="10px"
                    className="SCLabel"
                    data-testid="TicktFieeldRow$Scope"
                  >
                    Sub Type
                  </Text>
                  <RadioGroup
                    className={radioStyles.radiobutton}
                    isDisabled={disablePreDefaultFields()}
                    value={
                      currentSelectedMetaFieldValue[
                        META_FIELD_KEY.META_FIELD_SUB_TYPE
                      ] || DROPDOWN_TYPES.STATIC
                    }
                    onChange={(e) => {
                      const defaultValue = {
                        [META_FIELD_KEY.TEMPLATE_ID]: '',
                        [META_FIELD_KEY.FIELD_ID]: '',
                        [META_FIELD_KEY.META_UUID_COLUMN]: '',
                      };
                      const tempData = [defaultValue];
                      setSelectedTemplateFieldList(tempData);
                      setCurrentSelectedMetaFieldValue((pre) => ({
                        ...pre,
                        [META_FIELD_KEY.META_FIELD_SUB_TYPE]: e,
                        [META_FIELD_KEY.META_FIELD_SPECIAL_FIELD_TYPE]: null,
                        [META_FIELD_KEY.FIELD_DATA]: tempData,
                      }));
                      setErrorKeyList((pre) => ({
                        ...pre,
                        [META_FIELD_KEY.FIELD_DATA]: false,
                      }));
                    }}
                    data-testid="TicketFieldContentScopeRadioGrp"
                    mt="10px"
                    mb="10px"
                    // isDisabled={getDisabledForColumnType()}
                  >
                    <Radio
                      value="STATIC"
                      data-testid="TicketFieldScopeRadioInfo"
                      mr="20px"
                    >
                      STATIC
                    </Radio>
                    <Radio
                      value="SPECIAL_FIELD"
                      data-testid="TicketFieldScopeRadioInfo"
                      mr="20px"
                    >
                      SPECIAL FIELD
                    </Radio>
                    <Radio
                      value="DEPENDENT"
                      data-testid="TicketFieldScopeRadioInfo"
                    >
                      DEPENDENT
                    </Radio>
                  </RadioGroup>
                </Grid>
              )}

              {currentSelectedMetaFieldValue?.[
                META_FIELD_KEY.META_FIELD_TYPE
              ] === 'DROPDOWN' &&
                currentSelectedMetaFieldValue[
                  META_FIELD_KEY.META_FIELD_SUB_TYPE
                ] === DROPDOWN_TYPES.SPECIAL_FIELD && (
                  <CustomSelectBox
                    options={SPECIAL_FIELDS_DROPDOWN}
                    label={LABELS.FIELD_PROPERTIES.SPECIAL_FIELD_LABEL}
                    isMandatory
                    placeholder="Select special field"
                    value={getWholeOption(
                      currentSelectedMetaFieldValue[
                        META_FIELD_KEY.META_FIELD_SPECIAL_FIELD_TYPE
                      ],
                      SPECIAL_FIELDS_DROPDOWN
                    )}
                    onChange={(obj) => {
                      const defaultValue = {
                        [META_FIELD_KEY.TEMPLATE_ID]: '',
                        [META_FIELD_KEY.FIELD_ID]: '',
                        [META_FIELD_KEY.META_UUID_COLUMN]: '',
                      };
                      const tempData = [defaultValue];
                      setSelectedTemplateFieldList(tempData);
                      setCurrentSelectedMetaFieldValue((pre) => ({
                        ...pre,
                        [META_FIELD_KEY.META_FIELD_SPECIAL_FIELD_TYPE]:
                          obj.value,
                        [META_FIELD_KEY.FIELD_DATA]: tempData,
                      }));
                      setErrorKeyList((pre) => ({
                        ...pre,
                        [META_FIELD_KEY.FIELD_DATA]: false,
                        [META_FIELD_KEY.META_FIELD_SPECIAL_FIELD_TYPE]: false,
                      }));
                    }}
                    isError={
                      errorKeyList[META_FIELD_KEY.META_FIELD_SPECIAL_FIELD_TYPE]
                    }
                    errorMessage="Special field is required"
                    isDisabled={disablePreDefaultFields()}
                  />
              )}
              {/* Dependent field  start */}
              {currentSelectedMetaFieldValue?.[
                META_FIELD_KEY.META_FIELD_TYPE
              ] === 'DROPDOWN' &&
                currentSelectedMetaFieldValue[
                  META_FIELD_KEY.META_FIELD_SUB_TYPE
                ] === DROPDOWN_TYPES.DEPENDENT && (
                  <>
                    <CustomSelectBox
                      options={allHierarchyList}
                      label={LABELS.FIELD_PROPERTIES.HIERARCHY}
                      isMandatory
                      placeholder="Select Hierarchy"
                      value={getWholeOption(
                        currentSelectedMetaFieldValue[
                          META_FIELD_KEY.META_FIELD_HIERARCHY_ID
                        ],
                        allHierarchyList
                      )}
                      onChange={(obj) => {
                        const defaultValue = {
                          [META_FIELD_KEY.TEMPLATE_ID]: '',
                          [META_FIELD_KEY.FIELD_ID]: '',
                          [META_FIELD_KEY.META_UUID_COLUMN]: '',
                        };
                        const tempData = [defaultValue];
                        setSelectedTemplateFieldList(tempData);
                        setCurrentSelectedMetaFieldValue((pre) => ({
                          ...pre,
                          [META_FIELD_KEY.META_FIELD_HIERARCHY_ID]: obj.value,
                          [META_FIELD_KEY.FIELD_DATA]: tempData,
                        }));
                        setErrorKeyList((pre) => ({
                          ...pre,
                          [META_FIELD_KEY.FIELD_DATA]: false,
                          [META_FIELD_KEY.META_FIELD_HIERARCHY_ID]: false,
                        }));
                      }}
                      isError={
                        errorKeyList[META_FIELD_KEY.META_FIELD_HIERARCHY_ID]
                      }
                      errorMessage="Hierarchy is required"
                      isDisabled={disablePreDefaultFields()}
                    />
                    <Box mt="10px">
                      <CustomInput
                        isMandatory
                        value={
                          currentSelectedMetaFieldValue[
                            META_FIELD_KEY.META_FIELD_LEVEL_OF_FIELD_TYPE
                          ]
                        }
                        label="Select Level"
                        onChange={(obj) => {
                          const defaultValue = {
                            [META_FIELD_KEY.TEMPLATE_ID]: '',
                            [META_FIELD_KEY.FIELD_ID]: '',
                            [META_FIELD_KEY.META_UUID_COLUMN]: '',
                          };
                          const tempData = [defaultValue];
                          setSelectedTemplateFieldList(tempData);
                          setCurrentSelectedMetaFieldValue((pre) => ({
                            ...pre,
                            [META_FIELD_KEY.META_FIELD_LEVEL_OF_FIELD_TYPE]:
                              obj.target.value,
                            [META_FIELD_KEY.FIELD_DATA]: tempData,
                          }));
                          setErrorKeyList((pre) => ({
                            ...pre,
                            [META_FIELD_KEY.FIELD_DATA]: false,
                            [META_FIELD_KEY.META_FIELD_LEVEL_OF_FIELD_TYPE]: false,
                          }));
                        }}
                        isError={
                          errorKeyList[
                            META_FIELD_KEY.META_FIELD_LEVEL_OF_FIELD_TYPE
                          ]
                        }
                        errorMessage="Level should be positive number"
                        isDisabled={disablePreDefaultFields()}
                      />
                    </Box>
                  </>
              )}
              {/* Dependent field end */}

              <Text className="SCLabel" mt="10px">
                Select Fields
              </Text>
              {selectedTemplateAndFieldList?.length > 0 ? (
                <Box data-testid="template-field-list">
                  {selectedTemplateAndFieldList?.map((item, index) => (
                    <Grid
                      display="flex"
                      gap="30px"
                      templateColumns="5.5fr 5.5fr 1fr"
                      key={item[META_FIELD_KEY.META_FIELD_ID]}
                    >
                      <GridItem pt="30px" w="100%">
                        <CustomSelectBox
                          options={removeMatchingOption(
                            currentSelectedMetaFieldValue[
                              META_FIELD_KEY.META_FIELD_SUB_TYPE
                            ] === DROPDOWN_TYPES.DEPENDENT &&
                              currentSelectedMetaFieldValue[
                                META_FIELD_KEY.META_FIELD_HIERARCHY_ID
                              ]
                              ? hierarchyIdWithItem.hierarchyMapWithItem[
                                currentSelectedMetaFieldValue[
                                  META_FIELD_KEY.META_FIELD_HIERARCHY_ID
                                ]
                              ]
                              : templateOptionList,
                            selectedTemplateAndFieldList
                          )}
                          value={getStatusObject(
                            item?.[META_FIELD_KEY.TEMPLATE_ID],
                            currentSelectedMetaFieldValue[
                              META_FIELD_KEY.META_FIELD_SUB_TYPE
                            ] === DROPDOWN_TYPES.DEPENDENT &&
                              currentSelectedMetaFieldValue[
                                META_FIELD_KEY.META_FIELD_HIERARCHY_ID
                              ]
                              ? hierarchyIdWithItem.hierarchyMapWithItem[
                                currentSelectedMetaFieldValue[
                                  META_FIELD_KEY.META_FIELD_HIERARCHY_ID
                                ]
                              ]
                              : templateOptionList
                          )}
                          onChange={(e) => {
                            onChangeSelectBoxForTemplate(
                              e.value,
                              e.uuidColumn,
                              index
                            );
                          }}
                          label={index === 0 ? <span>Ticket Type</span> : ''}
                          isError={
                            errorKeyList[META_FIELD_KEY.FIELD_DATA] &&
                            !item?.[META_FIELD_KEY.TEMPLATE_ID]
                          }
                          errorMessage={
                            currentSelectedMetaFieldValue?.[
                              META_FIELD_KEY.FIELD_DATA
                            ]?.length > 0
                              ? 'Ticket type is required'
                              : ''
                          }
                          aria-label="Ticket Type"
                          isDisabled={disablePreDefaultFields()
                            ? disablePreDefaultFields() : getDisabledForItemType()
                          }
                        />
                      </GridItem>

                      <GridItem pt="30px" w="100%">
                        <CustomSelectBox
                          options={getFieldValueByMetaFieldType(item)}
                          value={getStatusObject(
                            item?.[META_FIELD_KEY.FIELD_ID],
                            getFieldValueByMetaFieldType(item)
                          )}
                          onChange={(e) => {
                            onChangeSelectBox(e.value, index);
                          }}
                          label={
                            index === 0 && (
                              <span>{showLabelForField(index)}</span>
                            )
                          }
                          isError={
                            errorKeyList[META_FIELD_KEY.FIELD_DATA] &&
                            !item?.[META_FIELD_KEY.FIELD_ID]
                          }
                          errorMessage={
                            currentSelectedMetaFieldValue[
                              META_FIELD_KEY.META_FIELD_TYPE
                            ] === 'SLA'
                              ? 'SLA is required'
                              : 'Field is required'
                          }
                          aria-label="Field"
                          isDisabled={disablePreDefaultFields()
                            ? disablePreDefaultFields() : getDisabledForItemType()
                          }
                        />
                      </GridItem>
                      <GridItem
                        pt={index === 0 ? '60px' : '30px'}
                        alignSelf="center"
                      >
                        <IconButton
                          aria-label="Delete"
                          icon={<DeleteIcon color="red" />}
                          variant="ghost"
                          _hover={{ bg: 'transparent' }}
                          _active={{ bg: 'transparent' }}
                          isDisabled={disablePreDefaultFields()}
                          onClick={() => onDeleteSelectedTemplateAndFieldList(index)}
                          data-testid="linkedMetaFieldDrawerDeleteFromMetaFieldData"
                          size="md"
                        />
                      </GridItem>
                    </Grid>
                  ))}
                </Box>
              ) : null}
              {isLastFieldIdPresent && (
                <Flex justifyContent="flex-end" pt="15px">
                  <CustomButton
                    style={{ color: COLORS.LIGHT, border: 'none' }}
                    variant="outline"
                    buttonText="Add More Ticket Type"
                    svg={CirclePlusIcon}
                    svghover={CirclePlusIcon}
                    _hover={{
                      color: useColorModeValue(COLORS.LIGHT, COLORS.LIGHT),
                    }}
                    onClick={onAddSelectedMetaFieldValue}
                    data-testid="linkedMetaFieldDrawerMetaAddMoreField"
                    isDisabled={disablePreDefaultFields()}
                  />
                </Flex>
              )}
            </>
          )}
        </DrawerBody>
        <DrawerFooter>
          <Flex gap={3}>
            <SecondaryCustomButton
              buttonText={LABELS.ACTIONS.DISCARD}
              onClick={() => {
                setOpenDrawer(false);
              }}
            />
            <PrimaryCustomButton
              buttonText={LABELS.ACTIONS.ADD}
              onClick={handleAddClick}
              isLoading={isLoading}
              data-testid="linkedMetaFieldButtonAdd"
            />
          </Flex>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

LinkedMetaFieldDrawer.propTypes = {
  selectedMetaFieldValue: PropTypes.object,
  openDrawer: PropTypes.bool.isRequired,
  setOpenDrawer: PropTypes.func.isRequired,
  isCreateMetaField: PropTypes.bool,
  isEditMetaFieldName: PropTypes.bool,
};
LinkedMetaFieldDrawer.defaultProps = {
  selectedMetaFieldValue: {},
  isCreateMetaField: true,
  isEditMetaFieldName: true,
};
export default LinkedMetaFieldDrawer;
