/* eslint-disable react/prop-types */
import React from 'react';
import { Box, Flex, Text, useColorModeValue } from '@chakra-ui/react';
import { COLORS } from '../../../../utils/enums/colors';
import CustomInput from '../../../../components/InputBox/Input';
import { SLA_COLOR_MAP, SLA_FORM_KEY_MAP } from '../SLA/utils';
import CustomSelectBox from '../../../../components/SelectBox/Select';
import { customStyles } from '../Metrics/utils';
import { renderTagsCategory2 } from '../Tags/TagBuilder';
import { SecondaryCustomButton } from '../../../../components/Button/PrimarySecondaryButton';

const testNumber = (val) => !val || /^\d+$/.test(val);

const SlaOutcomeAndReminder = ({
  slaData,
  setErrorData,
  handleErrorData,
  handleChange,
  getBackGroundColor,
  addOutCome,
  errorData,
  deleteTags,
}) => (
  <Box pt="20px">
    <Box data-testid="EditSlaModalOutcomeContainerBox">
      <Text
        className="SCLabel"
        data-testid="EditSlaModalOucomeTextP"
        color={COLORS.DEFAULT}
        paddingBottom="9px"
      >
        OUTCOME
      </Text>
      <Flex
        alignItems="flex-end"
        gap="10px"
        flexWrap="wrap"
        data-testid="EditSlaModalOutcomeInnerFlex"
      >
        <Box w="90px" data-testid="EditSlaModalOutcomeInnerBox">
          <CustomInput
            label="Stages"
            value={slaData?.[SLA_FORM_KEY_MAP.STAGES] || ''}
            rightElement="%"
            onChange={(e) => {
              if (testNumber(e.target.value)) {
                const { value } = e.target;

                if (value && (value <= 0 || value > 100)) {
                  setErrorData((prev) => ({
                    ...prev,
                    [SLA_FORM_KEY_MAP.OUTCOME]:
                      'Stages should be greater than 0 & less than 100',
                  }));
                } else {
                  handleErrorData(SLA_FORM_KEY_MAP.OUTCOME, value);
                }

                handleChange(SLA_FORM_KEY_MAP.STAGES, value);
              }
            }}
            id="EditSlaModalStagesDivCInput"
          />
        </Box>
        <Box data-testid="EditSlaModalCSColorBox">
          <CustomSelectBox
            label="Color"
            options={SLA_COLOR_MAP}
            styles={customStyles()}
            value={slaData?.[SLA_FORM_KEY_MAP.COLOR] || ''}
            onChange={(e) => {
              handleChange(SLA_FORM_KEY_MAP.COLOR, e);
              setErrorData((prev) => ({
                ...prev,
                [SLA_FORM_KEY_MAP.OUTCOME]: '',
              }));
            }}
            id="EditSlaModal2SelectBox"
          />
        </Box>
        <Box>
          <div
            style={{
              background: getBackGroundColor(
                slaData?.[SLA_FORM_KEY_MAP.COLOR]?.value
              ),
              width: 38,
              height: 38,
              border: '1px solid rgb(226, 232, 240)',
              borderRadius: 5,
            }}
          />
        </Box>
        <Box data-testid="EditSlaModalCILabelBox" w="170px">
          <CustomInput
            label="Label"
            value={slaData?.[SLA_FORM_KEY_MAP.LABEL] || ''}
            onChange={(e) => {
              handleChange(SLA_FORM_KEY_MAP.LABEL, e.target.value);
            }}
            id="EditSlaModalLAbelDivCInput"
          />
        </Box>
        <SecondaryCustomButton
          buttonText="Add"
          onClick={addOutCome}
          id="EditSlaModalCILabelBoxaddBtn"
          isDisabled={
            !slaData?.[SLA_FORM_KEY_MAP.STAGES] ||
            !slaData?.[SLA_FORM_KEY_MAP.COLOR]?.value ||
            !slaData?.[SLA_FORM_KEY_MAP.LABEL]
          }
        />
      </Flex>

      {errorData?.[SLA_FORM_KEY_MAP.OUTCOME] ? (
        <span className="field-error">
          {errorData?.[SLA_FORM_KEY_MAP.OUTCOME]}
        </span>
      ) : null}

      <Box mt="20px">
        {renderTagsCategory2({
          key: SLA_FORM_KEY_MAP.OUTCOME,
          data: slaData,
          title: 'Outcome',
          deleteTags,
          renderTagLabel: (data) => `
                  ${data[SLA_FORM_KEY_MAP.STAGES]}%, 
                  ${data[SLA_FORM_KEY_MAP.COLOR]}, 
                  ${data[SLA_FORM_KEY_MAP.LABEL]}
                `,
        })}
      </Box>
    </Box>
  </Box>
);
export default SlaOutcomeAndReminder;
