import React from 'react';
import { Input ,Textarea,Select} from "@chakra-ui/react"
import { deleteRenderer } from "../../../DynamicRenderer/MetricsAndSLA/Metrics/utils"
import CustomSelectBox from "../../../../components/SelectBox/Select"
export const BOT_IDENTICATION_FIELDS_TRIGGERS=({onEditDescription,onDelete,onChangeInformationRetrievalPreference,onChangeCrossReferencedFields,fields})=>[
  {
    name: 'Field',
    label: 'Field',
    type: 'string',
    cellRenderer: ({ rowData }) => <Input value={rowData?.label} disabled={true} /> ,
  },
  {
    name: 'Type',
    label: 'Type',
    type: 'string',
    cellRenderer: ({ rowData }) => <Input value={rowData?.type} disabled={true} />,
  },
  {
    name: 'Category',
    label: 'Category',
    type: 'string',
    cellRenderer: ({ rowData }) => (
        <Input value={rowData?.type_based_attributes?.required ? "Required" : "Optional"} disabled={true} />
    ),
    },
    {
        name: 'Information Retrieval Preference',
        label: 'Information Retrieval Preference',
        type: 'string',
        cellRenderer: ({ rowData }) => {
            const handleChange = (event) => {
                onChangeInformationRetrievalPreference(rowData,event.target.value)
            }
            return(
                <Select defaultValue={rowData?.informationRetrievalPreference || "May Ask Leading Questions"} onChange={handleChange} >
                    <option value='May Ask Leading Questions' >May Ask Leading Questions</option>
                    <option value='Fetch From Conversation' >Fetch From Conversation</option>
                </Select>
            )
        },
  },
  {
    name: 'Description for Bot',
    label: 'Description for Bot',
    type: 'string',
    cellRenderer: ({ rowData }) =>{
      const handleBlur = (event) => {
        const updatedDescription = event.target.value;
        if (updatedDescription !== rowData.botDescription) {
          onEditDescription(rowData, updatedDescription);
        }
      };
            return(
             <Textarea resize="none" overflowY="auto" placeholder="Enter description" defaultValue={rowData.botDescription ? rowData.botDescription : "" }  onBlur={handleBlur}/>
            )
    },
  },
  {
    name: 'Action',
    label: 'Action',
    type: 'string',
    cellRenderer: ({ rowData }) =>(
      !rowData?.type_based_attributes?.required ?
        <div>
          {deleteRenderer({
            title: 'Delete this field',
            description: 'Are you sure you want to delete this field?',
            onConfirm: () => {
              onDelete(rowData);
            },
            dataTestId: `Fields-${rowData.name}-delete`,
          })}
        </div>
            : 
            <div>--</div>
      ),
  },
];
