import React from 'react';
import { Box, Checkbox, Text, useColorModeValue } from '@chakra-ui/react';
import { COLORS } from '../../../utils/enums/colors';
import CustomButton from '../../../components/Button/SimpleButton';
import CustomSelectBox from '../../../components/SelectBox/Select';
import { getWholeOption, getWholeOptionHTML } from '../../TicketTemplateWorkflow/helper';
import QuillEditor from '../../../components/QuillEditor';
import {
  EMAIL_QUILL_TOOLBAR,
  EMAIL_TEMPLATE_KEY_MAP,
  parseAPIToCustomMention,
  parseAPIToRichTextMention,
  SEND_AS_OPTIONS,
} from '../../CommunicationTemplates/utills';
import CustomMention from '../../../components/Mention';
import { getStatusObject } from '../../DynamicRenderer/AddEditSignatureDrawer/utils';
import HTMLPreviewModal from '../../../components/PreviewModal/HTMLPreviewModal';
import { NOTES, convertMentionToHTML } from '../../../components/Mention/helpers';
import CustomInput from '../../../components/InputBox/Input';

const renderEmailForm = (
  actionToPerform,
  handleChange,
  handleChanges,
  actionToPerformSelectedIndex,
  handleActionErrorData,
  actionErrorData,
  getDataForEmail,
  openPreviewModal,
  isModalOpen,
  closePreviewModal,
  mentionObj,
  linkedRelationItemId,
  selectedTemplate,
  actorFieldOptions,
  queueFieldOptions
) => {
  const options = [
    { label: 'Actor Name', value: 'actor' },
    { label: 'Queue Name', value: 'queue' },
    { label: 'Custom', value: 'custom' },
  ];
  const fallbackOptions = [
    { label: 'Queue Name', value: 'queue' },
    { label: 'Custom', value: 'custom' },
  ];
  return (
    <>
      <Box marginTop="10px">
        <Checkbox
          isChecked={actionToPerform?.from?.aliasIsChecked}
          onChange={() => {
            handleChange(
              !actionToPerform?.from?.aliasIsChecked,
              `action_to_perform[${actionToPerformSelectedIndex}].from.aliasIsChecked`
            );
            handleActionErrorData('from.aliasIsChecked');
          }}
          id="DynamicRenderer6DivCInput"
        >
          <Text fontSize={'14px !important'} fontWeight={'500 !important'} lineHeight={'20px !important'}>{'Add Alias'}</Text>
        </Checkbox>
      </Box>
      {/* Alias Selection */}
      {actionToPerform?.from?.aliasIsChecked && (
        <CustomSelectBox
          label="From Alias"
          options={options}
          value={getWholeOption(actionToPerform?.from?.alias, options)}
          onChange={(e) => {
            handleChange(
              e.value,
              `action_to_perform[${actionToPerformSelectedIndex}].from.alias`,
            );
            handleActionErrorData('from.alias');
          }}
          isError={!!actionErrorData?.from}
          errorMessage={actionErrorData?.from || ''}
          isMandatory={false}
          id="DynamicRenderer6DivCInput"
          linkedItemId={linkedRelationItemId}
        />
      )}

      {/* Actor Name Alias Selection */}
      {actionToPerform?.from?.alias === 'actor' && actionToPerform?.from?.aliasIsChecked && (
        <Box marginTop="10px" data-testid="DynamicRendererSendMailBoxFromAlias">
          <CustomSelectBox
            label="Select Actor"
            options={actorFieldOptions}
            value={getWholeOption(actionToPerform?.from?.actor, actorFieldOptions)}
            onChange={(e) => {
              handleChange(
                e.value,
                `action_to_perform[${actionToPerformSelectedIndex}].from.actor`,
              );
              handleActionErrorData('from.actor');
            }}
            isError={!!actionErrorData?.from?.actor}
            errorMessage={actionErrorData?.from?.actor || ''}
            isMandatory={false}
            id="DynamicRenderer6DivCInput"
            linkedItemId={linkedRelationItemId}
          />

          {/* fallback Alias Checkbox */}
          {actionToPerform?.from?.alias === 'actor' && actionToPerform?.from?.aliasIsChecked && (
            <Checkbox
              marginTop="10px"
              isChecked={actionToPerform?.from?.fallbackAliasIsChecked}
              onChange={() => {
                handleChange(
                  !actionToPerform?.from?.fallbackAliasIsChecked,
                  `action_to_perform[${actionToPerformSelectedIndex}].from.fallbackAliasIsChecked`
                );
                handleActionErrorData('from.fallbackAliasIsChecked');
              }}
              id="DynamicRenderer6DivCInput"
            >
              <Text fontSize={'14px !important'} fontWeight={'500 !important'} lineHeight={'20px !important'}>
                {'Fallback alias if mapped actor field is blank'}
              </Text>
            </Checkbox>
          )}
          {/* fallback Alias Selection */}
          {actionToPerform?.from?.fallbackAliasIsChecked && actionToPerform?.from?.aliasIsChecked && (
            <CustomSelectBox
              label="Fallback Alias"
              options={fallbackOptions}
              value={getWholeOption(actionToPerform?.from?.fallbackAlias, fallbackOptions)}
              onChange={(e) => {
                handleChange(
                  e.value,
                  `action_to_perform[${actionToPerformSelectedIndex}].from.fallbackAlias`
                );
                handleActionErrorData('from.fallbackAlias');
              }}
              isError={!!actionErrorData?.from?.fallbackAlias}
              errorMessage={actionErrorData?.from?.fallbackAlias || ''}
              isMandatory={false}
              id="DynamicRenderer6DivCInput"
              linkedItemId={linkedRelationItemId}
            />
          )}
        </Box>
      )}

      {/* Queue Name Alias Selection */}
      {actionToPerform?.from?.alias === 'queue' && actionToPerform?.from?.aliasIsChecked && (
        <Box marginTop="10px" data-testid="DynamicRendererSendMailBoxFromAlias">
          <CustomSelectBox
            label="Select Queue"
            options={queueFieldOptions}
            value={getWholeOption(actionToPerform?.from?.queue, queueFieldOptions)}
            onChange={(e) => {
              handleChange(e.value, `action_to_perform[${actionToPerformSelectedIndex}].from.queue`);
              handleActionErrorData('from.queue');
            }}
            isError={!!actionErrorData?.from?.queue}
            errorMessage={actionErrorData?.from?.queue || ''}
            isMandatory={false}
            id="DynamicRenderer6DivCInput"
            linkedItemId={linkedRelationItemId}
          />
        </Box>
      )}

      { actionToPerform?.from?.aliasIsChecked && actionToPerform?.from?.fallbackAliasIsChecked && actionToPerform?.from?.fallbackAlias === 'queue' && actionToPerform?.from?.alias === 'actor' && (
        <Box marginTop="10px" data-testid="DynamicRendererSendMailBoxFallbackAlias">
          <CustomSelectBox
            label="Select Queue"
            options={queueFieldOptions}
            value={getWholeOption(actionToPerform?.from?.queue, queueFieldOptions)}
            onChange={(e) => {
              handleChange(e.value, `action_to_perform[${actionToPerformSelectedIndex}].from.queue`);
              handleActionErrorData('from.queue');
            }}
            isError={!!actionErrorData?.from?.queue}
            errorMessage={actionErrorData?.from?.queue || ''}
            isMandatory={false}
            id="DynamicRenderer6DivCInput"
            linkedItemId={linkedRelationItemId}
          />
        </Box>
      )}


      {/* Custom Alias Input */}
      {actionToPerform?.from?.alias === 'custom' && actionToPerform?.from?.aliasIsChecked && (
        <Box marginTop="10px" data-testid="DynamicRendererSendMailBoxFromOthers">
          <CustomInput
            label="Custom"
            value={actionToPerform?.from?.other}
            onChange={(e) => {
              handleChange(
                e.target.value,
                `action_to_perform[${actionToPerformSelectedIndex}].from.other`
              );
              handleActionErrorData('from.other');
            }}
            isError={!!actionErrorData?.from?.other}
            errorMessage={actionErrorData?.from?.other || ''}
            isMandatory={false}
            id="DynamicRenderer6DivCInput"
            linkedItemId={linkedRelationItemId}
          />
        </Box>
      )}
      {actionToPerform?.from?.aliasIsChecked && actionToPerform?.from?.fallbackAlias === 'custom' && actionToPerform?.from?.fallbackAliasIsChecked && (
        <Box marginTop="10px" data-testid="DynamicRendererSendMailBoxFromOthers">
          <CustomInput
            label="Custom"
            value={actionToPerform?.from?.other}
            onChange={(e) => {
              handleChange(
                e.target.value,
                `action_to_perform[${actionToPerformSelectedIndex}].from.other`
              );
              handleActionErrorData('from.other');
            }}
            isError={!!actionErrorData?.from?.other}
            errorMessage={actionErrorData?.from?.other || ''}
            isMandatory={false}
            id="DynamicRenderer6DivCInput"
            linkedItemId={linkedRelationItemId}
          />
        </Box>
      )}
      <Box marginTop="10px" data-testid="DynamicRendererSendMailBox">
        <CustomMention
          label="To"
          value={actionToPerform.to}
          onChange={(e) => {
            handleChange(
              e,
              `action_to_perform[${actionToPerformSelectedIndex}].to`
            );
            handleActionErrorData('to');
          }}
          isError={!!actionErrorData?.to}
          errorMessage={actionErrorData?.to || ''}
          isMandatory={false}
          id="DynamicRenderer6DivCInput"
          linkedItemId={linkedRelationItemId}
          showQueueAndActorEmailOnly
        />
      </Box>
      <Box marginTop="10px" data-testid="DynamicRendererSendMailBoxCc">
        <CustomMention
          label="Cc"
          value={actionToPerform.cc}
          onChange={(e) => {
            handleChange(
              e,
              `action_to_perform[${actionToPerformSelectedIndex}].cc`
            );
            handleActionErrorData('cc');
          }}
          isError={!!actionErrorData?.cc}
          errorMessage={actionErrorData?.cc || ''}
          isMandatory={false}
          id="DynamicRenderer6DivCInput"
          linkedItemId={linkedRelationItemId}
          showQueueAndActorEmailOnly
        />
      </Box>
      <Box marginTop="10px" data-testid="DynamicRendererSendMailBoxBcc">
        <CustomMention
          label="Bcc"
          value={actionToPerform.bcc}
          onChange={(e) => {
            handleChange(
              e,
              `action_to_perform[${actionToPerformSelectedIndex}].bcc`
            );
            handleActionErrorData('bcc');
          }}
          isError={!!actionErrorData?.bcc}
          errorMessage={actionErrorData?.bcc || ''}
          isMandatory={false}
          id="DynamicRenderer6DivCInput"
          linkedItemId={linkedRelationItemId}
          showQueueAndActorEmailOnly
        />
      </Box>
      <Box marginTop="10px" data-testid="DynamicRendererSendMailSujectBox">
        <CustomMention
          label="Subject"
          value={actionToPerform.subject}
          onChange={(e) => {
            handleChange(
              e,
              `action_to_perform[${actionToPerformSelectedIndex}].subject`
            );
            handleActionErrorData('subject');
          }}
          isError={!!actionErrorData?.subject}
          errorMessage={actionErrorData?.subject || ''}
          isMandatory={false}
          id="DynamicRenderer7DivCInput"
          linkedItemId={linkedRelationItemId}
          mentions
        />
      </Box>
      <Box marginTop="10px" data-testid="DynamicRendererSendMailV2Box">
        <CustomSelectBox
          options={getDataForEmail()}
          value={getWholeOption(
            actionToPerform.emailTemplateId,
            getDataForEmail(),
          )}
          onChange={(e) => {
            debugger
            const events = [
              {
                value:
                  e?.send_as === 'html'
                    ? parseAPIToCustomMention(e?.emailBody)
                    : parseAPIToRichTextMention(e?.emailBody),
                path: `action_to_perform[${actionToPerformSelectedIndex}].template`,
              },
              {
                value:e?.value,
                path: `action_to_perform[${actionToPerformSelectedIndex}].emailTemplateId`,
              },
              {
                value:
                  e?.send_as === 'html'
                    ? parseAPIToCustomMention(e?.emailSignature)
                    : parseAPIToRichTextMention(e?.emailSignature),
                path: `action_to_perform[${actionToPerformSelectedIndex}].signature`,
              },
              {
                value: e?.subject,
                path: `action_to_perform[${actionToPerformSelectedIndex}].subject`,
              },
              {
                value: e?.send_as,
                path: `action_to_perform[${actionToPerformSelectedIndex}].send_as`,
              },
            ];
            handleChanges(events);
            handleActionErrorData('template');
          }}
          label="Email Template"
          isClearable={true} // Enable the clear option here
          isError={false}
          aria-label="emailTemplate"
        />
      </Box>
      <Box mt="10px">
        <CustomSelectBox
          value={getStatusObject(
            actionToPerform[EMAIL_TEMPLATE_KEY_MAP.SEND_AS] || 'text',
            SEND_AS_OPTIONS
          )}
          options={SEND_AS_OPTIONS}
          onChange={(e) => {
            handleChange(
              e.value,
              `action_to_perform[${actionToPerformSelectedIndex}].send_as`
            );
            handleActionErrorData('send_as');
          }}
          label="Send as"
          aria-label="send-as"
          isDisabled={actionToPerform.template || actionToPerform.signature}
        />
      </Box>
      {actionToPerform.send_as === 'html' ? (
        <Box mt="20px" w="100%">
          <CustomMention
            label="Email body"
            value={actionToPerform.template || ''}
            onChange={(value) => {
              handleChange(
                value,
                `action_to_perform[${actionToPerformSelectedIndex}].template`
              );
              handleActionErrorData('template');
            }}
            placeholder="Email Body"
            height="260px"
            showHardCodMention={[NOTES]}
            isMulti
            multiLine
          />
          {actionErrorData?.template ? (
            <span className="field-error">{actionErrorData?.template}</span>
          ) : null}
        </Box>
      ) : (
        <Box
          marginTop="10px"
          data-testid="DynamicRendererSendMailV2TextAreaBox"
        >
          <QuillEditor
            editorvalue={actionToPerform.template}
            toolBarModules={EMAIL_QUILL_TOOLBAR}
            format=""
            customEditorContainerStyles={{ height: '120px' }}
            handleOnChange={(value) => {
              handleChange(
                value,
                `action_to_perform[${actionToPerformSelectedIndex}].template`
              );
              handleActionErrorData('template');
            }}
            label="Email Body"
            testId="AddOrEditEmailTemplateDrawerQuillEditor"
            mentionObj={mentionObj}
            showClearButton
          />
          {actionErrorData?.template ? (
            <span className="field-error">{actionErrorData?.template}</span>
          ) : null}
        </Box>
      )}

      <Box marginRight="10px" data-testid="DynamicRendererSendMailBtnBox">
        <CustomButton
          buttonText="Preview"
          variant="outline"
          colorScheme="customBlue"
          style={{
            height: 30,
            marginTop: '8px',
          }}
          color={useColorModeValue(COLORS.LIGHT, COLORS.DARK)}
          onClick={openPreviewModal}
          id="DynamicRendererSendMailPreviewBtnText"
        />
        <HTMLPreviewModal
          open={isModalOpen}
          closePreviewModal={closePreviewModal}
          title="Email Preview"
          content={
            isModalOpen
              ? [
                {
                  label: 'Email Body',
                  html:
                      actionToPerform.send_as === 'html'
                        ? convertMentionToHTML(
                          actionToPerform.template,
                          selectedTemplate
                        )
                        : actionToPerform.template,
                },
                {
                  label: 'Email Signature',
                  html:
                      actionToPerform.send_as === 'html'
                        ? convertMentionToHTML(
                          actionToPerform.signature,
                          selectedTemplate
                        )
                        : actionToPerform.signature,
                },
              ]
              : []
          }
        />
      </Box>
      {actionToPerform.send_as === 'html' ? (
        <Box mt="20px" w="100%">
          <CustomMention
            label="Email Signature"
            value={actionToPerform.signature || ''}
            onChange={(value) => {
              handleChange(
                value,
                `action_to_perform[${actionToPerformSelectedIndex}].signature`
              );
              handleActionErrorData('signature');
            }}
            placeholder="Email Signature"
            height="260px"
            isMulti
            multiLine
          />
        </Box>
      ) : (
        <Box mt="20px" w="100%">
          <QuillEditor
            editorvalue={actionToPerform.signature}
            toolBarModules={EMAIL_QUILL_TOOLBAR}
            format=""
            customEditorContainerStyles={{ height: '120px' }}
            handleOnChange={(value) => {
              handleChange(
                value,
                `action_to_perform[${actionToPerformSelectedIndex}].signature`
              );
              handleActionErrorData('signature');
            }}
            label="Email Signature"
            placeholder="Email Signature"
            testId="AddOrEditEmailTemplateDrawerQuillEditor"
            mentionObj={mentionObj}
            showClearButton
          />
        </Box>
      )}
    </>
  );
};

export default renderEmailForm;
