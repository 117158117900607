/* eslint-disable indent */
import React, { useCallback, useMemo, useState } from 'react';

import {
  Badge,
  Tag,
  TagCloseButton,
  TagLabel,
  useColorModeValue,
} from '@chakra-ui/react';
import { components } from 'react-select';

import { v4 as uuidv4 } from 'uuid';
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';

import CustomSelectBox from '../../../components/SelectBox/Select';
import CustomButton from '../../../components/Button/SimpleButton';
import CustomField from './CustomFields';
import { ACTOROPERATORS, FALLBACK_OPERATORS, OPERATORS_MAP } from '../filters.constants';

import {
  BOOLEAN_OPTIONS,
  addValue,
  associateBy,
  createMap,
  generateOptions,
  getLeftLabel,
  getOperatorIdMapForAllOperators,
  getOperators,
  getRightOperandValue,
  getRightOptionsForSelectedField,
} from './customDynamicSelect.helpers';
import { LIGHT, DARK } from '../../../utils/enums/colors';
import { LABELS } from '../../../utils/enums/labels';

const { Option } = components;

const IconOption = (props) => {
  const { data } = props || {};
  const { label: tempLabel, isDefault } = data || {};

  return (
    <Option {...props}>
      <div className="flex justify-between">
        <div>{tempLabel}</div>
        {isDefault ? (
          <div className="ml-[10px] text-[#ccc] italic">DEFAULT</div>
        ) : null}
      </div>
    </Option>
  );
};

const CustomDynamicSelect = ({
  label,
  onChange,
  fields,
  values,
  isMandatory,
  fromTemplate,
}) => {
  const [currentValue, setCurrentValue] = useState({
    leftOperand: null,
    operator: null,
    rightOperand: null,
  });

  // To create a map for easy referencing the values later
  const optionsIdMap = useMemo(
    () =>
      associateBy({
        options: fields,
        key: 'id',
      }),
    [fields]
  );
  // To get the field type based on the selected left field
  const selectedFieldType = useMemo(() => {
    const id = currentValue?.leftOperand;
    if (id) {
      return optionsIdMap?.[id]?.type || '';
    }
    return '';
  }, [optionsIdMap, currentValue?.leftOperand]);

  // To get the operators
  const operators = useMemo(() => {
    const id = currentValue?.leftOperand;
    const selectedField = optionsIdMap?.[id || ''];

    return getOperators({ field: selectedField, type: selectedFieldType });
  }, [optionsIdMap, currentValue?.leftOperand, selectedFieldType]);

  // To create a map for the operators
  const operatorIdMap = useMemo(() => {
    if (!Array.isArray(fields)) {
      return getOperatorIdMapForAllOperators();
    }

    const temp = [];
    fields.forEach((f) => {
      if (Array.isArray(f?.operators)) {
        temp.push(...f.operators);
      }
    }, []);

    return getOperatorIdMapForAllOperators({ operators: [...temp.flat(), ...FALLBACK_OPERATORS] });
  }, [fields]);

  // Left operand options
  const leftOptions = useMemo(
    () =>
      generateOptions({
        options: fields,
        label: fromTemplate ? 'label' : 'name',
      }),
    [fields]
  );

  // To create id -> left option map
  const leftOptionMap = useMemo(
    () =>
      createMap({
        options: leftOptions,
      }),
    [leftOptions]
  );

  const rightOptionsForSelectedField = useMemo(() => {
    const id = currentValue?.leftOperand;
    if (!id) {
      return null;
    }

    const selectedField = optionsIdMap?.[id];
    return getRightOptionsForSelectedField({
      field: selectedField,
      operator: currentValue?.operator,
    });
  }, [currentValue?.leftOperand, currentValue?.operator, optionsIdMap]);

  // To create id -> name map for all right options
  const rightOptionMap = useMemo(() => {
    const map = {};

    BOOLEAN_OPTIONS.forEach((b) => {
      map[b.value] = b.label;
    });

    if (Array.isArray(fields)) {
      fields.forEach((f) => {
        if (Array.isArray(f?.defaultOptions)) {
          f.defaultOptions.forEach((op) => {
            map[op.value] = op.label;
          });
        }

        const optionMap = f?.optionMap || {};
        if (typeof optionMap === 'object') {
          const opMap = Object.values(optionMap)?.flat();
          if (Array.isArray(opMap)) {
            opMap.forEach((op) => {
              if (op) {
                map[op.value] = op.label;
              }
            });
          }
        }
      });
    }
    return map;
  }, [fields]);

  // Triggered when we change the left operand
  const handleLeftOperandChange = useCallback((e) => {
    const val = e.value;
    setCurrentValue(() => ({
      leftOperand: val,
      operator: null,
      rightOperand: null,
      isSLA: false,
    }));
  }, []);

  // Triggered when we change the operator
  const handleOperatorChange = useCallback((e) => {
    const val = e.value;

    setCurrentValue((prev) => ({
      ...prev,
      operator: val,
    }));
  }, []);

  // Triggered when we change the right operand
  const handleRightOperandChange = useCallback((e) => {
    setCurrentValue((prev) => ({
      ...prev,
      rightOperand: e,
    }));
  }, []);

  const handleSLAChange = () => {
    setCurrentValue((prev) => ({
      ...prev,
      isSLA: !currentValue.isSLA,
    }));
  };

  // When we create a new filter
  const onAdd = useCallback(() => {
    let { rightOperand } = currentValue;
    if (selectedFieldType === 'NUMBER') {
      try {
        rightOperand = parseInt(rightOperand, 10);
      } catch (e) {
        rightOperand = 0;
      }
    }

    const temp = {
      id: uuidv4(),
      op: currentValue.operator,
      column: currentValue.leftOperand,
      operand: rightOperand,
      isSLA: currentValue.isSLA,
    };

    const tempValues = addValue({
      existingValues: values,
      newValue: temp,
      optionsIdMap,
    });
    onChange(tempValues);

    setCurrentValue({
      leftOperand: null,
      operator: null,
      rightOperand: null,
      isSLA: false,
    });
  }, [onChange, currentValue, values, optionsIdMap]);

  // When we delete a filter
  const onDelete = useCallback(
    (id) => {
      const tempValues = Array.isArray(values) ? cloneDeep(values) : [];
      const temp = tempValues.filter((f) => f.id !== id);
      onChange(temp);
    },
    [onChange, values]
  );

  // To get the value of selected right operand
  const getValue = useCallback(
    (val) => {
      const type = optionsIdMap?.[val?.column]?.type;

      return getRightOperandValue({
        value: val?.operand,
        rightOptionMap,
        type,
      });
    },
    [rightOptionMap, optionsIdMap]
  );

  // To get the label of the left operand
  const leftLabel = useCallback(
    (val) =>
      getLeftLabel({
        value: val,
        leftOptionMap,
      }),
    [leftOptionMap]
  );

  const isAddEnabled = useMemo(() => {
    if (currentValue?.leftOperand === 'deleted_seq') {
      setCurrentValue((pre) => ({ ...pre, rightOperand: -1 }));
    }
    if (currentValue?.leftOperand) {
      const val = getValue({
        operand: currentValue?.rightOperand,
        column: currentValue?.leftOperand,
      });

      // If operators are not provided then rightOption needs to be selected
      if (!operators) {
        return !!val;
      }

      // If rightOptions aren't configured, then operator needs to be selected
      if (!rightOptionsForSelectedField) {
        return !!currentValue?.operator;
      }

      return !!val && !!currentValue?.operator;
    }

    return false;
  }, [
    getValue,
    currentValue?.leftOperand,
    currentValue?.operator,
    currentValue?.rightOperand,
    selectedFieldType,
    operators,
    rightOptionsForSelectedField,
  ]);

  const isMulti = useMemo(() => {
    const id = currentValue?.leftOperand;
    const operator = currentValue?.operator;

    const multiOperators = [
      'IN',
      'in',
      'NOT IN',
      'not in',
      'not_in',
      'IS ONE OF',
      'is_one_of',
      'is one of',
    ];

    if (multiOperators.includes(operator)) {
      return true;
    }

    const val = optionsIdMap?.[id]?.isMulti;

    if (typeof val === 'boolean') {
      return val;
    }

    return true;
  }, [optionsIdMap, currentValue?.leftOperand, currentValue?.operator]);

  return (
    <div>
      {label ? (
        <div className="mb-2">
          <span>{label}</span>
          {isMandatory ? (
            <span style={{ color: 'red', marginLeft: '4px' }}> * </span>
          ) : null}
        </div>
      ) : null}

      <div>
        <div>
          <div className="mb-2">
            <CustomSelectBox
              options={leftOptions}
              value={
                currentValue?.leftOperand
                  ? {
                      value: currentValue.leftOperand,
                      label: leftOptionMap[currentValue.leftOperand] || '',
                    }
                  : null
              }
              components={{ Option: IconOption }}
              onChange={handleLeftOperandChange}
            />
          </div>

          {operators ? (
            <div className="mb-2">
              <CustomSelectBox
                options={operators}
                value={
                  currentValue?.operator
                    ? {
                        label: operatorIdMap[currentValue.operator],
                        value: currentValue.operator,
                      }
                    : null
                }
                onChange={handleOperatorChange}
              />
            </div>
          ) : null}

          {currentValue?.leftOperand &&
          (currentValue?.operator &&
            currentValue?.operator !== OPERATORS_MAP.IS_EMPTY &&
            currentValue?.operator !== OPERATORS_MAP.IS_NOT_EMPTY &&
            currentValue?.operator !== ACTOROPERATORS.IS_EMPTY &&
            currentValue?.operator !== OPERATORS_MAP.IS_NOT_EMPTY) ? (
            <div className="mb-2">
              <CustomField
                type={selectedFieldType}
                options={rightOptionsForSelectedField}
                value={currentValue?.rightOperand}
                onChange={handleRightOperandChange}
                isMulti={isMulti}
                rightOptionMap={rightOptionMap}
                isSLA={currentValue?.isSLA}
                onSLAChange={handleSLAChange}
              />
            </div>
          ) : null}

          <div className="mb-2">
            <CustomButton
              className={`border-[#2563EB] `}
              color={useColorModeValue(LIGHT, DARK)}
              buttonText={LABELS.ACTIONS.ADD}
              variant="outline"
              height="32px"
              mt="0px"
              width="66px"
              padding="0px 30px"
              isDisabled={!isAddEnabled}
              onClick={onAdd}
            />
          </div>
        </div>
      </div>

      <div className="flex flex-wrap gap-1">
        {Array.isArray(values)
          ? values.map((val) => (
              <Tag
                variant="outline"
                colorScheme="blue"
                key={val.id}
                flexWrap="wrap"
                padding={1}
              >
                <TagLabel mr="1">{leftLabel(val)}</TagLabel>

                {val?.op ? (
                  <Badge colorScheme="gray" mr="1">
                    {operatorIdMap?.[val?.op]}
                  </Badge>
                ) : null}

                <TagLabel className="!block">{getValue(val)}</TagLabel>

                <TagCloseButton onClick={() => onDelete(val.id)} />
              </Tag>
            ))
          : null}
      </div>
    </div>
  );
};

/**
 * typeof fields
  {
    id: string;
    name: string;
    type: values;
    isDefault?: boolean; -> Default is false
    operators?: {
      label: string,
      value: string,
    }[];
    defaultOptions?: {
      label: string,
      value: string,
    }[] | null; -> If null no tight options will be shown
    optionMap?: {
      [operatorValue]?: {
        label: string,
        value: string,
      }[] | null; -> If null no tight options will be shown
    }
    isMulti?: boolean; // Default is true
    dynamicOptions?: {
      endPoint: string;
      labelPath: string;
      valuePath: string;
    };
  }
 */

CustomDynamicSelect.propTypes = {
  label: PropTypes.string,
  fields: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  values: PropTypes.array,
  isMandatory: PropTypes.bool,
  fromTemplate: PropTypes.bool,
};

CustomDynamicSelect.defaultProps = {
  label: '',
  values: [],
  isMandatory: false,
  fromTemplate: false,
};

export default CustomDynamicSelect;
