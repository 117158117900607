/* eslint-disable import/prefer-default-export */
import { calculatedHight } from '../../../utils/enums/constVariable';
import { convertFieldType, convertScope } from './helpers';

export const fieldsJson = {
  name: 'fields',
  label: 'Ticket Fields',
  table_name: 'fields_table',
  route: '/fields',
  permissions: [
    {
      name: 'edit',
      type: 'edit',
      roles: ['ticket_analyst', 'ticket_admin'],
      conditions: [
        {
          AND: [
            {
              OR: [
                {
                  condition: 'user_id = $user.id',
                },
                {
                  condition: 'collaborator_id = $user.id',
                },
              ],
            },
            {
              condition: 'status != published',
            },
          ],
        },
      ],
    },
    {
      name: 'view',
      type: 'view',
      roles: ['ticket_analyst', 'ticket_admin'],
    },
    {
      name: 'create',
      type: 'create',
      roles: ['ticket_analyst', 'ticket_admin'],
    },
    {
      name: 'approve',
      type: 'edit',
      roles: ['ticket_admin'],
    },
    {
      name: 'reject',
      type: 'edit',
      roles: ['ticket_admin'],
    },
  ],
  columns: [
    {
      name: 'name',
      label: 'Name',
      column_name: 'name',
      data_type: 'string',
    },
    {
      name: 'status',
      label: 'Status',
      column_name: 'status',
      data_type: 'string',
    },
    {
      name: 'parent',
      label: 'Parent',
      column: 'name',
      related_entity: 'parent_ticket_template',
    },
    {
      name: 'updated_on',
      label: 'Update On',
      column_name: 'updated_on',
      data_type: 'timestamp',
    },
  ],
  related_entities: [
    {
      name: 'parent_ticket_template',
      join_column: 'parent_id',
      related_table: 'ticket_template',
      related_join_column: 'id',
    },
    {
      name: 'ticket_template_fields',
      join_column: 'id',
      related_table: 'ticket_template_fields',
      related_join_column: 'ticket_template_id',
    },
  ],
  views: [
    {
      name: 'fields_container',
      label: 'Fields Container',
      show_label: false,
      type: 'container',
      style: { padding: '0px', boxSizing: 'borderBox' },
      elements: [
        {
          name: 'fields_title',
          type: 'text',
          value: 'Ticket Fields',
          style: { fontSize: '20px', fontWeight: '600' },
        },
      ],
      views: [
        {
          name: 'fields_expanded_list',
          label: 'Fields List',
          type: 'list_exp',
          calculatedHight,
          apiReq: {
            reqCols: ['*'],
            orderBy: 'created_on asc',
            endPoint: '/fields/list',
          },
          related_entity: 'ticket_template_fields',
          columns: [
            {
              col_name: 'label',
              type: 'string',
            },
            {
              col_name: 'type',
              type: 'string',
              cellRenderer: convertFieldType,
            },
            {
              col_name: 'scope',
              type: 'string',
              cellRenderer: convertScope,
            },
            {
              col_name: 'actions',
              type: 'actions',
            },
          ],
          filter: [
            {
              condition: 'is_active = true',
            },
          ],
          actions: [
            {
              name: 'edit_fields',
              icon: 'editIcon',
              type: 'button',
              target_type: 'popup',
              target_modal_id: 'editFieldModal',
              page: 'edit_field_masterpage',
            },
            {
              name: 'delete_fields',
              icon: 'deleteIcon',
              type: 'button',
              target_type: 'popup',
              label_key: 'label', // Key name to be displayed in modal as label
              target_modal_id: 'deleteModal',
              master_type: 'Field',
              listKey: 'fields_expanded_list', // identify the redux status for update it
              // eslint-disable-next-line no-template-curly-in-string
              target_url: '/fields/delete?$filter=id eq ${id}',
              page: 'edit_field_masterpage',
            },
            {
              name: 'copy_fieldsets',
              icon: 'copyIcon',
              type: 'button',
              target_url: '/fields/clone', // *for api call
              method: 'post',
              listKey: 'fields_expanded_list', // identify the redux status for update it
              target_type: 'popup',
              target_modal_id: 'crudTemplateCloneModal',
              master_type: 'field',
              page: 'edit_field_masterpage',
            },
          ],
          filterKey: 'name',
          listKey: 'fields_expanded_list',
        },
        {
          name: 'action_container',
          label: 'Action Container',
          show_label: false,
          type: 'container',
          elements: [
            {
              name: 'search_field_item',
              type: 'search',
              label: 'Search fields...',
              placeholder: 'Search fields...',
              entity: 'Fields',
              listKey: 'fields_expanded_list',
              filterKey: '',
              style: { height: '40px' },
              inputGroupStyle: { width: 'fit-content' },
            },
            {
              name: 'fields_count',
              type: 'count_div',
              listKey: 'fields_expanded_list',
            },
            {
              name: 'create_new_action',
              label: 'Add New Ticket Field',
              show_label: true,
              is_button: true,
              icon: '',
              action_type: 'get',
              permission: 'edit',
              view: 'edit_view',
              navigation: 'popup',
              target_type: 'popup',
              target_modal_id: 'addFieldsModal',
              type: 'button',
              classname: 'editbutton',
              style: {
                color: '#FFFFFF',
                fontWeight: '600',
                backgroundColor: '#2563EB',
              },
            },
          ],
          view_layout: {
            width: '100%',
            no_of_cols: 12,
            rows: [
              {
                i: 'search_field_item',
                x: 0,
                y: 0,
                w: 6,
                maxH: 'Infinity',
              },
              {
                i: 'create_new_action',
                x: 0,
                y: 0,
                w: 4,
                // colEnd: -1,
                // style: {
                //   display: 'inherit',
                //   justifyContent: 'end',
                // },
                maxH: 'Infinity',
              },
              {
                i: 'fields_count',
                x: 0,
                y: 0,
                w: 2,
                maxH: 'Infinity',
              },
            ],
          },
        },
      ],

      view_layout: {
        width: '100%',
        no_of_cols: 12,
        rows: [
          {
            i: 'fields_title',
            x: 0,
            y: 0,
            w: 6,
            maxH: 'Infinity',
          },
          {
            i: 'action_container',
            x: 0,
            y: 1,
            w: 6,
            h: 1,
          },
          {
            i: 'fields_expanded_list',
            x: 0,
            y: 2,
            w: 12,
            h: 1,
          },
        ],
      },
    },
  ],

  view_layout: {
    width: '100%',
    no_of_cols: 12,
    rows: [
      {
        i: 'fields_container',
        x: 0,
        y: 0,
        w: 12,
        h: 1,
      },
    ],
  },
};
