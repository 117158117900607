/* eslint-disable no-restricted-globals */
/* eslint-disable no-restricted-syntax */
/* eslint-disable default-param-last */
/* eslint-disable indent */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-cycle */
/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import { Input } from '@chakra-ui/input';
import {
  Box,
  Flex,
  Grid,
  GridItem,
  Text,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Textarea,
  Checkbox,
  useColorModeValue,
  RadioGroup,
  Radio,
  FormControl,
  FormErrorMessage,
  Tooltip,
  Progress
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import {
  CheckCircleIcon,
  InfoOutlineIcon,
  TriangleDownIcon,
  DeleteIcon,
} from '@chakra-ui/icons';
import { useParams } from 'react-router-dom';
import _set from 'lodash/set';
import { useSelector } from 'react-redux';
import { isEmpty, keys, includes, cloneDeep } from 'lodash';
import styles from '../styles/templatebuilder.module.scss';
import radioStyles from '../CreateEditTemplate/createtemplate.module.scss';
import {
  ALERT_COLOR,
  COLORS,
  DARK,
  // DEFAULT,
  DRAFT_BG,
  GREY_BACKGROUND,
  LIGHT,
} from '../../../utils/enums/colors';
import { SettingsTabTypes, FieldTypes } from '../../../utils/enums/types';
import {
  ACTION_LABELS,
  CUSTUM_TYPE,
  DATE_DEFAULT_VALUE,
  FIELD_PROPERTY_LABELS,
  LABELS,
} from '../../../utils/enums/labels';
import CustomInput from '../../../components/InputBox/Input';
import CustomTextArea from '../../../components/Textarea/CustomTextArea';
import { BASE_SETTINGS_KEYS, TEMPLATE_ROLE_SETTINGS } from '../utils/constants';
import CustomSelectBox from '../../../components/SelectBox/Select';
// import DynamicExpressionWithTags
// from '../../../components/dynamicExpressionWithTags/DynamicExpressionWithTags';
import DynamicMutliSelectWithInputTags from '../../../components/dynamicSelectWithInputTags/DynamicSelectWithInputTags';
import {
  CreateFlowRoleSettingOptions,
  roleSettingSelectOpt,
} from '../../DynamicRenderer/customTemplateModal/CustomTemplateModalLayout';
import customStyleFromRuleForSelect from '../../../context/CustomTheme/CustomStyleFromRuleForSelect';
import {
  SCOPE_TYPE_FOR_FIELD,
  roleSettigWorkflowOpt,
} from '../../../utils/enums/selectOption';
import CustomToolTip from '../../../components/Tooltip/Tooltip';
import { camelCase, checkForV2, isEmptyCondition } from '../../../utils/helper';
import CustomButton from '../../../components/Button/SimpleButton';
import { DROPDOWN_TYPES } from '../../TenantFilters/filters.constants';
import ConditionsExpressions from '../../../components/ConditionsExpressions/ConditionsExpressions';

const FieldContent = ({
  isError,
  baseData,
  fieldData,
  checkIsAllRequireTrue,
  activeSetting,
  setActiveSetting,
  SETTINGS_TABS,
  SETTINGS_TABS_LABEL_MAP,
  setCollectionData,
  collectionData,
  setFields,
  fields,
  edit,
  type,
  setIsError,
}) => {
  const { selectedTemplate } = useSelector((state) => state.viewState || {});
  const isLight = useColorModeValue(ACTION_LABELS.LIGHT, ACTION_LABELS.DARK);
  const [callDropdownData, setCallDropdownData] = useState([]);
  const [staticValue, setStaticValue] = useState([]);
  const [dynamicError, setDynamicError] = useState({});
  const [showDependentFields, setShowDependentFields] = useState(
    fields?.parentOf ?? false
  );
  const [filteredFields, setFilteredFields] = useState([]);
  const [childrenDropdownData, setChildrenDropdownData] = useState([]);
  const shouldHideDependentFieldCheckbox =
    window.location.pathname.includes('/fields') || checkForV2();

  const adjustTextareaHeight = (textarea) => {
      if (textarea) {
        textarea.style.height = 'auto';
        textarea.style.height = `${textarea.scrollHeight}px`;
      }
    };

  const isDescendant = (
    node,
    potentialAncestor,
    dropdownOptionsToBeHiddenSet,
    hasParent
  ) => {
    const fieldIndex = filteredFields?.findIndex(
      (option) => option?.value === camelCase(node?.label)
    );
    const nodeChild = filteredFields?.[fieldIndex]?.parentOf || null;

    if (hasParent) {
      dropdownOptionsToBeHiddenSet.add(filteredFields?.[fieldIndex]);
    }

    if (node?.value === potentialAncestor?.value) {
      return true; // The node is a descendant
    }

    if (nodeChild) {
      for (let i = 0; i < nodeChild.length; i++) {
        if (
          isDescendant(
            nodeChild[i],
            potentialAncestor,
            dropdownOptionsToBeHiddenSet,
            true
          )
        ) {
          return true;
        }
      }
    }
    return false; // The node is not a descendant
  };

  const isNonValidParent = (
    node,
    currentNode,
    dropdownOptionsToBeHiddenSet
  ) => {
    if (node?.value === currentNode?.value) {
      dropdownOptionsToBeHiddenSet.add(node);
    } else if (
      isDescendant(node, currentNode, dropdownOptionsToBeHiddenSet, false)
    ) {
      dropdownOptionsToBeHiddenSet.add(node);
    }
  };
  const generateDropdownOptions = () => {
    const fieldIndex = filteredFields?.findIndex(
      (option) => option?.value === camelCase(fields?.label)
    );
    const currentNode = filteredFields[fieldIndex] || null;

    const dropdownOptionsToBeHiddenSet = new Set();

    if (currentNode !== null) {
      dropdownOptionsToBeHiddenSet.add(currentNode);
    }

    for (const filteredField of filteredFields) {
      isNonValidParent(
        filteredField,
        currentNode,
        dropdownOptionsToBeHiddenSet
      );
    }

    const dropdownOptionsToBeHidden = [...dropdownOptionsToBeHiddenSet];
    const renderDropdownOptions = filteredFields
      ?.filter((field) => !dropdownOptionsToBeHidden.includes(field))
      .map((option) => ({ label: option?.label, value: option?.value }));

    setChildrenDropdownData(renderDropdownOptions);
  };
  useEffect(() => {
    if (selectedTemplate && selectedTemplate.fields) {
      const filteredOptions = selectedTemplate.fields
        .filter((item) => item.type === 'DROPDOWN')
        .map((item) => {
          const { label } = item;
          const value = camelCase(label);
          const parentOf =
            item?.type_based_attributes?.dependency?.parentOf || [];
          return {
            label,
            value,
            parentOf,
          };
        });

      setFilteredFields(filteredOptions);
    }
  }, [selectedTemplate, selectedTemplate?.fields]);

  useEffect(() => {
    generateDropdownOptions();
  }, [filteredFields, fields]);

  const parseKeyValue = (label = 'label', value = 'value', options) =>
    Array.isArray(options) && label && value
      ? collectionData?.dynamicValues?.map((i) => ({
          ...i,
          label: i[label],
          value: i[value],
        }))
      : [];

  const parseStaticValue = (value) =>
    typeof value === 'string'
      ? collectionData?.staticValues.split('\n')?.map((i) => ({
          label: i,
          value: i,
        }))
      : Array.isArray(value)
      ? [...value]
      : [];

  useEffect(() => {
    const newCallDropdownData = parseKeyValue(
      fields?.collection?.labelPath,
      fields?.collection?.valuePath,
      collectionData?.dynamicValues
    );
    setCallDropdownData(newCallDropdownData);
  }, [
    fields?.collection?.labelPath,
    fields?.collection?.valuePath,
    collectionData?.dynamicValues,
  ]);

  useEffect(() => {
    const defaultValue = {
      label: '',
      value: '',
      rank: '',
    };
    const data = [...(parseStaticValue(collectionData?.staticValues) || [])];

    if (!data.length) {
      data.push({ ...defaultValue });
    }
    setStaticValue([...data]);
  }, []);

  const { id } = useParams();

  const onChangeDefaultValue = (selectedOptions) => {
    setCollectionData((prevData) => ({
      ...prevData,
      [BASE_SETTINGS_KEYS.DEFAULT_VALUE]: selectedOptions.value,
    }));
    const selectedValue = selectedOptions ? selectedOptions.value : null;
    setFields({
      ...fields,
      [BASE_SETTINGS_KEYS.DEFAULT_VALUE]: selectedValue,
    });
  };

  const isMultiCheckboxSelect = (isChecked) => {
    setCollectionData((prevData) => ({
      ...prevData,
      isMulti: isChecked,
    }));
  };
  const isTemplateLevel = window.location.pathname.includes('/template');

  const showInSingleLIne = baseData?.row1?.some((item) =>
    item?.key?.includes(BASE_SETTINGS_KEYS.SLUG)
  );

  const checkIfKeyPresent = (dynamicValues, val, key) => {
    const dynamicKeys = dynamicValues?.length
      ? Object.keys(dynamicValues[0])
      : [];
    setDynamicError({ ...dynamicError, [key]: !dynamicKeys?.includes(val) });
  };

  const getErrorMessage = ({ key, errorString, value }) => {
    if (key === BASE_SETTINGS_KEYS.SLUG) {
      return '';
    }
    if (typeof errorString === 'function') {
      return errorString({ value });
    }

    return errorString;
  };

  const [expLoad, setExpLoad] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setExpLoad(false);
    }, 3000);
  }, []);

  const trimWhitespaces = (data) => {
    if (Array.isArray(data)) {
      const cleanedData = data?.map((item) => {
        if (typeof value === 'string') return item;
        const cleanedItem = {};
        Object.keys(item).forEach((key) => {
          const value = item[key];
          cleanedItem[key] = typeof value === 'string' ? value.trim() : value;
        });
        return cleanedItem;
      });
      return cleanedData;
    } return data;
  };

  const onChangeStaticValue = (value, path) => {
    if (path.includes('label')) {
      setIsError((pre) => ({ ...pre, staticValuesLabel: false }));
    }
    if (path.includes('value')) {
      setIsError((pre) => ({ ...pre, staticValuesValue: false }));
    }
    if (path.includes('rank')) {
      setIsError((pre) => ({
        ...pre,
        staticRankValue: false,
        isStaticRankValueNumber: false,
      }));
    }
    setIsError((pre) => ({ ...pre, isDuplicateStaticValue: false }));
    let newStaticValue = cloneDeep(staticValue);
    _set(newStaticValue, path, value);
    setStaticValue(newStaticValue);
    newStaticValue = trimWhitespaces(newStaticValue);
    setCollectionData((prevData) => ({
      ...prevData,
      [BASE_SETTINGS_KEYS.STATIC_VALUES]: newStaticValue,
    }));
    setFields({
      ...fields,
      [BASE_SETTINGS_KEYS.STATIC_VALUES]: newStaticValue,
    });
  };

  const undoErrorOnChange = (staticListErrors, key, index) => {
    if (staticListErrors && staticListErrors[key]) {
      const deepCopyStaticListErrors = [...(isError?.staticListErrors || [])];
      delete deepCopyStaticListErrors[index][key];
      setIsError((prev) => (
        { ...prev,
          staticListErrors: deepCopyStaticListErrors,
        }));
      }
  };

  const onAddStaticValue = () => {
    const defaultValue = {
      rank: '',
      label: '',
      value: '',
    };
    const newStaticValue = [...staticValue, defaultValue];
    setStaticValue(newStaticValue);
    setCollectionData((prevData) => ({
      ...prevData,
      [BASE_SETTINGS_KEYS.STATIC_VALUES]: newStaticValue,
    }));
    setFields({
      ...fields,
      [BASE_SETTINGS_KEYS.STATIC_VALUES]: newStaticValue,
    });
  };

  const onDeleteStaticValue = (index) => {
    const newStaticValue = [...staticValue];
    newStaticValue.splice(index, 1);
    setStaticValue(newStaticValue);
    setCollectionData((prevData) => ({
      ...prevData,
      [BASE_SETTINGS_KEYS.STATIC_VALUES]: newStaticValue,
    }));
    if (isEmpty(newStaticValue)) {
      const defaultValue = {
        label: '',
        value: '',
        rank: '',
      };
      setCollectionData((prevData) => ({
        ...prevData,
        [BASE_SETTINGS_KEYS.STATIC_VALUES]: newStaticValue,
      }));
      setStaticValue([{ ...defaultValue }]);
    }
    setFields({
      ...fields,
      [BASE_SETTINGS_KEYS.STATIC_VALUES]: newStaticValue,
    });
  };

  const onChangeOfParent = (selectedValue) => {
    setFilteredFields((prevFilterFields) => {
      const fieldIndex = prevFilterFields.findIndex(
        (option) => option?.value === camelCase(fields?.label)
      );

      if (fieldIndex !== -1) {
        const updatedFields = [...prevFilterFields];
        updatedFields[fieldIndex] = {
          ...updatedFields[fieldIndex],
          parentOf: selectedValue,
        };

        setFields((prevFields) => ({
          ...prevFields,
          parentOf: updatedFields[fieldIndex]?.parentOf,
        }));

        return updatedFields;
      }

      return prevFilterFields;
    });
  };

  return (
    <>
      <Flex
        bg={useColorModeValue(GREY_BACKGROUND, '#4F5765')}
        className={styles.tab__container}
        data-testid="CustomTemplateModalBoxContainerFlex"
      >
        {SETTINGS_TABS?.map((settingsTabType) => (
          <Box
            key={settingsTabType}
            role="tab"
            className={styles.tab}
            onClick={() =>
              checkIsAllRequireTrue() && setActiveSetting(settingsTabType)
            }
            data-testid="CustomTemplateModalTabsContainerBox"
          >
            <Text
              className={`${styles.tab__text} ${
                activeSetting === settingsTabType
                  ? useColorModeValue(
                      styles.tab__selected__light,
                      styles.tab__selected__dark
                    )
                  : ''
              }`.trim()}
            >
              {SETTINGS_TABS_LABEL_MAP[settingsTabType]}
            </Text>
          </Box>
        ))}
      </Flex>
      {activeSetting === SettingsTabTypes.BASIC && (
        <>
          <Grid
            templateColumns={
              showInSingleLIne ? 'repeat(1, 1fr)' : 'repeat(2, 1fr)'
            }
            gap={showInSingleLIne ? '0px' : '30px'}
            pt="20px"
            data-testid="FieldContentColumnsGrid"
          >
            {baseData?.row1?.map((fieldInfo) => (
              <GridItem key={fieldInfo.label} w="100%">
                <Text
                  mb="8px"
                  as="label"
                  className="SCLabel"
                  style={{
                    display:
                      fieldInfo.key === BASE_SETTINGS_KEYS.SLUG ? 'none' : '',
                  }}
                  data-testid="FieldContentColumnGridItem"
                >
                  {`${fieldInfo.label}`}
                  {fieldInfo?.required && (
                    <span className={styles.required}>{' *'}</span>
                  )}
                </Text>
                {fieldInfo.render ? (
                  fieldInfo.render()
                ) : (
                  <CustomInput
                    onChange={(e) => fieldInfo.onChange(e.target.value, edit)}
                    value={fields?.[fieldInfo?.valueKey] || ''}
                    placeholder={fieldInfo.placeholder}
                    isError={isError[fieldInfo.key] || false}
                    helperText=" "
                    isReadOnly={fieldInfo.isReadOnly || false}
                    errorMessage={getErrorMessage({
                      key: fieldInfo.key,
                      errorString: fieldInfo.errorString,
                      value: fields?.[fieldInfo?.valueKey],
                    })}
                    style={{
                      display:
                        fieldInfo.key === BASE_SETTINGS_KEYS.SLUG ? 'none' : '',
                    }}
                    id="FieldContentCIDivCInput"
                  />
                )}
              </GridItem>
            ))}
          </Grid>
          <Text
            pb="10px"
            pt="30px"
            className="SCLabel"
            data-testid="TicktFieeldRow$Scope"
          >
            {LABELS.TITLE.SCOPE_TYPE}
          </Text>
          <Grid templateColumns="repeat(4, 1fr)" gap={5}>
            {SCOPE_TYPE_FOR_FIELD.map((item) => (
              <RadioGroup
                className={radioStyles.radiobutton}
                value={fields.scope || 'TICKET'}
                onChange={(e) => {
                  setFields({
                    ...fields,
                    [BASE_SETTINGS_KEYS.SCOPE]: e,
                  });
                }}
                isDisabled={!!fields?.defaultFieldBot}
                data-testid="TicketFieldContentScopeRadioGrp"
              >
                <Radio
                  value={item.value}
                  key={item.value}
                  data-testid="TicketFieldScopeRadioInfo"
                  isDisabled={!!fields?.defaultFieldBot}
                >
                  {item.label}
                  {item.toolTip && (
                    <Tooltip
                      padding="10px"
                      borderRadius="6px"
                      label={
                        <Flex alignItems="start">
                          <InfoOutlineIcon
                            mr="8px"
                            color={DRAFT_BG}
                            width="24px"
                            height="24px"
                          />
                          <Text className={styles.label}>{item.toolTip}</Text>
                        </Flex>
                      }
                      bg={useColorModeValue('white', '#374151')}
                      color={useColorModeValue(ALERT_COLOR, 'white')}
                    >
                      <InfoOutlineIcon ml="5px" />
                    </Tooltip>
                  )}
                </Radio>
              </RadioGroup>
            ))}
          </Grid>
          <Grid
            templateColumns="repeat(1, 1fr)"
            data-testid="FieldContentFieldInfoRow2Grid"
          >
            {baseData?.row2?.map((fieldInfo) => (
              <GridItem
                pt="30px"
                key={fieldInfo.label}
                w="100%"
                data-testid="FieldContentFieldInfoTextRow2GridItem"
              >
                <Text
                  mb="8px"
                  as="label"
                  className="SCLabel"
                  data-testid="FieldContentRow2Text"
                >
                  {`${fieldInfo.label}`}
                  {fieldInfo?.required && fieldInfo.label && (
                    <span className={styles.required}>{' *'}</span>
                  )}
                </Text>
                {fieldInfo.render ? (
                  fieldInfo.render(fields, isError)
                ) : (
                  <Textarea
                    value={fields?.[fieldInfo?.valueKey] || ''}
                    onChange={(e) => fieldInfo.onChange(e.target.value)}
                    placeholder={fieldInfo.Placeholder}
                    size="lg"
                    focusBorderColor={useColorModeValue(LIGHT, DARK)}
                    maxH="30rem"
                    data-testid="FieldContentRow2Textarea"
                    isDisabled={!!fields?.defaultFieldBot}
                    ref={(ref) => adjustTextareaHeight(ref)}
                  />
                )}
              </GridItem>
            ))}
          </Grid>
          <>
            {type === FieldTypes.LABEL && (
              <Text
                style={{ color: 'blue' }}
                gap="30px"
                pt="35px"
                data-testid="TicktFieeldContentListtmap"
              >
                List Map
              </Text>
            )}
            <Grid
              templateColumns="repeat(2, 1fr)"
              gap="30px"
              pt="15px"
              data-testid="TicktFieeldContentMainGrid"
            >
              {baseData?.row3?.map((fieldInfo) => (
                <GridItem
                  key={fieldInfo.label}
                  w="100%"
                  data-testid="TicktFieeldContentGridIttem"
                >
                  <Text mb="8px" as="label" className="SCLabel">
                    {`${fieldInfo.label}`}
                    {fieldInfo?.required && fieldInfo.label && (
                      <span className={styles.required}>{' *'}</span>
                    )}
                  </Text>
                  {fieldInfo.render ? (
                    fieldInfo.render(fields, isError)
                  ) : (
                    <CustomInput
                      onKeyDown={(e) => {
                        if (fieldInfo.type === 'number' && (e.key === 'e' || e.key === 'E')) {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) => fieldInfo.onChange(e.target.value)}
                      value={fields?.[fieldInfo?.valueKey] || ''}
                      placeholder={fieldInfo.placeholder}
                      type={fieldInfo.type || 'text'}
                      isError={isError[fieldInfo.key] || false}
                      helperText=" "
                      errorMessage={
                        isError[
                          `${BASE_SETTINGS_KEYS.DEFAULT_VALUE}_message`
                        ] || fieldInfo.errorString
                      }
                      id="FieldContent1CIDivCInput"
                      isDisabled={!!fields?.defaultFieldBot}
                    />
                  )}
                </GridItem>
              ))}
            </Grid>
            {/* date default  start */}
            {baseData?.row9 && (
              <Box
                gap="30px"
                pt="35px"
                data-testid="TicktFieeldContentRow9Grid"
              >
                <Box
                  key={baseData?.row9[0].label}
                  w="100%"
                  data-testid="TicktFieeldContentRow9GridItem"
                >
                  <Text
                    mb="8px"
                    as="label"
                    className="SCLabel"
                    data-testid="TicktFieeldContentRow9TextBase"
                  >
                    {`${baseData?.row9[0].label}`}
                    {baseData?.row9[0]?.required && (
                      <span className={styles.required}>{' *'}</span>
                    )}
                  </Text>
                  {baseData?.row9[0].render(fields)}
                </Box>
                {fields?.[baseData?.row9[0]?.valueKey] ===
                  DATE_DEFAULT_VALUE.OFFSET_POSITIVE && (
                  <Box
                    key={baseData?.row9[1].label}
                    w="100%"
                    data-testid="TicktFieeldContentACLabelGridItem"
                  >
                    <Text
                      mb="8px"
                      as="label"
                      className="SCLabel"
                      data-testid="TicktFieeldContentSCLabelText"
                    >
                      {`${baseData?.row9[1].label}`}
                      {baseData?.row9[1]?.required && (
                        <span className={styles.required}>{' *'}</span>
                      )}
                    </Text>
                    {/* {baseData?.row9[1].render()} */}
                    {baseData?.row9[1].render ? (
                      baseData?.row9[1].render()
                    ) : (
                      <FormControl
                        isInvalid={isError[baseData?.row9[1].key] || false}
                        data-testid="TicktFieeldContentDataFormControl"
                      >
                        <NumberInput
                          onChange={(value) => {
                            baseData?.row9[1].onChange(value);
                          }}
                          value={fields?.[baseData?.row9[1]?.valueKey] || ''}
                          focusBorderColor={useColorModeValue(LIGHT, DARK)}
                          data-testid="TicktFieeldContentNumberInnput"
                        >
                          <NumberInputField />
                          <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                          </NumberInputStepper>
                        </NumberInput>
                        <FormErrorMessage data-testid="TicktFieeldContentFormErrorMsg">
                          {baseData?.row9[1].errorString}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Box>
                )}
                {fields?.[baseData?.row9[0]?.valueKey] ===
                  DATE_DEFAULT_VALUE.OFFSET_NEGATIVE && (
                  <Box
                    key={baseData?.row9[2].label}
                    w="100%"
                    data-testid="TicktFieeldContentTestGridITem"
                  >
                    <Text
                      mb="8px"
                      as="label"
                      className="SCLabel"
                      data-testid="TicktFieeldContentRuuiredText"
                    >
                      {`${baseData?.row9[2].label}`}
                      {baseData?.row9[2]?.required && (
                        <span className={styles.required}>{' *'}</span>
                      )}
                    </Text>
                    {/* {baseData?.row9[1].render()} */}
                    {baseData?.row9[2].render ? (
                      baseData?.row9[2].render()
                    ) : (
                      <FormControl
                        isInvalid={isError[baseData?.row9[2].key] || false}
                        data-testid="TicktFieeldContentTestIdFormCntrl"
                      >
                        <NumberInput
                          onChange={(value) => {
                            baseData?.row9[2].onChange(value);
                          }}
                          value={fields?.[baseData?.row9[2]?.valueKey] || ''}
                          focusBorderColor={useColorModeValue(LIGHT, DARK)}
                        >
                          <NumberInputField />
                          <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                          </NumberInputStepper>
                        </NumberInput>
                        <FormErrorMessage>
                          {baseData?.row9[2].errorString}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Box>
                )}
              </Box>
            )}
            {/* date default end */}
          </>
          {baseData?.row4 && (
            <Text
              pb="10px"
              pt="30px"
              className="SCLabel"
              data-testid="TicktFieeldRow$TextContent"
            >
              {LABELS.TITLE.COLLECTION_TYPE}
            </Text>
          )}
          <Grid
            templateColumns="repeat(4, 1fr)"
            gap={5}
            data-testid="TicktFieeldContentFieldInfoGrid"
          >
            {baseData?.row4?.map((fieldInfo, index) => (
              <GridItem
                // eslint-disable-next-line react/no-array-index-key
                key={`row4-field${index}`}
                data-testid="TicktFieeldContentRow4dGridItem"
              >
                <Text
                  mb="8px"
                  as="samp"
                  data-testid="TicktFieeldContentSampText"
                />
                {fieldInfo.render ? (
                  fieldInfo.render()
                ) : (
                  <RadioGroup
                    className={radioStyles.radiobutton}
                    value={
                      collectionData?.collection_type || DROPDOWN_TYPES.STATIC
                    }
                    onChange={(e) => {
                      setFields((prevState) => ({
                        ...prevState,
                        [BASE_SETTINGS_KEYS.DEFAULT_VALUE]: '',
                        collection: {
                          ...prevState?.collection,
                          [BASE_SETTINGS_KEYS.SPECIAL_FIELD]: '',
                          [BASE_SETTINGS_KEYS.LIST]: [],
                          [BASE_SETTINGS_KEYS.ACTOR_TYPE]: '',
                        },
                      }));
                      setCollectionData((pre) => ({
                        ...pre,
                        [BASE_SETTINGS_KEYS.VALUES]: '',
                        collection_type: e,
                        [BASE_SETTINGS_KEYS.SPECIAL_FIELD]: '',
                        [BASE_SETTINGS_KEYS.LIST]: [],
                        [BASE_SETTINGS_KEYS.ACTOR_TYPE]: '',
                      }));
                    }}
                    data-testid="TicktFieeldContentRow4RadioGrp"
                  >
                    {' '}
                    <Radio
                      value={fieldInfo.value}
                      key={fieldInfo.value}
                      isDisabled={fieldInfo?.isDisabled}
                      data-testid="TicktFieeldContentRadioInfo"
                    >
                      {fieldInfo.label}
                    </Radio>
                  </RadioGroup>
                )}
              </GridItem>
            ))}
          </Grid>
          {collectionData?.collection_type === DROPDOWN_TYPES.STATIC ? (
            <>
              {staticValue.length > 0 ? (
                <Box>
                  {staticValue.map((item, index) => {
                    const staticListErrors = isError?.staticListErrors?.find(
                      (error, errorIndex) => errorIndex === index
                    );
                    return (
                      <Grid display="flex" gap="30px">
                        <GridItem pt="30px">
                          <Text mb="8px" as="label" className="SCLabel">
                            {LABELS.FIELD_PROPERTIES.RANK}
                            <span className={styles.required}>{' *'}</span>
                          </Text>
                          <CustomInput
                            value={item.rank || ''}
                            type="number"
                            onChange={(e) => {
                              undoErrorOnChange(
                                staticListErrors,
                                'rank',
                                index
                              );
                              onChangeStaticValue(
                                e.target.value,
                                `[${index}].rank`
                              );
                            }}
                            placeholder=""
                            isError={staticListErrors?.rank || false}
                            helperText=" "
                            errorMessage={staticListErrors?.rank}
                          />
                        </GridItem>
                        <GridItem pt="30px">
                          <Text mb="8px" as="label" className="SCLabel">
                            {LABELS.FIELD_PROPERTIES.LABEL}
                            <span className={styles.required}>{' *'}</span>
                          </Text>
                          <CustomInput
                            value={item.label || ''}
                            onChange={(e) => {
                              undoErrorOnChange(
                                staticListErrors,
                                'label',
                                index
                              );
                              onChangeStaticValue(
                                e.target.value,
                                `[${index}].label`
                              );
                            }}
                            placeholder=""
                            isError={staticListErrors?.label || false}
                            helperText=" "
                            errorMessage={staticListErrors?.label}
                          />
                        </GridItem>

                        <GridItem pt="30px">
                          <Text mb="8px" as="label" className="SCLabel">
                            {LABELS.FIELD_PROPERTIES.VALUE}
                            <span className={styles.required}>{' *'}</span>
                          </Text>
                          <CustomInput
                            onChange={(e) => {
                              undoErrorOnChange(
                                staticListErrors,
                                'value',
                                index
                              );
                              onChangeStaticValue(
                                e.target.value,
                                `[${index}].value`,
                                index
                              );
                              if (item.value === fields.default_value) {
                                setFields((prev) => ({
                                  ...prev,
                                  default_value: e.target.value,
                                }));
                              }
                            }}
                            value={item.value || ''}
                            placeholder=""
                            isError={staticListErrors?.value || false}
                            helperText=" "
                            errorMessage={staticListErrors?.value}
                          />
                        </GridItem>
                        <GridItem pt="40px" alignSelf="center">
                          <DeleteIcon
                            mx="4"
                            color="red"
                            onClick={() => {
                              onDeleteStaticValue(index);
                            }}
                          />
                        </GridItem>
                      </Grid>
                  );
})}
                  <Box>
                    {' '}
                    {isError.isDuplicateStaticValue && (
                      <span className="field-error">
                        Rank, label or value can not be duplicate
                      </span>
                    )}
{' '}
                    {isError?.isStaticRankValueNumber && (
                      <span className="field-error">
                        Rank should not be bigger than the size of the static
                        value.
                      </span>
                    )}
{' '}
                  </Box>
                  <Flex justify="space-between" mt="10px">
                    <CustomButton
                      variant="outline"
                      pl="25px"
                      pr="25px"
                      buttonColor="customBlue"
                      isDisabled={
                        !staticValue.every((item) => item.label && item.value)
                      }
                      buttonText={LABELS.ACTIONS.ADD}
                      onClick={() => {
                        onAddStaticValue(staticValue);
                      }}
                      size="md"
                    />
                    <GridItem>
                      <Checkbox
                        isChecked={collectionData?.isMulti}
                        onChange={(e) => isMultiCheckboxSelect(e.target.checked)}
                      >
                        Enable Multi-Select
                      </Checkbox>
                    </GridItem>
                  </Flex>
                </Box>
              ) : null}
              <Grid templateColumns="repeat(2, 1fr)" gap="30px">
                {baseData?.row7?.map((each) => (
                  <GridItem pt="30px" key={each.label} w="100%">
                    <Text mb="8px" as="label" className="SCLabel">
                      {each.label}
                      {each?.subLabel && (
                        <span
                          className={
                            isLight === ACTION_LABELS.LIGHT
                              ? `${styles.sizeUnit}`
                              : ''
                          }
                        >
                          {each.subLabel}
                        </span>
                      )}
                      {each?.required && (
                        <span className={styles.required}>{' *'}</span>
                      )}
                    </Text>
                    {each.render ? (
                      each.render(fields)
                    ) : each.renderType ? (
                      <CustomTextArea
                        onChange={(e) => each.onChange(e.target.value)}
                        value={fields?.[each?.valueKey] || ''}
                        placeholder={each.placeholder}
                        isError={isError[each.key] || false}
                        helperText=" "
                        errorMessage={each.errorString}
                      />
                    ) : (
                      <CustomInput
                        onChange={(e) => each.onChange(e.target.value)}
                        value={fields?.[each?.valueKey] || ''}
                        type={each?.type}
                        placeholder={each.placeholder}
                        isError={isError[each.key] || false}
                        helperText=" "
                        errorMessage={each.errorString}
                      />
                    )}
                  </GridItem>
                ))}
              </Grid>
            </>
          ) : collectionData?.collection_type === DROPDOWN_TYPES.DYNAMIC ? (
            <>
              <Grid data-testid="TicktFieeldContentDynamicGrid">
                {baseData?.row5?.map((each) => (
                  <GridItem
                    pt="30px"
                    key={each.label}
                    w="100%"
                    data-testid="TicktFieeldContentBaseeDataGridItem"
                  >
                    <Text
                      mb="8px"
                      as="label"
                      className="SCLabel"
                      data-testid="TicktFieeldContentsubLabelText"
                    >
                      {each.label}
                      {each?.subLabel && (
                        <span
                          className={
                            isLight === ACTION_LABELS.LIGHT
                              ? `${styles.sizeUnit}`
                              : ''
                          }
                        >
                          {each.subLabel}
                        </span>
                      )}
                      {each?.required && (
                        <span className={styles.required}>{' *'}</span>
                      )}
                    </Text>
                    {each.render ? (
                      each.render(fields)
                    ) : (
                      <CustomInput
                        onChange={(e) => each.onChange(e.target.value)}
                        value={fields?.[each?.valueKey] || ''}
                        type={each?.type}
                        placeholder={each.placeholder}
                        isError={isError[each.key] || false}
                        helperText=" "
                        errorMessage={each.errorString}
                        id="FieldContent4CIDivCInput"
                      />
                    )}
                  </GridItem>
                ))}
              </Grid>
              <Grid
                templateColumns="repeat(2, 1fr)"
                gap="30px"
                data-testid="TicktFieeldContentGgridBaseGrid"
              >
                {baseData?.row6?.map((each) => (
                  <GridItem
                    pt="10px"
                    key={each.label}
                    w="100%"
                    data-testid="TicktFieeldContentRow6GridItem"
                  >
                    <Text
                      mb="8px"
                      as="label"
                      className="SCLabel"
                      data-testid="TicktFieeldContentRoww6Text"
                    >
                      {each.label}
                      {each?.subLabel && (
                        <span
                          className={
                            isLight === ACTION_LABELS.LIGHT
                              ? `${styles.sizeUnit}`
                              : ''
                          }
                        >
                          {each.subLabel}
                        </span>
                      )}
                      {each?.required && (
                        <span className={styles.required}>{' *'}</span>
                      )}
                    </Text>
                    {each.render ? (
                      each.render()
                    ) : (
                      <CustomTextArea
                        onChange={(e) => each.onChange(e.target.value)}
                        value={fields?.[each?.valueKey] || ''}
                        placeholder={each.placeholder}
                        isError={isError[each.key] || false}
                        helperText=" "
                        errorMessage={each.errorString}
                        height="130px"
                        id="TicketFieldTextArea"
                      />
                    )}
                  </GridItem>
                ))}
                {!checkForV2() && (
                  <GridItem
                    mt="-15px"
                    data-testid="TicktFieeldContentRow66GridItem"
                  >
                    <div>
                      <Text
                        mb="8px"
                        as="label"
                        className="SCLabel"
                        data-testid="TicktFieeldContentRoew6Label"
                      >
                        Label
                        <CustomToolTip
                          id="tooltip-label"
                          toolTipText={
                            dynamicError?.labelError ? 'Key not matching' : ''
                          }
                        >
                          {dynamicError?.labelError ? (
                            <InfoOutlineIcon ml="2" mb="0.5" color="red" />
                          ) : fields?.collection?.labelPath ? (
                            <CheckCircleIcon ml="2" mb="0.5" color="green" />
                          ) : (
                            ''
                          )}
                        </CustomToolTip>
                      </Text>
                      <CustomInput
                        onChange={(e) => {
                          setFields((prevState) => ({
                            ...prevState,
                            collection: {
                              ...prevState?.collection,
                              labelPath: e.target.value,
                            },
                          }));
                        }}
                        value={fields?.collection?.labelPath}
                        onBlur={(e) =>
                          checkIfKeyPresent(
                            fields?.dynamicValues,
                            e.target.value,
                            'labelError'
                          )
                        }
                        placeholder="Label"
                        isError={dynamicError?.labelError}
                        helperText=" "
                        errorMessage=""
                        id="FieldContent5CIDivCInput"
                      />
                      <Text
                        mb="8px"
                        as="label"
                        className="SCLabel"
                        data-testid="TicktFieeldContentRiow6ValueText"
                      >
                        Value
                        <CustomToolTip
                          id="tooltip-value"
                          toolTipText={
                            dynamicError?.valueError ? 'Key not matching' : ''
                          }
                        >
                          {dynamicError?.valueError ? (
                            <InfoOutlineIcon ml="2" mb="0.5" color="red" />
                          ) : fields?.collection?.valuePath ? (
                            <CheckCircleIcon ml="2" mb="0.5" color="green" />
                          ) : (
                            ''
                          )}
                        </CustomToolTip>
                      </Text>
                      <CustomInput
                        onChange={(e) => {
                          setFields((prevState) => ({
                            ...prevState,
                            collection: {
                              ...prevState?.collection,
                              valuePath: e.target.value,
                            },
                          }));
                        }}
                        value={fields?.collection?.valuePath}
                        onBlur={(e) =>
                          checkIfKeyPresent(
                            fields?.dynamicValues,
                            e.target.value,
                            'valueError'
                          )
                        }
                        isError={dynamicError?.valueError}
                        placeholder="Value"
                        helperText=" "
                        errorMessage=""
                        id="FieldContent6CIDivCInput"
                      />
                    </div>
                    <div data-testid="TicktFieeldContentOptionDataDiv">
                      <Text
                        mb="8px"
                        as="label"
                        className="SCLabel"
                        data-testid="TicktFieeldContentOptionText"
                      >
                        Options Preview
                      </Text>
                      <CustomSelectBox
                        placeholder="Options"
                        value={fields?.collection?.default_value}
                        onChange={onChangeDefaultValue}
                        focusBorderColor={useColorModeValue(
                          COLORS.LIGHT,
                          COLORS.DARK
                        )}
                        options={callDropdownData}
                        styles={customStyleFromRuleForSelect}
                        isDisabled={
                          !fields?.collection?.labelPath ||
                          !fields?.collection?.valuePath ||
                          dynamicError?.valueError ||
                          dynamicError?.labelError
                        }
                        size="md"
                        className={styles.selectedBoxRoleSetting}
                        components={{ DropdownIndicator: TriangleDownIcon }}
                        id="FieldsContentSelectBox"
                      />
                    </div>
                  </GridItem>
                )}
                {/* <GridItem mt="-15px">
                  <Text mb="8px" as="label" className="SCLabel">
                    Collection
                  </Text>
                  <Box
                    mt="3px"
                    style={{
                      border: '1px solid #E2E3E4',
                      borderRadius: '4px',
                      whiteSpace: 'pre-line',
                      height: '130px',
                      padding: '8px',
                      overflowY: 'auto',
                    }}
                  >
                    {Array.isArray(collectionData?.dynamicValues)
                      ? `[
                        ${collectionData?.dynamicValues
                          ?.map((item) => JSON.stringify(item, null, 2))
                          ?.join(',\n')}
                        ]`
                      : ''}
                  </Box>
                </GridItem> */}
              </Grid>

              {!shouldHideDependentFieldCheckbox && (
                <Grid
                  templateColumns="repeat(1, 1fr)"
                  gap="30px"
                  data-testid="TicktFieeldContentDependentGrid"
                >
                  <GridItem pt="30px" w="100%" pb="30px">
                    <Checkbox
                      isChecked={showDependentFields}
                      onChange={(e) => setShowDependentFields(e.target.checked)}
                      data-testid="TicktFieeldContentDependentCheckbox"
                    >
                      Dependent Fields
                    </Checkbox>
                    {showDependentFields && (
                      <>
                        <Box
                          pt="30px"
                          w="100%"
                          data-testid="TicktFieeldContentParentOfBox"
                        >
                          <Text
                            mb="8px"
                            as="label"
                            className="SCLabel"
                            w="100%"
                            data-testid="TicktFieeldContentParentText"
                          >
                            Parent of
                          </Text>
                          <CustomSelectBox
                            isMulti
                            placeholder="Options"
                            value={fields?.parentOf}
                            onChange={(e) => onChangeOfParent(e)}
                            focusBorderColor={useColorModeValue(
                              COLORS.LIGHT,
                              COLORS.DARK
                            )}
                            options={childrenDropdownData}
                            styles={customStyleFromRuleForSelect}
                            size="md"
                            id="TicktFieeldContentFilterFieldTextCustomSelect"
                          />
                        </Box>
                        <Box
                          pt="30px"
                          w="100%"
                          data-testid="TicktFieeldContentFilterBox"
                        >
                          <Text
                            mb="8px"
                            as="label"
                            className="SCLabel"
                            data-testid="TicktFieeldContentFilterFieldText"
                          >
                            Filter Field
                          </Text>
                          <CustomInput
                            value={fields?.filterField}
                            onChange={(e) => {
                              setFields((prev) => ({
                                ...prev,
                                filterField: e.target.value,
                              }));
                            }}
                            id="FieldContent7CIDivCInput"
                          />
                        </Box>
                      </>
                    )}
                  </GridItem>
                </Grid>
              )}
              <Grid
                templateColumns="repeat(2, 1fr)"
                gap={1}
                data-testid="TicktFieeldContentEnableGrid"
              >
                <GridItem data-testid="TicktFieeldContentEnableGridITEM">
                  <Checkbox
                    isChecked={collectionData?.isMulti}
                    onChange={(e) => isMultiCheckboxSelect(e.target.checked)}
                  >
                    Enable Multi-Select
                  </Checkbox>
                </GridItem>
              </Grid>
            </>
          ) : collectionData?.collection_type ===
            DROPDOWN_TYPES.SPECIAL_FIELD ? (
            <Box
              mt="15px"
              w="250px"
              data-testid="TicktFieeldContentRow8RenderBox"
            >
              {baseData?.row8?.map((each) => (
                <Box key={each.label} data-testid="TicktFieeldContentmapBox">
                  {each.render(collectionData)}
                </Box>
              ))}
            </Box>
          ) : collectionData?.collection_type === DROPDOWN_TYPES.DEPENDENT ? (
            <Box
              mt="15px"
              w="250px"
              data-testid="TicktFieeldContentRow12RenderBox"
            >
              {baseData?.row12?.map((each) => (
                <Box key={each.label} data-testid="TicktFieeldContentmapBox">
                  {each.render(collectionData)}
                </Box>
              ))}
            </Box>
          ) : null}
        </>
      )}
      {activeSetting === SettingsTabTypes.ADVANCED && (
        <>
          <Grid
            pb="30px"
            templateColumns={
              fieldData?.row1?.length > 1 ? 'repeat(2, 1fr)' : 'repeat(1, 1fr)'
            }
            gap="30px"
            pt="20px"
            data-testid="TicktFieeldContentAdvcGrid"
          >
            {fieldData?.row1?.map((fieldInfo) => (
              <GridItem
                key={fieldInfo.label}
                w="100%"
                data-testid="TicktFieeldContentAdvGridItem"
              >
                <Text
                  mb="8px"
                  as="label"
                  className="SCLabel"
                  data-testid="TicktFieeldContentFieldAdvText"
                >
                  {fieldInfo.label}
                </Text>
                {fieldInfo.render ? (
                  fieldInfo.render()
                ) : (
                  <Input
                    onChange={(e) => fieldInfo.onChange(e.target.value)}
                    value={fields?.[fieldInfo?.valueKey] || ''}
                    placeholder={fieldInfo.placeholder}
                    focusBorderColor={useColorModeValue(LIGHT, DARK)}
                  />
                )}
              </GridItem>
            ))}
          </Grid>
          {fieldData?.row5?.length > 0 && (
            <Grid
              templateColumns="repeat(2, 1fr)"
              gap="30px"
              pt="35px"
              data-testid="TicktFieeldContentRow5FieldGrid"
            >
              <GridItem
                key={fieldData?.row5[0]?.label}
                w="100%"
                data-testid="TicktFieeldContentFieldGridIyem"
              >
                <Text
                  mb="8px"
                  as="label"
                  className="SCLabel"
                  data-testid="TicktFieeldContentDataRow5Text"
                >
                  {`${fieldData?.row5[0]?.label}`}
                  {fieldData?.row5[0]?.required && (
                    <span className={styles.required}>{' *'}</span>
                  )}
                </Text>
                {fieldData?.row5[0]?.render()}
              </GridItem>
              {fieldData?.row5[0]?.value === CUSTUM_TYPE.CUSTOM && (
                <GridItem
                  key={fieldData?.row5[1]?.label}
                  w="100%"
                  data-testid="TicktFieeldContentrow5ReqGriditem"
                >
                  <Text mb="8px" as="label" className="SCLabel">
                    {`${fieldData?.row5[1]?.label}`}
                    {fieldData?.row5[1]?.required && (
                      <span className={styles.required}>{' *'}</span>
                    )}
                  </Text>
                  {fieldData?.row5[1]?.render()}
                </GridItem>
              )}
            </Grid>
          )}
          {!!fieldData?.row2?.length && (
            <Grid
              pb="30px"
              templateColumns={
                type === FieldTypes.MEDIA ? 'repeat(1, 1fr)' : 'repeat(2, 1fr)'
              }
              gap="30px"
              pt="20px"
              data-testid="TicktFieeldContentRow2Grid"
            >
              {fieldData?.row2?.map((fieldInfo) => (
                <GridItem
                  gap="10px"
                  key={fieldInfo.label}
                  w="100%"
                  data-testid="TicktFieeldContentRow2GridItem"
                >
                  <Text
                    mb="8px"
                    as="label"
                    className="SCLabel"
                    data-testid="TicktFieeldContentRow2GridItemText"
                  >
                    {fieldInfo.label}
                  </Text>
                  {fieldInfo?.render ? (
                    fieldInfo.render(fields)
                  ) : (
                    <FormControl
                      isInvalid={isError[fieldInfo.key] || false}
                      data-testid="TicktFieeldContentRow2FOrmControl"
                    >
                      <NumberInput
                        onChange={(value) => {
                          fieldInfo.onChange(value);
                        }}
                        value={fields?.[fieldInfo?.valueKey] || ''}
                        focusBorderColor={useColorModeValue(LIGHT, DARK)}
                      >
                        <NumberInputField />
                        <NumberInputStepper>
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </NumberInput>
                      <FormErrorMessage>
                        {fieldInfo.errorString}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </GridItem>
              ))}
            </Grid>
          )}
          {!!fieldData?.row4?.length && (
            <Grid
              pb="30px"
              templateColumns="repeat(1, 1fr)"
              gap="30px"
              pt="20px"
              data-testid="TicktFieeldContentRow4GridL"
            >
              {fieldData?.row4?.map((fieldInfo) => (
                <GridItem
                  gap="10px"
                  key={fieldInfo.label}
                  w="100%"
                  data-testid="TicktFieeldContentRow4GridItem"
                >
                  <Text
                    mb="8px"
                    as="label"
                    className="SCLabel"
                    data-testid="TicktFieeldContentRow4GridItemText"
                  >
                    {fieldInfo.label}
                  </Text>
                  {fieldInfo.render ? (
                    fieldInfo.render()
                  ) : (
                    <Textarea
                      value={fields?.[fieldInfo?.valueKey] || ''}
                      onChange={(e) => fieldInfo.onChange(e.target.value)}
                      placeholder={fieldInfo.Placeholder}
                      size="sm"
                      focusBorderColor={useColorModeValue(LIGHT, DARK)}
                    />
                  )}
                </GridItem>
              ))}
            </Grid>
          )}
          <Grid
            templateColumns="repeat(3, 1fr)"
            gap="30px"
            pt="20px"
            data-testid="TicktFieeldContentRow3Gridd"
          >
            {fieldData?.row3?.map((fieldInfo) => {
              if (
                collectionData?.isMulti &&
                fieldInfo.label === LABELS.FIELD_PROPERTIES.REPORTABLE
              ) {
                return null;
              }
              // if (
              //   (collectionData?.isMulti === false ||
              //     collectionData?.isMulti === undefined) &&
              //   fieldInfo.label === LABELS.FIELD_PROPERTIES.INCLUDE_IN_REPORT
              // ) {
              //   return null;
              // }

              if (Object.keys(collectionData).length !== 0 &&
                fieldInfo.label === LABELS.FIELD_PROPERTIES.INCLUDE_IN_REPORT) {
                  return null;
              }

              const fieldKeys = keys(fields);
              if (
                !edit &&
                !includes(fieldKeys, 'is_reportable') &&
                !includes(fieldKeys, 'is_searchable') &&
                !includes(fieldKeys, 'required') &&
                !includes(fieldKeys, 'include_in_report')
              ) {
                fields.is_reportable = true;
                fields.is_searchable = true;
                fields.required = true;
                fields.include_in_report = true;
              }
              // if (!edit && includes(fieldKeys, 'include_in_report')) {
              //   fields.include_in_report = true;
              // }
              return (
                <GridItem
                  gap="10px"
                  key={fieldInfo.label}
                  w="100%"
                  data-testid="TicktFieeldContentRow3GridIteem"
                >
                  <Checkbox
                    isChecked={fields?.[fieldInfo?.valueKey] || false}
                    colorScheme="customBlue"
                    onChange={(e) => fieldInfo.onChange(e.target.checked)}
                    data-testid="TicktFieeldContentRow3Gridtemcheck"
                    isDisabled={!!fields?.defaultFieldBot}
                  >
                    {fieldInfo.label}
                  </Checkbox>
                </GridItem>
              );
            })}
          </Grid>
        </>
      )}
      {activeSetting === SettingsTabTypes.ROLE && (
        <>
          <Box mt="20px">
            <DynamicMutliSelectWithInputTags
              values={fields[TEMPLATE_ROLE_SETTINGS.VIEWERS] || []}
              label="Viewers"
              onChange={(value) => {
                setFields((prev) => ({
                  ...prev,
                  [TEMPLATE_ROLE_SETTINGS.VIEWERS]: value,
                }));
              }}
              templateId={id}
              selectOptions={
                isTemplateLevel
                  ? roleSettingSelectOpt
                  : CreateFlowRoleSettingOptions
              }
            />
            {!checkForV2() && (
              <DynamicMutliSelectWithInputTags
                values={fields[TEMPLATE_ROLE_SETTINGS.RESTRICTREAD] || []}
                label="Restrict Read"
                onChange={(value) => {
                  setFields((prev) => ({
                    ...prev,
                    [TEMPLATE_ROLE_SETTINGS.RESTRICTREAD]: value,
                  }));
                }}
                templateId={id}
                selectOptions={roleSettigWorkflowOpt}
              />
            )}
            <Box
              mt="20px"
              mb="10px"
              data-testid="TicktFieeldContentRow3DynamicBox"
            >
              <DynamicMutliSelectWithInputTags
                values={fields[TEMPLATE_ROLE_SETTINGS.EDITORS] || []}
                label="Editors"
                onChange={(value) => {
                  setFields((prev) => ({
                    ...prev,
                    [TEMPLATE_ROLE_SETTINGS.EDITORS]: value,
                  }));
                }}
                templateId={id}
                selectOptions={
                  isTemplateLevel
                    ? roleSettingSelectOpt
                    : CreateFlowRoleSettingOptions
                }
              />
            </Box>
          </Box>
          {isTemplateLevel ? (
            <>
              <Box mt="20px">
              <Flex
                justifyContent="flex-start"
                marginLeft="26px"
                marginTop="18px"
              />
                {expLoad && <Progress size="xs" isIndeterminate />}
                <ConditionsExpressions
                  templateId={id}
                  value={fields[TEMPLATE_ROLE_SETTINGS.EXPRESSION] || {}}
                  onChange={(value) => {
                    const { and } = value || {};
                    const updatedValue = isEmptyCondition(and) ? {} : value;
                    setFields((prev) => ({
                      ...prev,
                      [TEMPLATE_ROLE_SETTINGS.EXPRESSION]: updatedValue,
                    }));
                  }}
                  label={FIELD_PROPERTY_LABELS.SHOW_ON_MATCH}
                />
              </Box>
              <ConditionsExpressions
                templateId={id}
                value={fields[TEMPLATE_ROLE_SETTINGS.ENABLED_EXPRESSION] || {}}
                onChange={(value) => {
                  const { and } = value || {};
                  const updatedValue = isEmptyCondition(and) ? {} : value;
                  setFields((prev) => ({
                    ...prev,
                    [TEMPLATE_ROLE_SETTINGS.ENABLED_EXPRESSION]: updatedValue,
                  }));
                }}
                label={FIELD_PROPERTY_LABELS.ENABLE_ON_MATCH}
              />
              <ConditionsExpressions
                templateId={id}
                value={fields[TEMPLATE_ROLE_SETTINGS.REQUIRED_EXPRESSION] || {}}
                onChange={(value) => {
                  const { and } = value || {};
                  const updatedValue = isEmptyCondition(and) ? {} : value;
                  setFields((prev) => ({
                    ...prev,
                    [TEMPLATE_ROLE_SETTINGS.REQUIRED_EXPRESSION]: updatedValue,
                  }));
                }}
                label={FIELD_PROPERTY_LABELS.REQUIRED_ON_MATCH}
              />
            </>
          ) : (
            <Box />
          )}
        </>
      )}
    </>
  );
};

FieldContent.propTypes = {
  baseData: PropTypes.instanceOf(Object).isRequired,
  isError: PropTypes.bool.isRequired,
  fieldData: PropTypes.instanceOf(Object).isRequired,
  checkIsAllRequireTrue: PropTypes.func.isRequired,
  activeSetting: PropTypes.string.isRequired,
  setActiveSetting: PropTypes.func.isRequired,
  SETTINGS_TABS: PropTypes.instanceOf([]).isRequired,
  SETTINGS_TABS_LABEL_MAP: PropTypes.instanceOf(Object).isRequired,
  setCollectionData: PropTypes.func.isRequired,
  collectionData: PropTypes.object.isRequired,
  setFields: PropTypes.func.isRequired,
  fields: PropTypes.object.isRequired,
  edit: PropTypes.bool,
  type: PropTypes.string.isRequired,
  setIsError: PropTypes.func,
};

FieldContent.defaultProps = { edit: false, setIsError: () => {} };

export default FieldContent;
