import React, { useCallback, useMemo, useState } from 'react';

import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import CustomInput from '../../components/InputBox/Input';
import DynamicMutliSelectWithInputTags from '../../components/dynamicSelectWithInputTags/DynamicSelectWithInputTags';
import CustomTextArea from '../../components/Textarea/CustomTextArea';
import CustomDrawer from '../TenantFilters/CustomDrawer';
import CustomInderminateCheckBox from '../../components/IndeterminateCheckbox';

import { roleSettingSelectOpt } from '../../utils/enums/selectOption';
import { FORM_MAP, validatePayload } from './utils';

import styles from './audience.module.scss';
import { getAudience } from '../../redux/FieldDefaults';

const AddNewAudience = ({
  selectedAudience,
  onCreateOrEditAudience,
  intentOptions,
  open,
  setOpen,
  isLoading,
}) => {
  const [data, setData] = useState({ ...(selectedAudience || {}) });
  const [errorKeyListWithMessage, setErrorKeyListWithMessage] = useState({});
  const audiences = useSelector(getAudience);
  const onDrawerClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleChange = useCallback((key, value) => {
    setData((prev) => ({
      ...prev,
      [key]: value,
    }));

    setErrorKeyListWithMessage((prev) => ({
      ...prev,
      [key]: '',
    }));
  }, []);

  const drawerBody = useMemo(
    () => (
      <>
        <div className="mb-4">
          <CustomInput
            label="Name"
            value={data?.[FORM_MAP.NAME] || ''}
            onChange={(e) => handleChange(FORM_MAP.NAME, e.target.value)}
            isError={errorKeyListWithMessage?.[FORM_MAP.NAME] || false}
            errorMessage={errorKeyListWithMessage?.[FORM_MAP.NAME] || ''}
            isMandatory
          />
        </div>

        <div className="mb-4">
          <CustomInderminateCheckBox
            label="Intent Groups"
            data={intentOptions}
            value={data?.[FORM_MAP.INTENT_GROUPS] || []}
            onChange={(e) => handleChange(FORM_MAP.INTENT_GROUPS, e)}
            isMandatory
            errorMessage={
              errorKeyListWithMessage?.[FORM_MAP.INTENT_GROUPS] || ''
            }
            parentSelectsChild={false}
          />
        </div>

        <div className={styles.dynamicSelect}>
          <DynamicMutliSelectWithInputTags
            values={data?.[FORM_MAP.ROLE] || {}}
            label="Roles"
            isMandatory
            selectOptions={roleSettingSelectOpt}
            onChange={(e) => handleChange(FORM_MAP.ROLE, e)}
            includeUserAttributes
          />

          {errorKeyListWithMessage?.[FORM_MAP.ROLE] ? (
            <span className="field-error">
              {errorKeyListWithMessage?.[FORM_MAP.ROLE]}
            </span>
          ) : null}
        </div>

        <div>
          <CustomTextArea
            label="Description"
            value={data?.[FORM_MAP.DESCRIPTION] || ''}
            onChange={(e) => handleChange(FORM_MAP.DESCRIPTION, e.target.value)}
          />
        </div>
      </>
    ),
    [errorKeyListWithMessage, data, handleChange, intentOptions]
  );

  const handleSave = useCallback(() => {
    const isNameExist = audiences?.some(x=>x?.name === data?.name);
    const validationCheck = validatePayload(data, isNameExist);

    if (!validationCheck.success) {
      setErrorKeyListWithMessage(validationCheck.message);
      return;
    }

    onCreateOrEditAudience(validationCheck.payload);
  }, [data, onCreateOrEditAudience]);

  return (
    <CustomDrawer
      title="Audience"
      closeDrawer={onDrawerClose}
      handleSave={handleSave}
      open={open}
      drawerBody={drawerBody}
      isLoading={isLoading}
    />
  );
};

AddNewAudience.propTypes = {
  selectedAudience: PropTypes.object,
  intentOptions: PropTypes.array,
  onCreateOrEditAudience: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

AddNewAudience.defaultProps = {
  selectedAudience: {},
  intentOptions: [],
  isLoading: false,
};

export default AddNewAudience;
