import { getMentionOptionLabel, parseCustomMentionToAPI } from '../../pages/CommunicationTemplates/utills';
import { convertFieldType } from '../../pages/MasterScreen/ConfigJson/helpers';
import { FieldTypes } from '../../utils/enums/types';

const HARDCODED_HTML_NOTE_LIST = [
  { id: 'note.current_note', display: 'Current Note' },
  {
    id: 'note.all_public_notes_latest_first',
    display: 'All Public Notes ( Latest first)',
  },
  {
    id: 'note.all_public_notes_latest_last',
    display: 'All Public Notes ( Latest Last)',
  },
  { id: 'note.all_notes_latest_first', display: 'All Notes (Latest First)' },
  { id: 'note.all_notes_latest_last', display: 'All Notes (Latest Last)' },
  { id: 'note.latest_public_note', display: 'Latest Public Note' },
  { id: 'note.latest_note', display: 'Latest Note' },
];

const createFieldOptionsForCustomMention = (template, showQueueAndActorEmailOnly, isCannedNotes) => {
  if (showQueueAndActorEmailOnly) {
    const queueDropdownFieldList = [...(template?.fields || [])].filter((data) => data.type === FieldTypes.DROPDOWN && data.type_based_attributes?.collection.collection_type === 'SPECIAL_FIELD' && data.type_based_attributes?.collection.specialField === 'queue');
    const QUEUE_LIST = queueDropdownFieldList.map((item) => {
      const queueOptions = ['Supervisors', 'members', 'watchers'];
      return queueOptions.map((q) => ({
        id: `queueData['${item.id}']?.${q}`,
        display: `${item.label}.${q}`,
      }));
    });

    return QUEUE_LIST.flat();
  }

  const SLA_DATA = Object.values(
    template?.slas || {}
  )
    .flatMap((data) => data)
    .map((item) => ({
      id: `sla['${item.id}']`,
      display: getMentionOptionLabel({
        name: item.name,
        type: 'Sla',
      }),
    }));

  const METRIC_DATA = [
    ...(template?.metric || []),
  ].map((item) => ({
    id: `metric['${item.id}']`,
    display: getMentionOptionLabel({
      name: item.name,
      type: 'Metric',
    }),
  }));

  const FIELD_DATA = [...(template?.fields || [])]
    .filter((data) =>
      showQueueAndActorEmailOnly
        ? data.type === FieldTypes.DROPDOWN &&
            data.type_based_attributes?.collection.collection_type ===
              'SPECIAL_FIELD' &&
            data.type_based_attributes?.collection.specialField === 'queue'
        : true
    )
    .filter((item) => !(isCannedNotes && item?.type === 'MEDIA'))
    .map((item) => ({
      id: showQueueAndActorEmailOnly
        ? `queueAssociation['${item.id}']`
        : `ticket.data['${item.id}']`,
      display: getMentionOptionLabel({
        name: item?.label,
        type: convertFieldType({ rowData: item }),
      }),
    }));

  return [...FIELD_DATA, ...SLA_DATA, ...METRIC_DATA, ...HARDCODED_HTML_NOTE_LIST];
};

const createActorOptionsForCustomMention = (template, showQueueAndActorEmailOnly) => {
  const currentTemplateActorsOptions = [];
  [...(template?.actors || [])].forEach((item) => {
    if (showQueueAndActorEmailOnly) {
      const actorUserNameOption = {
        id: `actor['${item.id}']?.userResponse?.email`,
        display: getMentionOptionLabel({
          name: item.name,
          type: 'Actor',
          subType: 'Email',
        }),
      };

      currentTemplateActorsOptions.push(actorUserNameOption);
    } else {
      const actorFirstNameOption = {
        id: `actor['${item.id}']?.userResponse?.first_name`,
        display: getMentionOptionLabel({
          name: item.name,
          type: 'Actor',
          subType: 'First Name',
        }),
      };

      const actorLastNameOption = {
        id: `actor['${item.id}']?.userResponse?.last_name`,
        display: getMentionOptionLabel({
          name: item.name,
          type: 'Actor',
          subType: 'Last Name',
        }),
      };
      currentTemplateActorsOptions.push(actorFirstNameOption);
      currentTemplateActorsOptions.push(actorLastNameOption);
    }
  });

  if (showQueueAndActorEmailOnly) {
    const queueDropdownFieldList = [...(template?.fields || [])].filter((data) => data.type === FieldTypes.DROPDOWN && data.type_based_attributes?.collection.collection_type === 'SPECIAL_FIELD' && data.type_based_attributes?.collection.specialField === 'queue');
    const QUEUE_LIST = queueDropdownFieldList.map((item) => {
      const queueOptions = ['Supervisors', 'members', 'watchers'];
      return queueOptions.map((q) => ({
        id: `queueData['${item.id}']?.${q}`,
        display: `${item.label}.${q}`,
      }));
    }).flat();

    currentTemplateActorsOptions.push(...QUEUE_LIST);
  }
  return currentTemplateActorsOptions;
};

const HARDCODED_FIELDS_LIST = [
  { id: 'ticket.object_id', display: 'Ticket ID' },
  { id: 'ticketLink', display: 'Ticket Link' },
  { id: 'created_at', display: 'Created At' },
];

const HARDCODED_RELATED_FIELDS_LIST = [
  { id: 'parentTicket.object_id', display: 'Ticket ID - Related' },
];

const HARDCODED_ACTORS_LIST = [
  { id: 'cc_watchers', display: 'CC Watcher' },
  { id: 'to_watchers', display: 'To Watcher' },
  { id: 'submitted_by', display: 'Submitted By' },
  { id: 'actioned_by', display: 'Actioned By' },
];

const mentonHardCodeName = {
  NOTES: 'notes'
};
export const { NOTES } = mentonHardCodeName;

const getAllFieldOptionsForCustomMention = ({
  showQueueAndActorEmailOnly,
  includeLinkedItemFields,
  currentTemplateFieldSLAMetricOptions = [],
  relatedItemFields = [],
  relatedItemSla = [],
  relatedItemMetric = [],
  mentions,
  isCannedNotes
}) => {
  if (showQueueAndActorEmailOnly) {
    return [...currentTemplateFieldSLAMetricOptions];
  }
  let allFields = [...HARDCODED_FIELDS_LIST];

  if (mentions) {
    allFields = allFields.filter((field) => field.id !== 'ticketLink');
  }

  if (isCannedNotes) {
    allFields = allFields.filter((field) => field.id !== 'ticketLink');
  }

  if (includeLinkedItemFields) {
    allFields.push(...HARDCODED_RELATED_FIELDS_LIST);
  }

  allFields.push(...currentTemplateFieldSLAMetricOptions);

  if (includeLinkedItemFields) {
    allFields.push(...relatedItemFields);
    allFields.push(...relatedItemSla);
    allFields.push(...relatedItemMetric);
  }

  return allFields;
};

const getAllActorOptionsForCustomMention = ({
  showQueueAndActorEmailOnly,
  currentTemplateActorOptionsVal = [],
  relatedItemActorOptions = [],
}) => {
  if (showQueueAndActorEmailOnly) {
    return [...currentTemplateActorOptionsVal, ...HARDCODED_ACTORS_LIST];
  }
  return [...HARDCODED_ACTORS_LIST, ...currentTemplateActorOptionsVal, ...relatedItemActorOptions];
};

const getAllHardCodeMentionList = (showHardCodMention) => {
  const hardCodeMention = [];
  if (showHardCodMention?.includes(NOTES)) {
    return [...hardCodeMention, ...HARDCODED_HTML_NOTE_LIST];
  }
  return hardCodeMention;
};

const replaceMentionWithFieldAndActorOptions = (text, fieldOptions, actorOptions) => {
  const fieldOptionMap = {};
  fieldOptions.forEach((option) => {
    fieldOptionMap[option.id] = option.display;
  });

  Object.keys(fieldOptionMap).forEach((fieldKey) => {
    text = text.replaceAll(`<%= ${fieldKey} %>`, `$${fieldOptionMap[fieldKey]}`);
  });

  const actorOptionMap = {};
  actorOptions.forEach((option) => {
    actorOptionMap[option.id] = option.display;
  });

  Object.keys(actorOptionMap).forEach((actorKey) => {
    text = text.replaceAll(`<%= ${actorKey} %>`, `@${actorOptionMap[actorKey]}`);
  });
  return text;
};

const convertMentionToHTML = (htmlString, template) => {
  const parsedFromCustomToAPI = parseCustomMentionToAPI(htmlString);

  const currentTemplateFieldOptions = createFieldOptionsForCustomMention(
    template,
    false
  );

  const currentTemplateActorOptions = createActorOptionsForCustomMention(
    template,
    false
  );

  const allFieldOptions = getAllFieldOptionsForCustomMention({
    currentTemplateFieldSLAMetricOptions: currentTemplateFieldOptions,
  });

  const allActorOptions = getAllActorOptionsForCustomMention({
    currentTemplateActorOptionsVal: currentTemplateActorOptions,
  });

  return replaceMentionWithFieldAndActorOptions(
    parsedFromCustomToAPI,
    allFieldOptions,
    allActorOptions
  );
};

export {
  HARDCODED_FIELDS_LIST,
  HARDCODED_RELATED_FIELDS_LIST,
  HARDCODED_ACTORS_LIST,
  createFieldOptionsForCustomMention,
  createActorOptionsForCustomMention,
  getAllFieldOptionsForCustomMention,
  getAllActorOptionsForCustomMention,
  getAllHardCodeMentionList,
  replaceMentionWithFieldAndActorOptions,
  convertMentionToHTML,
};
