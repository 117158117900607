import Joi from 'joi';
import axiosInstance from '../../utils/axios/axios';
import { getTenantData } from '../../helpers/tenantUrl';
import { FieldTypes } from '../../utils/enums/types';

export const emptyMailObj = {
  protocol: 'IMAP',
  host: '',
  port: '',
  userName: '',
  password: '',
  authType: '',
  redirectUrl: '',
  clientId: '',
  clientSecret: '',
  refreshToken: '',
  maxTry: '',
  coolOffTime: '',
  skipTime: '',
  timeout: '',
  azureDirId: '',
  folderMapping: [],
  allowedDomains: [],
  excludeAutoReplies: [],
  aliasToEmail: [],
  lifecycle_status: '',
  itemTypeId: '',
  offerId: '',
  fieldMapping: {},
  scope: '',
  tokenEndpoint: ''
};

export const AUTH_TYPES = {
  PASSWORD: 'PASSWORD',
  O_AUTH: 'OAUTH',
};

export const AUTH_TYPE = [
  { value: AUTH_TYPES.PASSWORD, label: 'Password' },
  { value: AUTH_TYPES.O_AUTH, label: 'O Auth' },
];

export const TICKET_STATUS = [
  { value: 'new', label: 'New' },
  { value: 'closed', label: 'Closed' },
];

export const validateCreateMailPayload = Joi.object({
  protocol: Joi.string().required().messages({
    'string.empty': 'Protocol is required',
    'any.required': 'Protocol is required',
  }),
  host: Joi.string().required().messages({
    'string.empty': 'Host is required',
    'any.required': 'Host is required',
  }),
  port: Joi.number().min(0).max(65535).required()
    .messages({
      'number.base': 'Port must be a number',
      'number.empty': 'Port is required',
      'number.min': 'Port must be greater than or equal to 0',
      'number.max': 'Port must be less than or equal to 65535',
      'any.required': 'Port is required',
    }),
  userName: Joi.string().email({ tlds: { allow: false } }).required().messages({
    'string.empty': 'User name is required',
    'string.email': 'User name must be a valid email address',
    'any.required': 'User name is required',
  }),
  authType: Joi.string().required().messages({
    'string.empty': 'Auth Type is required',
    'any.required': 'Auth Type is required',
  }),
  maxTry: Joi.number().greater(0).required().messages({
    'number.base': 'Maximum tries must be a number',
    'any.required': 'Maximum tries is required',
    'number.greater': 'Maximum tries must be greater than 0.',
  }),
  coolOffTime: Joi.number().greater(0).required().messages({
    'number.base': 'Cool of time must be a number',
    'any.required': 'Cool of time is required',
    'number.greater': 'Cool of time must be greater than 0.',
  }),
  skipTime: Joi.number().greater(0).required().messages({
    'number.base': 'Skip time must be a number',
    'number.greater': 'Skip time must be greater than 0.',
  }),
  timeout: Joi.number().greater(0).required().messages({
    'number.base': 'Timeout must be a number',
    'number.greater': 'Timeout must be greater than 0.',
  }),
  password: Joi.when('authType', {
    is: AUTH_TYPES.PASSWORD,
    then: Joi.string().required().messages({
      'string.empty': 'Password is required',
      'any.required': 'Password is required',
    }),
    otherwise: Joi.string().allow('', null),
  }),
  azureDirId: Joi.when('authType', {
    is: AUTH_TYPES.O_AUTH,
    then: Joi.string().required().messages({
      'string.empty': 'Azure Active Directory Tenant Id is required',
      'any.required': 'Azure Active Directory Tenant Id is required',
    }),
    otherwise: Joi.string().allow('', null),
  }),
  redirectUrl: Joi.when('authType', {
    is: AUTH_TYPES.O_AUTH,
    then: Joi.string().required().messages({
      'string.empty': 'RedirectUrl is required',
      'any.required': 'RedirectUrl is required',
    }),
    otherwise: Joi.string().allow('', null),
  }),
  clientId: Joi.when('authType', {
    is: AUTH_TYPES.O_AUTH,
    then: Joi.string().required().messages({
      'string.empty': 'ClientId is required',
      'any.required': 'clientId is required',
    }),
    otherwise: Joi.string().allow('', null),
  }),
  clientSecret: Joi.when('authType', {
    is: AUTH_TYPES.O_AUTH,
    then: Joi.string().required().messages({
      'string.empty': 'ClientSecret is required',
      'any.required': 'ClientSecret is required',
    }),
    otherwise: Joi.string().allow('', null),
  }),
  scope: Joi.when('authType', {
    is: AUTH_TYPES.O_AUTH,
    then: Joi.string().required().messages({
      'string.empty': 'Scope is required',
      'any.required': 'Scope is required',
    }),
    otherwise: Joi.string().allow('', null),
  }),
  tokenEndpoint: Joi.when('authType', {
    is: AUTH_TYPES.O_AUTH,
    then: Joi.string().required().messages({
      'string.empty': 'Token Endpoint is required',
      'any.required': 'Token Endpoint is required',
    }),
    otherwise: Joi.string().allow('', null),
  }),
  refreshToken: Joi.when('authType', {
    is: AUTH_TYPES.O_AUTH,
    then: Joi.string().required().messages({
      'string.empty': 'RefreshToken is required',
      'any.required': 'RefreshToken is required',
    }),
    otherwise: Joi.string().allow('', null),
  }),
  folderMapping: Joi.array().min(1).items(Joi.object()),
  allowedDomains: Joi.array()
    .items(
      Joi.string().domain().required().messages({
        'array.min': 'add allowed domains at least one',
        'any.required': 'add domain type'
      })
    ).required(),
  excludeAutoReplies: Joi.array()
    .items(Joi.string().email({ tlds: { allow: false } })).empty(),
  aliasToEmail: Joi.array().items(Joi.string().email({ tlds: { allow: false } })).empty(),
  lifecycle_status: Joi.string(),
  itemTypeId: Joi.string().required().messages({
    'string.empty': 'Template is required',
    'any.required': 'Template is required',
  }),
  offerId: Joi.string().allow(''),
  fieldMapping: Joi.object(),
});

export const validateCreateMailPayloadOAuth = validateCreateMailPayload.keys({
  password: Joi.string().allow('', null),
  azureDirId: Joi.string().required().messages({
    'string.empty': 'Azure Active Directory Tenant Id is required',
    'string.base': 'Azure Active Directory Tenant Id is required',
    'any.required': 'Azure Active Directory Tenant Id is required',
  }),
  redirectUrl: Joi.string().required().messages({
    'string.empty': 'Redirect URL is required',
    'string.base': 'Redirect URL is required',
    'any.required': 'Redirect URL is required',
  }),
  clientId: Joi.string().required().messages({
    'string.empty': 'Client ID is required',
    'string.base': 'Client ID is required',
    'any.required': 'Client ID is required',
  }),
  clientSecret: Joi.string().required().messages({
    'string.empty': 'Client Secret is required',
    'string.base': 'Client Secret is required',
    'any.required': 'Client Secret is required',
  }),
  refreshToken: Joi.string().required().messages({
    'string.empty': 'Refresh Token is required',
    'string.base': 'Refresh Token is required',
    'any.required': 'Refresh Token is required',
  }),
});

export const validateCreateMailPayloadPassword = validateCreateMailPayload.keys({
  password: Joi.string().required().messages({
    'string.empty': 'Password is required',
    'string.base': 'Password is required',
    'any.required': 'Password is required',
  }),
  azureDirId: Joi.string().allow('', null),
  redirectUrl: Joi.string().allow('', null),
  clientId: Joi.string().allow('', null),
  clientSecret: Joi.string().allow('', null),
  refreshToken: Joi.string().allow('', null),
});

export const createPayloadForMailServer = (data) => {
  const payload = {
    protocol: data?.protocol || '',
    host: data?.host || '',
    port: data?.port || '',
    userName: data?.userName || '',
    password: data?.password || '',
    authType: data?.authType || '',
    redirectUrl: data?.redirectUrl || '',
    clientId: data?.clientId || '',
    clientSecret: data?.clientSecret || '',
    refreshToken: data?.refreshToken || '',
    maxTry: data?.maxTry || null,
    coolOffTime: data?.coolOffTime || '',
    skipTime: data?.skipTime || null,
    timeout: data?.timeout || null,
    azureDirId: data?.azureDirId || '',
    folderMapping: data.folderMapping || [],
    allowedDomains: data.allowedDomains || [],
    excludeAutoReplies: data.excludeAutoReplies || [],
    aliasToEmail: data.aliasToEmail || [],
    lifecycle_status: data.lifecycle_status || 'DRAFT',
    itemTypeId: data.itemTypeId || '',
    offerId: data.offerId || '',
    fieldMapping: data.fieldMapping || {},
    scope: data.scope || 'https://outlook.office365.com/.default',
    tokenEndpoint: data.tokenEndpoint || ''
  };
  return payload;
};

const validateRequiredMappings = (template, payload) => {
  const requiredFieldErrorMessages = {};
  const requiredFields = (template?.fields || [])?.filter((field) => {
    if (!field?.scope || field?.scope === 'TICKET') {
      return field?.type_based_attributes?.required;
    }
    return false;
  });
  const requiredIdLabelMap = {};
  requiredFields.forEach((field) => {
    requiredIdLabelMap[field.id] = field.label;
  });

  const requiredMappings = (payload?.fieldMapping?.requiredMappings || [])
    .map((mapObject) => ({
      id: mapObject.field.value,
      value: mapObject.value,
    }));

  const requiredIdValueMap = {};
  requiredMappings.forEach((mapObject) => {
    requiredIdValueMap[mapObject.id] = mapObject.value;
  });

  if (requiredFields.length !== requiredMappings.length) {
    requiredFieldErrorMessages.requiredMappings = 'All required fields must be mapped';
  }

  const missingFields = [];

  Object.keys(requiredIdLabelMap).forEach((id) => {
    if (requiredIdValueMap[id] === undefined || requiredIdValueMap[id] === '' || requiredIdValueMap[id] === null) {
      missingFields.push(requiredIdLabelMap[id]);
    }
  });

  if (missingFields.length > 0) {
    requiredFieldErrorMessages.requiredMappings = `The following fields are required: ${missingFields.join(', ')}`;
  }
  return requiredFieldErrorMessages;
};

const validateEmailMappings = (payload) => {
  const emailMappingErrorMessages = {};
  if (!payload.fieldMapping?.attachmentFieldId) {
    emailMappingErrorMessages.attachment = 'Attachment field is required';
  }
  if (!payload.fieldMapping?.emailAttachmentFieldId && payload.fieldMapping.isEmailAttachmentShow) {
    emailMappingErrorMessages.emailAttachmentFieldId = 'Email as attachment is required';
  }
  const emailFields = {};
  payload.fieldMapping?.emailMapping?.forEach((mapObject) => {
    if (mapObject.emailKey === 'subject') {
      emailFields.subject = mapObject.fieldId;
    }

    if (mapObject.emailKey === 'body') {
      emailFields.body = mapObject.fieldId;
    }

    if (mapObject.emailKey === 'importance') {
      emailFields.importance = mapObject.fieldId;
      emailFields.importanceMaps = mapObject.mapping || {};
    }

    if (mapObject.emailKey === 'sender') {
      emailFields.sender = mapObject.fieldId;
    }
  });

  if (!emailFields.subject) {
    emailMappingErrorMessages.subject = 'Subject field is required';
  }

  if (!emailFields.body) {
    emailMappingErrorMessages.body = 'Body field is required';
  }
  if (!emailFields.sender) {
    emailMappingErrorMessages.sender = 'Sender field is required';
  }

  if (!emailFields.importance) {
    emailMappingErrorMessages.importance = 'Importance field is required';
  } else {
    if (!emailFields.importanceMaps.high) {
      emailMappingErrorMessages.high = 'High value is required';
    }

    if (!emailFields.importanceMaps.medium) {
      emailMappingErrorMessages.medium = 'Medium value is required';
    }

    if (!emailFields.importanceMaps.low) {
      emailMappingErrorMessages.low = 'Low value is required';
    }
  }

  return emailMappingErrorMessages;
};

const validateOptionalMappings = (template, payload) => {
  const allFields = (template?.fields || []);
  const allFieldsIdLabelMap = {};
  allFields.forEach((field) => {
    allFieldsIdLabelMap[field.id] = field.label;
  });

  const optionalMappings = (payload?.fieldMapping?.optionalMappings || [])
    .map((mapObject) => ({
      id: mapObject.field.value,
      value: mapObject.value,
    }));

  const optionalIdValueMap = {};
  optionalMappings.forEach((mapObject) => {
    optionalIdValueMap[mapObject.id] = mapObject.value;
  });

  const missingFields = [];
  const optionalMappingErrorMessages = {};

  Object.keys(optionalIdValueMap).forEach((id) => {
    if (optionalIdValueMap[id] === undefined || optionalIdValueMap[id] === '' || optionalIdValueMap[id] === null) {
      missingFields.push(allFieldsIdLabelMap[id]);
    }
  });

  if (missingFields.length > 0) {
    optionalMappingErrorMessages.optionalMappings = `Missing value for following fields: ${missingFields.join(', ')}`;
  }
  return optionalMappingErrorMessages;
};
export const validateUserName = (payload, allMailList) => {
  if (payload.lifecycle_status === 'PUBLISHED') {
    const usernameCount = allMailList
      .filter((item) => item.userName.toLowerCase() === payload.userName.toLowerCase()).length;
    if (usernameCount >= 1) {
      return { userName: 'Username already taken. Choose another.' };
    }
    return {};
  }
  return {};
};

export const customValidations = (payload, template) => {
  if (!template) {
    return {};
  }

  const requiredFieldErrorMessages = validateRequiredMappings(template, payload);
  const emailMappingErrorMessages = validateEmailMappings(payload);
  const optionalMappingErrorMessages = validateOptionalMappings(template, payload);
  return {
    ...requiredFieldErrorMessages,
    ...emailMappingErrorMessages,
    ...optionalMappingErrorMessages,
  };
};

export const errorToTabIndexMap = {
  protocol: 0,
  host: 0,
  port: 0,
  userName: 0,
  authType: 0,
  maxTry: 0,
  coolOffTime: 0,
  skipTime: 0,
  timeout: 0,
  password: 0,
  azureDirId: 0,
  redirectUrl: 0,
  clientId: 0,
  clientSecret: 0,
  refreshToken: 0,
  lifecycle_status: 0,
  folderMapping: 1,
  allowedDomains: 1,
  excludeAutoReplies: 1,
  aliasToEmail: 1,
  itemTypeId: 2,
  offerId: 2,
  fieldMapping: 2,
  optionalMappings: 2,
  requiredMappings: 2,
  attachment: 2,
  subject: 2,
  body: 2,
  importance: 2,
  high: 2,
  medium: 2,
  low: 2,
};

export const processErrorWithMessage = (errors, index = 0) => {
  const errorKeys = {};
  (errors || [])?.forEach((item) => {
    const key = item.path[index];
    if (key === 'itemTypes') {
      errorKeys[item.path[2]] = item.message;
    } else {
      errorKeys[key] = item.message;
    }
  });
  return errorKeys;
};

export const getMailServer = async (id) => {
  try {
    const filterString = `&$filter=${'id'} eq ${id}`;
    const response = await axiosInstance.get(
      `${'emailServerConfig/list'}?$top=${1}&$skip=${0}&$select=${'*'?.toString()}&$orderby=${'created_on desc'}${filterString}`
    );
    if (response.data) {
      return {
        error: null,
        response: response?.data
      };
    }
    return { error: 'some thing went wrong', response: null };
  } catch (error) {
    return {
      error,
      response: null,
    };
  }
};
export const getQueueList = async () => {
  try {
    const tenantInformation = getTenantData();
    const tenantId = tenantInformation?.id;
    const params = {
      tenant_id: tenantId,
    };

    const res = await axiosInstance.get('queueTypes', { params });
    const queue = res?.data?.[0];
    if (!Array.isArray(queue)) {
      return { error: 'some thing went wrong', response: [] };
    }
    const enrichedOptions = queue.map((q) => ({
      label: q?.properties?.queueLabel || q?.queue_name,
      value: q?.id.toString(),
    }));

    return {
      error: null,
      response: enrichedOptions
    };
  } catch (error) {
    return {
      error,
      response: [],
    };
  }
};
export const getStateForMailServerFromApi = (apiState) => {
  const data = {
    ...apiState,
    domain: apiState?.domain[0]?.name,
    emails: apiState?.emails[0],
    itemTypeId: apiState.itemTypes[0].itemTypeId,
    defaultTicketStatus: apiState.itemTypes[0].defaultTicketStatus,
  };
  return data;
};
export const MAIL_SERVER_TAB = [
  {
    id: 'connection',
    title: 'Connection',
    tabType: 'CONNECTION',
  },
  {
    id: 'reading_settings',
    title: 'Reading Settings',
    tabType: 'READING_SETTINGS',
  },
  {
    id: 'ticket_mapping_settings',
    title: 'Ticket Mapping Settings',
    tabType: 'TICKET_MAPPING_SETTINGS',
  },
];
export const FOLDER_MAP_OPTION_LIST = [
  {
    value: 'FAIL_FOLDER',
    label: 'Fail Folder'
  },
  {
    value: 'SUCCESS_FOLDER',
    label: 'Success Folder'
  },
  {
    value: 'EXCLUSION_FOLDER',
    label: 'Exclusion Folder'
  }
];

export const MAIL_FIELD_TYPES = {
  MEDIA: 'MEDIA',
  DROPDOWN: 'DROPDOWN',
  TEXT: 'TEXT',
  STRING: 'STRING',
  OTHER: 'OTHER',
};

export const getFieldType = (field) => {
  if (field.type === FieldTypes.MEDIA) {
    return MAIL_FIELD_TYPES.MEDIA;
  }

  if (field.type === FieldTypes.TEXT || field.type === FieldTypes.TEXT_AREA) {
    return MAIL_FIELD_TYPES.TEXT;
  }

  if (field.type === FieldTypes.RICH_TEXT) {
    return MAIL_FIELD_TYPES.STRING;
  }

  if (field.type === FieldTypes.DROPDOWN && field.type_based_attributes?.collection?.collection_type === 'STATIC') {
    return MAIL_FIELD_TYPES.DROPDOWN;
  }

  return MAIL_FIELD_TYPES.OTHER;
};

export const mergeOptionalAndEmailMappings = (payload) => {
  const { fieldMapping } = payload;
  const { emailMapping, optionalMappings } = fieldMapping;

  return {
    ...payload,
    fieldMapping: {
      attachmentFieldId: fieldMapping?.attachmentFieldId || '',
      emailAttachmentFieldId: fieldMapping?.emailAttachmentFieldId || '',
      emailMapping: [
        ...(emailMapping || []),
        ...(optionalMappings || []).map((mapObj) => ({
          fieldId: mapObj?.field?.value,
          value: mapObj?.value,
        }))
      ],
      requiredMappings: fieldMapping?.requiredMappings || [],
    }
  };
};

export const splitOptionalAndEmailMappings = (fieldMapping = {}, fields = []) => {
  const { emailMapping, requiredMappings } = fieldMapping;

  const emailMappingsArray = [];
  const optionalMappingsArray = [];

  emailMapping?.forEach((mapObj) => {
    if (Object.keys(mapObj).includes('emailKey')) {
      emailMappingsArray.push(mapObj);
    } else {
      optionalMappingsArray.push(mapObj);
    }
  });

  // const requiredFields = fields?.filter((field) => field?.type_based_attributes?.required) || [];
  // let requiredMappingsFallback = requiredMappings;
  // if (offerId && (!requiredMappings || requiredMappings?.length === 0)) {
  //   requiredMappingsFallback = requiredFields.map((field) => ({
  //     field: { label: field.label, value: field.id },
  //     value: undefined,
  //   }));
  // }

  return {
    isEmailAttachmentShow: !!fieldMapping.emailAttachmentFieldId,
    attachmentFieldId: fieldMapping.attachmentFieldId || '',
    emailAttachmentFieldId: fieldMapping.emailAttachmentFieldId || '',
    emailMapping: emailMappingsArray,
    optionalMappings: optionalMappingsArray.map((mapObj) => ({
      field: {
        label: fields.find((field) => field.id === mapObj.fieldId).label,
        value: mapObj.fieldId
      },
      value: mapObj.value,
    })),
    requiredMappings: requiredMappings || [],
  };
};
export const removeMatchingElementsForFolder = (arr1, arr2) => {
  // Create a set of 'source' values from arr1 for quick lookup
  const sourceSet = new Set(arr1.map((item) => item.source));

  // Filter arr2, keeping only those elements whose 'value' is not in the sourceSet
  return arr2.filter((item) => !sourceSet.has(item.value));
};
