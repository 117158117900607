/* eslint-disable import/prefer-default-export */
import React from 'react';

import { Avatar, Flex, Text, Tooltip, Box } from '@chakra-ui/react';
import {
  // CopyIcon, DeleteIcon, EditIcon,
  StarIcon
} from '@chakra-ui/icons';
import {
  TrashIcon,
  PencilSquareIcon,
  Square2StackIcon,
} from '@heroicons/react/24/outline';

import dayjs from 'dayjs';

import TableColumnText from '../../../components/Text/TableColumnText';

import { getColorForStatus } from '../utils';
import { CARD_LISTS_TOOLTIP_LABELS } from '../../../utils/enums/labels';

import styles from '../cardLayout.module.scss';
import { getCreatedByInfo } from '../helpers';
import { isCurrentUserTemplateOwner } from '../../../utils/helper/roleBasedAccess';

export const renderFavorite = ({ isFavorite, toggleFavorite }) => (
    <Box
      className={styles.starIconStyle}
    >
    <Tooltip
      label={
        isFavorite
          ? CARD_LISTS_TOOLTIP_LABELS.UNMARK_AS_FAVORITE
          : CARD_LISTS_TOOLTIP_LABELS.MARK_AS_FAVORITE
      }
      placement="top"
      hasArrow
    >
    {isFavorite ? (
      <StarIcon
        color="yellow"
        width="18"
        height="18px"
        onClick={(e) => {
          e.stopPropagation();
          toggleFavorite();
        }}
      />
    ) : (
      <StarIcon
        color="white"
        width="18px"
        height="18px"
        onClick={(e) => {
          e.stopPropagation();
          toggleFavorite();
        }}
      />
    )}
    </Tooltip>
    </Box>
    // {/* <IconButton
    //   className={styles.starIconStyle}
    //   icon={
    //     isFavorite ? <StarIcon color="yellow" /> : <StarIcon color="white" />
    //   }
    //   onClick={(e) => {
    //     e.stopPropagation();
    //     toggleFavorite();
    //   }}
    // /> */}
);

export const scrollToTemplate = (parentRef, templateId, isScrolled) => {
  if (templateId) {
    const element = parentRef.current?.querySelector(`#template_${templateId}`);
    if (element) {
      element.scrollIntoView({ behavior: 'auto', block: 'center' });
      isScrolled.current = true;
    }
  }
};

export const renderStatus = (lifeCycle = '') => (
  <Text
    className={`${styles.statusStyle} ${getColorForStatus(
      lifeCycle
    )} rounded-[3px] inline`}
  >
    {lifeCycle.split('_').join(' ')}
  </Text>
);

export const columns = ({
  favouriteMap,
  onEdit,
  onDelete,
  onClone,
  toggleFavorite,
}) => {
  const cols = [
    {
      col_name: 'Ticket Type Title',
      cellRenderer: ({ rowData }) => <TableColumnText text={rowData?.name} showLength={23} />,
    },
    {
      col_name: 'Favorite',
      cellRenderer: ({ rowData }) =>
        renderFavorite({
          isFavorite: favouriteMap[rowData.id],
          toggleFavorite: () => toggleFavorite(rowData.id),
        }),
    },
    {
      col_name: 'Description',
      cellRenderer: ({ rowData }) => (
        <TableColumnText text={rowData?.properties?.description} showLength={25} />
      ),
    },
    {
      col_name: 'Prefix',
      cellRenderer: ({ rowData }) => (
        <TableColumnText text={rowData?.properties?.prefix} showLength={10} />
      ),
    },
    {
      col_name: 'Status',
      cellRenderer: ({ rowData }) => renderStatus(rowData?.lifecycle_status),
    },
    {
      col_name: 'Created By',
      cellRenderer: ({ rowData }) => {
        if (!rowData?.properties?.owners) {
          return null;
        }

        return (
          <Flex gap="10px" alignItems="center">
            <Avatar
              size="xs"
              name={rowData?.created_by || rowData?.properties?.owners[0] || ''}
            />
            <TableColumnText text={getCreatedByInfo(rowData)} />
          </Flex>
        );
      },
    },
    {
      col_name: 'Last Updated',
      cellRenderer: ({ rowData }) => {
        if (rowData?.updated_on) {
          return (
            <Box
              fontSize="14px"
              color="#6B7280"
              fontWeight="400"
              lineHeight="normal"
            >
              {dayjs(rowData?.updated_on).format('D MMM YYYY, h:mm A')}
            </Box>
          );
        }
        return '';
      },
    },
    {
      col_name: 'Actions',
      cellRenderer: ({ rowData }) => (
          <div className="flex gap-[10px]">
            <Tooltip
              label={CARD_LISTS_TOOLTIP_LABELS.EDIT}
              placement="top"
              hasArrow
            >
              <PencilSquareIcon
                color="#94A3B8"
                data-testid={`ticket-template-${rowData.name}-edit`}
                className="cursor-pointer h-6 w-6"
                onClick={(e) => {
                  e.stopPropagation();
                  onEdit(rowData.id, rowData.name);
                }}
              />
            </Tooltip>

            <Tooltip
              label={CARD_LISTS_TOOLTIP_LABELS.CLONE}
              placement="top"
              hasArrow
            >
              <Square2StackIcon
                color="#94A3B8"
                className="cursor-pointer h-6 w-6"
                data-testid={`ticket-template-${rowData.name}-clone`}
                onClick={(e) => {
                  e.stopPropagation();
                  onClone(rowData.id, rowData.name);
                }}
              />
            </Tooltip>

            {!rowData?.wasApproved &&
              isCurrentUserTemplateOwner(rowData, false) && (
                <Tooltip
                  label={CARD_LISTS_TOOLTIP_LABELS.DELETE}
                  placement="top"
                  hasArrow
                >
                  <TrashIcon
                    color="#94A3B8"
                    className="cursor-pointer h-6 w-6"
                    data-testid={`ticket-template-${rowData.name}-delete`}
                    onClick={(e) => {
                      e.stopPropagation();
                      onDelete(rowData.id);
                    }}
                  />
                </Tooltip>
            )}
          </div>
      ),
    },
  ];

  return cols;
};
