import React, { useCallback, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Flex,
  FormLabel,
  Grid,
  GridItem,
  Text,
  Tooltip,
  useColorModeValue,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from '@chakra-ui/react';
import { cloneDeep, isArray, isEmpty, isString } from 'lodash';
import _map from 'lodash/map';
import { DeleteIcon, InfoOutlineIcon } from '@chakra-ui/icons';
import { Mention, MentionsInput } from 'react-mentions';
import { ReactSVG } from 'react-svg';
import he from 'he';
import CustomInput from '../InputBox/Input';
import { ALERT_COLOR, COLORS } from '../../utils/enums/colors';
import CustomButton from '../Button/SimpleButton';
import CustomSelectBox from '../SelectBox/Select';
import {
  getWholeMultiSelectOptions,
  getWholeOption,
} from '../../pages/TicketTemplateWorkflow/helper';
import CustomDatePicker from '../Datepicker/AntdDatePicker';
import {
  DROPDOWN_TYPES,
  SPECIAL_FIELD_TYPE,
} from '../../pages/TenantFilters/filters.constants';
import {
  getAllUniqueUsers,
  getSelectedFieldHierarchyMap,
  getSelectedHierarchy,
  getTeamQueueMap,
  getUserList,
} from './utils';
import defaultStyle, {
  darkMentionDefaultStyle,
} from '../Mention/mentionDefaultStyle';
import { getUserAttributes } from '../dynamicSelectWithInputTags/dynamicSelectWithInputTags.helper';
import { availableUsersListInTenantLevel } from '../../utils/helper/roleBasedAccess';
import FramePlus from '../../assets/icons/FramePlus.svg';
import { selectOptionsChannels } from '../../pages/DynamicRenderer/customTemplateModal/CustomTemplateModalLayout';
import QuillEditor from '../QuillEditor';
import { FIELD_QUILL_TOOLBAR, parseAPIToCustomMention, parseAPIToRichTextMention, parseCustomMentionToAPI, useMentionObj } from '../../pages/CommunicationTemplates/utills';
import CustomMention from '../Mention';

const insertDependentFields = (
  fieldList,
  recordState,
  hierarchies,
  fieldHierarchyMap
) => {
  if (hierarchies?.length === 0 || fieldHierarchyMap?.length === 0) {
    return [];
  }

  const depFields = [];

  fieldHierarchyMap?.forEach((mapping) => {
    const sequence = mapping.map;

    for (let i = 0; i < sequence.length; i++) {
      const depRecord = recordState.find(
        (record) => record?.field?.value === sequence[i]
      );

      if (!depRecord) {
        depFields.push(sequence[i]);
        i = sequence.length + 1;
      }
    }
  });

  return fieldList.filter((item) => depFields.includes(item.id));
};
// Function to find children of the selected item
const findChildrenByTitle = (title, hierarchy) => {
  for (let i = 0; i < hierarchy.length; i++) {
    const item = hierarchy[i];

    if (item.title === title) {
      return item.children;
    }

    if (item.children.length) {
      const result = findChildrenByTitle(title, item.children);
      if (result && result.length) {
        return result;
      }
    }
  }
  return [];
};

const getDependentOptions = (
  recordState,
  selectedFieldInfo,
  fieldHierarchyMap,
  hierarchiesArray
) => {
  if (hierarchiesArray.length === 0 || fieldHierarchyMap.length === 0) {
    return [];
  }

  const selectedFieldMapping = getSelectedFieldHierarchyMap(
    fieldHierarchyMap,
    selectedFieldInfo.id
  );
  if (!selectedFieldMapping) {
    return [];
  }

  const selectedHierarchy = getSelectedHierarchy(
    hierarchiesArray,
    selectedFieldMapping.heirachyListId
  );
  if (!selectedHierarchy) {
    return [];
  }

  const sequence = selectedFieldMapping.map;
  const index = sequence.indexOf(selectedFieldInfo.id);

  if (index === 0) {
    return selectedHierarchy.hierarchy.map((item) => ({
      value: item.title,
      label: item.title,
    }));
  }

  const prevField = index - 1;
  const prevRecordValue = recordState.find(
    (record) => record.field.value === sequence[prevField]
  );
  if (prevRecordValue) {
    const currentHierarchy = findChildrenByTitle(
      prevRecordValue.value,
      selectedHierarchy.hierarchy
    );

    if (currentHierarchy && currentHierarchy.length > 0) {
      return currentHierarchy.map((item) => ({
        value: item.title,
        label: item.title,
      }));
    }
    return [];
  }
  return [];
};

const SelectFieldFromTemplateWithDelete = ({
  fieldList,
  // eslint-disable-next-line no-unused-vars
  teamQueueMap,
  label,
  value,
  onChange,
  workFlowStatusList,
  // eslint-disable-next-line no-unused-vars
  isRequired,
  preventFieldRepeat,
  fieldNameLabel,
  fieldValueLabel,
  actionLabel,
  hideBorder,
  hierarchies,
  fieldHierarchyMap,
  hideHeadersOnEmpty,
  similaritiesField,
  parentFields,
  sourceTemplateName,
  targetTemplateName,
  createLayoutFields,
  hideWorkflowStatus,
  actorAssociationMap,
  showMentionComponent,
  linkedItemId,
}) => {
  const [mapActorOptionWithKey, setMapActorOptionWithKey] = useState({});
  const [queueOptionList, setQueueOptionList] = useState([]);
  const [userAttributes, setUserAttributes] = useState([]);
  const cursorRef = useRef(0);
  const { mentionObj } = useMentionObj({ linkedItemId, userAttributes });

  const getQueueOptions = (list, isWhiteList) => {
    if (!isEmpty(list) && !isEmpty(queueOptionList)) {
      const listQueueIds = list.map((queue) => `${queue.queueId}`);
      if (isWhiteList) {
        return queueOptionList.filter((option) =>
          listQueueIds.includes(option.value)
        );
      }
      return queueOptionList.filter(
        (option) => !listQueueIds.includes(option.value)
      );
    }
    return queueOptionList;
  };

  useEffect(() => {
    getTeamQueueMap().then((item) => {
      const allOptions = [];
      Object.values(item).forEach((data) =>
        allOptions.push(
          ...data.queues.map((queue) => ({
            value: `${queue.id}`,
            label: `${data.team} - ${queue.name}`,
          }))
        )
      );
      setQueueOptionList(allOptions);
    });
    getUserAttributes().then((data) => {
      setUserAttributes(data.response?.rows);
    });
  }, []);

  useEffect(() => {
    const fetchActorOptions = async () => {
      const obj = {};

      const actorPromises = actorAssociationMap
        .filter(
          (actorMapItem) =>
            !isEmpty(actorMapItem) && !isEmpty(actorMapItem.log_field_id)
        )
        .map(async (actorMapItem) => {
          const queueField = fieldList.find(
            (item) => item.id === actorMapItem.log_field_id
          );
          if (queueField && queueField.type_based_attributes.collection) {
            const { list, isWhiteList } =
              queueField.type_based_attributes.collection;
            const queueIdList = getQueueOptions(list, isWhiteList);
            try {
              const results = await Promise.all(
                queueIdList.map((queueId) => getUserList(queueId.value))
              );
              const data = getAllUniqueUsers(results);
              obj[actorMapItem.associated_field_id] = data;
            } catch (error) {
              console.error('Error fetching user lists:', error);
            }
          }
        });

      await Promise.all(actorPromises);
      setMapActorOptionWithKey(obj);
    };

    fetchActorOptions();
  }, [actorAssociationMap, fieldList, queueOptionList]);

  const [recordState, setRecordState] = useState([]);
  const [optionList, setOptionList] = useState([]);

  useEffect(() => {
    const existingFields = value?.map((item) => item.field?.label) || [];
    const requiredFields = fieldList
      .filter(
        (item) =>
          item?.type_based_attributes?.required &&
          !existingFields.includes(item.label)
      )
      .map((item) => ({
        field: { value: item.id, label: item.label },
        value: '',
      }));
      setRecordState([...requiredFields, ...value]);
    }, [fieldList]);
  useEffect(() => {
    onChange(recordState);
  }, [recordState]);

  useEffect(() => {
    const validFields = fieldList.filter(
      (item) =>
        item.type !== 'MEDIA' &&
        item.type !== 'LABEL' &&
        item.scope !== 'WORKFLOW' &&
        item.scope !== 'RELATION'
    );
    const uniqueFields = validFields.filter((item) => {
      if (preventFieldRepeat) {
        return !recordState.some((record) => record.field?.value === item.id);
      }
      return true;
    });
    const uniqueHideWorkflow = uniqueFields.filter((item) => {
      if (hideWorkflowStatus) {
        if (
          item.type === 'DROPDOWN' &&
          item?.type_based_attributes?.collection?.specialField ===
            'workflowState'
        ) {
          return false;
        }
        return true;
      }

      return true;
    });
    const filterOutDependentFields = uniqueHideWorkflow.filter(
      (item) =>
        !(
          item.type === 'DROPDOWN' &&
          item.type_based_attributes?.collection?.collection_type ===
            'DEPENDENT'
        )
    );

    const dependentFields = insertDependentFields(
      uniqueHideWorkflow,
      recordState,
      hierarchies,
      fieldHierarchyMap
    );

    setOptionList(
      [...filterOutDependentFields, ...dependentFields]
        .map((item) => ({
          value: item.id,
          label: item.label,
        }))
        .filter((options) => {
          if (createLayoutFields && createLayoutFields.length > 0) {
            return createLayoutFields.includes(options.value);
          }
          return true;
        })
    );
  }, [fieldList, recordState, hierarchies, fieldHierarchyMap]);

  const hasEjsPattern = (str) => {
    const ejsPattern = /<%[\s\S]+?%>/;
    return ejsPattern.test(str);
  };

  const handleChangeForInput = (
    val,
    index,
    state,
    fieldInfo = null,
    mentions = null
  ) => {
    const recordList = cloneDeep(state);
    if (recordList[index]) {
      const recordToUpdate = recordList[index];
      const updationId = recordToUpdate.field.value;

      const selectedMapping = getSelectedFieldHierarchyMap(
        fieldHierarchyMap,
        updationId
      );

      if (selectedMapping) {
        const indexOfUpdatedField = selectedMapping.map.indexOf(
          recordToUpdate.field.value
        );
        if (indexOfUpdatedField !== -1) {
          // Update the field
          recordList[index].value = val;
          // Dependent field, clear all dependent fields below it
          for (
            let i = indexOfUpdatedField + 1;
            i < selectedMapping.map.length;
            i++
          ) {
            const dependentRecordIndex = recordList.findIndex(
              (item) => item.field.value === selectedMapping.map[i]
            );

            if (dependentRecordIndex > -1) {
              recordList[dependentRecordIndex].value = '';
            }
          }
        } else {
          // Not a dependent field, update
          recordList[index].value = val;
        }
      } else {
        // No dependent fields, update
        recordList[index].value = val;
      }
      if (hasEjsPattern(val)) {
        recordList[index].static = false;
      }
      if (
        (fieldInfo?.type === 'TEXT' ||
          fieldInfo?.type === 'RICH_TEXT' ||
          fieldInfo?.type === 'TEXT_AREA' ||
          fieldInfo?.type === 'NUMBER') &&
        showMentionComponent
      ) {
        if (sourceTemplateName !== '') {
          val = val
            ?.toString()
            ?.replace(new RegExp(`${sourceTemplateName}`, 'g'), 'sourceData');
        }
        if (targetTemplateName !== '') {
          val = val
            ?.toString()
            ?.replace(new RegExp(`${targetTemplateName}`, 'g'), 'targetData');
        }
        recordList[index].value = val;
        recordList[index].ejs = true;

        if (mentions != null) {
          let hasMention = false;
          if (mentions.length !== 0) {
            mentions.forEach((m) => {
              if (m.value.includes('(Actor Dropdown)')) {
                hasMention = true;
              }
            });
          }
          if (hasMention) {
            recordList[index].hasUserAttr = 'v ';
          } else {
            delete recordList[index].userAttr;
            setRecordState(recordList);
          }
        }
      }

      setRecordState(recordList);
    } else {
      console.error(`Element at index ${index} is undefined`);
    }
  };

  const showRightOption = useCallback(
    (
      fieldListFull,
      record,
      indexValue,
      state,
      hierarchiesArray,
      fieldHierarchyMapObj,
      similarFields,
      relatedFields
    ) => {
      const selectedFieldInfo = fieldListFull.find(
        (item) => item.id === record?.field?.value
      );
      const defaultMentionStyle = { ...defaultStyle };
      defaultMentionStyle['&singleLine'].width = '100%';
      defaultMentionStyle['&singleLine'].input.padding = 7;

      if (
        !isEmpty(selectedFieldInfo) &&
        ((selectedFieldInfo?.type === 'DROPDOWN' &&
          selectedFieldInfo?.type_based_attributes?.collection
            ?.collection_type === DROPDOWN_TYPES.STATIC) ||
          (selectedFieldInfo?.type === 'TAGS' &&
            selectedFieldInfo?.type_based_attributes?.collection
              ?.collection_type === DROPDOWN_TYPES.STATIC))
      ) {
        const options =
          selectedFieldInfo?.type_based_attributes?.collection?.staticValues ||
          [];
        const isMulti =
          selectedFieldInfo?.type_based_attributes?.collection?.isMulti;
        let selectedRelation = '';
        let optionSelect = [];

        if (isArray(options)) {
          optionSelect = options;
        }

        if (isString(options)) {
          optionSelect = options
            .split('\n')
            .map((item) => ({ label: item, value: item }));
        }

        if (hasEjsPattern(record?.value)) {
          selectedRelation = { label: 'Dynamic Value', value: 'dynamic' };
        }
        if (record?.static === true || (!selectedRelation && record?.value)) {
          selectedRelation = { label: 'Static Value', value: 'static' };
        }
        optionSelect = [...optionSelect, { label: 'None', value: ' ' }];

        return (
          <div>
            {targetTemplateName !== '' && similarFields[record.field.value] && (
              <CustomSelectBox
                placeholder="Select a option"
                options={[
                  { label: 'Static Value', value: 'static' },
                  { label: 'Dynamic Value', value: 'dynamic' },
                ]}
                value={selectedRelation}
                onChange={(val) => {
                  if (val.value === 'dynamic') {
                    handleChangeForInput(
                      `<%= relationData.${
                        similarFields[record.field.value]
                      } %>`,
                      indexValue,
                      state
                    );
                  } else {
                    const entries = [...state];
                    entries[indexValue].static = val.value === 'static';
                    setRecordState(entries);
                  }
                }}
              />
            )}
            {(selectedRelation.value === 'static' ||
              targetTemplateName === '' ||
              !similarFields[record.field.value]) && (
              <CustomSelectBox
                options={optionSelect || []}
                placeholder="Select a option"
                value={
                  isMulti
                    ? getWholeMultiSelectOptions(
                      record?.value || '',
                      optionSelect
                    )
                    : getWholeOption(record?.value || '', optionSelect)
                }
                onChange={(val) => {
                  if (isMulti && isArray(val)) {
                    handleChangeForInput(
                      val.map((item) => item.value),
                      indexValue,
                      state
                    );
                  } else {
                    handleChangeForInput(val.value, indexValue, state);
                  }
                }}
                focusBorderColor={useColorModeValue(COLORS.LIGHT, COLORS.DARK)}
                id="DynamicRendererUpdateFieldCSelect"
                isMulti={isMulti}
              />
            )}
          </div>
        );
      }
      if (
        !isEmpty(selectedFieldInfo) &&
        selectedFieldInfo?.type === 'RICH_TEXT'
      ) {
        const convertHtmlToText = (html) => {
          const tempElement = document.createElement('div');
          tempElement.innerHTML = html;
          return tempElement.textContent;
        };

        let selectedUserAttr;

        const userAttrOptions = userAttributes?.map((item) => ({
          label: item.label,
          value: item.key,
        }));

        if (record?.userAttr && userAttrOptions) {
          userAttrOptions.forEach((item) => {
            if (item.value === record?.userAttr.trim()) {
              selectedUserAttr = item;
            }
          });
        }

        // decoding back double encoded html string recieved from backend
        const decodedValue = he.decode(record.value ? record.value : '' );
        
        return (
          <>
            {!record?.showEditor && (
              <CustomInput
                onClick={() => {
                  const recordList = cloneDeep(state);
                  recordList[indexValue].showEditor = true;
                  setRecordState(recordList);
                }}
                value={convertHtmlToText(parseAPIToRichTextMention(decodedValue))}
                />
                )}
              {record?.showEditor && (
                <QuillEditor
                  mentionObj={mentionObj}
                  editorvalue={decodedValue? parseAPIToRichTextMention(decodedValue) :''}
                  toolBarModules={FIELD_QUILL_TOOLBAR}
                  format=""
                  customEditorStyles={{}}
                  handleOnChange={(e, mentions) => {
                    handleChangeForInput(
                      e,
                      indexValue,
                      state,
                      selectedFieldInfo,
                      mentions
                    );
                  }}
                  onMentionAdd={(mentions) => {
                    // let hasMention = false;
                    // if (mentions.length !== 0) {
                    //   mentions.forEach((m) => {
                    //     if (m.value.includes('(Actor Dropdown)')) {
                    //       hasMention = true;
                    //     }
                    //   });
                    // }
                    // if (hasMention) {
                    //   const recordList = cloneDeep(state);
                    //   recordList[indexValue].userAttr = true;
                    //   setRecordState(recordList);
                    // } else {
                    //   const recordList = cloneDeep(state);
                    //   delete recordList[indexValue].userAttr;
                    //   setRecordState(recordList);
                    // }
                  }}
                  testId="RTEQuillEditor"
                  onBlur={() => {
                    const recordList = cloneDeep(state);
                    recordList[indexValue].showEditor = false;
                    setRecordState(recordList);
                  }}
                  focus
                />
              )}
              {(record?.hasUserAttr || record?.userAttr) && (
                <Flex mt="10px" gap="10px" alignItems="center">
                  <CustomSelectBox
                    options={userAttrOptions || []}
                    placeholder="Select a option"
                    value={selectedUserAttr}
                    onChange={(val) => {
                      const recordList = cloneDeep(state);
                      recordList[indexValue].userAttr = val.value;
                      setRecordState(recordList);
                    }}
                    focusBorderColor={useColorModeValue(
                      COLORS.LIGHT,
                      COLORS.DARK
                    )}
                    id="DynamicRendererUpdateFieldCSelect"
                  />
                  <DeleteIcon
                    className="cursor-pointer"
                    height="1rem"
                    width="1rem"
                    color="red"
                    onClick={() => {
                      const recordList = cloneDeep(state);
                      delete recordList[indexValue].value;
                      delete recordList[indexValue].disabled;
                      delete recordList[indexValue].userAttr;
                      setRecordState(recordList);
                    }}
                  />
                </Flex>
              )}
          </>
        );
      }
      if (
        !isEmpty(selectedFieldInfo) &&
        sourceTemplateName !== '' &&
        (selectedFieldInfo?.type === 'EMAIL' ||
          selectedFieldInfo?.type_based_attributes?.collection?.specialField ===
            SPECIAL_FIELD_TYPE.ACTORS ||
          selectedFieldInfo?.type.toUpperCase() === 'TEXT' ||
          selectedFieldInfo?.type === 'RICH_TEXT' ||
          selectedFieldInfo?.type === 'Rich Text' ||
          selectedFieldInfo?.type === 'TEXT_AREA' ||
          selectedFieldInfo?.type === 'Text Area')
      ) {
        const suggestionList = [];
        
        const userAttrOptions = userAttributes?.map((item) => ({
          label: item.label,
          value: item.key,
        }));

        const addSuggestions = (fields, templateName, isTarget = false) => {
          fields.forEach((field) => {
            const isActor = field.type_based_attributes?.collection?.specialField === 'actor';
            const isEmail = field.type === 'EMAIL';
            
            if (isActor || isEmail) {
              suggestionList.push({
                id: `${isTarget ? 'parentTicket' : 'ticket'}.data[${field.id}]`,
                display: `${templateName}.${field.label}`,
              });
        
              if (isActor) {
                userAttrOptions.forEach(({ value, label }) => {
                  suggestionList.push({
                    id: `${isTarget ? 'parentActor' : 'actor'}['${field.id}']?.userResponse?.attributes?.${value} || ''`,
                    display: `${templateName}.${field.label} ${label}`,
                  });
                });
              }
            }
          });
        };
        
        // Add suggestions for sourceTemplateName
        if (sourceTemplateName) {
          addSuggestions(relatedFields, sourceTemplateName);
        }
        
        // Add suggestions for targetTemplateName excluding selectedFieldInfo.id
        if (targetTemplateName) {
          const filteredFields = fieldList.filter(field => field.id !== selectedFieldInfo.id);
          addSuggestions(filteredFields, targetTemplateName, true);
        }
        

        let mentionValue = record?.attr || record?.value || '';

        return (
          <div>
            <CustomMention
              value={parseAPIToCustomMention(mentionValue) || ''}
              onChange={(value) => {
                const recordList = cloneDeep(state);
                  recordList[indexValue].value = parseCustomMentionToAPI(value);
                  setRecordState(recordList);
              }}
              placeholder="Mention using '$'"
              suggestionList={suggestionList || []}
              style={useColorModeValue(
                defaultMentionStyle,
                darkMentionDefaultStyle
              )}
            />
            {/* <Mention
                trigger="$"
                data={suggestionList || []}
                markup="<%= __id__ %>"
                displayTransform={(id, display) =>
                  `\${${display
                    ?.toString()
                    ?.replace('sourceData', sourceTemplateName)
                    ?.replace('targetData', targetTemplateName)}}`
                }
                onAdd={(id) => {
                  const recordList = cloneDeep(state);
                  recordList[indexValue].disabled = true;
                  if (selectedFieldInfo?.type.toUpperCase() === 'DROPDOWN') {
                    recordList[indexValue].value = `<%= ${id} %>`;
                  } else {
                    let newValue = recordList[indexValue].value || '';
                    const lastDollarIndex = newValue.lastIndexOf('$');
                    if (lastDollarIndex !== -1) {
                      const mentionValue = `<%= ${id} %>`;
                      const beforeMention = newValue.slice(0, lastDollarIndex);
                      newValue = beforeMention + mentionValue;
                      recordList[indexValue].value = newValue;
                      newValue = newValue.replace(/\${(.+?)}/g, mentionValue);
                      recordList[indexValue].value = newValue.replace(
                        /\$/g,
                        ''
                      );
                    } else {
                      const mentionValue = `<%= ${id} %>`;
                      newValue = newValue.replace(/\${(.+?)}/g, mentionValue);
                      newValue += mentionValue;
                      recordList[indexValue].value = newValue.replace(
                        /\$/g,
                        ''
                      );
                    }
                  }
                  setRecordState(recordList);
                }}
              />
            </MentionsInput>
            {(record.disabled || record?.userAttr) && (
              <Flex mt="10px" gap="10px" alignItems="center">
                <CustomSelectBox
                  options={userAttrOptions || []}
                  placeholder="Select a option"
                  value={selectedUserAttr}
                  onChange={(val) => {
                    const recordList = cloneDeep(state);
                    recordList[indexValue].userAttr = val.value;
                    setRecordState(recordList);
                  }}
                  focusBorderColor={useColorModeValue(
                    COLORS.LIGHT,
                    COLORS.DARK
                  )}
                  id="DynamicRendererUpdateFieldCSelect"
                />
                <DeleteIcon
                  className="cursor-pointer"
                  height="1rem"
                  width="1rem"
                  color="red"
                  onClick={() => {
                    const recordList = cloneDeep(state);
                    delete recordList[indexValue].value;
                    delete recordList[indexValue].disabled;
                    delete recordList[indexValue].userAttr;
                    setRecordState(recordList);
                  }}
                />
              </Flex> 
              )}
              */}
          </div>
        );
      }

      if (
        !isEmpty(selectedFieldInfo) &&
        selectedFieldInfo.type === 'NUMBER' &&
        showMentionComponent
      ) {
        const suggestionList = [];
        relatedFields.forEach((field) => {
          if (field.type === 'NUMBER') {
            suggestionList.push({
              id: `sourceData.${field.label}`,
              display: `${sourceTemplateName}.${field.label}`,
            });
          }
        });
        fieldList.forEach((field) => {
          if (field.type === 'NUMBER' && field.id !== selectedFieldInfo.id) {
            suggestionList.push({
              id: `targetData.${field.label}`,
              display: `${targetTemplateName}.${field.label}`,
            });
          }
        });

        let mentionValue = record?.attr || record?.value || '';
        mentionValue = mentionValue
          ?.toString()
          ?.replace('<%= ', '${')
          ?.replace(' %>', '}')
          ?.replace('sourceData', `${sourceTemplateName}`)
          ?.replace('targetData', `${targetTemplateName}`);

        return (
          <div className="flex flex-col gap-2">
            <MentionsInput
              value={mentionValue}
              singleLine
              onChange={(e) => {
                handleChangeForInput(
                  e.target.value,
                  indexValue,
                  state,
                  selectedFieldInfo
                );
              }}
              style={useColorModeValue(
                defaultMentionStyle,
                darkMentionDefaultStyle
              )}
              allowSpaceInQuery
            >
              <Mention
                trigger="$"
                data={suggestionList || []}
                markup="<%= __id__ %>"
                displayTransform={(id, display) =>
                  `\${${display
                    ?.toString()
                    ?.replace('sourceData', sourceTemplateName)
                    ?.replace('targetData', targetTemplateName)}}`
                }
              />
            </MentionsInput>
          </div>
        );
      }
      if (
        !isEmpty(selectedFieldInfo) &&
        selectedFieldInfo.type === 'NUMBER' &&
        !showMentionComponent
      ) {
        return (
          <NumberInput
            onChange={(val) => {
              handleChangeForInput(Number(val), indexValue, state);
            }}
            value={record?.value?.toString() || ''}
            focusBorderColor={useColorModeValue(COLORS.LIGHT, COLORS.DARK)}
            data-testid="TicktFieeldContentNumberInnput"
            min={0}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        );
      }
      if (!isEmpty(selectedFieldInfo) && selectedFieldInfo.type === 'DATE') {
        let selectedRelation = '';
        if (record?.static || !Number.isNaN(Date.parse(record.value))) {
          selectedRelation = { label: 'Static Value', value: 'static' };
        } else if (
          record?.static === false ||
          (Number.isNaN(Date.parse(record.value)) && record.value !== '')
        ) {
          selectedRelation = { label: 'Mapping', value: 'dynamic' };
        } else {
          selectedRelation = {};
        }
        const suggestionList = [];
        relatedFields.forEach((field) => {
          if (field.type === 'DATE') {
            suggestionList.push({
              label: `${sourceTemplateName}.${field.label}`,
              value: `<%= sourceData.${field.label} %>`,
            });
          }
        });

        return (
          <div>
            {targetTemplateName !== '' && suggestionList.length > 0 && (
              <CustomSelectBox
                placeholder="Select a option"
                options={[
                  { label: 'Static Value', value: 'static' },
                  { label: 'Dynamic Value', value: 'dynamic' },
                ]}
                value={selectedRelation}
                onChange={(val) => {
                  const entries = [...state];
                  entries[indexValue].static = val.value === 'static';
                  entries[indexValue].value = '';
                  setRecordState(entries);
                }}
              />
            )}
            {selectedRelation.value === 'dynamic' && (
              <CustomSelectBox
                options={suggestionList || []}
                placeholder="Select a option"
                value={suggestionList.find(
                  (item) => item.value === record.value
                )}
                onChange={(val) => {
                  const entries = [...state];
                  entries[indexValue].static = val.value === 'static';
                  entries[indexValue].value = val.value;
                  setRecordState(entries);
                }}
                focusBorderColor={useColorModeValue(COLORS.LIGHT, COLORS.DARK)}
                id="DynamicRendererUpdateFieldCSelect"
              />
            )}
            {(selectedRelation.value === 'static' ||
              targetTemplateName === '') && (
              <CustomDatePicker
                value={record?.value || ''}
                customHeight="38px"
                dateFormat={
                  selectedFieldInfo.isAllowTimeField
                    ? 'DD/MM/YYYY HH:mm'
                    : 'DD/MM/YYYY'
                }
                selectedDate={record?.value || ''}
                onChange={(e) => {
                  const finalValue = e === '' ? '' : new Date(e).toISOString();
                  handleChangeForInput(finalValue, indexValue, state);
                }}
                showTime={selectedFieldInfo.isAllowTimeField}
              />
            )}
          </div>
        );
      }
      if (!isEmpty(selectedFieldInfo) && selectedFieldInfo.type === 'BOOLEAN') {
        const optionSelect = [
          { value: 'false', label: 'False' },
          { value: 'true', label: 'True' },
        ];
        const suggestionList = [];
        relatedFields.forEach((field) => {
          if (field.type === 'BOOLEAN' && field.id !== selectedFieldInfo.id) {
            suggestionList.push({
              label: `${sourceTemplateName}.${field.label}`,
              value: `<%= sourceData.${field.label} %>`,
            });
          }
        });
        let selectedRelation = {};

        if (
          record?.static ||
          record?.value === 'true' ||
          record?.value === 'false'
        ) {
          selectedRelation = { label: 'Static Value', value: 'static' };
          record.static = true;
        }
        if (record?.value && (hasEjsPattern(record.value) || !record?.static)) {
          selectedRelation = { label: 'Mapping', value: 'dynamic' };
        }

        return (
          <div>
            {suggestionList.length > 0 && targetTemplateName !== '' && (
              <CustomSelectBox
                placeholder="Select a option"
                options={[
                  { label: 'Static Value', value: 'static' },
                  { label: 'Mapping', value: 'dynamic' },
                ]}
                value={selectedRelation}
                onChange={(val) => {
                  const entries = [...state];
                  entries[indexValue].static = val.value === 'static';
                  entries[indexValue].value = '';
                  setRecordState(entries);
                }}
              />
            )}
            {selectedRelation?.value === 'dynamic' && (
              <CustomSelectBox
                options={suggestionList || []}
                placeholder="Select a option"
                value={suggestionList.find(
                  (item) => item.value === record.value
                )}
                onChange={(val) => {
                  const entries = [...state];
                  entries[indexValue].static = val.value === 'static';
                  entries[indexValue].value = val.value;
                  setRecordState(entries);
                }}
                focusBorderColor={useColorModeValue(COLORS.LIGHT, COLORS.DARK)}
                id="DynamicRendererUpdateFieldCSelect"
              />
            )}
            {(selectedRelation?.value !== 'dynamic' ||
              targetTemplateName === '') && (
              <CustomSelectBox
                options={optionSelect || []}
                placeholder="Select a option"
                value={getWholeOption(record?.value || '', optionSelect)}
                onChange={(val) => {
                  handleChangeForInput(val.value, indexValue, state);
                }}
                focusBorderColor={useColorModeValue(COLORS.LIGHT, COLORS.DARK)}
                id="DynamicRendererUpdateFieldCSelect"
              />
            )}
          </div>
        );
      }
      if (
        !isEmpty(selectedFieldInfo) &&
        selectedFieldInfo?.type_based_attributes?.collection
          ?.collection_type === DROPDOWN_TYPES.SPECIAL_FIELD &&
        selectedFieldInfo?.type_based_attributes?.collection?.specialField ===
          SPECIAL_FIELD_TYPE.WORKFLOW
      ) {
        const options = [...workFlowStatusList];
        let selectedRelation;

        if (hasEjsPattern(record?.value)) {
          selectedRelation = { label: 'Mapping', value: 'dynamic' };
        }
        if (record?.static === true) {
          selectedRelation = { label: 'Static Value', value: 'static' };
        }
        const hasDynamicValue = similarFields[record.field.value] != null;

        return (
          <>
            {hasDynamicValue && (
              <CustomSelectBox
                placeholder="Select a option"
                options={[
                  { label: 'Static Value', value: 'static' },
                  { label: 'Mapping', value: 'dynamic' },
                ]}
                value={selectedRelation}
                onChange={(val) => {
                  if (val.value === 'dynamic') {
                    handleChangeForInput(
                      `<%= relationData.${
                        similarFields[record.field.value]
                      } %>`,
                      indexValue,
                      state
                    );
                  } else {
                    const entries = [...state];
                    entries[indexValue].static = val.value === 'static';
                    setRecordState(entries);
                  }
                }}
              />
            )}
            {(record.static || !hasDynamicValue) && (
              <CustomSelectBox
                options={options || []}
                placeholder="Select a status"
                value={getWholeOption(record?.value || '', options)}
                onChange={(val) => {
                  handleChangeForInput(val.value, indexValue, state);
                }}
                focusBorderColor={useColorModeValue(COLORS.LIGHT, COLORS.DARK)}
                id="DynamicRendererUpdateFieldCSelect"
              />
            )}
          </>
        );
      }

      if (
        !isEmpty(selectedFieldInfo) &&
        selectedFieldInfo?.type_based_attributes?.collection
          ?.collection_type === DROPDOWN_TYPES.SPECIAL_FIELD &&
        selectedFieldInfo?.type_based_attributes?.collection?.specialField ===
          SPECIAL_FIELD_TYPE.QUEUE
      ) {
        const { list, isWhiteList } =
          selectedFieldInfo.type_based_attributes.collection;
        let selectedRelation;

        if (hasEjsPattern(record?.value)) {
          selectedRelation = { label: 'Mapping', value: 'dynamic' };
        }
        if (record?.static === true) {
          selectedRelation = { label: 'Static Value', value: 'static' };
        }
        const hasDynamicValue = similarFields[record.field.value] != null;

        return (
          <div>
            {hasDynamicValue && (
              <CustomSelectBox
                placeholder="Select a option"
                options={[
                  { label: 'Static Value', value: 'static' },
                  { label: 'Mapping', value: 'dynamic' },
                ]}
                value={selectedRelation}
                onChange={(val) => {
                  if (val.value === 'dynamic') {
                    handleChangeForInput(
                      `<%= relationData.${
                        similarFields[record.field.value]
                      } %>`,
                      indexValue,
                      state
                    );
                  } else {
                    const entries = [...state];
                    entries[indexValue].static = val.value === 'static';
                    setRecordState(entries);
                  }
                }}
              />
            )}
            {(record.static || !hasDynamicValue) && (
              <CustomSelectBox
                options={[...getQueueOptions(list, isWhiteList)] || []}
                placeholder="Select a queue"
                value={getWholeOption(
                  record?.value || '',
                  getQueueOptions(list, isWhiteList) || []
                )}
                onChange={(val) => {
                  handleChangeForInput(val.value, indexValue, state);
                }}
                focusBorderColor={useColorModeValue(COLORS.LIGHT, COLORS.DARK)}
                id="DynamicRendererUpdateFieldCSelect"
              />
            )}
          </div>
        );
      }

      if (
        !isEmpty(selectedFieldInfo) &&
        selectedFieldInfo?.type_based_attributes?.collection
          ?.collection_type === DROPDOWN_TYPES.SPECIAL_FIELD &&
        selectedFieldInfo?.type_based_attributes?.collection?.specialField ===
          SPECIAL_FIELD_TYPE.SOURCE
      ) {
        let selectedRelation = '';
        if (hasEjsPattern(record?.value)) {
          selectedRelation = { label: 'Dynamic Value', value: 'dynamic' };
        }
        if (record?.static === true || (!selectedRelation && record?.value)) {
          selectedRelation = { label: 'Static Value', value: 'static' };
        }

        const selectOptions = [...selectOptionsChannels, {label: 'None', value: ' '}];

        return (
          <div>
            {targetTemplateName !== '' && similarFields[record.field.value] && (
              <CustomSelectBox
                placeholder="Select a option"
                options={[
                  { label: 'Static Value', value: 'static' },
                  { label: 'Dynamic Value', value: 'dynamic' },
                ]}
                value={selectedRelation}
                onChange={(val) => {
                  if (val.value === 'dynamic') {
                    handleChangeForInput(
                      `<%= relationData.${
                        similarFields[record.field.value]
                      } %>`,
                      indexValue,
                      state
                    );
                  } else {
                    const entries = [...state];
                    entries[indexValue].static = val.value === 'static';
                    setRecordState(entries);
                  }
                }}
              />
            )}
            {(selectedRelation.value === 'static' ||
              targetTemplateName === '' ||
              !similarFields[record.field.value]) && (
              <CustomSelectBox
                options={selectOptions || []}
                placeholder="Select a option"
                value={getWholeOption(
                  record?.value || '',
                  selectOptionsChannels
                )}
                onChange={(val) => {
                  handleChangeForInput(val.value, indexValue, state);
                }}
                focusBorderColor={useColorModeValue(COLORS.LIGHT, COLORS.DARK)}
                id="DynamicRendererUpdateFieldCSelect"
              />
            )}
          </div>
        );
      }

      if (
        !isEmpty(selectedFieldInfo) &&
        selectedFieldInfo?.type_based_attributes?.collection
          ?.collection_type === DROPDOWN_TYPES.DEPENDENT
      ) {
        let selectedRelation;
        if (hasEjsPattern(record?.value)) {
          selectedRelation = { label: 'Mapping', value: 'dynamic' };
        }
        if (record?.static === true) {
          selectedRelation = { label: 'Static Value', value: 'static' };
        }
        const hasDynamicValue = similarFields[record.field.value] != null;

        const options = getDependentOptions(
          state,
          selectedFieldInfo,
          fieldHierarchyMapObj,
          hierarchiesArray
        );

        options.push({ label: 'None', value: ' ' });

        return (
          <div>
            {hasDynamicValue && (
              <CustomSelectBox
                placeholder="Select a option"
                options={[
                  { label: 'Static Value', value: 'static' },
                  { label: 'Mapping', value: 'dynamic' },
                ]}
                value={selectedRelation}
                onChange={(val) => {
                  if (val.value === 'dynamic') {
                    handleChangeForInput(
                      `<%= relationData.${
                        similarFields[record.field.value]
                      } %>`,
                      indexValue,
                      state
                    );
                  } else {
                    const entries = [...state];
                    entries[indexValue].static = val.value === 'static';
                    setRecordState(entries);
                  }
                }}
              />
            )}
            {(record.static || !hasDynamicValue) && (
              <CustomSelectBox
                options={options}
                placeholder="Select a value"
                value={getWholeOption(record?.value || '', options)}
                onChange={(val) => {
                  handleChangeForInput(val.value, indexValue, state);
                }}
                focusBorderColor={useColorModeValue(COLORS.LIGHT, COLORS.DARK)}
                id="DynamicRendererUpdateFieldCSelect"
              />
            )}
          </div>
        );
      }
      if (
        targetTemplateName === '' &&
        !isEmpty(selectedFieldInfo) &&
        selectedFieldInfo?.type_based_attributes?.collection
          ?.collection_type === DROPDOWN_TYPES.SPECIAL_FIELD &&
        selectedFieldInfo?.type_based_attributes?.collection?.specialField ===
          SPECIAL_FIELD_TYPE.ACTORS
      ) {
        let options;
        let selectedRelation;
        if (hasEjsPattern(record?.value)) {
          selectedRelation = { label: 'Mapping', value: 'dynamic' };
        }
        if (record?.static === true) {
          selectedRelation = { label: 'Static Value', value: 'static' };
        }
        const hasDynamicValue = similarFields[record.field.value] != null;
        const actorMapItem = actorAssociationMap.find(
          (item) => item.associated_field_id === selectedFieldInfo.id
        );

        if (!isEmpty(actorMapItem) && !isEmpty(actorMapItem.log_field_id)) {
          // const queueField = fieldList.find(
          //   (item) => item.id === actorMapItem.log_field_id
          // );
          // const { list, isWhiteList } =
          //   queueField.type_based_attributes.collection;
          // const queueIdList = [...getQueueOptions(list, isWhiteList)];

          // Promise.all(queueIdList.map((queueId) => getUserList(queueId.value)))
          //   .then((results) => {
          //     const data = getAllUniqueUsers(results);
          //     options = data;
          //   })
          //   .catch((error) => {
          //     console.error('Error fetching user lists:', error);
          //   });
          options = mapActorOptionWithKey[selectedFieldInfo.id];
        } else {
          options = availableUsersListInTenantLevel() || [];
        }

        return (
          <div>
            {hasDynamicValue && (
              <CustomSelectBox
                placeholder="Select a option"
                options={[
                  { label: 'Static Value', value: 'static' },
                  { label: 'Mapping', value: 'dynamic' },
                ]}
                value={selectedRelation}
                onChange={(val) => {
                  if (val.value === 'dynamic') {
                    handleChangeForInput(
                      `<%= relationData.${
                        similarFields[record.field.value]
                      } %>`,
                      indexValue,
                      state
                    );
                  } else {
                    const entries = [...state];
                    entries[indexValue].static = val.value === 'static';
                    setRecordState(entries);
                  }
                }}
              />
            )}
            {(record.static || !hasDynamicValue) && (
              <CustomSelectBox
                options={options}
                placeholder="Select a value"
                value={getWholeOption(record?.value || '', options)}
                onChange={(val) => {
                  handleChangeForInput(val.value, indexValue, state);
                }}
                focusBorderColor={useColorModeValue(COLORS.LIGHT, COLORS.DARK)}
                id="DynamicRendererUpdateFieldCSelect"
              />
            )}
          </div>
        );
      }

      if (
        !isEmpty(selectedFieldInfo) ||
        selectedFieldInfo?.type === 'TEXT' ||
        selectedFieldInfo?.type === 'TEXT_AREA'
      ) {
        const suggestionList = [];
        
        const userAttrOptions = userAttributes?.map((item) => ({
          label: item.label,
          value: item.key,
        }));

        const addSuggestions = (fields, templateName, isTarget = false) => {
          fields.forEach((field) => {
            const isActor = field.type_based_attributes?.collection?.specialField === 'actor';
            const isEmail = field.type === 'EMAIL';
            
            // if (isActor || isEmail) {
              suggestionList.push({
                id: `${isTarget ? 'parentTicket' : 'ticket'}.data[${field.id}]`,
                display: `${templateName}.${field.label}`,
              });
        
              if (isActor) {
                userAttrOptions.forEach(({ value, label }) => {
                  suggestionList.push({
                    id: `${isTarget ? 'parentActor' : 'actor'}['${field.id}']?.userResponse?.attributes?.${value} || ''`,
                    display: `${templateName}.${field.label} ${label}`,
                  });
                });
              }
            // }
          });
        };
        
        // Add suggestions for sourceTemplateName
        if (sourceTemplateName) {
          addSuggestions(relatedFields, sourceTemplateName);
        }
        
        // Add suggestions for targetTemplateName excluding selectedFieldInfo.id
        if (targetTemplateName) {
          const filteredFields = fieldList.filter(field => field.id !== selectedFieldInfo.id);
          addSuggestions(filteredFields, targetTemplateName, true);
        }

        return (
          <CustomMention
              value={parseAPIToCustomMention(record.value) || ''}
              onChange={(value) => {
                 handleChangeForInput(
                  parseCustomMentionToAPI(value),
                    indexValue,
                    state,
                    selectedFieldInfo
                  );
              }}
              placeholder="Mention using '$'"
              suggestionList={suggestionList || []}
              style={useColorModeValue(
                defaultMentionStyle,
                darkMentionDefaultStyle
              )}
            />
          // <MentionsInput
          //   value={record.value
          //     ?.toString()
          //     ?.replace(/\${sourceData/g, `\${${sourceTemplateName}`)
          //     ?.replace(/\${targetData/g, `\${${targetTemplateName}`)
          //     ?.replace(regex, (match, code) => `\${${code.trim()}}`)}
          //   singleLine
          //   onChange={(e) => {
          //     handleChangeForInput(
          //       e.target.value,
          //       indexValue,
          //       state,
          //       selectedFieldInfo
          //     );
          //   }}
          //   style={useColorModeValue(
          //     defaultMentionStyle,
          //     darkMentionDefaultStyle
          //   )}
          //   allowSpaceInQuery
          // >
          //   <Mention
          //     trigger="$"
          //     data={suggestionList || []}
          //     markup={`\${__id__}`}
          //     displayTransform={(id, display) =>
          //       `\${${display
          //         ?.toString()
          //         ?.replace('sourceData', sourceTemplateName)
          //         ?.replace('targetData', targetTemplateName)}}`
          //     }
          //   />
          // </MentionsInput>
        );
      }
      return (
        <CustomInput
          value={record?.value}
          onChange={(e) => {
            handleChangeForInput(e.target.value, indexValue, state);
          }}
          id="DynamicRendererSDivCInput"
        />
      );
    },
    [queueOptionList, mapActorOptionWithKey]
  );

  const addRecord = () => {
    const defaultEntry = {
      field: '',
      value: '',
    };
    setRecordState((pre) => [...pre, defaultEntry]);
  };
  const handleChangeForSelectField = (val, index) => {
    const afterDeleteRecord = [...recordState];
    afterDeleteRecord[index] = {
      field: val,
      value: '',
    };
    setRecordState([...afterDeleteRecord]);
  };

  const deleteRecord = (index) => {
    const recordStateCopy = cloneDeep(recordState);

    if (fieldHierarchyMap && fieldHierarchyMap.length) {
      const recordToDelete = recordStateCopy[index];
      const deletionId = recordToDelete.field.value;
      const selectedMapping = getSelectedFieldHierarchyMap(
        fieldHierarchyMap,
        deletionId
      );

      if (selectedMapping) {
        const indexOfDeletedField = selectedMapping.map.indexOf(deletionId);

        // Dependent field, delete all dependent fields below it including current one
        for (let i = indexOfDeletedField; i < selectedMapping.map.length; i++) {
          const dependentRecordIndex = recordStateCopy.findIndex(
            (item) => item.field.value === selectedMapping.map[i]
          );

          if (dependentRecordIndex > -1) {
            recordStateCopy.splice(dependentRecordIndex, 1);
          }
        }
      } else {
        // Not a dependent field, delete
        recordStateCopy.splice(index, 1);
      }
    } else {
      // No dependent fields, delete
      recordStateCopy.splice(index, 1);
    }

    setRecordState(recordStateCopy);
  };

  const showRestricted = (fieldListFull, record) => {
    const selectedFieldInfo = fieldListFull.find(
      (item) => item.id === record?.field?.value
    );
    const viewRole = [
      ...(selectedFieldInfo?.permissions?.viewers?.roles || []),
    ].map((item) => `Role:${item}`);
    const viewUser = selectedFieldInfo?.permissions?.viewers?.users || [];
    const editRole = [
      ...(selectedFieldInfo?.permissions?.editors?.roles || []),
    ].map((item) => `Role:${item}`);
    const editUser = selectedFieldInfo?.permissions?.editors?.users || [];
    return (
      (!isEmpty(viewRole) ||
        !isEmpty(viewUser) ||
        !isEmpty(editRole) ||
        !isEmpty(editUser)) && (
        <Box bg="#F8E1DE" color="#CF3626" pt="5px" pb="5px">
          <Tooltip
            hasArrow
            marginTop="10px"
            padding="10px"
            width="490px"
            label={
              <Box>
                <Flex
                  alignItems="start"
                  borderBottom="1px solid #D1D5DB"
                  pb="4px"
                >
                  <InfoOutlineIcon mr="8px" color="#CF3626" mt="5px" />
                  <Text>Restricted To:</Text>
                </Flex>
                {(!isEmpty(viewRole) || !isEmpty(viewUser)) && (
                  <>
                    <Text fontSize="17px" pb="10px" pt="10px">
                      View Access
                    </Text>
                    {!isEmpty(viewRole) && (
                      <Flex pt="10px">
                        <Flex>
                          <Text>User Attribute -</Text>
                        </Flex>
                        <Flex gap="2" wrap="wrap">
                          {viewRole.map((vRole) => (
                            <Box bg="#DEE8FC" color="#2563EB">
                              {vRole}
                            </Box>
                          ))}
                        </Flex>
                      </Flex>
                    )}
                    {!isEmpty(viewUser) && (
                      <Flex pt="10px">
                        <Flex>
                          <Text>User-</Text>
                        </Flex>
                        <Flex>
                          {viewUser.map((vRole) => (
                            <Box bg="#DEE8FC" color="#2563EB">
                              {vRole}
                            </Box>
                          ))}
                        </Flex>
                      </Flex>
                    )}
                  </>
                )}
                {(!isEmpty(viewRole) || !isEmpty(viewUser)) && (
                  <>
                    <Text fontSize="17px" pb="10px" pt="10px">
                      View Access
                    </Text>
                    {!isEmpty(editRole) && (
                      <Flex pt="10px">
                        <Flex>
                          <Text>Edit Access -</Text>
                        </Flex>
                        <Flex gap="2" wrap="wrap">
                          {editRole.map((vRole) => (
                            <Box bg="#DEE8FC" color="#2563EB">
                              {vRole}
                            </Box>
                          ))}
                        </Flex>
                      </Flex>
                    )}
                    {!isEmpty(editUser) && (
                      <Flex pt="10px">
                        <Flex>
                          <Text>User-</Text>
                        </Flex>
                        <Flex>
                          {editUser.map((vRole) => (
                            <Box bg="#DEE8FC" color="#2563EB">
                              {vRole}
                            </Box>
                          ))}
                        </Flex>
                      </Flex>
                    )}
                  </>
                )}
              </Box>
            }
            bg={useColorModeValue('white', '#374151')}
            color={useColorModeValue(ALERT_COLOR, 'white')}
          >
            <Box display="flex" alignItems="center" cursor="pointer">
              <InfoOutlineIcon ml="5px" />
              <Text ml="5px" mr="5px">
                Restricted
              </Text>
            </Box>
          </Tooltip>
        </Box>
      )
    );
  };
  const showHeaders = !hideHeadersOnEmpty || recordState.length > 0;

  const deleteIcon = (record, indx, fieldList) => {
    const selectedFieldInfo = fieldList.find(
      (item) => item.id === record?.field?.value
    );
    if (selectedFieldInfo?.type_based_attributes?.required) return null;
    return (
      <DeleteIcon
                className="cursor-pointer"
                height="1rem"
                width="1rem"
                color="red"
                onClick={() => {
                  deleteRecord(indx);
                }}
              />
    )
  }


  return (
    <Box marginTop="1rem" data-testid="DynamicRendererSlackRecordsBox">
      <FormLabel
        className="SCLabel"
        data-testid="DynamicRendererSlackFormLabel"
      >
        {label}
        {/* {isRequired && (
                              <span style={{ color: 'red', marginLeft: '4px' }}>*</span>
                              )} */}
      </FormLabel>
      <Box
        marginTop="20px"
        marginRight="10px"
        border={!hideBorder ? '1px solid #E2E8F0' : ''}
        borderRadius="6px"
        data-testid="DynamicRendererFieldBox"
      >
        <Grid
          templateColumns="repeat(12, 1fr)"
          columnGap={30}
          borderBottom={!hideBorder ? '1px solid #E2E8F0' : ''}
          padding="15px"
          data-testid="DynamicRendererFieldGrid"
        >
          {showHeaders && (
            <>
              <GridItem colSpan={5} data-testid="DynamicRendererFieldGridItem">
                <FormLabel
                  fontWeight={500}
                  className="ellipsis"
                  data-testid="DynamicRendererFieldNameFormLabl"
                >
                  {fieldNameLabel}
                </FormLabel>
              </GridItem>
              <GridItem colSpan={5}>
                <FormLabel
                  fontWeight={500}
                  className="ellipsis"
                  data-testid="DynamicRendererFieldValueGridItem"
                >
                  {fieldValueLabel}
                </FormLabel>
              </GridItem>
              <GridItem colSpan={1} data-testid="DynamicRendererActionGridItem">
                {!hideBorder && (
                  <FormLabel
                    fontWeight={500}
                    className="ellipsis"
                    data-testid="DynamicRendererActionFormLabel"
                  >
                    Restricted?
                  </FormLabel>
                )}
              </GridItem>
              <GridItem colSpan={1} data-testid="DynamicRendererActionGridItem">
                {!hideBorder && (
                  <FormLabel
                    fontWeight={500}
                    className="ellipsis"
                    data-testid="DynamicRendererActionFormLabel"
                  >
                    {actionLabel}
                  </FormLabel>
                )}
              </GridItem>
            </>
          )}
        </Grid>
        {_map(recordState, (record, indx) => (
          <Grid
            key={record?.field?.value}
            templateColumns="repeat(12, 1fr)"
            columnGap={30}
            padding="15px"
            data-testid="DynamicRendererUpdateGrid"
          >
            <GridItem
              colSpan={5}
              data-testid="DynamicRendererUpdateGriSelectdItem"
            >
              <CustomSelectBox
                options={optionList || []}
                placeholder="Select a field"
                value={record.field}
                onChange={(val) => {
                  handleChangeForSelectField(val, indx);
                }}
                focusBorderColor={useColorModeValue(COLORS.LIGHT, COLORS.DARK)}
                id="DynamicRendererUpdateFieldCSelect"
              />
            </GridItem>
            <GridItem
              colSpan={5}
              data-testid="DynamicRendererUpdateRecordGridItem"
            >
              {showRightOption(
                fieldList,
                record,
                indx,
                recordState,
                hierarchies,
                fieldHierarchyMap,
                similaritiesField,
                parentFields
              )}
            </GridItem>
            <GridItem
              colSpan={1}
              data-testid="DynamicRendererUpdateDeleteGridItem"
            >
              {showRestricted(fieldList, record, indx)}
            </GridItem>
            <GridItem
              colSpan={1}
              data-testid="DynamicRendererUpdateDeleteGridItem"
            >
                            {deleteIcon(record, indx, fieldList)}
            </GridItem>
          </Grid>
        ))}
        <Box margin="10px" data-testid="DynamicRendererUpdateAddFieldBox">
          <CustomButton
            leftIcon={
              <ReactSVG
                src={FramePlus}
                mr="10px"
                color={useColorModeValue(COLORS.LIGHT, COLORS.DARK)}
              />
            }
            buttonText="Add fields"
            variant="outline"
            colorScheme="customBlue"
            style={{
              height: 30,
              border: 'none',
              color: 'inherit',
              // pointerEvents: 'none',
              backgroundColor: 'inherit',
            }}
            onClick={() => {
              addRecord();
            }}
            isDisabled={optionList.length === 0 && preventFieldRepeat}
            id="DynamicRendererUpdateAddIconCButton"
          />
        </Box>
      </Box>
    </Box>
  );
};

SelectFieldFromTemplateWithDelete.propTypes = {
  fieldList: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  workFlowStatusList: PropTypes.array.isRequired,
  isRequired: PropTypes.bool,
  preventFieldRepeat: PropTypes.bool,
  fieldNameLabel: PropTypes.string,
  fieldValueLabel: PropTypes.string,
  actionLabel: PropTypes.string,
  hideBorder: PropTypes.bool,
  teamQueueMap: PropTypes.array,
  hierarchies: PropTypes.array,
  fieldHierarchyMap: PropTypes.object,
  hideHeadersOnEmpty: PropTypes.bool,
  similaritiesField: PropTypes.object,
  parentFields: PropTypes.array,
  sourceTemplateName: PropTypes.string,
  targetTemplateName: PropTypes.string,
  createLayoutFields: PropTypes.array,
  hideWorkflowStatus: PropTypes.bool,
  actorAssociationMap: PropTypes.array,
  showMentionComponent: PropTypes.bool,
  linkedItemId: PropTypes.string,
};
SelectFieldFromTemplateWithDelete.defaultProps = {
  value: [],
  isRequired: false,
  preventFieldRepeat: false,
  fieldNameLabel: 'Field Name',
  fieldValueLabel: 'Default Value',
  actionLabel: 'Action',
  hideBorder: false,
  teamQueueMap: [],
  hierarchies: [],
  fieldHierarchyMap: [],
  hideHeadersOnEmpty: false,
  similaritiesField: {},
  parentFields: [],
  sourceTemplateName: '',
  targetTemplateName: '',
  createLayoutFields: [],
  hideWorkflowStatus: false,
  actorAssociationMap: [],
  showMentionComponent: true,
  linkedItemId: '',
};
export default SelectFieldFromTemplateWithDelete;
