/* eslint-disable indent */
import { FaBlackTie, FaUserPlus } from 'react-icons/fa';
import {
  HiOutlineUserGroup,
  HiOutlineWrench,
  HiOutlineTicket,
  HiOutlineQueueList,
} from 'react-icons/hi2';
import { BsSliders } from 'react-icons/bs';
import { MdDashboard, MdOutlineSupportAgent } from 'react-icons/md';
import { TfiComments , TfiAgenda} from 'react-icons/tfi';
import { IoTicketOutline } from 'react-icons/io5';
import { RxDashboard } from 'react-icons/rx';

import React from 'react';
import { Box } from '@chakra-ui/react';
import { checkForV2 } from '../helper';

import TicketLogo from '../../assets/icons/TicketTemplateLogo.svg';
import MailServer from '../../assets/icons/MServer.svg';
import WorkflowLogo from '../../assets/icons/TicketWorkflow.svg';
import ActorSidebarIcon from '../../assets/icons/ActorNavIcon.svg';
// import FieldSetsIcon from '../../assets/icons/FieldSetsIcon.svg';
import FieldsSidebarIcon from '../../assets/icons/FieldsSidebarIcon.svg';
import signatureIcon from '../../assets/icons/Signature.svg';
import metaIcon from '../../assets/icons/Meta.svg';
import FilterIcon from '../../assets/icons/Filter.svg';
import Audience from '../../assets/icons/Audience.svg';
import FieldDefault from '../../assets/icons/FieldDefaults.svg';
import HirarchyBuilder from '../../assets/icons/HirarchyBuilder.svg';
import IntentGroup from '../../assets/icons/IntentGroup.svg';

export const ROUTES_DEFINED = {
  TEMPLATE_CARDLIST: '/template-cardlist',
  WORKFLOW_LIST: '/workflow-list',
  TEMPLATE_MAKERS: '/template-makers',
  ACTORS: '/actors',
  FIELDSETS: '/fieldsets',
  FIELDS: '/fields',
  SERVICE_CATALOGUE: '/catalog',
  WORK_FLOW: '/work-flow',
  SIGNATURE_MASTER_LIST: '/signature-list',
  META_FIELDS: '/meta-fields',
  FILTERS: '/filters',
  MAIL_SERVER: '/mail-server',
  FIELD_DEFAULT: '/offers',
  AUDIENCE: '/audience',
  INTENT: '/intent-builder',
  HIERARCHY: '/hierarchy-builder',
  LOCALES: '/locales',
  PERMISSIONS: '/permissions',
  OTHER_SETTINGS: '/other-settings',
  BOT_INTEGRATION: '/bot-integration',
  OFFERS: '/offers-master'
};

export const ROUTES_DEFINED_MAP = Object.keys(ROUTES_DEFINED).reduce(
  (prev, curr) => {
    if (Object.prototype.hasOwnProperty.call(ROUTES_DEFINED, curr)) {
      prev[ROUTES_DEFINED[curr]] = curr;
    }
    return prev;
  },
  {}
);

const v2Only = JSON.parse(localStorage.getItem('tenant'))?.custom?.v2Only;

const allowedRole = ['asc_admin'];
// const allowedRole = ['ticket_admin'];

const v2OnlyRoutes = [
  {
    path: '/agent-ui-v2/inbox',
    exact: true,
    app: { path: 'agent-ui-v2/inbox' },
    isNav: false,
    name: 'tickets',
    label: 'Ticket Management',
    iconType: 'outline',
    iconName: HiOutlineTicket,
    iconWidth: '1.5rem',
    iconHeight: '1.5rem',
    allowedRole: ['ticket_agent', 'ticket_user', 'ticket_agent_supervisor', 'ticket_admin'],
    children: [
      {
        path: '/agent-ui-v2/inbox',
        exact: true,
        app: { path: 'agent-ui-v2/inbox' },
        isNav: true,
        name: 'myTicketsV2',
        label: 'Ticket Inbox',
        iconName: IoTicketOutline,
        iconWidth: '1.5rem',
        iconHeight: '1.5rem',
        allowedRole: ['ticket_agent', 'ticket_user', 'ticket_agent_supervisor', 'ticket_admin'],
      },
      {
        path: '/catalog-v2',
        exact: true,
        label: 'Service Catalog',
        name: 'catalog',
        isNav: true,
        iconName: HiOutlineWrench,
        iconType: 'outline',
        iconWidth: '1.5rem',
        iconHeight: '1.5rem',
        allowedRole: ['ticket_admin', 'asc_admin'],
        children: [
          {
            path: '/template-cardlist',
            highlightRouts: ['/template-makers'],
            exact: true,
            isNav: true,
            name: 'ticketServiceCatalogue',
            label: 'Ticket Type',
            // iconsName: TicketLogo,
            iconWidth: '1.5rem',
            iconHeight: '1.5rem',
            allowedRole: ['ticket_admin', 'asc_admin']
          },
          {
            path: '/bot-integration',
            exact: true,
            isNav: true,
            name: 'botIntegration',
            label: 'Bot Integration',
            // iconsName: TicketLogo,
            iconWidth: '1.5rem',
            iconHeight: '1.5rem',
            allowedRole:['ticket_admin', 'asc_admin']
          },
          {
            path: '/workflow-list',
            highlightRouts: ['/work-flow'],
            exact: true,
            isNav: true,
            name: 'ticketTemplateWorkflow',
            label: 'Workflow',
            // iconsName: WorkflowLogo,
            iconWidth: '1.5rem',
            iconHeight: '1.5rem',
            allowedRole: ['ticket_admin', 'asc_admin']
          },
          {
            path: '/actors',
            isNav: true,
            exact: true,
            name: 'actors',
            label: 'Actors',
            // iconsName: ActorSidebarIcon,
            iconWidth: '1.5rem',
            iconHeight: '1.5rem',
            iconType: 'outline',
            allowedRole: ['ticket_admin', 'asc_admin']
          },
          {
            path: '/fields',
            isNav: true,
            exact: true,
            name: 'fields',
            label: 'Fields',
            // iconsName: FieldsSidebarIcon,
            iconWidth: '1.5rem',
            iconHeight: '1.5rem',
            iconType: 'outline',
            allowedRole: ['ticket_admin', 'asc_admin']
          },
          {
            path: '/filters',
            isNav: true,
            exact: true,
            name: 'Filters',
            label: 'Filters',
            // iconsName: FilterIcon,
            iconWidth: '1.5rem',
            iconHeight: '1.5rem',
            iconType: 'outline',
            allowedRole: ['ticket_admin', 'asc_admin']
          },
          {
            path: '/meta-fields',
            isNav: true,
            exact: true,
            name: 'Meta Fields',
            label: 'Meta Fields',
            // iconsName: metaIcon,
            iconWidth: '1.5rem',
            iconHeight: '1.5rem',
            iconType: 'outline',
            allowedRole: ['ticket_admin', 'asc_admin']
          },
          {
            path: '/locales',
            isNav: true,
            exact: true,
            name: 'Locales',
            label: 'Locales',
            // iconsName: metaIcon,
            iconWidth: '1.5rem',
            iconHeight: '1.5rem',
            iconType: 'outline',
            allowedRole: ['ticket_admin', 'asc_admin']
          },
          {
            path: '/offers-master',
            isNav: true,
            exact: true,
            name: 'Offers',
            label: 'Offers',
            // iconsName: metaIcon,
            iconWidth: '1.5rem',
            iconHeight: '1.5rem',
            iconType: 'outline',
            allowedRole: ['ticket_admin', 'asc_admin']
          },
          {
            path: '/mail-server',
            isNav: true,
            exact: true,
            name: 'Mail Server',
            label: 'Mail Server',
            // iconsName: MailServer,
            iconWidth: '1.5rem',
            iconHeight: '1.5rem',
            iconType: 'outline',
            allowedRole: ['ticket_admin', 'asc_admin']
          },
          // {
          //   path: '/offers',
          //   isNav: true,
          //   exact: true,
          //   name: 'Offers',
          //   label: 'Offers',
          //   iconsName: FieldDefault,
          //   iconWidth: '1.5rem',
          //   iconHeight: '1.5rem',
          //   iconType: 'outline',
          //   allowedRole:['ticket_admin', 'asc_admin']
          // },
          {
            path: '/audience',
            isNav: true,
            exact: true,
            name: 'audience',
            label: 'Audience',
            // iconsName: Audience,
            iconWidth: '1.5rem',
            iconHeight: '1.5rem',
            allowedRole: ['ticket_admin', 'asc_admin']
          },
          {
            path: '/intent-builder',
            isNav: true,
            exact: true,
            name: 'Intents',
            label: 'Intents',
            // iconsName: IntentGroup,
            iconWidth: '1.5rem',
            iconHeight: '1.5rem',
            iconType: 'outline',
            allowedRole: ['ticket_admin', 'asc_admin']
          },
          {
            path: '/hierarchy-builder',
            isNav: true,
            exact: true,
            name: 'hierarchy_builder',
            label: 'Hierarchy Builder',
            // iconsName: HirarchyBuilder,
            iconWidth: '1.5rem',
            iconHeight: '1.5rem',
            iconType: 'outline',
            allowedRole: ['ticket_admin', 'asc_admin']
          },
          {
            path: '/permissions',
            isNav: true,
            exact: true,
            name: 'permissions',
            label: 'Permissions',
            // iconsName: HirarchyBuilder,
            iconWidth: '1.5rem',
            iconHeight: '1.5rem',
            iconType: 'outline',
            allowedRole: ['ticket_admin', 'asc_admin']
          },
          {
            path: '/other-settings',
            isNav: true,
            exact: true,
            name: 'other_settings',
            label: 'Other Settings',
            iconWidth: '1.5rem',
            iconHeight: '1.5rem',
            iconType: 'outline',
            allowedRole: ['ticket_admin', 'asc_admin']
          },
        ],
      },
      {
        path: '/agent-ui-v2/queue',
        exact: true,
        app: { path: 'agent-ui-v2/queue' },
        isNav: true,
        name: 'queues',
        label: 'Queues',
        iconName: HiOutlineQueueList,
        iconWidth: '1.5rem',
        iconHeight: '1.5rem',
        allowedRole: ['asc_admin', 'ticket_admin', 'ticket_agent_supervisor'],
      },
      {
        path: '/dashboard',
        exact: true,
        app: { path: 'dashboard' },
        isNav: true,
        name: 'dashboard',
        label: 'Tickets Dashboard',
        iconType: 'outline',
        iconName: RxDashboard,
        iconWidth: '1.5rem',
        iconHeight: '1.5rem',
        allowedRole: ['ticket_admin', 'asc_admin', 'ticket_agent', 'ticket_supervisor'],
        children: [
          {
            path: '/dashboard',
            exact: true,
            isNav: true,
            name: 'dashboards',
            label: 'Dashboards',
            iconType: 'outline',
            iconName: MdDashboard,
            iconWidth: '1.5rem',
            iconHeight: '1.5rem',
            allowedRole: ['ticket_agent', 'ticket_agent_supervisor', 'ticket_admin', 'asc_admin']
          },
          {
            path: '/dashboard/reports',
            exact: true,
            isNav: true,
            name: 'reports',
            label: 'Reports',
            iconType: 'outline',
            iconName: TfiAgenda,
            iconWidth: '1.5rem',
            iconHeight: '1.5rem',
            allowedRole: ['ticket_admin', 'ticket_agent_supervisor']
          },
        ],
      },
    ],
  },
];

const betaUserRoutes = [
  {
    path: '/',
    exact: true,
    label: 'Ticket Management V2',
    name: 'ticketManagementV2',
    isNav: true,
    allowedRole: ['beta_user'],
    iconElement: (
      <Box
        color="white"
        marginBottom="5px"
        px="5px"
        fontSize="8px"
        lineHeight="normal"
        justifyContent="center"
        alignItems="center"
        py="1px"
        style={{
          borderRadius: '0px 10px',
          background: 'linear-gradient(110deg, #6DD2F5 18.55%, #677BF2 89.65%)',
        }}
      >
        Beta
      </Box>
    ),
    children: [
      {
        path: '/catalog-v2',
        exact: true,
        label: 'Service Catalog',
        name: 'catalog',
        isNav: true,
        iconName: HiOutlineWrench,
        iconType: 'outline',
        iconWidth: '1.5rem',
        iconHeight: '1.5rem',
        allowedRole: ['beta_user'],
        children: [
          {
            path: '/template-cardlist',
            highlightRouts: ['/template-makers'],
            exact: true,
            isNav: true,
            name: 'ticketServiceCatalogue',
            label: 'Ticket Type',
            iconsName: TicketLogo,
            iconWidth: '1.5rem',
            iconHeight: '1.5rem',
            allowedRole: ['ticket_admin', 'asc_admin'],
          },
          {
            path: '/workflow-list',
            highlightRouts: ['/work-flow'],
            exact: true,
            isNav: true,
            name: 'ticketTemplateWorkflow',
            label: 'Workflow',
            iconsName: WorkflowLogo,
            iconWidth: '1.5rem',
            iconHeight: '1.5rem',
            allowedRole: ['ticket_admin', 'asc_admin'],
          },
          {
            path: '/actors',
            isNav: true,
            exact: true,
            name: 'actors',
            label: 'Actors',
            iconsName: ActorSidebarIcon,
            iconWidth: '1.5rem',
            iconHeight: '1.5rem',
            iconType: 'outline',
            allowedRole: ['ticket_admin', 'asc_admin'],
          },
          {
            path: '/fields',
            isNav: true,
            exact: true,
            name: 'fields',
            label: 'Fields',
            iconsName: FieldsSidebarIcon,
            iconWidth: '1.5rem',
            iconHeight: '1.5rem',
            iconType: 'outline',
            allowedRole: ['ticket_admin', 'asc_admin'],
          },
          {
            path: '/filters',
            isNav: true,
            exact: true,
            name: 'Filters',
            label: 'Filters',
            iconsName: FilterIcon,
            iconWidth: '1.5rem',
            iconHeight: '1.5rem',
            iconType: 'outline',
            allowedRole: ['ticket_admin', 'asc_admin'],
          },
          {
            path: '/meta-fields',
            isNav: true,
            exact: true,
            name: 'Meta Fields',
            label: 'Meta Fields',
            iconsName: metaIcon,
            iconWidth: '1.5rem',
            iconHeight: '1.5rem',
            iconType: 'outline',
            allowedRole: ['ticket_admin', 'asc_admin'],
          },
          {
            path: '/locales',
            isNav: true,
            exact: true,
            name: 'Locales',
            label: 'Locales',
            iconsName: metaIcon,
            iconWidth: '1.5rem',
            iconHeight: '1.5rem',
            iconType: 'outline',
            allowedRole: ['ticket_admin', 'asc_admin'],
          },
          {
            path: '/mail-server',
            isNav: true,
            exact: true,
            name: 'Mail Server',
            label: 'Mail Server',
            iconsName: MailServer,
            iconWidth: '1.5rem',
            iconHeight: '1.5rem',
            iconType: 'outline',
            allowedRole: ['ticket_admin', 'asc_admin'],
          },
          // {
          //   path: '/offers',
          //   isNav: true,
          //   exact: true,
          //   name: 'Offers',
          //   label: 'Offers',
          //   iconsName: FieldDefault,
          //   iconWidth: '1.5rem',
          //   iconHeight: '1.5rem',
          //   iconType: 'outline',
          //   allowedRole: [ 'ticket_admin', 'asc_admin'],
          // },
          {
            path: '/audience',
            isNav: true,
            exact: true,
            name: 'audience',
            label: 'Audience',
            iconsName: Audience,
            iconWidth: '1.5rem',
            iconHeight: '1.5rem',
            allowedRole: ['ticket_admin', 'asc_admin'],
          },
          {
            path: '/intent-builder',
            isNav: true,
            exact: true,
            name: 'Intents',
            label: 'Intents',
            iconsName: IntentGroup,
            iconWidth: '1.5rem',
            iconHeight: '1.5rem',
            iconType: 'outline',
            allowedRole: ['ticket_admin', 'asc_admin'],
          },
          {
            path: '/hierarchy-builder',
            isNav: true,
            exact: true,
            name: 'hierarchy_builder',
            label: 'Hierarchy Builder',
            iconsName: HirarchyBuilder,
            iconWidth: '1.5rem',
            iconHeight: '1.5rem',
            iconType: 'outline',
            allowedRole: ['ticket_admin', 'asc_admin'],
          },
        ],
      },
      {
        path: '/agent-ui-v2/inbox',
        exact: true,
        app: { path: 'agent-ui-v2/inbox' },
        isNav: true,
        name: 'myTicketsV2',
        label: 'My Tickets V2',
        iconName: IoTicketOutline,
        iconWidth: '1.5rem',
        iconHeight: '1.5rem',
        allowedRole: ['ticket_agent', 'ticket_user', 'ticket_agent_supervisor', 'ticket_admin'],
      },
      {
        path: '/dashboard',
        exact: true,
        app: { path: 'dashboard' },
        isNav: true,
        name: 'dashboard',
        label: 'Tickets Dashboard',
        iconType: 'outline',
        iconName: RxDashboard,
        iconWidth: '1.5rem',
        iconHeight: '1.5rem',
        allowedRole: ['ticket_agent', 'ticket_agent_supervisor', 'ticket_admin'],
      },
    ],
  },
];

export const routeList = [
  {
    path: '/',
    exact: true,
    isNav: false,
  },
  { path: '/', exact: true, isNav: false },
  { path: '/login', exact: true, isNav: false },
  { path: '/logout', exact: true, isNav: false },
  { path: '/register', exact: true, isNav: false },
  { path: '/register/:product', isNav: false },
  {
    // path: '/virtual-agent-mgmt',
    path: '/botmanagement/dashboard',
    exact: true,
    app: { path: 'botmanagement' },
    isNav: true,
    name: 'virtualAgentMgmt',
    label: 'Virtual Agent Mgmt',
    iconName: HiOutlineUserGroup,
    iconWidth: '1.5rem',
    iconHeight: '1.5rem',
    iconType: 'outline',
    allowedRole: ['bot_analyst', 'bot_admin'],
  },
  {
    path: '/approvals',
    exact: true,
    app: { path: 'approvals' },
    isNav: false,
    name: 'tickets',
    label: 'Requests Management',
    iconType: 'outline',
    allowedRole: ['approver', 'requestor'],
  },
  {
    ...(!v2Only
      ? {
          path: '/agent/inbox',
          exact: true,
          app: { path: 'agent/inbox' },
          isNav: false,
          name: 'tickets',
          label: 'Ticket Management',
          iconType: 'outline',
          iconName: HiOutlineTicket,
          iconWidth: '1.5rem',
          iconHeight: '1.5rem',
          allowedRole: [
            'ticket_user',
            'ticket_agent',
            'ticket_agent_supervisor',
            'ticket_admin',
          ],
        }
      : {}),
  },
  ...(v2Only ? v2OnlyRoutes : []),
  {
    path: '/livechat',
    exact: true,
    app: { path: 'livechat' },
    isNav: false,
    name: 'liveChat',
    label: 'Live Chat',
    iconType: 'outline',
    iconName: TfiComments,
    iconWidth: '22px',
    iconHeight: '1.5rem',
    allowedRole: ['chat_agent', 'chat_agent_admin', 'chat_agent_supervisor'],
  },
  {
    path: '/learning',
    exact: true,
    app: { path: 'learning' },
    isNav: false,
    name: 'learningApp',
    label: 'Learning Mgmt',
    iconType: 'outline',
    iconName: FaBlackTie,
    iconWidth: '1.5rem',
    iconHeight: '1.5rem',
    allowedRole: ['trainee', 'content_author', 'coach', 'expert'],
  },
  {
    path: '/user-management',
    exact: true,
    label: 'Admin App',
    name: 'admin',
    isNav: true,
    allowedRole: ['user_admin', 'tenant_admin', 'bot_user'],
    iconName: BsSliders,
    iconType: 'outline',
    iconWidth: '22px',
    iconHeight: '1.5rem',
  },
  {
    // path: '/virtual-agent',
    path: '/virtualagent/',
    exact: true,
    app: { path: 'virtualagent' },
    isNav: true,
    name: 'virtualAgent',
    label: 'Virtual Agent',
    iconName: MdOutlineSupportAgent,
    iconType: 'outline',
    iconWidth: '1.5rem',
    iconHeight: '1.5rem',
    allowedRole: ['bot_user'],
  },
  ...(!v2Only ? betaUserRoutes : []),
  // {
  //   path: '/agent/support',
  //   exact: true,
  //   app: { path: 'agent/support' },
  //   isNav: true,
  //   name: 'support',
  //   label: 'Support',
  //   iconName: FaUserPlus,
  //   iconType: 'outline',
  //   iconWidth: '1.5rem',
  //   iconHeight: '1.5rem',
  //   allowedRole: ['bot_analyst', 'chat_agent', 'chat_agent_admin', 'chat_agent_supervisor', 'content_author', 'coach', 'expert', 'user_admin', 'tenant_admin', 'ticket_agent', 'ticket_agent_supervisor'],
  // },
];
