import React, { useEffect, useState } from 'react';
import { Drawer, DrawerHeader, DrawerBody, DrawerFooter, DrawerContent, Flex, IconButton, Text, InputGroup, Input, Stack, Button, Box, Popover, PopoverTrigger, PopoverContent, PopoverHeader, PopoverBody, } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import TextArea from 'antd/es/input/TextArea';
import { PiWarningLight } from 'react-icons/pi';
import { BsInfoCircle } from 'react-icons/bs';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { IoAdd } from 'react-icons/io5';
import './triggers.scss'
const EditTriggersDrawer = ({
  setSelectedTriggerField,
  selectedTriggerField,
  setShowTriggersDrawer,
  openDrawer,
  setOpenEditDrawer,
  saveTrigger,
  setTriggerKeyValues,
  triggerKeyValues,
  triggerContext,
  setTriggerContext,
  triggersOffersData,
  errorMessage,
  isPublishing
}) => {
  const [triggerKey, setTriggerKey] = useState('');
  const [triggerExists, setTriggerExists] = useState({});
  const [isPopoverOpen, setIsPopoverOpen] = useState({ context: false, phrases: false });

  const TriggerLabels = {
    OFFER_NAME: 'Offer/Ticket Type Name',
    TRIGGER_CONTEXT: 'Trigger Context',
    KEYS: 'Trigger Keywords/Phrases'
  };

  const phrasesOpen = () => setIsPopoverOpen({ context: false, phrases: true });
  const phrasesClose = () => setIsPopoverOpen({ context: false, phrases: false });
  const contextOpen = () => setIsPopoverOpen({ context: true, phrases: false });
  const contextClose = () => setIsPopoverOpen({ context: false, phrases: false });

  const onCloseEditTriggersDrawer = () => {
    setOpenEditDrawer(false);
    setShowTriggersDrawer(true);
    setSelectedTriggerField({});
    setTriggerExists({});
  };
  const onAddTriggerKeys = (triggerKey) => {
    if (triggerKey.trim() !== '' && !triggerKeyValues.includes(triggerKey)) {
      const existingOffer = triggersOffersData.find((item) => item.keys.includes(triggerKey));
      setTriggerExists({ value: triggerKey, name: existingOffer?.name });
      const isExistingTriggerKey = triggerKeyValues.find((item) => item === triggerKey);
      !isExistingTriggerKey && !existingOffer && setTriggerKeyValues([...triggerKeyValues, triggerKey]);
      setTriggerKey('');
    }
    setTriggerKey('');
  };
  const onDeleteTriggerKeys = (triggerVal) => {
    const updatedTriggerKeys = triggerKeyValues.filter((item) => item !== triggerVal);
    setTriggerKeyValues(updatedTriggerKeys);
  };
  useEffect(() => {
    if (selectedTriggerField?.context) { setTriggerContext(selectedTriggerField.context); }
    if (selectedTriggerField?.keys) { setTriggerKeyValues(selectedTriggerField.keys); }
  }, [selectedTriggerField]);

  const handleChange = (e, index) => {
    const updatedTriggerKeys = [...triggerKeyValues];
    updatedTriggerKeys[index] = e.target.value;
    setTriggerKeyValues(updatedTriggerKeys);
  };

  return (
    <Drawer
      size="lg"
      placement="right"
      onClose={setShowTriggersDrawer}
      isOpen={openDrawer}
      data-testid="EditTriggersDrawer"
      closeOnOverlayClick={false}
    >
      <DrawerContent className="EditTriggersDrawerContent">
        <DrawerHeader className="header">
          <Flex alignItems="center" gap="10px">
          <IconButton
            colorScheme="blue"
            aria-label="Close Drawer"
            size="sm"
            borderRadius="50%"
            minW="1.5rem"
            height="1.5rem"
            variant="outline"
            icon={<ArrowBackIcon />}
            onClick={() => {
              setSelectedTriggerField({});
              setTriggerExists({});
              setOpenEditDrawer(false);
              setShowTriggersDrawer(true);
            }}
            data-testid="closeEditTriggersDrawer"
          />
          <Text fontSize="16px">Edit Trigger</Text>
          </Flex>
        </DrawerHeader>
        <DrawerBody className="body" data-testid="EditTriggersDrawerBody">
            <Stack>
              <Box className="field">
                  <Box className="input-label">
                    <Box>
                      <label>{TriggerLabels.OFFER_NAME}</label>
                      <span>*</span>
                    </Box>
                  </Box>
                <InputGroup>
                  <Input
                    data-testid="offerName"
                    value={selectedTriggerField?.name}
                    isDisabled
                  />
                </InputGroup>
              </Box>
              <Box className="field">
                <Box className="input-label" position="relative">
                  <Box>
                    <label>{TriggerLabels.TRIGGER_CONTEXT}</label>
                    <span>*</span>
                  </Box>
                  <Popover isOpen={isPopoverOpen.context} onOpen={contextOpen} onClose={contextClose} placement="right" closeOnBlur={false}>
                    <PopoverTrigger><BsInfoCircle onMouseEnter={contextOpen} onMouseLeave={contextClose} /></PopoverTrigger>
                    <PopoverContent position="absolute" left="143px" width="473px" onMouseEnter={contextOpen} onMouseLeave={contextClose}>
                      <PopoverHeader>
                        <BsInfoCircle />
                        <Text>Trigger Context</Text>
                      </PopoverHeader>
                      <PopoverBody className="popover-body">
                        <Text>The context or user intent that this trigger is designed to recognize and respond to.</Text>
                        <Text className="popover-example">Example: "User intent to check the current status of an existing order."</Text>
                      </PopoverBody>
                    </PopoverContent>
                  </Popover>
                </Box>
                <InputGroup>
                  <TextArea data-testid="triggerContext" value={triggerContext} name="triggerContext" rows={5} onChange={(e) => { setTriggerContext(e.target.value); }} />
                </InputGroup>
                {errorMessage && errorMessage?.context && <Text className="error-message">{errorMessage?.context}</Text>}
              </Box>
              <Box className="field trigger-keys">
                <Box className="input-label" position="relative">
                  <Box>
                    <label>{TriggerLabels.KEYS}</label>
                    <span>*</span>
                  </Box>
                  <Popover isOpen={isPopoverOpen.phrases} onOpen={phrasesOpen} onClose={phrasesClose} placement="right" closeOnBlur={false}>
                    <PopoverTrigger><BsInfoCircle onMouseEnter={phrasesOpen} onMouseLeave={phrasesClose} /></PopoverTrigger>
                    <PopoverContent position="absolute" left="213px" width="408px" onMouseEnter={phrasesOpen} onMouseLeave={phrasesClose}>
                      <PopoverHeader display="flex">
                        <BsInfoCircle color="#E79B04" />
                        <Text>Trigger Keywords/Phrases</Text>
                      </PopoverHeader>
                      <PopoverBody className="popover-body">
                        <Text>Specific words or phrases that, when detected in the user’s input, should activate this trigger</Text>
                        <Text className="popover-example">Example: "order status, track my order, where is my order, order update"</Text>
                      </PopoverBody>
                    </PopoverContent>
                  </Popover>

                </Box>
                <Box className="trigger-keys-list">
                  {
                    triggerKeyValues && triggerKeyValues.length > 0 ?
                      triggerKeyValues?.map((item, index) => (
                        <Box key={index} className="trigger-keys-item">
                          <InputGroup>
                            <Input data-testid="submittedTriggerKey" value={item} onChange={(e) => handleChange(e, index)} />
                          </InputGroup>
                          <Box>
                            <IconButton
                              className="delete"
                              variant="outline"
                              data-testid="deleteTriggerKey"
                              icon={<RiDeleteBin6Line />}
                              onClick={() => onDeleteTriggerKeys(item)}
                            />
                          </Box>
                        </Box>
                      ))
                      : null
                  }
                </Box>
                <Box className="trigger-key-input">
                  <InputGroup>
                    <Input data-testid="triggerKey" value={triggerKey} onChange={(e) => setTriggerKey(e.target.value)} />
                  </InputGroup>
                  <IconButton
                    className="add"
                    variant="outline"
                    data-testid="addTriggerKey"
                    disabled={triggerKey === ''}
                    icon={<IoAdd />}
                    onClick={() => { onAddTriggerKeys(triggerKey); }}
                  />
                </Box>
                {errorMessage && errorMessage.keys && <Text className="error-message">{errorMessage?.keys}</Text>}
                {triggerExists && triggerExists?.name && Object.keys(triggerExists).length > 0 && (
                  <Box data-testid="triggerExists" className="trigger-exists">
                    <PiWarningLight />
                    <Text>
                      <Text className="key-name" as="span">
                      {' '}
                      {`"${triggerExists.value}"`}
                      </Text>
                      {' '}
                      prompt seems to be associated with
                      {' '}
                      <Text as="span" className="trigger-name">{`"${triggerExists.name}"`}</Text>
                      {' '}
                      trigger.
                    </Text>
                  </Box>
                )}
              </Box>
            </Stack>
        </DrawerBody>
        <DrawerFooter>
           <Flex gap="10px">
              <Button variant="outline" onClick={onCloseEditTriggersDrawer}>Cancel</Button>
              <Button variant="outline" onClick={() => { saveTrigger(false); }}>Save Draft</Button>
              <Button className="publish" variant="solid" colorScheme="blue" isLoading={isPublishing?true:false} onClick={() => { saveTrigger(true); }}>Publish Trigger</Button>
           </Flex>
        </DrawerFooter>
      </DrawerContent>

    </Drawer>
  );
};

export default EditTriggersDrawer;
