import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  DrawerHeader,
  Flex,
  IconButton,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import PropTypes from 'prop-types';
import { ArrowBackIcon } from '@chakra-ui/icons';
import NewFieldContent from './AddNewFieldContent';
import { LABELS } from '../../../utils/enums/labels';

const AddNewFieldModal = ({ isAddField, setIsAddField, onClick }) => (
  <Drawer
    size="xl"
    placement="right"
    onClose={() => setIsAddField(false)}
    isOpen={isAddField}
    id="DrawerViewZModal"
    closeOnOverlayClick={false}
  >
    <DrawerOverlay />
    <DrawerContent padding="10px">
      <DrawerHeader px="14px" py="0px">
      <Flex alignItems="center" gap="10px">
            <IconButton
              colorScheme="blue"
              aria-label="Close Drawer"
              size="sm"
              borderRadius="50%"
              minW="1.5rem"
              height="1.5rem"
              variant="outline"
              icon={<ArrowBackIcon />}
              onClick={() => setIsAddField(false)}
            />
            <Text fontSize="16px">
              {LABELS.TITLE.SELECT_FIELD_FOR_TEMPLATE}
            </Text>
          </Flex>
      </DrawerHeader>
      <DrawerBody px="14px">
        <NewFieldContent onClick={onClick} />
      </DrawerBody>
    </DrawerContent>
  </Drawer>
);

AddNewFieldModal.propTypes = {
  isAddField: PropTypes.bool.isRequired,
  setIsAddField: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default AddNewFieldModal;
