/* eslint-disable import/no-cycle */
import React, { useState, useEffect } from 'react';
import { useColorModeValue } from '@chakra-ui/color-mode';
import { Box, Flex, Spacer } from '@chakra-ui/layout';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import CustomButton from '../../../components/Button/SimpleButton';
import CustomModal from '../../../components/Modal/Modal';
import { BLACK, COLORS, WHITE } from '../../../utils/enums/colors';
import CustomTextArea from '../../../components/Textarea/CustomTextArea';
import CustomToast from '../../../components/Notification/Notification';
import { updateViewState } from '../../../redux/ViewSlice/ViewReducer';
import { LABELS } from '../../../utils/enums/labels';
import { updateLifecycleStatus } from './customTemplateModal.helper';

const TemplateApproveRejectModal = ({
  isOpen,
  setIsOpen,
  templateName,
  templateId,
  statusType,
  selectedTemplate,
  transitionLabel,
}) => {
  const [comment, setComment] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [btnText, setBtnText] = useState('Publish');
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const { addToast } = CustomToast();
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const onLoad = (type) => {
    switch (type) {
      case 'DRAFT':
        if (transitionLabel === 'Reject') {
          setModalTitle('Reject Template');
          setBtnText('Reject');
        } else {
          setModalTitle('Draft');
          setBtnText('Draft');
        }

        break;
      case 'APPROVED':
        setModalTitle('Approve Template');
        setBtnText('Approve');
        break;
      case 'REJECTED':
        setModalTitle('Reject Template');
        setBtnText('Reject');
        break;
      case 'PUBLISHED':
        setModalTitle('Publish Template');
        setBtnText('Draft');
        break;
      case 'PENDING_REVIEW':
        setModalTitle('Send to Review Template');
        setBtnText('Review');
        break;
      default:
        setModalTitle('Draft');
        setBtnText('Draft');
    }
  };

  const validateTemplateData = () => {
    if (!selectedTemplate.v1_name) {
      return 'Template name is required';
    }
    if (!selectedTemplate.tenant_id) {
      return 'Tenant id is required';
    }
    if (!selectedTemplate.fields || selectedTemplate.fields.length === 0) {
      return 'Template fields are required';
    }
    if (
      selectedTemplate &&
      !selectedTemplate?.fields.some((item) => item.type === 'MEDIA')
    ) {
      return 'Template field must contain attachment type field';
    }
    if (
      !selectedTemplate?.fields?.some(
        (item) =>
          item?.type_based_attributes?.collection?.specialField === 'source'
      )
    ) {
      return 'Template field must contain source type field';
    }
    if (!selectedTemplate.workflow) {
      return 'Template workflow is required';
    }
    if (!selectedTemplate.workflow?.workflow_status) {
      return 'Template workflow status is required';
    }
    // if (
    //   !selectedTemplate.workflow?.workflow_rules ||
    //   selectedTemplate.workflow?.workflow_rules.length === 0
    // ) {
    //   return 'Template workflow rules are required';
    // }
    return null;
  };
  const validateAttachment = () => {
    if (
      selectedTemplate &&
      !selectedTemplate?.fields.some((item) => item.type === 'MEDIA')
    ) {
      return 'Template field must contain attachment type field';
    }
    return null;
  };

  useEffect(() => {
    onLoad(statusType);
    setError(null);
    setComment('');
  }, [statusType]);

  const validation = () => {
    setError(null);
    let errorMessage = '';
    if (isEmpty(comment)) {
      const message = 'Comment cannot be empty';
      setError(message);
      return message;
    }
    if (statusType === 'PENDING_REVIEW') {
      errorMessage = validateAttachment();
      setError(errorMessage);
      return errorMessage;
    }
    if (statusType === 'APPROVED') {
      errorMessage = validateTemplateData();
      setError(errorMessage);
      return errorMessage;
    }
    return errorMessage;
  };

  const onFinish = async () => {
    setError(null);
    setIsLoading(true);
    const payload = {
      data: {
        lifecycle_status: statusType !== 'REJECTED' ? statusType : 'DRAFT',
        note: comment || 'Empty Note',
      },
    };

    const response = await updateLifecycleStatus(payload, templateId);

    if (response?.error) {
      setError(response.error);
    } else {
      addToast({
        message: 'Successfully updated the status',
        type: 'success',
        duration: 2000,
        isClosable: true,
      });
      dispatch(
        updateViewState({
          stateKey: 'selectedTemplate',
          value: {
            ...selectedTemplate,
            lifecycle_status: statusType,
          },
        })
      );
      //navigate(0);
      setIsOpen(false);
    }
    setIsLoading(false);
  };

  const onSubmit = () => {
    const errorCheck = validation();
    if (isEmpty(errorCheck)) {
      onFinish();
    }
  };

  return (
    <CustomModal
      id="View-json-modal"
      modalTitle={modalTitle}
      modalContent={
        <>
          <Flex data-testid="TemplateApproveRejectModalContainerFlex">
            <h1
              className="text-primary"
              data-testid="TemplateApproveRejectModalHeaderH1"
            >
              {templateName}
            </h1>
            <Spacer />
          </Flex>
          <Box pt="4" data-testid="TemplateApproveRejectModalCommentBox">
            Comment
          </Box>
          <CustomTextArea
            onChange={(e) => {
              setComment(e.target.value);
            }}
            value={comment}
            placeholder="Your comment here"
            isError={false}
            helperText=" "
            errorMessage=""
            height="221px"
            id="node_description"
          />
        </>
      }
      modalFooter={
        <>
          {error && (
            <span
              className="field-error"
              style={{ marginRight: '10px', marginTop: 0 }}
              data-testid="TemplateApproveRejectModalErrorTextSpan"
            >
              {error}
            </span>
          )}
          <CustomButton
            color={useColorModeValue(BLACK, WHITE)}
            onClick={() => {
              setIsOpen(false);
            }}
            buttonText={LABELS.ACTIONS.CANCEL}
            variant="outline"
            className="mr-4"
            id="TemplateApproveRejectModalCancelBtn"
          />
          <CustomButton
            color="white"
            id="TemplateApproveRejectModalRejectBtn"
            buttonText={btnText}
            variant="solid"
            bg={
              btnText === 'Reject'
                ? 'red'
                : useColorModeValue(COLORS.LIGHT, COLORS.DARK)
            }
            _hover={btnText === 'Reject' && ''}
            onClick={() => onSubmit()}
            isLoading={isLoading}
          />
        </>
      }
      open={isOpen}
      setOpen={setIsOpen}
    />
  );
};

TemplateApproveRejectModal.propTypes = {
  templateName: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  templateId: PropTypes.string.isRequired,
  statusType: PropTypes.string.isRequired,
  selectedTemplate: PropTypes.object.isRequired,
  transitionLabel: PropTypes.string.isRequired,
};

export default TemplateApproveRejectModal;
