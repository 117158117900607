import { isDarkThemeApplied } from '../../pages/TicketTemplateBuilder/utils/helper';

const getColorTheme = (isDark) => (isDark ? '#BB86FC' : '#2563EB');
const getBackgroundColor = (isDark, isDisabled) => {
  if (isDisabled) {
    return isDark ? '#201f22' : '#fafafb';
  }
  return isDark ? '#4F5765' : '#fff';
};

const getTextColor = (isDark, isDisabled) =>{
  if (isDisabled) {
    return isDark ? '#59606b' : '#c7c9cf';
  }
  return isDark ? 'white' : 'hsl(0, 0%, 20%)';
};

export const customStyleFromRuleForSelect = {
  control: (provided, state) => {
    const isSelected = state?.isSelected || state?.isFocused;
    const isDark = isDarkThemeApplied();
    const borderColor = isSelected ? getColorTheme(isDark) : '#e2e8f0';
    return {
      ...provided,
      borderRadius: '6px',
      minHeight: '30px !important',
      height: 'auto', // Adjust height automatically based on content
      minWidth: '12.5rem',
      color: getTextColor(isDark),
      boxShadow: state.isFocused ? null : null,
      background: getBackgroundColor(isDark, state.isDisabled),
      borderColor,
      fontSize: '14px',
      overflow: 'hidden', // Hide overflow to prevent unwanted scroll bars
    };
  },
  menu: (...props) => {
    const position = {};

    const minTop = 2;

    try {
      const {
        width,
        bottom,
        top: boundingTop,
      } = props[1].innerRef.current.previousSibling.getBoundingClientRect();
      const { height } = props[1].innerRef.current.getBoundingClientRect();
      const inputValue = props?.[1]?.selectProps?.inputValue || '';
      position.width = `${width}px`;
      const top = bottom + minTop;
      if (inputValue?.length === 1) position.top = `${top}px`;
      else if (height + top >= window.innerHeight) {
        position.top = `${boundingTop - height - 10}px`;
      } else {
        position.top = `${top}px`;
      }
    } catch (err) {
      position.width = '100%';
      position.top = `${minTop}px`;
    }

    return {
      marginTop: '4px', // Ensure there is a gap between the control and menu
      zIndex: 99999,
      position: 'fixed',
      background: isDarkThemeApplied() ? '#4F5765' : '#fff',
      boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
      ...position,
    };
  },
  valueContainer: (provided) => ({
    ...provided,
    minHeight: '30px !important',
    height: 'auto', // Adjust height automatically based on content,
    padding: '0 6px',
    color: isDarkThemeApplied() ? 'white' : 'hsl(0, 0%, 20%)',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: isDarkThemeApplied() ? 'white' : 'hsl(0, 0%, 20%)',
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: isDarkThemeApplied()
      ? 'hsl(0, 0%, 20%)'
      : 'hsl(0, 0%, 90%)',
    color: isDarkThemeApplied() ? 'white' : 'hsl(0, 0%, 20%)',
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: isDarkThemeApplied() ? 'white' : 'hsl(0, 0%, 20%)',
  }),
  input: (provided) => ({
    ...provided,
    color: isDarkThemeApplied() ? 'white' : 'hsl(0, 0%, 20%)',
    margin: '0px',
    '::before': {
      position: 'relative',
    },
    '::after': {
      position: 'relative',
    },
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: 'auto', // Adjust height automatically based on content
    minHeight: '30px !important',
  }),
  option: (provided, { isDisabled, isFocused, isSelected }) => {
    const isDark = isDarkThemeApplied();
    const selectedBackgroundColor = isDark ? '#BB86FC' : '#2684FF';
    const focusedBackgroundColor = isDark ? '#394353' : '#deebff';
    const backgroundColor = (() => {
      if (isDisabled) return 'blue';
      if (isSelected) return selectedBackgroundColor;
      if (isFocused) return focusedBackgroundColor;
      return undefined;
    })();

    const activeBackgroundColor =
      !isDisabled && (isSelected || isFocused) ? backgroundColor : undefined;

    return {
      ...provided,
      backgroundColor,
      cursor: 'pointer',
      ':active': {
        ...provided[':active'],
        backgroundColor: activeBackgroundColor,
      },
    };
  },
  placeholder: (provided, state) => ({
    ...provided,
    color: getTextColor(isDarkThemeApplied(), state.isDisabled),
  }),
};

export default customStyleFromRuleForSelect;
