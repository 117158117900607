import React, { useEffect,useState } from 'react'
import { useParams } from 'react-router'
import SilentInputsTableWithSearch from './SilentInputsTableWithSearch'
import axiosInstance,{axiosInstanceWithoutBaseUrl} from '../../../../utils/axios/axios'
import { SILENT_INPUTS_TRIGGERS } from './utils'

const SilentInputs = () => {
    const [isFetching,setIsFetching]=useState(false)
    const [selectedTemplate,setSelectedTemplate]=useState([])
    const [silentInputsData,setSilentInputsData]=useState([])
    const [dropdownFields, setDropdownFields]=useState([])
    const [offers,setOffers] = useState([])
    const [qnaRecords,setQnaRecords]=useState([])
    const [deletedFields,setDeletedFields]=useState([])

    const templateId = useParams()
    const id=templateId['*'].match(/\d+/)[0]
    const fields = selectedTemplate ? selectedTemplate[0]?.fields : []
    console.log("sel",selectedTemplate)
    
    const getFields=async()=>{
        setIsFetching(true)
        try {
          const response = await axiosInstance.get(
            `/templates/list?$top=1&$skip=0&$select=*&$filter=id%20eq%20${id}&history=true`
          );
          if (Array.isArray(response?.data?.rows)) {
            setSelectedTemplate(response?.data?.rows)
          }
          setIsFetching(false)
          return [];
        } catch (err) {
          setIsFetching(false)
          return [];
        }
      }
    const getOffers = async () => {
        try {
          const response = await axiosInstance.get(
            `/fieldDefaults/list?$select=*&$filter=item_type%20eq%20%27${selectedTemplate[0]?.id}%27%20and%20offer_type%20ne%20%27email%27`
          );
          if (Array.isArray(response?.data?.rows)) {
            setOffers(response.data.rows);
            return response.data.rows;
          }
          return [];
        } catch (err) {
          return [];
        }
      };
    const getQnaRecords = async () => {
        try {
          // setIsFetching(true);
          const response = await axiosInstanceWithoutBaseUrl.get(
            `/botmgmt-service/qna/qnaRecords?limit=2000&sortName=1&sortOrder=desc&type=flow&answer=NA&botIntegration=True`
          );
          if (Array.isArray(response?.data?.data)) {
            // setIsFetching(false);
            setQnaRecords(response?.data?.data);
          }
          return [];
        } catch (err) {
          // setIsFetching(false);
        }
    };
    useEffect(()=>{
        getFields()
    },[])
    useEffect(()=>{
      if(selectedTemplate){
         getOffers()
         getQnaRecords()
      }
      },[selectedTemplate])
    useEffect(()=>{
        const silentInputFields=selectedTemplate[0]?.fields.filter((item)=>{
          if(item?.silentInput) {return item}
          else {return null}
        })
        setSilentInputsData(silentInputFields)
        if (Array.isArray(selectedTemplate[0]?.fields)) {
          const botFields = selectedTemplate[0].fields.filter((item) => {
            if (item?.type_based_attributes?.collection?.collection_type === 'SPECIAL_FIELD' || 
                item?.type_based_attributes?.collection?.collection_type === 'DEPENDENT') {
              return false;
            }
            if (Array.isArray(item?.permissions?.viewers?.roles)) {
              const roles = item.permissions.viewers.roles;
              if (roles.includes('bot_user') || roles.includes('ticket_user')) {
                return true;
              }
              return false;
            }
            return true;
          });
          const silentFields=selectedTemplate[0]?.fields.filter(item=>!botFields.includes(item))
          const filteredItems=silentFields.filter(item=>!silentInputFields.includes(item))
          setDropdownFields(filteredItems)
        }
    },[selectedTemplate])
  


    const onEditDescription = (rowData, value) => {
      const updatedTableData = silentInputsData.map((item) => {
        if (item.id === rowData.id) {
          return { ...item, botDescription: value };
        }
        return item;
      });
      setSilentInputsData(updatedTableData);
    }
    const onChangeCrossReferencedFields = (rowData, fieldValues) => {
      const updatedTableData = silentInputsData.map(item => {
        if (item.id === rowData.id) {
          return { ...item, crossReferencedFields: fieldValues.map(item=>{
            return {label:item.value,value:item.value}
          }) };
        }
        return item;
      });
      setSilentInputsData(updatedTableData);
    }
    const onChangeOffers=(rowData,offerValues)=>{
      const updatedTableData = silentInputsData.map(item => {
        if (item.id === rowData.id) {
          return { ...item, selectedOffers: offerValues.map(item=>{
            return {label:item.label,value:item.value}
          }) };
        }
        return item;
      });
      setSilentInputsData(updatedTableData);
    }

    const onDelete = (fieldsTobeDelete)=>{
      const fields = silentInputsData.filter((item) => item.id !== fieldsTobeDelete?.id);
      setSilentInputsData(fields);
      setDeletedFields([...deletedFields,fieldsTobeDelete]);
    }
    const handleReset=()=>{}

  return (
    <>
        <SilentInputsTableWithSearch
            columns={SILENT_INPUTS_TRIGGERS({onEditDescription,onDelete,onChangeCrossReferencedFields,onChangeOffers,offers,fields})}
            isFetching={isFetching}
            selectedTemplate={selectedTemplate}
            tableData={silentInputsData}
            handleReset={handleReset}
            dropdownFields={dropdownFields}
            silentInputsData={silentInputsData}
            setSilentInputsData={setSilentInputsData}
            qnaRecords={qnaRecords}
            deletedFields={deletedFields}
            setDeletedFields={setDeletedFields}
        />
    </>
  )
}

export default SilentInputs