/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable indent */
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import {
  AddIcon,
  DeleteIcon,
  DragHandleIcon,
  EditIcon,
} from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { isEmpty, filter } from 'lodash';
import useUndo from 'use-undo';
import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg';
import { useSelector } from 'react-redux';
import differenceBy from 'lodash/differenceBy';
import _cloneDeep from 'lodash/cloneDeep';
import { BORDER_DARK, COLORS } from '../../../utils/enums/colors';
import DragDiv from './DragDesign';
import FieldIcon from '../../../assets/icons/FieldIcon.svg';
import UndoIcon from '../../../assets/icons/Undo.svg';
import RedoIcon from '../../../assets/icons/Redo.svg';
import UndoWhite from '../../../assets/icons/UndoWhite.svg';
import RedoWhite from '../../../assets/icons/RedoWhite.svg';
import AddEditLayoutSectionModal from './AddEditLayoutSectionModal';
import EditFieldLayoutModal from './EditFieldLayout';
import CustomButton from '../../../components/Button/SimpleButton';
import styles from '../styles/templatebuilder.module.scss';
import { checkForV2 } from '../../../utils/helper';
import { switchObjectsOrderById } from './utils/specialFieldsJson';
import CommonAttributesDrawer from '../../TicketMetaFields/CommonAttributesDrawer';
import {
  metaTableAttributeList,
  metaTableColumnAttributeList,
} from '../../TicketMetaFields/CommonAttributesDrawer/utils';
import SpecialFieldDrawer from '../../TicketMetaFields/SpecialFieldDrawer';
// import specialFieldList, {
//   mergeArrays,
// } from '../../TicketMetaFields/SpecialFieldDrawer/utils';
import { SCOPE_MAP, layoutViewTypes } from '../../../utils/enums/selectOption';
import { SPECIAL_FIELD_TYPE } from '../../TenantFilters/filters.constants';
import InsertFieldDrawer from '../InsertFieldDrawer';
import { showToast } from '../../../utils/crud';

const DragAndResizeLayout = ({
  itemsStateList,
  setItemsStateList,
  layoutType,
  viewType,
  metaFieldTableAttributes,
  setMetaFieldTableAttributes,
  metaFieldLayoutTitle,
  mainwidth,
}) => {
  const [draggingItem, setDraggingItem] = useState(null);
  const [draggingItemP, setDraggingItemP] = useState(null);
  const [openEditSectionModal, setOpenEditSectionModal] = useState(false);
  const [openEditFieldModal, setOpenEditFieldModal] = useState(false);
  const [fieldData, setFieldData] = useState({});
  const [sectionData, setSectionData] = useState({});
  const [openMetaTableAttributes, setOpenMetaTableAttributes] = useState(false);
  const [tableAttributeState, setTableAttributeState] = useState({
    ...metaFieldTableAttributes,
  });
  const [tableColumnAttributeState, setTableColumnAttributeState] = useState(
    {}
  );
  const [openTableColumnAttribute, setOpenTableColumnAttribute] =
    useState(false);
  const [openSpecialFieldDrawer, setOpenSpecialFieldDrawer] = useState(false);
  const [openFieldListDrawer, setOpenFieldListDrawer] = useState(false);
  const selectedTemplate = useSelector(
    (state) => state?.viewState?.selectedTemplate
  );

  const [state, { set: setItemsState, undo, redo, canUndo, canRedo }] =
    useUndo(itemsStateList);

  const getFlattenItems = (arr) => {
    const flattenArr = [];
    const flattenItem = (item) => {
      if (item?.type === 'component') {
        if (item?.subItems && item?.subItems?.length > 0) {
          item?.subItems?.forEach((subItem) => {
            flattenItem(subItem);
          });
        }
      }
      flattenArr.push(item);
    };
    arr?.forEach((item) => {
      flattenItem(item);
    });

    return flattenArr;
  };

  const remainingFields = useCallback(() => {
    const flattenItems = getFlattenItems(itemsStateList);
    const fieldDifferences = differenceBy(
      selectedTemplate?.fields,
      flattenItems,
      'id'
    );
    if (viewType === layoutViewTypes.INBOX) {
      return filter(
        fieldDifferences,
        (field) => field.type !== 'MEDIA' && field.type !== 'RICH_TEXT'
      )?.map((i) => ({
        ...i,
        height: 1,
        width: 6,
        isBadge: i.type !== 'TEXT' || i.type !== 'DATE',
      }));
    }
    if (viewType === layoutViewTypes.GRID) {
      return filter(
        fieldDifferences,
        (field) => field.scope === SCOPE_MAP.TICKET || isEmpty(field.scope)
      )?.map((i) => ({ ...i, height: 1, width: 6 }));
    }
    if (viewType === layoutViewTypes.TRANSITION) {
      return filter(
        fieldDifferences,
        (field) =>
          field.scope === SCOPE_MAP.WORKFLOW ||
          field.scope === SCOPE_MAP.TICKET ||
          isEmpty(field.scope)
      )?.map((i) => ({ ...i, height: 1, width: 12 })); // Width = 12 as it is a single column
    }
    return filter(
      fieldDifferences,
      (field) =>
        field.type !== 'DROPDOWN' &&
        field?.type_based_attributes?.collection?.specialField !==
          SPECIAL_FIELD_TYPE.WORKFLOW
    )?.map((i) => ({ ...i, height: 1, width: 6 }));
  }, [selectedTemplate, itemsStateList]);

  const [
    deleteState,
    {
      set: setDeletedFieldItems,
      undo: undoDelete,
      redo: redoDelete,
      // canundo: canUndoDelete,
      // canRedo: canRedoDelete,
    },
  ] = useUndo(remainingFields());

  const { present: itemsState } = state;

  const { present: deletedFieldItems } = deleteState;
  // This will trigger when we add section from parent
  useEffect(() => {
    setItemsState(itemsStateList);
  }, [itemsStateList]);

  // This is to store the items back to the originalState
  useEffect(() => {
    setItemsStateList(itemsState);
  }, [itemsState]);

  useEffect(() => {
    setMetaFieldTableAttributes(tableAttributeState);
  }, [tableAttributeState]);

  const removeDragItemById = () => {
    // Helper function to recursively remove an item by ID from an array of items
    const removeItemById = (items, id) =>
      items.filter((item) => {
        if (item.id === id) {
          // If the item matches the ID, remove it
          return false;
        }
        if (item.subItems && item.subItems.length > 0) {
          // If the item has subItems, recursively remove the item from subItems
          item.subItems = removeItemById(item.subItems, id);
        }
        return true; // Keep the item in the array
      });

    return removeItemById(_cloneDeep(itemsState), draggingItem?.id);
  };

  const addItemByIndex = (parentArray, objToBeAdded, initialState) => {
    const dropItemIndex = parentArray?.findIndex(
      (element) => element.id === objToBeAdded.id
    );
    const dragItemIndex = initialState?.findIndex(
      (element) => element.id === draggingItem?.id
    );
    if (dropItemIndex >= dragItemIndex) {
      parentArray.splice(dropItemIndex + 1, 0, draggingItem);
      return parentArray;
    }
    parentArray.splice(dropItemIndex, 0, draggingItem);
    return parentArray;
  };

  const addItem = (deleteDragItem, dropItem, dropP) => {
    const dropHasParent = !!dropP?.id;
    if (!dropHasParent) {
      return addItemByIndex(deleteDragItem, dropItem, itemsState);
    }

    return deleteDragItem.map((each) => {
      if (each.id === dropP.id) {
        return {
          ...each,
          subItems: addItemByIndex(
            each.subItems,
            dropItem,
            itemsState.filter((ins) => ins.id === dropP.id)[0]?.subItems
          ),
        };
      }

      const subItems = each?.subItems;
      if (Array.isArray(subItems)) {
        each.subItems = addItem(subItems, dropItem, dropP);
      }

      return each;
    });
  };

  const handleDragAndDrop = (dropItem, dropP = null) => {
    if (draggingItem?.type === 'component') {
      const isParent = !!itemsState.find((i) => i?.id === dropP?.id);

      if (!isParent) {
        showToast({
          title: 'Error',
          message: 'You can have more than two levels of nesting',
          type: 'error',
        });

        return;
      }
    }

    if (dropItem?.isStatic) return;

    if (dropItem.id !== draggingItem?.id && draggingItem) {
      const deleteDragItem = removeDragItemById();
      const updatedItem = addItem(deleteDragItem, dropItem, dropP);
      setItemsState(updatedItem);
    }
  };

  const dropOnParent = () => {
    const deleteDragItem = removeDragItemById();
    setItemsState([...deleteDragItem, draggingItem]);
  };

  const dropOnParentReplace = (dropItem) => {
    // We can use this code for dropOnParent function also
    // if the requirement is when we drag and drop a field on another field it should replace
    const newState = switchObjectsOrderById(
      itemsState,
      draggingItem.id,
      dropItem.id
    );
    setItemsState(newState);
  };

  const dropOnComponent = (dropItem, level = 0) => {
    if (dropItem.type_based_attributes) {
      delete dropItem.type_based_attributes;
    }

    if (!checkForV2() && draggingItem?.type === 'component') {
      dropOnParentReplace(dropItem);
      return;
    }

    if (level === 2 && draggingItem?.type === 'component') {
      return;
    }

    if (dropItem.id !== draggingItem?.id && draggingItem) {
      const deleteDragItem = removeDragItemById();
      const move = (moveArr, dragItem, dropId) => {
        const newArr = moveArr.map((item) => {
          if (item.id === dropId) {
            const subItems = item?.subItems || [];
            item.subItems = [...subItems, dragItem];
          }
          if (item?.subItems) {
            move(item?.subItems, dragItem, dropId);
          }
          return item;
        });
        return newArr;
      };

      const updated = move(
        _cloneDeep(deleteDragItem),
        draggingItem,
        dropItem.id
      );
      setItemsState(updated);
    }
  };

  const EmptySection = (
    <Box
      bg={useColorModeValue('white', 'gray.900')}
      align="center"
      justifyContent="center"
      width="100%"
      mb="4"
      data-testid="DragAndResizeLayoutBox"
    >
      <Box
        bg={useColorModeValue('white', 'gray.900')}
        border="1px"
        width="fit-content"
        borderColor="#E2E3E4"
        borderRadius="10px"
        borderStyle="dashed"
        data-testid="DragAndResizeLayoutFieldIconBox"
      >
        <ReactSVG
          src={FieldIcon}
          className={`p-2 ${useColorModeValue('', styles.colorWhite)}`}
        />
      </Box>
      <Box mt="3" data-testid="DragAndResizeLayoutTextBox" mb="3">
        <Text
          fontSize="16px"
          fontWeight="600"
          color={useColorModeValue(COLORS.TEXT_LIGHT, '')}
        >
          Drop Fields here
        </Text>
      </Box>
      <Box>The group is empty. You can drag & drop fields.</Box>
    </Box>
  );

  const editFields = (val) => {
    setFieldData(val);
    setOpenEditFieldModal(true);
  };

  const editTableColumnAttribute = (val) => {
    if (val.meta_field_id !== 'objectId') {
      setTableColumnAttributeState(val);
      setOpenTableColumnAttribute(true);
    }
  };
  const updateTableColumnAttribute = (val) => {
    const LIST_STATE = _cloneDeep(itemsState);
    const ROW_INDEX = LIST_STATE.findIndex(
      (item) => item.meta_field_id === val.meta_field_id
    );

    if (val.colSpan <= 0) {
      return; 
    }
  
    if (ROW_INDEX !== undefined && ROW_INDEX !== -1) {
      LIST_STATE[ROW_INDEX] = val;
      setItemsState(LIST_STATE);
    }
  };
  const editSection = (val) => {
    setSectionData(val);
    setOpenEditSectionModal(true);
  };

  const editSectionInLayout = (val) => {
    const sec = {
      id: `${val.name}-id`,
      type: 'component',
      sectionName: val?.name,
      sectionType: val?.type,
      width: val?.width || 12,
      hideSectionLabel: val?.hideSectionLabel,
      permissions: val?.permissions,
    };

    const updatedItemsList = itemsState?.map((i) => {
      if (i?.id === val?.id) {
        const up = { ...i, ...sec };
        return up;
      }
      if (i?.subItems) {
        i.subItems = i?.subItems?.map((ins) => {
          if (ins?.id === val?.id) {
            const updated = { ...ins, ...sec };
            return updated;
          }
          return ins;
        });
      }
      return i;
    });

    setItemsState(updatedItemsList);
    setOpenEditSectionModal(false);
  };

  const filterObjectFromArray = (array, targetObject) => {
    for (let i = 0; i < array.length; i++) {
      const item = array[i];

      // Check if the current item matches the targetObject
      if (item?.id === targetObject?.id) {
        // Remove the matched item from the array
        array.splice(i, 1);
        return array; // Object found and removed
      }

      // If the current item has subItems, recursively search in them
      if (item.subItems && Array.isArray(item.subItems)) {
        if (filterObjectFromArray(item.subItems, targetObject)) {
          // If the target object is found in subItems, return true
          return array;
        }
      }
    }
    // Object not found in the array or its subItems
    return false;
  };

  const deleteSection = (val) => {
    const allSectionFields = val?.subItems;
    const updatedArray = filterObjectFromArray(
      JSON.parse(JSON.stringify(itemsState)),
      val
    );
    setItemsState([...updatedArray, ...allSectionFields]);
  };

  const editFieldColspan = (val) => {
    const updateItem = (items, itemId) =>
      items.map((item) => {
        if (item?.id === itemId) {
          const commonData = {
            ...item,
            width: val?.width,
            height: val?.height,
            type_based_attributes: {
              ...item?.type_based_attributes,
              read_only: val?.read_only || false,
            },
            fieldFontSize: val?.fieldFontSize,
            fieldFontColor: val?.fieldFontColor,
            fieldFontWeight: val?.fieldFontWeight,
            slaViewType: val?.slaViewType,
            noteTypes: val?.noteTypes,
            isNoteRequired: val?.isNoteRequired,
            isBadge: val?.isBadge,
            isPriority: val?.isPriority,
            badgeColors: val?.badgeColors,
          };
          const extendedData =
            item?.type === 'SF_LINKED_TICKETS_CUSTOM'
              ? {
                  ...commonData,
                  label: val.label,
                  relationIds: val.relationIds,
                }
              : commonData;
          return extendedData;
        }
        if (item?.subItems && item?.subItems?.length > 0) {
          item.subItems = updateItem(item?.subItems, itemId);
        }
        return item;
      });

    const updatedItemsList = updateItem(_cloneDeep(itemsState), val.id);

    setItemsState(updatedItemsList);
    setOpenEditFieldModal(false);
  };
  const updateSpecialField = (value) => {
    const updatedValue = value.map((item) => ({
      ...item,
      colSpan: 1, // Set colSpan to 1
    }));
    setItemsState(updatedValue);
  };

  const deleteFields = (val) => {
    const deleteItem = (items, itemId) =>
      items
        .map((item) => {
          if (item?.id === itemId) {
            return null;
          }
          if (item?.subItems && item?.subItems?.length > 0) {
            item.subItems = deleteItem(item?.subItems, itemId);
          }
          return item;
        })
        .filter(Boolean); // Filter out null values (deleted items)
    const updatedItems = deleteItem(_cloneDeep(itemsState), val.id);
    const hasSpecialFields = selectedTemplate?.fields?.filter(
      (item) => item.id === val.id
    );
    if (hasSpecialFields?.length !== 0) {
      setDeletedFieldItems([...deletedFieldItems, val]);
    }
    setItemsState(updatedItems);
  };

  const insertFieldsToLayout = (addItems) => {
    setItemsState([...itemsState, ...addItems]);
    const newDeletedItems = deletedFieldItems?.filter(
      (deletedItem) =>
        !addItems.some((addedItem) => addedItem.id === deletedItem.id)
    );
    setDeletedFieldItems(newDeletedItems);
  };

  const nestedLevel2 = (subItem) => (
    <Box
      key={subItem?.id}
      // gridColumnEnd={`span 12`}
      gridColumnEnd={`span ${subItem?.width}`}
      // gridRowEnd={`span ${item.h}`}
      gridRowEnd="auto"
      // height="100%"
      border="1px"
      borderStyle="solid"
      borderRadius="10px"
      borderColor={useColorModeValue('rgba(0,0,0,0.25)', BORDER_DARK)}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      draggable
      onDragStart={(e) => {
        e.stopPropagation();
        // e.preventDefault();

        e.dataTransfer.setData('text/plain', '');
        e.dataTransfer.effectAllowed = 'move';
        e.dataTransfer.dropEffect = 'move';

        setDraggingItem(subItem);
      }}
      onDrop={(e) => {
        e.stopPropagation();
        e.preventDefault();

        dropOnComponent(subItem, 2); // on Drop on Component
        setDraggingItem(null);
        setDraggingItemP(null);
      }}
      onDragOver={(e) => {
        e.preventDefault();
      }}
    >
      <Box
        height="100%"
        borderRight="1px"
        borderColor={useColorModeValue('rgba(0,0,0,0.25)', BORDER_DARK)}
        py="1"
        px="2"
      >
        <DragHandleIcon height="100%" />
      </Box>
      <Grid
        templateColumns="repeat(12, 1fr)"
        flex="1 1 0px"
        gap={4}
        py="4"
        pl="2"
        pr="2"
        maxW="100%"
        overflowX="auto"
        sx={{
          '&::-webkit-scrollbar': {
            width: '5px',
            height: '5px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: 'lightgrey',
            borderRadius: '10px',
          },
        }}
      >
        <GridItem colSpan={12}>
          <Box display="flex" width="100%" justifyContent="space-between">
            <Box> {subItem?.sectionName} </Box>
            {checkForV2() && (
              <Box>
                <EditIcon
                  onClick={() => {
                    editSection(subItem);
                  }}
                />
                <DeleteIcon
                  mx="4"
                  onClick={() => {
                    deleteSection(subItem);
                  }}
                />
              </Box>
            )}
          </Box>
        </GridItem>
        {!subItem?.subItems?.length ? (
          <GridItem colSpan={12}>{EmptySection}</GridItem>
        ) : (
          subItem?.subItems?.map((ins) => 
            ins.type === 'component' ? (
              nestedLevel2(ins) // Handle level 3
            ) : (
            <Box
              key={ins.id}
              gridColumnEnd={`span ${ins.width}`}
              gridRowEnd={`span ${ins.height}`}
              border="1px"
              borderRadius="6px"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              borderColor={useColorModeValue('rgba(0,0,0,0.25)', BORDER_DARK)}
              draggable
              onDragStart={(e) => {
                e.stopPropagation();
                e.dataTransfer.setData('text/plain', '');
                e.dataTransfer.effectAllowed = 'move';
                e.dataTransfer.dropEffect = 'move';
                setDraggingItem(ins);
                setDraggingItemP(subItem);
              }}
              onDrop={(e) => {
                e.stopPropagation();
                e.preventDefault();

                handleDragAndDrop(ins, subItem); // test
                setDraggingItem(null);
                setDraggingItemP(null);
              }}
              onDragOver={(e) => {
                e.preventDefault();
              }}
            >
              <DragDiv
                draggableItemName={ins.label}
                onEdit={() => {
                  editFields(ins);
                }}
                onDelete={() => {
                  deleteFields(ins);
                }}
                type={ins?.type}
                isStatic={ins?.isStatic}
                scope={ins?.scope}
              />
            </Box>
          ))
        )}
      </Grid>
    </Box>
  );
  useEffect(() => {
    let stop = true;
    const draggables = document.querySelectorAll('.draggable');
    function scroll(step) {
      const scrollY = window.scrollY || window.pageYOffset;
      window.scrollTo(0, scrollY + step);

      if (!stop) {
        setTimeout(() => {
          scroll(step);
        }, 20);
      }
    }
    draggables.forEach((element) => {
      element.addEventListener('drag', (e) => {
        stop = true;

        if (e.clientY < 160) {
          stop = false;
          scroll(-1);
        }

        if (e.clientY > window.innerHeight - 160) {
          stop = false;
          scroll(1);
        }
      });

      element.addEventListener('dragend', (e) => {
        stop = true;
      });
    });

    function allowDrop(ev) {
      ev.preventDefault();
    }

    function drag(ev) {
      ev.dataTransfer.setData('text', ev.target.id);
    }

    function drop(ev) {
      ev.preventDefault();
      const data = ev.dataTransfer.getData('text');
      ev.target.appendChild(document.getElementById(data));
    }

    const draggableElements = document.querySelectorAll('.draggable');
    draggableElements.forEach((element) => {
      element.addEventListener('dragstart', drag);
      element.addEventListener('dragover', allowDrop);
      element.addEventListener('drop', drop);
    });

    // Cleanup function to remove event listeners
    return () => {
      draggables.forEach((element) => {
        element.removeEventListener('drag', scroll);
        element.removeEventListener('dragend', stop);
        element.removeEventListener('dragstart', drag);
        element.removeEventListener('dragover', allowDrop);
        element.removeEventListener('drop', drop);
      });
    };
  }, [draggingItem]);

  const calculatedWidth =
    layoutType === 'MOBILE' || viewType === 'TABLE'
      ? mainwidth || '400px'
      : '100%';

  return (
    <Box
      w="100%"
      display="block"
      data-testid="DragAndResizeLayoutContainerBox"
      overflowY="scroll"
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        py="4"
        data-testid="DragAndResizeLayoutButtonBox"
      >
        <CustomButton
          // leftIcon={<ReactSVG src={useColorModeValue(UndoIcon, UndoWhite)} />}
          svg={UndoIcon}
          svghover={UndoWhite}
          color={useColorModeValue('black', 'white')}
          onClick={() => {
            undoDelete();
            undo();
          }}
          buttonText="Undo"
          variant="outline"
          className="mr-4"
          isDisabled={!canUndo}
          id="DragAndResizeLayoutUndoTextBtn"
        />
        <CustomButton
          // leftIcon={<ReactSVG src={useColorModeValue(RedoIcon, RedoWhite)} />}
          svg={RedoIcon}
          svghover={RedoWhite}
          color={useColorModeValue('black', 'white')}
          onClick={() => {
            redo();
            redoDelete();
          }}
          buttonText="Redo"
          variant="outline"
          className="mr-4"
          isDisabled={!canRedo}
          id="DragAndResizeLayoutRedoBtn"
        />
      </Box>
      <Box
        display="flex"
        // justifyContent="center"
        alignItems="center"
        data-testid="DragAndResizeLayoutGridBox"
      >
        <Grid
          border="1px"
          borderStyle="dashed"
          mx="8"
          py="4"
          pl="2"
          pr="2"
          width={calculatedWidth}
          borderRadius="10px"
          borderColor={useColorModeValue('rgba(0,0,0,0.65)', BORDER_DARK)}
          templateColumns="repeat(12, 1fr)"
          // gridAutoRows="50px"
          gap={4}
          onDrop={(e) => {
            e.preventDefault();
            // handleDragAndDrop(item);
            dropOnParent();
            setDraggingItem(null);
            setDraggingItemP(null);
          }}
          onDragOver={(e) => {
            e.preventDefault();
          }}
          style={{ overflowX: 'hidden' }}
          data-testid="DragAndResizeLayoutItemGrid"
        >
          {!isEmpty(metaFieldLayoutTitle) && (
            <GridItem colSpan={12}>
              <Flex justify="space-between" align="center">
                <Box> {metaFieldLayoutTitle}</Box>
                <Box
                  display="flex"
                  justifyItems="center"
                  alignItems="center"
                  gap="25px"
                >
                  <Box>ColSpan(s)</Box>
                  {/* <EditIcon
                    onClick={() => {
                      setOpenMetaTableAttributes(true);
                    }}
                  /> */}
                </Box>
              </Flex>
            </GridItem>
          )}
          {itemsState?.map((item) =>
            item.type !== 'component' ? (
              <Box
                key={item?.id}
                position="relative"
                gridColumnEnd={
                  !isEmpty(metaFieldLayoutTitle)
                    ? `span 12`
                    : `span ${item?.width}`
                }
                // gridRowEnd={`span ${item.h}`}
                gridRowEnd={`span ${item?.height}`}
                // height="100%"
                border="1px"
                borderRadius="6px"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                borderColor={useColorModeValue(
                  item?.isStatic ? '#2563EB' : 'rgba(0,0,0,0.25)',
                  BORDER_DARK
                )}
                draggable
                onDragStart={(e) => {
                  if (item?.isStatic || item.meta_field_id === 'objectId') {
                    e.preventDefault(); // Prevent dragging for static fields
                    return;
                  }

                  e.dataTransfer.setData('text/plain', '');
                  e.dataTransfer.effectAllowed = 'move';
                  e.dataTransfer.dropEffect = 'move';
                  setDraggingItem(item);
                }}
                onDrop={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  if (item.meta_field_id !== 'objectId') {
                    handleDragAndDrop(item);
                  }
                  setDraggingItem(null);
                  setDraggingItemP(null);
                }}
                onDragOver={(e) => {
                  e.preventDefault();
                }}
                data-testid="DragAndResizeLayoutItemBox"
              >
                <DragDiv
                  draggableItemName={item?.label}
                  colSpanCount={item?.colSpan}
                  onChangeColSpan={(colSpan) => {
                    if (!isEmpty(metaFieldLayoutTitle)) {
                      updateTableColumnAttribute({ ...item, colSpan });
                    }
                  }}
                  showColSpan={!isEmpty(metaFieldLayoutTitle)}
                  onEdit={() => {
                    if (!isEmpty(metaFieldLayoutTitle)) {
                      editTableColumnAttribute(item);
                    } else {
                      editFields(item);
                    }
                  }}
                  onDelete={() => {
                    if (
                      item.meta_field_id === 'objectId' &&
                      !isEmpty(metaFieldLayoutTitle)
                    ) {
                      return;
                    }
                    deleteFields(item);
                  }}
                  type={item?.type}
                  isStatic={item?.isStatic}
                  scope={item?.scope}
                  isEditable={item?.meta_field_id !== 'objectId'}
                  isDeletable={item?.meta_field_id !== 'objectId'}
                  isDraggable={item?.meta_field_id !== 'objectId'}
                />
                {/* <Box
                  position="absolute"
                  top={0}
                  left="100%"
                  bottom={0}
                  w={`${((12 - item.width) / item.width + 0.08) * 100}%`}
                  display={`${draggingItem?.id === item.id ? 'none' : 'block'}`}
                /> */}
              </Box>
            ) : (
              <Box
                key={item.id}
                // gridColumnEnd={`span 12`}
                gridColumnEnd={`span ${item?.width}`}
                // gridRowEnd={`span ${item.h}`}
                gridRowEnd="auto"
                // height="100%"
                border="1px"
                borderStyle="solid"
                borderRadius="10px"
                borderColor={useColorModeValue('rgba(0,0,0,0.25)', BORDER_DARK)}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                draggable
                onDragStart={(e) => {
                  e.dataTransfer.setData('text/plain', '');
                  e.dataTransfer.effectAllowed = 'move';
                  e.dataTransfer.dropEffect = 'move';
                  setDraggingItem(item);
                }}
                onDrop={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  dropOnComponent(item); // on Drop on Component
                  setDraggingItem(null);
                  setDraggingItemP(null);
                }}
                onDragOver={(e) => {
                  e.preventDefault();
                }}
                data-testid="DragAndResizeLayoutDragBox"
              >
                <Box
                  height="100%"
                  borderRight="1px"
                  borderColor={useColorModeValue(
                    'rgba(0,0,0,0.25)',
                    BORDER_DARK
                  )}
                  py="1"
                  px="2"
                  data-testid="DragAndResizeLayoutDragIconBox"
                >
                  <DragHandleIcon height="100%" cursor={'grab'} />
                </Box>
                <Grid
                  templateColumns="repeat(12, 1fr)"
                  flex="1 1 0px"
                  gap={4}
                  py="4"
                  pl="2"
                  pr="2"
                  data-testid="DragAndResizeLayoutGrid"
                  maxW="100%"
                  overflowX="auto"
                  sx={{
                    '&::-webkit-scrollbar': {
                      width: '5px',
                      height: '5px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      background: 'lightgrey',
                      borderRadius: '10px',
                    },
                  }}
                >
                  <GridItem
                    colSpan={12}
                    data-testid="DragAndResizeLayoutGridItem"
                  >
                    <Box
                      display="flex"
                      width="100%"
                      justifyContent="space-between"
                      data-testid="DragAndResizeLayoutSNameBox"
                    >
                      <Box> {item?.sectionName} </Box>
                      {checkForV2() && (
                        <Box data-testid="DragAndResizeLayoutIconBox">
                          <EditIcon
                            cursor={'pointer'}
                            onClick={() => {
                              editSection(item);
                            }}
                          />
                          <DeleteIcon
                            mx="4"
                            cursor={'pointer'}
                            onClick={() => {
                              deleteSection(item);
                            }}
                          />
                        </Box>
                      )}
                    </Box>
                  </GridItem>
                  {!item?.subItems?.length ? (
                    <GridItem
                      colSpan={12}
                      data-testid="DragAndResizeLayoutESectionGridItem"
                    >
                      {EmptySection}
                    </GridItem>
                  ) : (
                    item?.subItems?.map((subItem) =>
                      subItem.type === 'component' ? (
                        nestedLevel2(subItem)
                      ) : (
                        <Box
                          key={subItem.id}
                          gridColumnEnd={`span ${subItem.width}`}
                          gridRowEnd={`span ${subItem.height}`}
                          border="1px"
                          borderRadius="6px"
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                          borderColor={useColorModeValue(
                            subItem?.isStatic ? '#2563EB' : 'rgba(0,0,0,0.25)',
                            BORDER_DARK
                          )}
                          draggable
                          onDragStart={(e) => {
                            e.stopPropagation();
                            e.dataTransfer.setData('text/plain', '');
                            e.dataTransfer.effectAllowed = 'move';
                            e.dataTransfer.dropEffect = 'move';
                            setDraggingItem(subItem);
                            setDraggingItemP(item);
                          }}
                          onDrop={(e) => {
                            e.stopPropagation();
                            e.preventDefault();

                            handleDragAndDrop(subItem, item); // test
                            setDraggingItem(null);
                            setDraggingItemP(null);
                          }}
                          onDragOver={(e) => {
                            e.preventDefault();
                          }}
                          data-testid="DragAndResizeLayoutDragDivBox"
                        >
                          <DragDiv
                            draggableItemName={subItem.label}
                            onEdit={() => {
                              editFields(subItem);
                            }}
                            onDelete={() => {
                              deleteFields(subItem);
                            }}
                            type={subItem?.type}
                            isStatic={subItem?.isStatic}
                            scope={subItem?.scope}
                          />
                        </Box>
                      )
                    )
                  )}
                </Grid>
              </Box>
            )
          )}
          <GridItem
            colSpan={12}
            border="1px"
            borderRadius="6px"
            borderColor={useColorModeValue(COLORS.LIGHT, COLORS.BORDER_DARK)}
            data-testid="DragAndResizeLayoutMenuGridItem"
          >
            <Menu>
              <MenuButton
                w="100%"
                alignItems="center"
                isActive
                as={Button}
                bg={COLORS.BG_LIGHT}
                color={COLORS.LIGHT}
                _hover={{
                  bg: COLORS.BG_LIGHT,
                  color: useColorModeValue(COLORS.LIGHT, 'white'),
                }}
                isDisabled={
                  isEmpty(metaFieldLayoutTitle) && isEmpty(deletedFieldItems)
                }
                data-testid="DragAndResizeLayoutMenuButton"
                onClick={() => {
                  if (isEmpty(metaFieldLayoutTitle)) {
                    setOpenFieldListDrawer(true);
                  } else {
                    setOpenSpecialFieldDrawer(true);
                  }
                }}
              >
                <AddIcon pb="0.5" mr="2" /> {
                  !isEmpty(metaFieldLayoutTitle)
                    ? 'Add More Meta Fields'
                    : 'Add More Fields'
                }
              </MenuButton>
            </Menu>
          </GridItem>
        </Grid>
      </Box>
      {openEditSectionModal && (
        <AddEditLayoutSectionModal
          isOpen={openEditSectionModal}
          setIsOpen={setOpenEditSectionModal}
          isEdit
          sectionData={sectionData}
          onCancel={() => setOpenEditSectionModal(false)}
          onConfirmation={(val) => editSectionInLayout(val)}
          selectedTemplateId={selectedTemplate.id}
        />
      )}
      {openEditFieldModal && (
        <EditFieldLayoutModal
          isOpen={openEditFieldModal}
          setIsOpen={setOpenEditFieldModal}
          fieldData={fieldData}
          onCancel={() => setOpenEditFieldModal(false)}
          onConfirmation={(val) => editFieldColspan(val)}
          viewType={viewType}
        />
      )}
      {openMetaTableAttributes && (
        <CommonAttributesDrawer
          openDrawer={openMetaTableAttributes}
          setOpenDrawer={setOpenMetaTableAttributes}
          title="Table Attributes"
          checkBoxList={metaTableAttributeList}
          setCommonAttributesState={setTableAttributeState}
          commonAttributesState={tableAttributeState}
        />
      )}
      {openTableColumnAttribute && (
        <CommonAttributesDrawer
          openDrawer={openTableColumnAttribute}
          setOpenDrawer={setOpenTableColumnAttribute}
          title="Column Attributes"
          checkBoxList={metaTableColumnAttributeList}
          setCommonAttributesState={updateTableColumnAttribute}
          commonAttributesState={tableColumnAttributeState}
          isColumn
        />
      )}
      {openSpecialFieldDrawer && (
        <SpecialFieldDrawer
          openDrawer={openSpecialFieldDrawer}
          setOpenDrawer={setOpenSpecialFieldDrawer}
          title={`Add ${metaFieldLayoutTitle ? 'Meta' : 'Special'} Fields`}
          checkBoxList={itemsState}
          setCommonAttributesState={updateSpecialField}
        />
      )}
      {openFieldListDrawer && (
        <InsertFieldDrawer
          openDrawer={openFieldListDrawer}
          setOpenDrawer={setOpenFieldListDrawer}
          title="Insert Field"
          checkBoxList={[]}
          setCommonAttributesState={insertFieldsToLayout}
          fieldList={deletedFieldItems}
        />
      )}
    </Box>
  );
};

DragAndResizeLayout.propTypes = {
  itemsStateList: PropTypes.array.isRequired,
  setItemsStateList: PropTypes.func.isRequired,
  layoutType: PropTypes.string.isRequired,
  viewType: PropTypes.string.isRequired,
  metaFieldTableAttributes: PropTypes.object,
  metaFieldLayoutTitle: PropTypes.string,
  setMetaFieldTableAttributes: PropTypes.func,
  mainwidth: PropTypes.string,
};
DragAndResizeLayout.defaultProps = {
  metaFieldTableAttributes: {},
  metaFieldLayoutTitle: '',
  mainwidth: '',
  setMetaFieldTableAttributes: () => {},
};

export default DragAndResizeLayout;
