import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import NotFoundPage from '../../layouts/common/NotFound';
import ConfigureTemplateViewContainer from '../../pages/TicketTemplateBuilder/ConfigureTemplateView';
import TicketTemplateDashboard from '../../pages/TicketTemplateBuilder/TemplateDashboard/TicketTemplateDashboard';
import TemplateEditor from '../../pages/TicketTemplateBuilder/TemplateEditor';
import TicketTemplateBuilder from '../../pages/TicketTemplateBuilder/TicketTemplateBuilder';
import TicketServiceCatalog from '../../TicketServiceCatalog';
import FlowWithProvider from '../../pages/TicketTemplateWorkflow/FlowWithProvider';
import FieldsetEditor from '../../pages/TicketTemplateBuilder/FieldsetEditor';
import CompositeEditor from '../../pages/TicketTemplateBuilder/CompositeEditor';
import TemplateCardList from '../../pages/TemplateCardListView/TemplateCardList';
import MasterScreenContainer from '../../pages/MasterScreen';
import { actorJson } from '../../pages/MasterScreen/ConfigJson/actorsJson';
import { fieldsJson } from '../../pages/MasterScreen/ConfigJson/fieldsJson';
import { fieldsetsJson } from '../../pages/MasterScreen/ConfigJson/fieldSetJson';
import { mailServerJson } from '../../pages/MasterScreen/ConfigJson/mailServerJson';
import { workFlowMasterListJson } from '../../pages/MasterScreen/ConfigJson/workFlowMasterListJson';
import { templateBuilderJson } from '../../pages/MasterScreen/ConfigJson/templateBuilderJson';
import { fieldsetBuilderJson } from '../../pages/MasterScreen/ConfigJson/fieldsetBuilderJson';
import { checkForV2 } from '../helper';
import Example from '../../pages/DynamicRenderer/example';
import { signaturesJson } from '../../pages/MasterScreen/ConfigJson/signatureJson';
import TicketMetaFields from '../../pages/TicketMetaFields';
import CreateMailServer from '../../pages/CreateMailServer';
import MasterAudience from '../../pages/MasterAudience';
import ExampleCopyHierarchy from '../../pages/DynamicRenderer/exampleCopyHierarchy';
import MasterFieldDefaults from '../../pages/MasterFieldDefaults';
import TenantFilters from '../../pages/TenantFilters';
import { ROUTES_DEFINED } from './routes';
import { hierarchyBuilderJson } from '../../pages/MasterScreen/ConfigJson/hierarchyBuilderJson';
import CreateEditHierarchyBuilder from '../../pages/CreateEditHierarchyBuilder';
import CreateEditIntentBuilder from '../../pages/CreateEditIntentBuilder';
import LocalesWrapper from '../../pages/TenantLocales';
import OffersWrapper from '../../pages/TenantOffers';
import { botIntegrationJson } from '../../pages/MasterScreen/ConfigJson/botIntegrationJson';
import TenantPermissionMap from '../../pages/TenantPermission';
import OtherSettings from '../../pages/OtherSettings';

const getStaticRoutes = () => {
  const routes = [
    {
      path: '/',
      element: <Navigate replace to="/template-cardlist" />,
      allowedRole: ['ticket_admin', 'asc_admin'],
    },
    {
      path: '/service-catalogue',
      element: <Navigate replace to="/template-cardlist" />,
      allowedRole: ['ticket_admin', 'asc_admin'],
    },
    {
      path: '/template-builder',
      element: <TicketTemplateBuilder />,
      allowedRole: ['ticket_admin', 'asc_admin'],
      children: [
        {
          path: '',
          element: <TicketTemplateDashboard />,
          allowedRole: ['ticket_admin', 'asc_admin'],
        },
        {
          path: '/template-builder/:id',
          element: <TemplateEditor />,
          allowedRole: ['ticket_admin', 'asc_admin'],
        },
        {
          path: '/template-builder/configure-template-view/:id',
          element: <ConfigureTemplateViewContainer />,
          allowedRole: ['ticket_admin', 'asc_admin'],
        },
        {
          path: '/template-builder/fieldset/:id',
          element: <FieldsetEditor />,
          allowedRole: ['ticket_admin', 'asc_admin'],
        },
        {
          path: '/template-builder/composite/:id',
          element: <CompositeEditor />,
          allowedRole: ['ticket_admin', 'asc_admin'],
        },
      ],
    },
    {
      path: '/template-catalog',
      element: <TicketServiceCatalog />,
      allowedRole: ['ticket_admin', 'asc_admin'],
    },
    {
      path: '/configure-template-view/:id',
      element: <ConfigureTemplateViewContainer />,
      allowedRole: ['ticket_admin', 'asc_admin'],
    },
    {
      path: '/template-cardlist',
      element: <TemplateCardList />,
      allowedRole: ['ticket_admin', 'asc_admin'],
    },
    {
      path: '/bot-integration',
      element: <TemplateCardList />,
      allowedRole: [ 'ticket_admin', 'asc_admin']
    },
    {
      path: '/bot-integration/*',
      element: (
        <MasterScreenContainer
          configJson={botIntegrationJson}
          key="bot-integration"
        />
      ),
      allowedRole: [ 'ticket_admin', 'asc_admin']
    },
    {
      path: '/fields',
      element: <MasterScreenContainer configJson={fieldsJson} key="fields" />,
      allowedRole: ['ticket_admin', 'asc_admin'],
    },
    {
      path: '/workflow-list',
      element: (
        <MasterScreenContainer
          configJson={workFlowMasterListJson}
          key="workFlow-List"
        />
      ),
      allowedRole: ['ticket_admin', 'asc_admin'],
    },
    {
      path: '/work-flow',
      element: <FlowWithProvider />,
      allowedRole: ['ticket_admin', 'asc_admin'],
    },
    {
      path: '/template-makers',
      element: (
        <MasterScreenContainer
          configJson={templateBuilderJson}
          key="template"
        />
      ),
      allowedRole: ['ticket_admin', 'asc_admin'],
      children: [
        {
          path: '/template-makers/configure-template-view/:id',
          element: <ConfigureTemplateViewContainer />,
          allowedRole: ['ticket_admin', 'asc_admin'],
        },
      ],
    },
    {
      path: '/fieldsets/fieldsets-makers',
      element: (
        <MasterScreenContainer
          configJson={fieldsetBuilderJson}
          key="fieldset"
        />
      ),
      allowedRole: ['ticket_admin', 'asc_admin'],
      children: [
        {
          path: '/fieldsets/fieldsets-makers/configure-template-view/:id',
          element: <ConfigureTemplateViewContainer />,
          allowedRole: ['ticket_admin', 'asc_admin'],
        },
      ],
    },
    {
      path: '/template-makers/*',
      element: (
        <MasterScreenContainer
          configJson={templateBuilderJson}
          key="template"
        />
      ),
      allowedRole: ['ticket_admin', 'asc_admin'],
    },
    {
      path: '/fieldsets/fieldsets-makers/*',
      element: (
        <MasterScreenContainer
          configJson={fieldsetBuilderJson}
          key="fieldset"
        />
      ),
      allowedRole: ['ticket_admin', 'asc_admin'],
    },
    {
      path: '/e',
      element: <Example />,
    },
    {
      path: '/dummy',
      element: <ExampleCopyHierarchy />,
    },
    { path: '/*', element: <NotFoundPage /> },
  ];

  if (checkForV2()) {
    routes.push(
      {
        path: '/actors',
        element: <MasterScreenContainer configJson={actorJson} key="actor" />,
        allowedRole: ['ticket_admin', 'asc_admin'],
      },
      {
        path: '/fieldsets',
        element: (
          <MasterScreenContainer configJson={fieldsetsJson} key="fieldsets" />
        ),
        allowedRole: ['ticket_admin', 'asc_admin'],
      },
      {
        path: '/signature-list',
        element: (
          <MasterScreenContainer
            configJson={signaturesJson}
            key="signature-list"
          />
        ),
        allowedRole: ['ticket_admin', 'asc_admin'],
      },
      {
        path: '/meta-fields',
        element: <TicketMetaFields />,
        allowedRole: ['ticket_admin', 'asc_admin'],
      },
      {
        path: '/locales',
        element: <LocalesWrapper />,
        allowedRole: ['ticket_admin', 'asc_admin'],
      },
      {
        path: '/offers-master',
        element: <OffersWrapper />,
        allowedRole: ['ticket_admin', 'asc_admin'],
      },
      {
        path: ROUTES_DEFINED.FILTERS,
        element: <TenantFilters />,
        allowedRole: ['ticket_admin', 'asc_admin'],
      },
      {
        path: '/mail-server',
        element: (
          <MasterScreenContainer
            configJson={mailServerJson}
            key="mail-server"
          />
        ),
        allowedRole: ['ticket_admin', 'asc_admin'],
      },
      {
        path: '/mail-server/create',
        element: <CreateMailServer />,
        allowedRole: ['ticket_admin', 'asc_admin'],
      },
      {
        path: '/mail-server/create/:id',
        element: <CreateMailServer />,
        allowedRole: ['ticket_admin', 'asc_admin'],
      },
      {
        path: ROUTES_DEFINED.FIELD_DEFAULT,
        element: <MasterFieldDefaults />,
        allowedRole: ['ticket_admin', 'asc_admin'],
      },
      {
        path: ROUTES_DEFINED.AUDIENCE,
        element: <MasterAudience />,
        allowedRole: ['ticket_admin', 'asc_admin'],
      },
      {
        path: ROUTES_DEFINED.INTENT,
        element: <CreateEditIntentBuilder />,
        allowedRole: ['ticket_admin', 'asc_admin'],
      },
      {
        path: '/hierarchy-builder',
        element: (
          <MasterScreenContainer
            configJson={hierarchyBuilderJson}
            key="mail-server"
          />
        ),
        allowedRole: ['ticket_admin', 'asc_admin'],
      },
      {
        path: '/hierarchy-builder/create',
        element: <CreateEditHierarchyBuilder />,
        allowedRole: ['ticket_admin', 'asc_admin'],
      },
      {
        path: '/hierarchy-builder/create/:id',
        element: <CreateEditHierarchyBuilder />,
        allowedRole: ['ticket_admin', 'asc_admin'],
      },
      {
        path: '/permissions',
        element: <TenantPermissionMap label="Tenant Permissions" />,
        allowedRole: ['ticket_admin', 'asc_admin'],
      },
      {
        path: '/other-settings',
        element: <OtherSettings />,
        allowedRole: ['ticket_admin', 'asc_admin'],
      }
    );
  }

  return routes;
};

export const staticRoutes = getStaticRoutes();

// export const allRoutesList = (routes) =>
//   routes?.map((route) => (
//     <Route key={route?.path} path={route.path} element={route?.element}>
//       {route?.children?.length && allRoutesList(route?.children)}
//     </Route>
//   ));

const checkRoles = (route, userRoles) => {
  return route.allowedRole?.some((role) => userRoles?.includes(role));
};

export const allRoutesList = (routes, userRoles) =>
  routes?.map((route) => {
    const isAuthorized = checkRoles(route, userRoles);
    if (window.location.pathname.includes('/catalog-v2/template-makers')) {
      return routes?.map((route) => (
        <Route key={route?.path} path={route.path} element={route?.element}>
          {route?.children?.length && allRoutesList(route?.children)}
        </Route>
      ));
    } else {
      return (
        <Route
          key={route?.path}
          path={route.path}
          element={
            isAuthorized ? (
              route?.element
            ) : (
              <div style={{ textAlign: 'center', color: 'red' }}>
                Sorry! You are not allowed to access this page.
              </div>
            )
          }
        >
          {route?.children?.length && allRoutesList(route?.children)}
        </Route>
      );
    }
  });

export const addRouteToParent = (routes, childRoutes) => [
  ...routes,
  childRoutes,
];

export const addRoute = (currentRoute, routes, childRoutes) => {
  const updatedRouteList = routes.map((ins) => {
    if (ins?.path === currentRoute) {
      const res = {
        ...ins,
        children: [...(ins?.children || []), ...childRoutes],
      };
      return res;
    }
    return ins;
  });

  return updatedRouteList;
};
