import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { Box, useColorModeValue } from '@chakra-ui/react';
import axiosInstance from '../../../utils/axios/axios';
import {
  getRelations,
} from '../../DynamicRenderer/Relation/relation.service';
import {
  createTemplateIdMapForRelation,
} from '../../TemplateNewEventAction/helper';
import customStyleFromRuleForSelect from '../../../context/CustomTheme/CustomStyleFromRuleForSelect';
import CustomSelectBox from '../../../components/SelectBox/Select';
import { DARK, LIGHT } from '../../../utils/enums/colors';
import {
  changeSelectedRuleWithKey,
  changeSelectedRuleWithMultiKeyValue,
} from '../../../redux/WorkFlowMainSlice/WorkFlowMainReducer';
import { getWholeOption } from '../helper';
import ConditionsExpressions from '../../../components/ConditionsExpressions/ConditionsExpressions';
import { isEmptyCondition } from '../../../utils/helper';

const getOffers = () => new Promise((resolve) => {
  axiosInstance
    .get(`/fieldDefaults/list?$select=*&$filter=offer_type ne 'email'`)
    .then((response) => {
      if (Array.isArray(response?.data?.rows)) {
        resolve(response.data.rows);
      } else {
        resolve([]);
      }
    })
    // eslint-disable-next-line no-unused-vars
    .catch((err) => {
      resolve([]);
    });
});

const RestricLinkedTicketContain = ({ errorData, setErrorData }) => {
  const dispatch = useDispatch();
  const { selectedWorkFlowRule } = useSelector((state) => state.workFlowMain);
  const [relatedItemOptions, setRelatedItemOptions] = useState({});
  const [relations, setRelations] = useState([]);
  const [offersById, setOffersById] = useState({});
  const [relatedOfferOptions, setRelatedOfferOptions] = useState([]);
  const [allTemplatesInTenant, setAllTemplatesInTenant] = useState({});
  const { selectedTemplate } = useSelector((reduxState) => reduxState.viewState);

  useEffect(() => {
    if (selectedTemplate) {
      getRelations(selectedTemplate.id).then(setRelations);
    }
  }, [selectedTemplate]);

  useEffect(() => {
    const offersMapById = {};

    getOffers().then((offers) => {
      offers.forEach((offer) => {
        offersMapById[offer.id] = offer.name;
      });
    });

    setOffersById(offersMapById);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const source = axios.CancelToken.source();
      try {
        const params = {
          $select: 'id, name',
        };
        const response = await axiosInstance.get('templates/list', {
          params,
          cancelToken: source.token,
        });
        if (response?.data?.rows) {
          const TEMP_TEMPLATE_RESPONSE = response?.data?.rows || [];
          const ID_LABEL_OBJ = TEMP_TEMPLATE_RESPONSE.reduce((acc, item) => {
            acc[item.id] = item.name;
            return acc;
          }, {});
          setAllTemplatesInTenant(ID_LABEL_OBJ);
        }
      } catch (err) {
        console.warn(err);
      }
    };
    fetchData();
  }, [selectedTemplate.id]);

  useEffect(() => {
    if (!offersById || !selectedWorkFlowRule.relationItemId || !relations?.length) {
      return;
    }

    const offerOptions = [{ value: '', label: 'None' }];
    const offersIdsRaw = [];

    relations.forEach((relation) => {
      if (relation.template_id_left === selectedWorkFlowRule.relationItemId) {
        offersIdsRaw.push(relation.offer_id_left);
      }

      if (relation.template_id_right === selectedWorkFlowRule.relationItemId) {
        offersIdsRaw.push(relation.offer_id_right);
      }
    });

    const offerIds = [...new Set(offersIdsRaw)];

    offerIds.filter((offerId) => !!offerId).forEach((offerId) => {
      offerOptions.push({
        value: offerId,
        label: offersById[offerId],
      });
    });
    setRelatedOfferOptions(offerOptions);
  }, [selectedWorkFlowRule.relationItemId, offersById, relations]);

  useEffect(() => {
    const itemOptions = createTemplateIdMapForRelation(
      relations,
      allTemplatesInTenant,
      selectedTemplate.id
    );
    setRelatedItemOptions(itemOptions);
  }, [relations, allTemplatesInTenant]);

  const handleErrorData = useCallback((keyName, val) => {
    setErrorData((prev) => {
      if (prev[keyName] && val) {
        return {
          ...prev,
          [keyName]: '',
        };
      }
      return prev;
    });
  }, []);

  return (
    <Box mt="30px">
      <Box mt="10px">
        <CustomSelectBox
          options={relatedItemOptions || []}
          placeholder="Select item type"
          value={getWholeOption(
            selectedWorkFlowRule.relationItemId,
            relatedItemOptions || []
          )}
          onChange={(value) => {
            handleErrorData('relationItemId', value.value);
            dispatch(
              changeSelectedRuleWithMultiKeyValue([
                {
                  keyValue: 'relationItemId',
                  value: value.value,
                },
                {
                  keyValue: 'relationOfferId',
                  value: '',
                },
                {
                  keyValue: 'relationItemStatus',
                  value: '',
                },
                {
                  keyValue: 'relationItemStatusOperator',
                  value: '',
                },
                {
                  keyValue: 'conditions',
                  value: {},
                },
                {
                  keyValue: 'countCondition',
                  value: {},
                },
              ])
            );
          }}
          focusBorderColor={useColorModeValue(LIGHT, DARK)}
          styles={customStyleFromRuleForSelect}
          isError={errorData?.relationItemId || false}
          errorMessage={errorData?.relationItemId || ''}
          id="DynamicRendererRelationItem"
          label="Item Type"
          isMandatory
        />
      </Box>
      <Box mt="10px">
        <CustomSelectBox
          options={relatedOfferOptions || []}
          placeholder="None"
          value={getWholeOption(
            selectedWorkFlowRule.relationOfferId,
            relatedOfferOptions || []
          )}
          onChange={(value) => {
            handleErrorData('relationOfferId', value.value);
            dispatch(
              changeSelectedRuleWithMultiKeyValue([{
                keyValue: 'relationOfferId',
                value: value.value,
              }])
            );
          }}
          focusBorderColor={useColorModeValue(LIGHT, DARK)}
          styles={customStyleFromRuleForSelect}
          isError={errorData?.relationOfferId || false}
          isDisabled={!selectedWorkFlowRule.relationItemId}
          errorMessage={errorData?.relationOfferId || ''}
          id="DynamicRendererRelationOffer"
          label="Offer"
        />
      </Box>
      {selectedWorkFlowRule.relationItemId && (
        <Box>
          <ConditionsExpressions
            templateId={selectedWorkFlowRule.relationItemId}
            value={selectedWorkFlowRule?.conditions || {}}
            onChange={(value) => {
              const { and } = value || {};
              const updatedValue = isEmptyCondition(and) ? { and: [] } : value;
              handleErrorData('conditions', value);
              dispatch(
                changeSelectedRuleWithKey({
                  keyValue: 'conditions',
                  value: updatedValue,
                })
              );
            }}
            label="Conditions"
          />
          {errorData?.conditions ? (
            <span
              className="field-error"
              data-testid="RestrictSpecificValueContainerErrorSpan"
            >
              {errorData.conditions}
            </span>
          ) : null}
        </Box>
      )}
      {selectedWorkFlowRule.relationItemId && (
        <Box>
          <ConditionsExpressions
            templateId={selectedWorkFlowRule.relationItemId}
            value={selectedWorkFlowRule?.countCondition || {}}
            onChange={(value) => {
              const { and } = value || {};
              const updatedValue = isEmptyCondition(and) ? { and: [] } : value;
              handleErrorData('countCondition', value);
              dispatch(
                changeSelectedRuleWithKey({
                  keyValue: 'countCondition',
                  value: updatedValue,
                })
              );
            }}
            showCount
            label="Group Function Conditions"
          />
          {errorData?.countCondition ? (
            <span
              className="field-error"
              data-testid="RestrictSpecificValueContainerErrorSpan"
            >
              {errorData.countCondition}
            </span>
          ) : null}
        </Box>
      )}
    </Box>
  );
};
RestricLinkedTicketContain.propTypes = {
  errorData: PropTypes.object.isRequired,
  setErrorData: PropTypes.func.isRequired,
};
export default RestricLinkedTicketContain;
