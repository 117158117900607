import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Flex, Grid, GridItem, useColorModeValue } from '@chakra-ui/react';
import { EditIcon } from '@chakra-ui/icons';
import { useSelector, useDispatch } from 'react-redux';
import _set from 'lodash/set';
import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import _startCase from 'lodash/startCase';
import _camelCase from 'lodash/camelCase';
import axios from 'axios';
import { cloneDeep } from 'lodash';
import CustomInput from '../../components/InputBox/Input';
import TextAreaBox from '../DynamicRenderer/TextArea';
import useTableSearchLocal from '../../utils/hooks/useTableSearchLocal';
import { deleteRenderer } from '../DynamicRenderer/MetricsAndSLA/Metrics/utils';
import {
  addEventInEventList,
  createPayloadAction,
  validateCompleteActionData,
  getTransformedChildTemplatesFieldsForUI,
  isEventConfigValid,
  processErrorWithMessage
} from './helper';
import {
  ACTION_TYPE_VALUE,
  fieldNameLabelMap,
} from '../DynamicRenderer/customEventAndTriggers/customEventAndTriggers.constant';
import { COMMUNICATION_TEMPLATES } from '../CommunicationTemplates/utills';
import { updateTemplate } from '../DynamicRenderer/customEventAndTriggers/customEventAndTriggers.helper';
import { getRelationDefinition } from '../DynamicRenderer/Relation/relation.service';
import axiosInstance from '../../utils/axios/axios';
import { updateViewState } from '../../redux/ViewSlice/ViewReducer';
import ActionBackHeader from './ActionBackHeader';
import ActionToPerform from './ActionToPerform';
import ActionCondition from './ActionCondition';
import { getTeamQueueMap } from '../../components/selectFieldFromTemplateWithDelete/utils';
import { getHierarchies } from '../../components/dynamicExpressionWithTags/dynamicExpressionWithTags.helper';
import useFetchTemplate from '../TicketMetaFields/LinkedMetaFieldDrawer/helper';
import { CustomDrawer } from '../TicketTemplateBuilder/ConfigureTemplateView/AddEditTemplateLayoutModal';
import { patchDataForTemplateWithPath } from '../TicketTemplateBuilder/utils/templateAPIUtils';
import CustomButton from '../../components/Button/SimpleButton';
import { DARK, LIGHT } from '../../utils/enums/colors';
import { getErrorMessagesPath } from '../../utils/validationSchemas/validationSchemas';
import CustomToast from '../../components/Notification/Notification';

const getOffersByTemplateIds = (itemTypeIds) => new Promise((resolve) => {
  if (!itemTypeIds?.length) {
    resolve([]);
  }

  axiosInstance.get(
    `fieldDefaults/list?$select=*&$filter=${itemTypeIds.map((id) => `item_type eq '${id}'`).join(' or ')}`
  ).then((response) => {
    const offers = response?.data?.rows || [];
    if (offers) {
      resolve(offers);
    } else {
      resolve([]);
    }
  }).catch(() => {
    resolve([]);
  });
});

const convertOldActionStateObject = (actionStateObject, currentTemplateId) => {
  if (!actionStateObject.eventConfig) {
    const newActionStateObject = {
      ...actionStateObject,
      eventConfig: [{
        listened_event: actionStateObject.listened_event || '',
        linked_item_type: actionStateObject.linked_item_relation === 'self'
          ? currentTemplateId
          : actionStateObject.linked_item_type || '',
      }],
    };
    delete newActionStateObject.listened_event;
    delete newActionStateObject.linked_item_type;
    delete newActionStateObject.linked_item_relation;

    return newActionStateObject;
  }

  return actionStateObject;
};

const CreateAndEditAction = ({
  actionStateObject,
  setActionState,
  relations,
  relatedTemplates,
}) => {
  const { selectedTemplate } = useSelector((state) => state.viewState);
  const actionState = convertOldActionStateObject(actionStateObject, selectedTemplate.id);

  const [errorKeyListWithMessage, setErrorKeyListWithMessage] = useState({});
  const { fieldMapByTemplateId } = useFetchTemplate();
  const [initialActionToPerform, setInitialActionToPerform] = useState(
    actionState?.action_to_perform || []
  );
  const [isLoading, setIsLoading] = useState(false);
  const { addToast } = CustomToast();
  const [templateIdMap, setTemplateIdMap] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isActionToPerformEditing, setIsActionToPerformEditing] = useState(false);
  const { filteredData: filteredActions } = useTableSearchLocal();
  const [actionToPerformSelectedIndex, setActionToPerformSelectedIndex] = useState(-1);
  const [actionErrorData, setActionErrorData] = useState({});
  const [teamQueueMap, setTeamQueueMap] = useState([]);
  const [hierarchies, setHierarchies] = useState(null);
  const [fieldHierarchyMaps, setFieldHierarchyMaps] = useState(null);
  const [relationItemTypeOption, setRelationItemTypeOption] = useState([]);
  const [eventsByTemplateId, setEventsByTemplateId] = useState({});
  const [fieldDefaultDataForCreateIssue, setFieldDefaultDataForCreateIssue] = useState({});
  const [filteredRelatedTemplateOptions, setFilteredRelatedTemplateOptions] = useState([]);
  const [relatedOffers, setRelatedOffers] = useState([]);

  const dispatch = useDispatch();

  const openPreviewModal = () => setIsModalOpen(true);
  const closePreviewModal = () => setIsModalOpen(false);
  const onActionToPerformEditClick = (rowId, column, from = '') => {
    if (column && column.type === 'actions' && from !== 'edit') {
      return;
    }
    setIsActionToPerformEditing(true);
    const rowIndex = (actionState.action_to_perform || []).findIndex(
      (a) => a.id === rowId
    );
    setActionToPerformSelectedIndex(rowIndex);
  };
  const updateState = (res) => {
    if (!res.error) {
      dispatch(
        updateViewState({
          stateKey: 'selectedTemplate',
          value: res?.response?.data,
        })
      );
    }
  };

  const onAddEventConfigRow = () => {
    setActionState((prev) => {
      const deepCopyPrev = cloneDeep(prev);
      deepCopyPrev.data.eventConfig = cloneDeep(actionState.eventConfig || []);
      deepCopyPrev.data.eventConfig.push({
        listened_event: '',
        linked_item_type: '',
      });
      return deepCopyPrev;
    });
  };

  const onRemoveEventConfigRow = (index) => {
    if (actionState.eventConfig.length === 1) {
      return;
    }

    setActionState((prev) => {
      const deepCopyPrev = cloneDeep(prev);
      deepCopyPrev.data.eventConfig = cloneDeep(actionState.eventConfig || []);
      deepCopyPrev.data.eventConfig.splice(index, 1);
      return deepCopyPrev;
    });
  };

  const oncancelActionType = () => {
    setIsActionToPerformEditing(false);
    setActionState((prev) => {
      const deepCopyPrev = JSON.parse(JSON.stringify(prev));
      if (!actionState?.action_to_perform) {
        delete deepCopyPrev.data.action_to_perform;
      } else {
        // on cancel update action initial state
        deepCopyPrev.data.action_to_perform = initialActionToPerform;
      }
      return deepCopyPrev;
    });
  };

  const completeCheckForPayload = (payload) => {
    const { error } = validateCompleteActionData.validate(payload, {
      abortEarly: false,
    });
    if (error) {
      const errorList = processErrorWithMessage(error.details);
      setErrorKeyListWithMessage(errorList);
      // const errors = getErrorMessagesPath(error, fieldNameLabelMap);
      // errors.forEach((e) => {
      //   addToast({
      //     message: e,
      //     type: 'error',
      //     duration: 1500,
      //     isClosable: true,
      //   });
      // });
      const localPayload = _cloneDeep(payload);
      _set(
        localPayload,
        'action_to_perform',
        localPayload.action_to_perform.filter((item) => item.action_type !== '')
      );
      setActionState((pre) => ({ ...pre, data: localPayload }));
      return true;
    }
    // if error do not close drawer
    setIsActionToPerformEditing(false);
    return false;
  };

  const deleteActionToPerform = async (rowId) => {
    const localActionState = _cloneDeep(actionState);
    const rowIndex = (localActionState.action_to_perform || []).findIndex(
      (a) => a.id === rowId
    );
    localActionState.action_to_perform.splice(rowIndex, 1);
    if (!localActionState.action_to_perform.length) {
      localActionState.enabled = false;
    }
    const globalActionList = _cloneDeep(selectedTemplate.events.actions || []);
    const payload = createPayloadAction(localActionState, selectedTemplate);

    if (completeCheckForPayload(payload)) {
      return;
    }
    const newUpdatedActionList = addEventInEventList(payload, globalActionList);
    const res = await updateTemplate({
      data: {
        events: selectedTemplate?.events?.events || [],
        actions: newUpdatedActionList,
      },
      id: selectedTemplate?.id,
      defaultErrorMessage: 'Failed to delete Action To Perform',
      successMessage: 'Successfully deleted Action To Perform',
    });
    updateState(res);
    if (!res.error) {
      // if action is deleted update initial state
      setInitialActionToPerform(payload?.action_to_perform || []);
      setActionState((pre) => ({ ...pre, data: payload }));
    }
  };

  const actionToPerformActionCellRenderer = useCallback(
    ({ rowData, column }) => {
      const onFirstLoadIcons = (
        <>
          <EditIcon
            className="cursor-pointer"
            onClick={
              () => {
                onActionToPerformEditClick(rowData?.id, column, 'edit');
              }
              // onActionToPerformEditClick(rowData?.id, column, 'edit')
            }
          />
          {deleteRenderer({
            title: 'Delete this Action To Perform',
            description: 'Are you sure you want to delete?',
            onConfirm: () => {
              deleteActionToPerform(rowData?.id);
            }, // ,
          })}
          {/* <DeleteIcon
                className="cursor-pointer"
                onClick={() => {
                  deleteActionToPerform(rowIndex);
                }}
              /> */}
        </>
      );
      return (
        <Flex justifyContent="center" gap="5px">
          {onFirstLoadIcons}
        </Flex>
      );
    },
    [actionState]
  );
  const actionToPerformTabColumns = useMemo(
    () => [
      {
        col_name: 'action_type',
        label: 'Action Type',
        type: 'string',
        filterable: true,
        cellRenderer: ({ rowData }) =>
          _startCase(_camelCase(rowData.action_type)),
      },
      {
        col_name: 'actions',
        type: 'actions',
        cellRenderer: actionToPerformActionCellRenderer,
        align: 'center',
      },
    ],
    [actionState]
  );

  const handleChange = (value, path) => {   
    setErrorKeyListWithMessage((pre) => ({ ...pre, [path]: '' }));
    const newActionState = _cloneDeep(actionState);
    if (path.includes('eventConfig') && path.includes('linked_item_type')) {
      _set(newActionState, path.replace('linked_item_type', 'listened_event'), '');
      _set(newActionState, path.replace('linked_item_type', 'linked_offer_id'), '');
    } else if (path.includes('from.alias')) {
      _set(newActionState, path.replace('alias', 'queue'), null);
      _set(newActionState, path.replace('alias', 'actor'), null);
      _set(newActionState, path.replace('alias', 'other'), null);
    }
    
    if (path.includes('action_to_perform') && path.includes('action_to_perform').from && newActionState.action_to_perform[0]?.from?.alias != 'actor' && newActionState.action_to_perform[0]?.from?.alias != 'queue_or_actor') {
      _set(newActionState, 'action_to_perform[0].from.fallbackAliasIsChecked', false);
      _set(newActionState, 'action_to_perform[0].from.fallbackAlias', '');
    }

    _set(newActionState, path, value);
    setActionState((pre) => ({ ...pre, data: newActionState }));
  };

  const handleChanges = (events) => {
    const newActionState = _cloneDeep(actionState);
    events.forEach((event) => {
      const { value, path } = event;
      _set(newActionState, path, value);
    });
    setActionState((pre) => ({ ...pre, data: newActionState }));
  };

  const handleActionErrorData = useCallback((key) => {
    setActionErrorData((prev) => {
      if (prev[key]) {
        return {
          ...prev,
          [key]: '',
        };
      }
      return prev;
    });
  });

  const getDataForEmail = useMemo(
    () => () =>
      [...(selectedTemplate?.[COMMUNICATION_TEMPLATES] || [])]
        ?.filter((ins) => ins.type === 'EMAIL')
        .map((item) => ({
          value: item.id,
          label: item.name,
          emailBody: item.email_body,
          emailSignature: item.email_signature,
          subject: item.subject,
          send_as: item.send_as,
        })) || [],
    [selectedTemplate]
  );

  const addNewActionToPerform = () => {
    const newEventSettings = _cloneDeep(actionState);
    const selectedActionToPerform = newEventSettings?.action_to_perform || [];
    selectedActionToPerform.push({ temp: true });
    setActionToPerformSelectedIndex(selectedActionToPerform.length - 1);
    setIsActionToPerformEditing(true);
    newEventSettings.action_to_perform = selectedActionToPerform;
    setActionState((pre) => ({ ...pre, data: newEventSettings }));
  };

  useEffect(() => {
    getTeamQueueMap()
      .then(setTeamQueueMap)
      .catch(() => setTeamQueueMap([]));
  }, []);

  useEffect(() => {
    if (actionToPerformSelectedIndex > -1 || actionState.action_to_perform) {
      const actionToPerform =
        actionState?.action_to_perform?.[actionToPerformSelectedIndex];
      const selectedTemplateId =
        actionToPerform?.issue_type?.value || selectedTemplate.id;
      let template = selectedTemplate;

      if (
        actionToPerform?.action_type === ACTION_TYPE_VALUE.CREATE_ISSUES &&
        relatedTemplates.length > 0
      ) {
        template = relatedTemplates.find(
          (item) => item.id === selectedTemplateId
        );
      }

      getTransformedChildTemplatesFieldsForUI(
        template,
        setFieldDefaultDataForCreateIssue
      );
    }
  }, [
    selectedTemplate,
    actionToPerformSelectedIndex,
    relatedTemplates,
    _get(
      actionState,
      `[action_to_perform[${actionToPerformSelectedIndex}].issue_type`
    ),
  ]);

  useEffect(() => {
    if (selectedTemplate?.field_hierarchy_map) {
      getHierarchies((hierarchy) => {
        setHierarchies(hierarchy);
        setFieldHierarchyMaps(selectedTemplate?.field_hierarchy_map || []);
      });
    }

    const fetchData = async () => {
      const source = axios.CancelToken.source();
      try {
        const params = { $select: '*' };
        const response = await axiosInstance.get('templates/list', {
          params,
          cancelToken: source.token,
        });
        if (response?.data?.rows) {
          const allTemplates = response?.data?.rows || [];
          const templateIdMapObj = allTemplates.reduce((acc, item) => {
            acc[item.id] = item;
            return acc;
          }, {});
          setTemplateIdMap(templateIdMapObj);
        }
      } catch (err) {
        console.warn(err);
      }
    };
    fetchData();
  }, [selectedTemplate]);

  useEffect(() => {
    if (Object.keys(templateIdMap).length === 0) {
      return;
    }
    // Get all related templates
    const currentTemplateId = selectedTemplate?.id;
    const relTempIdsRaw = [currentTemplateId];
    const relOfferIdsRaw = [];

    relations.forEach((relation) => {
      relTempIdsRaw.push(relation?.template_id_left);
      relTempIdsRaw.push(relation?.template_id_right);
      if (relation?.offer_id_left) {
        relOfferIdsRaw.push(relation?.offer_id_left);
      }
      if (relation?.offer_id_right) {
        relOfferIdsRaw.push(relation?.offer_id_right);
      }
    });

    const relatedTemplateIds = Array.from(new Set(relTempIdsRaw));
    const relatedOfferIds = Array.from(new Set(relOfferIdsRaw));

    // Get all events from related templates
    const allEvents = [];
    const allEventsByTemplateId = {};
    const relatedTemplateOptions = [];

    relatedTemplateIds.forEach((rtId) => {
      const template = templateIdMap[rtId];

      if (template) {
        relatedTemplateOptions.push({
          value: rtId,
          label: rtId === selectedTemplate?.id ? 'Self' : template.name,
        });
      }

      if (template?.events?.events) {
        allEvents.push(...template.events.events);
        allEventsByTemplateId[rtId] = template.events.events;
      }
    });

    getOffersByTemplateIds(relatedTemplateIds).then((offers) => {
      setRelatedOffers(offers.filter((offer) => relatedOfferIds.includes(offer.id)));
    });

    setEventsByTemplateId(allEventsByTemplateId);
    setRelationItemTypeOption(relatedTemplateOptions);
    // Remove triggers that are not part of the events
  }, [relations, templateIdMap, selectedTemplate]);

  useEffect(() => {
    const relatedTIds = Object.keys(eventsByTemplateId);
    const templateOptionsWithEvents = [];

    relatedTIds.forEach((rtId) => {
      if (eventsByTemplateId[rtId].length) {
        templateOptionsWithEvents.push({
          value: rtId,
          label: rtId === selectedTemplate?.id ? 'Self' : templateIdMap[rtId]?.name,
        });
      }
    });

    setFilteredRelatedTemplateOptions(templateOptionsWithEvents);
  }, [eventsByTemplateId, templateIdMap]);

  const onFinishSave = async () => {
    setIsLoading(true);
    const payload = createPayloadAction(actionState, selectedTemplate);
    payload.action_name = payload.action_name?.replace(/\s+/g, ' ').trim();
    if (completeCheckForPayload(payload)) {
      setIsLoading(false);
      return;
    }
    const newUpdatedEventList = addEventInEventList(
      { ...payload },
      selectedTemplate?.events?.actions
    );

    const isCreate =
      newUpdatedEventList?.length >
      (selectedTemplate?.events?.actions?.length || 0);
    const res = await patchDataForTemplateWithPath({
      id: selectedTemplate?.id,
      data: {
        path: 'events.actions',
        operation: isCreate ? 'create' : 'update',
        data: payload,
        id: !isCreate ? payload.id : undefined,
      },
      displayToastMessage: true,
      defaultErrorMessage: isCreate
        ? 'Failed to save the action'
        : 'Failed to update the action',
      successMessage: isCreate
        ? 'Successfully saved the action'
        : 'Successfully updated the action',
    });
    updateState(res);
    setIsLoading(false);
    setActionState(() => ({ data: {}, isShow: false }));
  };

  return (
    <CustomDrawer
      size="xl"
      modalTitle={
        <ActionBackHeader
          label="Action Info"
          onClick={() => {
            setActionState(() => ({ data: {}, isShow: false }));
          }}
          hideBackIcon="true"
        />
      }
      drawerBody={
        <>
          <Grid
            columnGap={30}
            data-testid="DynamicRendererEditContainenrGrid"
          >
            <GridItem colSpan={4} data-testid="DynamicRendererEditGridItem">
              <CustomInput
                label="Name"
                value={actionState.action_name || ''}
                onChange={(e) => {
                  handleChange(e.target.value, `action_name`);
                }}
                id="DynamicRenderer1DivCInput"
                mb="10px"
                isError={errorKeyListWithMessage?.action_name || false}
                errorMessage={errorKeyListWithMessage?.action_name || ''}
                isMandatory

              />
              <Box pt="10px">
                <TextAreaBox
                  rows={2}
                  value={actionState.description || ''}
                  label="Description"
                  onChange={(e) => handleChange(e.target.value, `description`)}
                  mt="10px"
                  mb="10px"
                />
              </Box>
            </GridItem>
          </Grid>

          <Box>
            <ActionCondition
              actionState={actionState}
              handleChange={handleChange}
              setActionState={setActionState}
              eventsByTemplateId={eventsByTemplateId}
              filteredRelatedTemplateOptions={filteredRelatedTemplateOptions}
              selectedTemplate={selectedTemplate}
              onFinishSave={onFinishSave}
              completeCheckForPayload={completeCheckForPayload}
              isLoading={isLoading}
              errorKeyListWithMessage={errorKeyListWithMessage}
              onAddEventConfigRow={onAddEventConfigRow}
              onRemoveEventConfigRow={onRemoveEventConfigRow}
              relatedOffers={relatedOffers}
            />
            {isEventConfigValid(actionState.eventConfig) && (
              <ActionToPerform
                isActionToPerformEditing={isActionToPerformEditing}
                setIsActionToPerformEditing={setIsActionToPerformEditing}
                addNewActionToPerform={addNewActionToPerform}
                actionToPerformTabColumns={actionToPerformTabColumns}
                filteredActions={filteredActions}
                actionState={actionState}
                onActionToPerformEditClick={onActionToPerformEditClick}
                actionToPerformSelectedIndex={actionToPerformSelectedIndex}
                handleChange={handleChange}
                handleChanges={handleChanges}
                handleActionErrorData={handleActionErrorData}
                actionErrorData={actionErrorData}
                getDataForEmail={getDataForEmail}
                openPreviewModal={openPreviewModal}
                isModalOpen={isModalOpen}
                closePreviewModal={closePreviewModal}
                selectedTemplate={selectedTemplate}
                onFinishSave={onFinishSave}
                relatedTemplates={relatedTemplates}
                isLoading={isLoading}
                teamQueueMap={teamQueueMap}
                hierarchies={hierarchies}
                fieldHierarchyMaps={fieldHierarchyMaps}
                fieldDefaultDataForCreateIssue={fieldDefaultDataForCreateIssue}
                relationsData={relations}
                relationItemTypeOption={relationItemTypeOption}
                fieldMapByTemplateId={fieldMapByTemplateId}
                oncancelActionType={oncancelActionType}
                templateIdMap={templateIdMap}
                relatedOffers={relatedOffers}
              />
            )}
            {/* {isEventConfigValid(actionState.eventConfig) &&
              errorKeyListWithMessage?.user_conditions && (
                <span className="field-error">
                  Select at least one user condition
                </span>
            )} */}
          </Box>
        </>
      }
      openDrawer
      setOpenDrawer={() => {
        setActionState(() => ({ data: {}, isShow: false }));
      }}
      drawerFooter={
        <Flex p="16px 10px" justify="flex-end">
          <CustomButton
            color="white"
            onClick={() => {
              onFinishSave();
            }}
            buttonText="Save"
            variant="solid"
            bg={useColorModeValue(LIGHT, DARK)}
            isLoading={isLoading}
          />
        </Flex>
      }
    />
  );
};

CreateAndEditAction.propTypes = {
  actionStateObject: PropTypes.object.isRequired,
  setActionState: PropTypes.func.isRequired,
  relations: PropTypes.array.isRequired,
  relatedTemplates: PropTypes.array.isRequired,
};

export default CreateAndEditAction;
