import React, {} from 'react';

import {
  Box,
  Flex,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';

import CustomTable from '../../components/Table/Table';
import CustomButton from '../../components/Button/SimpleButton';
import { COLORS } from '../../utils/enums/colors';

const Offers = ({
  columns,
  tableData,
  onRowClick,
  sortableConfig,
}) => (
    <>
      <style>
        {`
      .customHeight{
        padding-top:0px !important;
      }
      
      `}
      </style>
      <Box px="12px" data-testid="OffersTableViewContainerBox">
        <Box
          display="flex"
          justifyContent="space-between"
          data-testid="OffersTableViewContainerBoxFlex"
        >
          <Text style={{ fontSize: '20px', fontWeight: '600' }}>Offers</Text>
          <Flex
            gap="15px"
            flexWrap="wrap"
            data-testid="OffersTableViewContainerFlex"
            alignItems="right"
          >
            {sortableConfig?.showReorder && (
              <CustomButton
                classname="calculatedHeight"
                isLoading={sortableConfig?.btnTitle?.isLoading}
                isDisabled={sortableConfig?.btnTitle?.isLoading}
                buttonText={
                  sortableConfig?.isSortable
                    ? sortableConfig?.btnTitle?.toggleOn
                    : sortableConfig?.btnTitle?.toggleOff
                }
                onClick={() => {
                  if (sortableConfig?.isSortable) {
                    sortableConfig?.onOrderSave();
                    sortableConfig?.onToggleSortable(
                      !sortableConfig?.isSortable
                    );
                  } else {
                    sortableConfig?.onToggleSortable(
                      !sortableConfig?.isSortable
                    );
                  }
                }}
                variant="solid"
                style={{
                  backgroundColor: COLORS.LIGHT,
                  color: COLORS.WHITE,
                  borderColor: '#2563EB',
                }}
                color={useColorModeValue(COLORS.LIGHT, COLORS.DARK)}
              />
            )}
          </Flex>
        </Box>

        <Box my="16px" data-testid="OffersTableViewTableContainerBox">
          <CustomTable
            sortableConfig={sortableConfig}
            columns={columns}
            tableData={tableData}
            onRowClick={onRowClick}
            title="Locales"
          />
        </Box>
      </Box>
    </>
);

Offers.propTypes = {
  columns: PropTypes.array.isRequired,
  tableData: PropTypes.array.isRequired,
  onRowClick: PropTypes.func,
  sortableConfig:
  PropTypes.shape({
    isSortable: PropTypes.bool.isRequired,
    showReorder: PropTypes.bool.isRequired,
    onToggleSortable: PropTypes.func.isRequired,
    onOrderChange: PropTypes.func.isRequired,
    onOrderSave: PropTypes.func.isRequired,
    btnTitle:
      PropTypes.shape({
        toggleOff: PropTypes.string.isRequired,
        toggleOn: PropTypes.string.isRequired,
        isLoading: PropTypes.bool.isRequired
      }).isRequired
  })
};

Offers.defaultProps = {
  onRowClick: undefined,
  sortableConfig:
  {
    isSortable: false,
    showReorder: false,
    onToggleSortable: () => {},
    onOrderChange: () => {},
    onOrderSave: () => {},
    btnTitle:
      {
        toggleOff: 'Reorder',
        toggleOn: 'Save Order',
        isLoading: false
      },
  },
};

export default Offers;
