/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import _isEmpty from 'lodash/isEmpty';
import _cloneDeep from 'lodash/cloneDeep';
import _camelCase from 'lodash/camelCase';
import _startCase from 'lodash/startCase';

import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  Flex,
  Grid,
  GridItem,
  IconButton,
  Text,
  useColorModeValue,
  Checkbox,
  Box,
  TabPanel,
  Tabs,
  TabList,
  Tab,
  TabPanels,
} from '@chakra-ui/react';
import { ArrowBackIcon, DeleteIcon } from '@chakra-ui/icons';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import styles from './specialfield.module.scss';
import CustomButton from '../../../components/Button/SimpleButton';
import { LABELS } from '../../../utils/enums/labels';
import {
  COLORS,
  DARK,
  GREY_BACKGROUND,
  LIGHT,
} from '../../../utils/enums/colors';
import CustomInput from '../../../components/InputBox/Input';
import DynamicMutliSelectWithInputTags from '../../../components/dynamicSelectWithInputTags/DynamicSelectWithInputTags';
import { roleSettingSelectOpt } from '../../../utils/enums/selectOption';
import ConditionsExpressions from '../../../components/ConditionsExpressions/ConditionsExpressions';
import { TEMPLATE_ROLE_SETTINGS } from '../../TicketTemplateBuilder/utils/constants';
import { isEmptyCondition } from '../../../utils/helper';
import { getUserAttributes } from '../../../components/dynamicSelectWithInputTags/dynamicSelectWithInputTags.helper';
import CustomSelectBox from '../../../components/SelectBox/Select';

const TABS = {
  ROLE_SETTING: 'Role Setting',
  OTHER_SETTING: 'Other Setting',
};
const NOTE_TYPE_OPTION = [
  { value: 'private', label: 'Private' },
  { value: 'public', label: 'Public' },
  { value: 'chase', label: 'Chase' },
  { value: 'resolution', label: 'Resolution' },
];
const SpecialFieldDrawer = ({
  openDrawer,
  setCloseDrawer,
  title,
  saveSpecialField,
  selectedTemplate,
  setSelectedSpecialField,
  selectedSpecialField,
}) => {
  const [errorMsg, setErrorMsg] = useState({});
  const [activeTab, setActiveTab] = useState(TABS.ROLE_SETTING);
  const [userAttributes, setUserAttributes] = useState([]);
  const handleInputChange = useCallback((index, value) => {}, []);
  const tabConfigurations = [
    {
      label: TABS.ROLE_SETTING,
      testId: 'EditSpecialFieldTab',
      onClick: () => setActiveTab(TABS.ROLE_SETTING),
      isActive: activeTab === TABS.ROLE_SETTING,
    },
    {
      label: TABS.OTHER_SETTING,
      testId: 'EditSpecialSettingTab',
      onClick: () => {
        setActiveTab(TABS.OTHER_SETTING);
      },
      isActive: activeTab === TABS.OTHER_SETTING,
      isVisible: [
        'SF_PROFILE',
        'SF_NOTES',
        'SF_WATCHERS',
        'SF_HISTORY',
      ].includes(selectedSpecialField.type),
    },
  ];
  useEffect(() => {
    getUserAttributes()
      .then((res) => {
        const apiOption = res.response.rows.map((item) => {
          const localOption =
            item.valueLabels?.map((data) => ({
              value: data,
              label: _startCase(_camelCase(data)),
            })) || [];

          return {
            value: item.key,
            label: _startCase(item.label),
            rightOptionType: item.validationRule.field,
          };
        });
        setUserAttributes(apiOption);
      })
      .catch(() => []);
  }, []);

  return (
    <Drawer
      size="lg"
      placement="right"
      onClose={setCloseDrawer}
      isOpen={openDrawer}
      closeOnOverlayClick={false}
    >
      <DrawerContent>
        <DrawerHeader>
          <Flex alignItems="center" gap="10px">
            <IconButton
              colorScheme="blue"
              aria-label="Close Drawer"
              size="sm"
              borderRadius="50%"
              minW="1.5rem"
              height="1.5rem"
              variant="outline"
              icon={<ArrowBackIcon />}
              onClick={() => {
                setSelectedSpecialField({});
                setCloseDrawer(false);
              }}
            />
            <Text fontSize="16px">{title}</Text>
          </Flex>
        </DrawerHeader>
        <DrawerBody>
          <Tabs
            index={tabConfigurations.findIndex(({ isActive }) => isActive)}
            data-testid="EditMetricsTabs"
          >
            <TabList
              padding="0px"
              data-testid="EditMetricsTabList"
              bg={useColorModeValue(GREY_BACKGROUND, '#4F5765')}
              className={styles.tab__container}
            >
              {tabConfigurations.map(
                ({ label, testId, onClick, isActive, isVisible = true }) =>
                  isVisible && (
                    <Tab
                      key={label}
                      onClick={onClick}
                      border="none"
                      borderBottom="0px"
                      padding="5px"
                      className={`${styles.tab}`}
                      data-testid={testId}
                    >
                      <Text
                        px="12px"
                        py="8px"
                        className={`${styles.tab__text} ${
                          isActive
                            ? useColorModeValue(
                                styles.tab__selected__light,
                                styles.tab__selected__dark
                              )
                            : ''
                        }`.trim()}
                      >
                        {label}
                      </Text>
                    </Tab>
                  )
              )}
            </TabList>
            <TabPanels data-testid="EditMetricsTabPanels">
              <TabPanel data-testid="EditMetricsTabPanelMetric" mt="10px">
                <DynamicMutliSelectWithInputTags
                  values={
                    selectedSpecialField?.permissions?.[
                      TEMPLATE_ROLE_SETTINGS.VIEWERS
                    ] || []
                  }
                  label="Viewers"
                  onChange={(value) => {
                    setSelectedSpecialField((prev) => ({
                      ...prev,
                      permissions: {
                        ...prev.permissions,
                        [TEMPLATE_ROLE_SETTINGS.VIEWERS]: value,
                      },
                    }));
                  }}
                  templateId={selectedTemplate.id}
                  selectOptions={roleSettingSelectOpt}
                />
                <Box
                  mt="10px"
                  mb="10px"
                  data-testid="TicktFieeldContentRow3DynamicBox"
                >
                  {selectedSpecialField?.type !== 'SF_SMARTSUMMARY' && (
                    <DynamicMutliSelectWithInputTags
                      values={
                        selectedSpecialField?.permissions?.[
                          TEMPLATE_ROLE_SETTINGS.EDITORS
                        ] || []
                      }
                      label="Editors"
                      onChange={(value) => {
                        setSelectedSpecialField((prev) => ({
                          ...prev,
                          permissions: {
                            ...prev.permissions,
                            [TEMPLATE_ROLE_SETTINGS.EDITORS]: value,
                          },
                        }));
                      }}
                      templateId={selectedTemplate.id}
                      selectOptions={roleSettingSelectOpt}
                    />
                  )}
                </Box>
                <ConditionsExpressions
                  templateId={selectedTemplate.id}
                  value={
                    selectedSpecialField?.permissions?.[
                      TEMPLATE_ROLE_SETTINGS.EXPRESSION
                    ] || {}
                  }
                  onChange={(value) => {
                    const { and } = value || {};
                    const updatedValue = isEmptyCondition(and)
                      ? { and: [] }
                      : value;
                    setSelectedSpecialField((prev) => ({
                      ...prev,
                      permissions: {
                        ...prev.permissions,
                        [TEMPLATE_ROLE_SETTINGS.EXPRESSION]: updatedValue,
                      },
                    }));
                  }}
                  label="Expressions"
                />
              </TabPanel>
              <TabPanel data-testid="EditMetricsTabPanelSla" mt="10px">
                {selectedSpecialField.type === 'SF_PROFILE' && (
                  <CustomSelectBox
                    label="Attributes To Hide"
                    options={userAttributes}
                    value={selectedSpecialField.setting || ''}
                    onChange={(e) => {
                      setSelectedSpecialField((prev) => ({
                        ...prev,
                        setting: e,
                      }));
                    }}
                    isMulti
                    id="EditSlaModalSLAMetColorInput"
                  />
                )}
                {selectedSpecialField.type === 'SF_NOTES' && (
                  <CustomSelectBox
                    label="Note Type Allow"
                    options={NOTE_TYPE_OPTION}
                    value={selectedSpecialField.setting || ''}
                    onChange={(e) => {
                      setSelectedSpecialField((prev) => ({
                        ...prev,
                        setting: e,
                      }));
                    }}
                    isMulti
                    id="EditSlaModalSLAMetColorInput"
                  />
                )}
                {selectedSpecialField.type === 'SF_WATCHERS' && (
                  <CustomInput
                    type="number"
                    label="Max no of Watcher"
                    value={selectedSpecialField.setting || ''}
                    onChange={(e) => {
                      setSelectedSpecialField((prev) => ({
                        ...prev,
                        setting: e.target.value,
                      }));
                      setErrorMsg((pre) => ({ ...pre, watcher: false }));
                    }}
                    isError={errorMsg?.watcher || false}
                    errorMessage={
                      errorMsg?.watcher ? 'Watch must be positive number' : ''
                    }
                  />
                )}
                {selectedSpecialField.type === 'SF_HISTORY' && (
                  <CustomInput
                    type="number"
                    label="Fold History"
                    value={selectedSpecialField.setting || ''}
                    onChange={(e) => {
                      setSelectedSpecialField((prev) => ({
                        ...prev,
                        setting: e.target.value,
                      }));
                      setErrorMsg((pre) => ({ ...pre, history: false }));
                    }}
                    isError={errorMsg?.history || false}
                    errorMessage={
                      errorMsg?.history
                        ? 'Fold history must be positive number'
                        : ''
                    }
                  />
                )}
              </TabPanel>
            </TabPanels>
          </Tabs>
        </DrawerBody>
        <DrawerFooter>
          <CustomButton
            classname="calculatedHeight"
            variant="outline"
            pl="25px"
            pr="25px"
            buttonColor={LABELS.SIGNATURE_DRAWER_COLOR}
            buttonText={LABELS.ACTIONS.DISCARD}
            style={{
              border: 'none',
              background: COLORS.SIGNATURE_DRAWER_BG,
              height: '38px',
              marginRight: '10px',
              color: COLORS.SIGNATURE_DRAWER_COLOR,
            }}
            _hover={{ color: useColorModeValue(COLORS.BLACK, 'white') }}
            onClick={() => {
              setSelectedSpecialField({});
              setCloseDrawer(false);
            }}
            size="lg"
          />
          <CustomButton
            classname="calculatedHeight"
            variant="solid"
            pl="25px"
            pr="25px"
            style={{ height: '38px' }}
            buttonColor="customBlue"
            buttonText={LABELS.ACTIONS.SAVE}
            onClick={() => {
              if (selectedSpecialField.type === 'SF_WATCHERS') {
                if (parseInt(selectedSpecialField.setting, 10) < 0) {
                  setErrorMsg((pre) => ({ ...pre, watcher: true }));
                  return;
                }
              }
              if (selectedSpecialField.type === 'SF_HISTORY') {
                if (parseInt(selectedSpecialField.setting, 10) < 0) {
                  setErrorMsg((pre) => ({ ...pre, history: true }));
                  return;
                }
              }
              saveSpecialField();
              setCloseDrawer(false);
            }}
            size="lg"
            isLoading={false}
            data-testid="LocaleButtonAdd"
          />
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

SpecialFieldDrawer.propTypes = {
  openDrawer: PropTypes.bool.isRequired,
  setCloseDrawer: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  saveSpecialField: PropTypes.func.isRequired,
  selectedTemplate: PropTypes.object.isRequired,
  selectedSpecialField: PropTypes.object.isRequired,
  setSelectedSpecialField: PropTypes.func.isRequired,
};

export default SpecialFieldDrawer;
