import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import TriggersTableViewWithSearch from './TriggersTableViewWithSearch';
import EditTriggersDrawer from './EditTriggersDrawer';
import { COLUMNS_TRIGGERS } from './utils';
import axiosInstance, { axiosInstanceWithoutBaseUrl } from '../../../../utils/axios/axios';
import { showToastMessage } from '../../../TicketTemplateBuilder/utils/templateAPIUtils';

const Triggers = () => {
  const [openEditDrawer, setOpenEditDrawer] = useState(false);
  const [selectedTriggerField, setSelectedTriggerField] = useState({});
  const [showTriggersDrawer, setShowTriggersDrawer] = useState(false);
  const [triggersOffersData, setTriggersOffersData] = useState([]);
  const [triggerKeyValues, setTriggerKeyValues] = useState([]);
  const [triggerContext, setTriggerContext] = useState('');
  const [qnaRecords, setQnaRecords] = useState([]);
  const [errorMessage, setErrorMessage] = useState({});
  const [selectedTemplate, setSelectedTemplate] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [offers, setOffers] = useState([]);
  const [updateQnaRecords, setUpdateQnaRecords] = useState({ deleteQna: false, updateQna: false });
  const [isPublishing, setIsPublishing] = useState(false);

  const tenantId = JSON.parse(localStorage.getItem('tenant'))?.id;
  const templateId = useParams();
  const id = templateId['*'].match(/\d+/)[0];

  const TOASTER = {
    DELETE_ERROR: 'Error in deleting trigger',
    DELETE_SUCCESS: 'Trigger deleted successfully',
    PUBLISH_SUCCESS: 'Trigger published successfully',
    PUBLISH_ERROR: 'Error in publishing trigger',
    UPLOAD_SUCCESS: 'Trigger uploaded successfully',
    UPLOAD_ERROR: 'Error in uploading trigger',
    UPDATE_SUCCESS: 'Trigger updated successfully',
    UPDATE_ERROR: 'Error in updating trigger',
    FLOW_NOT_FOUND: 'Published flow not found',
  };
  const getOffers = async () => {
    try {
      const response = await axiosInstance.get(
        `/fieldDefaults/list?$select=*&$filter=item_type%20eq%20%27${selectedTemplate[0]?.id}%27%20and%20offer_type%20ne%20%27email%27`
      );
      if (Array.isArray(response?.data?.rows)) {
        setOffers(response.data.rows);
        return response.data.rows;
      }
      return [];
    } catch (err) {
      return [];
    }
  };
  const getQnaRecords = async () => {
    try {
      setIsFetching(true);
      const response = await axiosInstanceWithoutBaseUrl.get(
        `/botmgmt-service/qna/qnaRecords?limit=2000&sortName=1&sortOrder=desc&type=flow&answer=NA&botIntegration=True`
      );
      if (Array.isArray(response?.data?.data)) {
        setIsFetching(false);
        setQnaRecords(response?.data?.data);
      }
      return [];
    } catch (err) {
      setIsFetching(false);
    } finally {
      setIsFetching(false);
      return [];
    }
  };
  const getTemplateData = async () => {
    try {
      const response = await axiosInstance.get(
        `/templates/list?$top=1&$skip=0&$select=*&$filter=id%20eq%20${id}&history=true`
      );
      if (Array.isArray(response?.data?.rows)) {
        setSelectedTemplate(response?.data?.rows);
      }
      return [];
    } catch (err) {
      return [];
    }
  };
  useEffect(() => {
    const timeId = setTimeout(() => {
      getQnaRecords();
    }, 1000);
    return () => {
      clearTimeout(timeId);
    };
  }, [updateQnaRecords.deleteQna, updateQnaRecords.updateQna]);
  useEffect(() => {
    getTemplateData();
  }, []);
  useEffect(() => {
    if (selectedTemplate.length > 0) {
      getOffers();
    }
  }, [selectedTemplate]);

  function formatDateToIST(dateString) {
    const date = new Date(new Date(dateString).getTime());
    const options = { day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: '2-digit' };
    const formattedDate = date.toLocaleString('en-US', options).replace(',', '').replace(/, (\d+)/, ' $1');

    return formattedDate;
  }
  useEffect(() => {
    const flowName = selectedTemplate[0]?.name.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
    const qnaRecord = qnaRecords.filter((item) => item?.metadata[0]?.value === flowName);
    const qnaOffers = qnaRecord.length > 0 ? qnaRecord[0]?.metadata.find((meta) => meta.name === 'offers')?.value : [];
    const updatedData = offers.map((item) => {
      const matchedValues = qnaOffers && qnaOffers.length > 0 && qnaOffers.find((element) => element.name === item?.name);
      if (matchedValues) {
        return { keys: matchedValues.keys, context: matchedValues?.context, name: matchedValues?.name, id: item?.id, created: formatDateToIST(item?.created_on), status: matchedValues?.status, description: item?.description, fields: item?.field_attributes, audience: item?.audiences };
      }

      return { keys: [], context: '', name: item?.name, id: item?.id, created: '', status: '', description: item?.description, fields: item?.field_attributes, audience: item?.audiences };
    });
    setTriggersOffersData(updatedData);
  }, [qnaRecords, updateQnaRecords, selectedTemplate, offers]);
  const onEdit = (rowData) => {
    setSelectedTriggerField(rowData);
    setOpenEditDrawer(true);
  };
  const deleteQna = async (selectedTriggerField) => {
    const flowName = selectedTemplate[0]?.name.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
    const getQnaResponse = async () => {
      try {
        const response = qnaRecords.find((item) => item.metadata[0]?.value === flowName);
        return response;
      } catch (err) {
        console.log('error in Qna response', err);
      }
    };

    const qnaResponse = await getQnaResponse();
    if (qnaResponse !== undefined) {
      const qnaOffers = qnaResponse?.metadata.find((meta) => meta.name === 'offers')?.value;
      const filteredData = qnaOffers?.filter((item) => item?.name !== selectedTriggerField?.name);
      const deletedData = qnaOffers?.find((item) => item?.name == selectedTriggerField?.name)?.keys;
      const updatedMetadata = qnaResponse?.metadata.map((item) => item.name === 'offers' ? { ...item, value: filteredData } : { ...item });
      const updatedQuestions = qnaResponse?.questions.filter((item) => !deletedData.includes(item));
      const publishData = {
        pinecone: { deleteQna: true, ticket_type_ids: deletedData, tenant_id: tenantId },
      };
      const payload = {
        questions: updatedQuestions,
        metadata: updatedMetadata
      };
      payload.publishData = publishData;
      try {
        const qnaRecordId = qnaResponse?.id;
        const response = await axiosInstanceWithoutBaseUrl.put(
          `/botmgmt-service/qna/update/${qnaRecordId}`, payload);
        if (response) {
          setUpdateQnaRecords({ ...updateQnaRecords, updateQna: !updateQnaRecords.updateQna });
          setOpenEditDrawer(false);
          setSelectedTriggerField({});
          setTriggerContext('');
          setTriggerKeyValues([]);
          setErrorMessage({});
          showToastMessage({
            title: 'Success',
            description: TOASTER.DELETE_SUCCESS,
            status: 'success',
          });
          return response;
        }
        return [];
      } catch (err) {
        showToastMessage({
          title: 'Error',
          description: TOASTER.DELETE_ERROR,
          status: 'error',
        });
      }
    } else {
      showToastMessage({
        title: 'Error',
        description: TOASTER.DELETE_ERROR,
        status: 'error',
      });
    }
  };
  const updateQna = async (isPublish) => {
    const errors = {};
    if (triggerContext === '') errors.context = 'Trigger context is required';
    if (triggerKeyValues.length == 0) errors.keys = 'Trigger keys are required';
    if (Object.keys(errors).length > 0) {
      setErrorMessage(errors);
      return;
    }
    setErrorMessage({});
    const timestamp = Date.now();
    const date = new Date(timestamp);
    const formattedDate = date.toISOString().replace('T', ' ').substring(0, 19);
    const triggerIntent = `${selectedTemplate[0]?.name}-${selectedTriggerField?.name}`;
    const formattedTriggerIntent = triggerIntent.toLowerCase().replace(/\s+/g, '');
    const flowName = selectedTemplate[0]?.name.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
    const fields = selectedTemplate[0]?.fields.filter((item) => item?.requiredForBot);
    const fieldsData = fields.filter((item) => {
      if (item?.permissions?.expression && Array.isArray(item?.permissions?.expression.and)) {
        const conditions = item?.permissions.expression.and;
        if (conditions.find((item) => item.or.length > 0)) {
          return null;
        }

        return item;
      }
      return item;
    })
      .map((element) => {
        let infoSource = element?.informationRetrievalPreference;
        if (infoSource === 'May Ask Leading Questions') {
          infoSource = 'UserInput';
        } else if (infoSource === 'Fetch from Fields') {
          infoSource = 'Inference';
        } else if (infoSource === 'Fetch From Conversation') {
          infoSource = 'SystemGeneration';
        }
        return {
          field_name: element.label,
          description: element.description,
          mandatory: !!element?.type_based_attributes?.required,
          type: element?.type,
          valid_options: Array.isArray(element?.type_based_attributes?.list) ? element?.type_based_attributes?.list.map((item) => item.queue_name) : (Array.isArray(element?.type_based_attributes?.collection?.staticValues) ? element?.type_based_attributes?.collection?.staticValues.map((item) => item.value) : []),
          example: 'example',
          info_source: infoSource,
        }
      });
      const offerFieldValues=offers.find(item=>item.name===selectedTriggerField?.name)?.field_attributes
      
      const offerValues = offerFieldValues.reduce((result, item) => {
          result[item.field.label] = item.value;
          return result;
          },
          {offerId: selectedTriggerField?.id}
       );
    setIsPublishing(true);
    const getQnaResponse = async () => {
      try {
        const response = qnaRecords.find((item) => item.metadata[0]?.value === flowName);
        return response;
      } catch (err) {
        console.log('error in Qna response', err);
      }
    };
    const qnaResponse = await getQnaResponse();
    if (qnaResponse !== undefined) {
      const pineconePayload = {
        ticket_forms: [{
          trigger_intent: formattedTriggerIntent,
          title: selectedTemplate[0]?.name,
          description: triggerContext,
          trigger_meta: {
            answer: formattedTriggerIntent,
            trigger_name: flowName,
            max_questions: 1,
            description: triggerContext,
            type: 'flow',
            timestamp: formattedDate,
            audience: Array.isArray(selectedTriggerField?.audience) && selectedTriggerField?.audience.length > 0 ? selectedTriggerField?.audience : ['all'],
            question: triggerKeyValues,
            fields: fieldsData,
            offer_defaults:offerValues
          },
        }],
        tenantid: tenantId
      };
      const offersData = qnaResponse && qnaResponse?.metadata.find((item) => item.name == 'offers');
      const teamValue = qnaResponse && qnaResponse?.metadata.find((item) => item.name == 'team')?.value;
      let updatedOffers = [];
      if (offersData) {
        const itemExists = offersData.value.some((item) => item.name === selectedTriggerField.name);
        if (itemExists) {
          updatedOffers = offersData.value.map((item) => {
            if (item.name === selectedTriggerField.name) {
              return {
                ...item,
                keys: triggerKeyValues,
                context: triggerContext,
                status: isPublish ? 'Published' : 'Draft'
              };
            }
            return item;
          });
        } else {
          updatedOffers = [
            ...offersData.value,
            {
              name: selectedTriggerField.name,
              keys: triggerKeyValues,
              context: triggerContext,
              status: isPublish ? 'Published' : 'Draft'
            }
          ];
        }
      } else {
        updatedOffers = [{
          name: selectedTriggerField.name,
          keys: triggerKeyValues,
          context: triggerContext,
          status: isPublish ? 'Published' : 'Draft'
        }];
      }
      let allKeys = [];
      if (offersData) offersData?.value.forEach((item) => { allKeys = [...allKeys, ...item.keys]; });
      const uniqueQuestions = [...new Set([...allKeys, ...triggerKeyValues, ...qnaResponse.questions])];
      const metadata = qnaResponse?.metadata.map((item) => {
        if (item.name === 'offers') {
          return { ...item, value: updatedOffers };
        }
        return item;
      }) || [];
      if (!metadata.some((item) => item.name === 'offers')) {
        metadata.push({ name: 'offers', value: updatedOffers });
      }
      const payload = {
        questions: uniqueQuestions,
        metadata
      };
      if (isPublish) {
        const publish = {
          publish: true,
          ids: [qnaResponse?.id],
          type: 'flow',
          workspace: null,
          updatedBy: tenantId,
          serviceCatalog: true,
          pinecone: pineconePayload,
          workspace: `{"team":"${teamValue}"}`
        };
        payload.publishData = publish;
      }
      const updateQnaRecord = async () => {
        const qnaRecordId = await getQnaResponse();
        try {
          const response = await axiosInstanceWithoutBaseUrl.put(
            `/botmgmt-service/qna/update/${qnaRecordId?.id}`, payload);
          if (response) {
            setUpdateQnaRecords({ ...updateQnaRecords, updateQna: !updateQnaRecords.updateQna });
            setOpenEditDrawer(false);
            setSelectedTriggerField({});
            setTriggerContext('');
            setTriggerKeyValues([]);
            setErrorMessage({});
            setIsPublishing(false);
            showToastMessage({
              title: 'Success',
              description: isPublish ? TOASTER.PUBLISH_SUCCESS : TOASTER.UPDATE_SUCCESS,
              status: 'success',
            });
            return response;
          }
          return [];
        } catch (err) {
          setIsPublishing(false);
          showToastMessage({
            title: 'Error',
            description: isPublish ? TOASTER.PUBLISH_ERROR : TOASTER.UPDATE_ERROR,
            status: 'error',
          });
          setOpenEditDrawer(false);
          setSelectedTriggerField({});
          setTriggerContext('');
          setTriggerKeyValues([]);
          setErrorMessage({});
          return [];
        } finally {
          setIsPublishing(false);
        }
      };
      await updateQnaRecord();
    } else {
      setIsPublishing(false);
      showToastMessage({
        title: 'Error',
        description: TOASTER.FLOW_NOT_FOUND,
        status: 'error',
      });
    }
  };
  const saveTrigger = async (isPublish) => {
    await updateQna(isPublish);
  };
  const onDelete = async (selectedTriggerField) => {
    await deleteQna(selectedTriggerField);
  };
  return (
    <>
        <TriggersTableViewWithSearch
          columns={COLUMNS_TRIGGERS({ onEdit, onDelete })}
          tableData={triggersOffersData}
          isFetching={isFetching}
        />
        {openEditDrawer && (
          <EditTriggersDrawer
            openDrawer={openEditDrawer}
            setOpenEditDrawer={setOpenEditDrawer}
            selectedTriggerField={selectedTriggerField}
            setSelectedTriggerField={setSelectedTriggerField}
            setShowTriggersDrawer={setShowTriggersDrawer}
            saveTrigger={saveTrigger}
            triggerKeyValues={triggerKeyValues}
            setTriggerKeyValues={setTriggerKeyValues}
            triggerContext={triggerContext}
            setTriggerContext={setTriggerContext}
            triggersOffersData={triggersOffersData}
            errorMessage={errorMessage}
            isPublishing={isPublishing}
          />
        )}
    </>
  );
};

export default Triggers;
