import Organization from '../../assets/icons/Organization.svg';
import Team from '../../assets/icons/Team.svg';
import Queue from '../../assets/icons/Queue.svg';
import Group from '../../assets/icons/Group.svg';
import User from '../../assets/icons/User.svg';
import Role from '../../assets/icons/Role.svg';
import Actors from '../../assets/icons/Actors.svg';
import Asignee from '../../assets/icons/Assign_Icon.svg';
import Description from '../../assets/icons/Description_Icon.svg';
import Label from '../../assets/icons/Label_Icon.svg';
import Reporter from '../../assets/icons/Reporter_Icon.svg';
import Summary from '../../assets/icons/Summary_Icon.svg';
import { WorkFlowNodeTypes } from './types';
import { checkForV2 } from '../helper';

export const boolSelectOption = [
  { value: '', name: '' },
  { value: true, name: 'True' },
  { value: false, name: 'False' },
  { value: null, name: 'Null' },
];

export const numberFormatSelectOpt = [
  { value: '', name: '' },
  { value: 'integer', name: 'Integer' },
  { value: 'decimal', name: 'Decimal' },
  { value: 'float', name: 'Float' },
];

export const dateFormatSelectOpt = [
  { value: '', name: '' },
  { value: 'dd/mm/yyyy', name: 'Date (dd/mm/yyyy)' },
  {
    value: 'dd/mm/yyyy hh:mm AM/PM',
    name: 'Date and Time (dd/mm/yyyy hh:mm AM/PM)',
  },
  { value: 'hh:mm AM/PM', name: 'Time (hh:mm AM/PM)' },
  { value: 'mm/dd/yyyy', name: 'Date US (mm/dd/yyyy)' },
  {
    value: '(mm/dd/yyyy hh:mm AM/PM)',
    name: 'Date and time US (mm/dd/yyyy hh:mm AM/PM)',
  },
];

export const mediaFormatSelectOpt = [
  { value: '', name: '' },
  { value: 'mp4', name: 'MP4' },
  { value: 'jpg', name: 'JPG' },
  { value: 'mp3', name: 'MP3' },
  { value: 'pdf', name: 'PDF' },
];
export const mediaFormatSelectOpt2 = [
  { value: '*', label: 'All' },
  { value: 'images', label: 'Images' },
  { value: 'videos', label: 'Videos' },
  { value: 'documents', label: 'Documents' },
  { value: 'compressed_files', label: 'Compressed Files' },
];

export const mediaFormatSelectOpt2FileType = {
  images: ['jpg', 'jpeg', 'png', 'svg', 'gif', 'webp'],
  videos: ['mpg', 'mpeg', 'mov', 'mp4', 'wmv', 'avi'],
  documents: ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'html', 'txt'],
  compressed_files: ['zip', 'rar', 'tar'],
};

export const displayLocaleOpt = [
  { value: '', name: '' },
  { value: 'default', name: 'Default' },
  { value: 'custom', name: 'Custom' },
];
export const selectLocalDateOpt = [
  { value: '', name: '' },
  { value: 'US-English (mm-dd-yy)', name: 'US-English (mm-dd-yy)' },
];

export const layoutTypes = {
  DESKTOP: 'DESKTOP',
  MOBILE: 'MOBILE',
  BOT: 'Bot',
};

export const layoutTypeOption = [
  { value: layoutTypes.DESKTOP, label: 'Portal' },
  { value: layoutTypes.MOBILE, label: 'Mobile (Single Column)' },
  { value: layoutTypes.BOT, label: 'Bot' },
];

export const layoutTypeOptionNoBot = [
  { value: layoutTypes.DESKTOP, label: 'Portal' },
  { value: layoutTypes.MOBILE, label: 'Mobile (Single Column)' },
];

export const layoutInboxViewOption = [
  { value: layoutTypes.DESKTOP, label: 'Portal' },
];

export const layoutTransitionViewOption = [
  { value: layoutTypes.MOBILE, label: 'Mobile (Single Column)' },
];

export const layoutViewTypes = {
  GRID: 'GRID',
  INBOX: 'INBOX',
  TRANSITION: 'TRANSITION',
};

export const layoutViewGridTypeOption = [
  { value: layoutViewTypes.GRID, label: 'Grid' },
  { value: layoutViewTypes.INBOX, label: 'Inbox' },
  { value: layoutViewTypes.TRANSITION, label: 'Transition' },
];

export const layoutViewTypeOption = [
  { value: 'LIST', label: 'List' },
  { value: 'SEARCH', label: 'Search' },
  { value: 'TABLE', label: 'Table' },
];

export const minWidthDesktop = [
  { value: '1367', label: '1367' },
  { value: '1410', label: '1410' },
  { value: '1430', label: '1430' },
];

export const maxWidthDesktop = [
  { value: '1667', label: '1667' },
  { value: '1810', label: '1810' },
  { value: '2130', label: '2130' },
];

export const minWidthMobile = [
  { value: '367', name: '367' },
  { value: '410', name: '410' },
  { value: '430', name: '430' },
];

export const maxWidthMobile = [
  { value: '', name: '' },
  { value: 'integer', name: 'Integer' },
  { value: 'decimal', name: 'Decimal' },
  { value: 'float', name: 'Float' },
];

export const minWidthCustom = [
  { value: '', name: '' },
  { value: 'integer', name: 'Integer' },
  { value: 'decimal', name: 'Decimal' },
  { value: 'float', name: 'Float' },
];

export const maxWidthCustom = [
  { value: '', name: '' },
  { value: 'integer', name: 'Integer' },
  { value: 'decimal', name: 'Decimal' },
  { value: 'float', name: 'Float' },
];

export const sectionTypeOptions = [
  { value: '', name: '' },
  { value: 'PLAIN', name: 'Plain' },
  { value: 'COLLAPSIBLE', name: 'Collapsible' },
  { value: 'DEFAULT_COLLAPSIBLE', name: 'Default Collapsible' },
  { value: 'TAB', name: 'Tab' },
];

export const fieldColSpanOptions = [
  { value: '', name: '' },
  { value: '2', name: '2' },
  { value: '4', name: '4' },
  { value: '6', name: '6' },
  { value: '8', name: '8' },
  { value: '10', name: '10' },
  { value: '12', name: '12' },
];

export const fieldFontOptions = [
  { value: '', name: '' },
  { value: '40px', name: 'XXXL', fontWeight: 800 },
  { value: '28px', name: 'XXL', fontWeight: 700 },
  { value: '22px', name: 'XL', fontWeight: 600 },
  { value: '18px', name: 'L', fontWeight: 400 },
  { value: '16px', name: 'M', fontWeight: 300 },
  { value: '13px', name: 'S', fontWeight: 200 },
];

export const fieldColorOptions = [
  { value: '', name: '' },
  { value: '#1e90ff', name: 'blue' },
  { value: '#F1C40F', name: 'yellow' },
  { value: '#E74C3C', name: 'red' },
  { value: '#2AC769', name: 'green' },
  { value: '#1ABC9C', name: 'darkGreen' },
  { value: '#192434', name: 'black' },
  { value: '#8463F9', name: 'purple' },
];
export const badgeOptions = [
  { value: '', name: '' },
  { value: 'rgba(227, 56, 67, 0.2)', name: 'Red' },
  { value: 'rgba(215, 145, 10, 0.2)', name: 'Yellow' },
  { value: 'rgba(90, 140, 250, 0.2)', name: 'Blue' },
];

export const noteTypeOptions = [
  { value: 'public', label: 'Public' },
  { value: 'resolution', label: 'Resolution' },
];

export const WorkFlowCategoriesOption = [
  { value: WorkFlowNodeTypes.toDoNode, label: 'To Do' },
  { value: WorkFlowNodeTypes.toProgressNode, label: 'In Progress' },
  { value: WorkFlowNodeTypes.toDoneNode, label: 'Done' },
];

export const StatusCategoriesColorOption = [
  { value: WorkFlowNodeTypes.toDoNode, label: 'Black' },
  { value: WorkFlowNodeTypes.toProgressNode, label: 'Blue' },
  { value: WorkFlowNodeTypes.toCautionNode, label: 'Brown ' },
  { value: WorkFlowNodeTypes.toDoneNode, label: 'Green' },
  { value: WorkFlowNodeTypes.toFixingNode, label: 'Orange' },
];
export const userAttribute = 'user attribute';

export const roleSettingType = {
  ORG: 'org',
  TEAMS: 'teams',
  QUEUES: 'queues',
  GROUPS: 'groups',
  USERS: 'users',
  ROLES: 'roles',
  ACTORS: 'actors',
  ATTRIBUTE: userAttribute
};

export const roleSettingSelectOpt = [
  { value: roleSettingType.ORG, label: 'Organization', icon: Organization },
  { value: roleSettingType.TEAMS, label: 'Team', icon: Team },
  { value: roleSettingType.QUEUES, label: 'Queue', icon: Queue },
  { value: roleSettingType.GROUPS, label: 'Group', icon: Group },
  { value: roleSettingType.USERS, label: 'User', icon: User },
  { value: roleSettingType.ROLES, label: 'Role', icon: Role },
  { value: roleSettingType.ATTRIBUTE, label: 'User Attribute', icon: Group },
  /* Disabling Actors as we don't support them at the moment */
  checkForV2() && {
    value: roleSettingType.ACTORS,
    label: 'Actors',
    icon: Actors,
  },
];

export const roleSettingSelectOptForV2 = [
  { value: roleSettingType.ORG, label: 'Organization', icon: Organization },
  { value: roleSettingType.TEAMS, label: 'Team', icon: Team },
  { value: roleSettingType.QUEUES, label: 'Queue', icon: Queue },
  { value: roleSettingType.GROUPS, label: 'Group', icon: Group },
  { value: roleSettingType.USERS, label: 'User', icon: User },
  { value: roleSettingType.ROLES, label: 'Role', icon: Role },
  { value: roleSettingType.ACTORS, label: 'Actors', icon: Actors },
];

export const ruleSpecificValueOption = [
  { value: 'asignee', label: 'Asignee', icon: Asignee },
  { value: 'description', label: 'Description', icon: Description },
  { value: 'label', label: 'Label', icon: Label },
  { value: 'reporter', label: 'Reporter', icon: Reporter },
  { value: 'summary', label: 'Summary', icon: Summary },
];
export const orgDomainSelectOption = [
  { value: '@acme.co', label: '@acme.co' },
  { value: '@gamma.com', label: '@gamma.com' },
  { value: '@epsilon.com', label: '@epsilon.com' },
];
export const teamsSelectOption = [
  { value: 'Thunderbolts', label: 'Thunderbolts' },
  { value: 'Firebirds', label: 'Firebirds' },
];
export const queueSelectOption = [
  { value: 'Queue1', label: 'Queue1' },
  { value: 'Queue2', label: 'Queue2' },
  { value: 'Queue3', label: 'Queue3' },
];
export const userSelectOption = [
  { value: 'FitnessFanatic', label: 'FitnessFanatic' },
  { value: 'Bookworm84', label: 'Bookworm84' },
];
export const groupSelectOption = [
  { value: 'The Dream Team', label: 'The Dream Team' },
  { value: 'The Social Butterflies', label: 'The Social Butterflies' },
];
export const roleMapping = {
  org: 'Organization',
  teams: 'Team',
  queues: 'Queue',
  users: 'User',
  groups: 'Group',
  actors: 'Actor',
  roles: 'Role',
};
export const ruleExpressionCondtionOption = [
  { value: 'must be all', name: 'MUST BE ALL' },
  { value: 'must be one', name: 'MUST BE ONE' },
];
export const rulePermisstionOption = [
  { value: '', name: '' },
  { value: 'Administer “Test Project”', name: 'Administer “Test Project”' },
  {
    value: 'Add or remove issue watchers',
    name: 'Add or remove issue watchers',
  },
  { value: 'Delete any attachment', name: 'Delete any attachment' },
  { value: 'Delete any comment', name: 'Delete any comment' },
  { value: 'Delete any issue', name: 'Delete any issue' },
  { value: 'Delete any work log entry', name: 'Delete any work log entry' },
  { value: 'Edit any comment', name: 'Edit any comment' },
  { value: 'Edit any due date', name: 'Edit any due date' },
  { value: 'Edit any work log entry', name: 'Edit any work log entry' },
];
export const ruleReviewTextOption = [{ value: 'text', name: 'Text' }];
export const rulereviewBothOption = [
  { value: 'text', name: 'Text' },
  { value: 'number', name: 'A number' },
];

export const ruleCheckForTextOption = [
  { value: 'equals to', label: 'EQUALS TO' },
  { value: 'like', label: 'LIKE' },
  { value: 'is not empty', label: 'IS NOT EMPTY' },
  { value: 'is empty', label: 'IS EMPTY' },
];

export const ruleCheckForTextOptionV2 = [
  { value: 'equals to', label: 'EQUALS TO' },
  { value: 'not equals to', label: 'NOT EQUALS TO' },
  { value: 'like', label: 'LIKE' },
  { value: 'is not empty', label: 'IS NOT EMPTY' },
  { value: 'is empty', label: 'IS EMPTY' },
];

export const ruleCheckForTextNumberOption = [
  { value: 'equals', label: 'EQUALS TO' },
  { value: 'not equals to', label: 'NOT EQUALS TO' },
  { value: 'greater than', label: 'GREATER THAN' },
  { value: 'greater than or equals to', label: 'GREATER THAN OR EQUAL TO' },
  { value: 'less than', label: 'LESS THAN' },
  { value: 'less than or equals to', label: 'LESS THAN OR EQUAL TO' },
  { value: 'is not empty', label: 'IS NOT EMPTY' },
  { value: 'is empty', label: 'IS EMPTY' },
];

export const ruleCheckForDateOptions = [
  { value: 'between', label: 'BETWEEN' },
  { value: 'equals', label: 'EQUALS TO' },
  { value: 'not equals to', label: 'NOT EQUALS TO' },
  { value: 'greater than', label: 'GREATER THAN' },
  { value: 'greater than or equals to', label: 'GREATER THAN OR EQUAL TO' },
  { value: 'less than', label: 'LESS THAN' },
  { value: 'less than or equals to', label: 'LESS THAN OR EQUAL TO' },
  { value: 'is not empty', label: 'IS NOT EMPTY' },
  { value: 'is empty', label: 'IS EMPTY' },
];

export const ruleCheckForSelectOption = [
  { value: 'is', label: 'IS' },
  { value: 'not in', label: 'NOT IN' },
  { value: 'is one of', label: 'IS ONE OF' },
];

export const ruleCheckForSelectOptionV2 = [
  { value: 'is', label: 'IS' },
  { value: 'not equals to', label: 'NOT EQUALS TO' },
  { value: 'not in', label: 'NOT IN' },
  { value: 'is one of', label: 'IS ONE OF' },
  { value: 'is not empty', label: 'IS NOT EMPTY' },
  { value: 'is empty', label: 'IS EMPTY' },
];

export const ruleCheckForActorOption = [
  { value: 'is', label: 'IS' },
  { value: 'has attributes', label: 'HAS ATTRIBUTES' },
  { value: 'is not empty', label: 'IS NOT EMPTY' },
  { value: 'is empty', label: 'IS EMPTY' },
];

export const ruleCheckForActorOptionNoTemplate = [
  { value: 'is', label: 'IS' },
  { value: 'is not empty', label: 'IS NOT EMPTY' },
  { value: 'is empty', label: 'IS EMPTY' },
];

export const ruleCheckForBooleanOption = [
  { value: 'true', label: 'IS TRUE' },
  { value: 'false', label: 'IS FALSE' },
  { value: 'is not empty', label: 'IS NOT EMPTY' },
  { value: 'is empty', label: 'IS EMPTY' },
];

export const roleSettigWorkflowOpt = [
  { value: roleSettingType.ROLES, label: 'Role', icon: Role },
];

export const roleSettingWorkflowOptV2 = [
  { value: roleSettingType.ORG, label: 'Organization', icon: Organization },
  { value: roleSettingType.TEAMS, label: 'Team', icon: Team },
  { value: roleSettingType.QUEUES, label: 'Queue', icon: Queue },
  { value: roleSettingType.GROUPS, label: 'Group', icon: Group },
  { value: roleSettingType.USERS, label: 'User', icon: User },
  { value: roleSettingType.ROLES, label: 'Role', icon: Role },
  { value: roleSettingType.ACTORS, label: 'Actors', icon: Actors },
];
export const emailSignatureOption = [
  { value: 'actor', label: 'Actor' },
  { value: 'team', label: 'Team' },
  { value: 'queue', label: 'Queue' },
];
export const dateFormatSelectOptTwo = [
  { value: 'dd/mm/yyyy', label: 'Date (dd/mm/yyyy)' },
  {
    value: 'dd/mm/yyyy hh:mm AM/PM',
    label: 'Date and Time (dd/mm/yyyy hh:mm AM/PM)',
  },
  { value: 'hh:mm AM/PM', label: 'Time (hh:mm AM/PM)' },
  { value: 'mm/dd/yyyy', label: 'Date US (mm/dd/yyyy)' },
  {
    value: '(mm/dd/yyyy hh:mm AM/PM)',
    label: 'Date and time US (mm/dd/yyyy hh:mm AM/PM)',
  },
];
export const displayLocaleOptTwo = [
  { value: 'default', label: 'Default' },
  { value: 'custom', label: 'Custom' },
];
export const selectLocalDateOptTwo = [
  { value: 'US-English (mm-dd-yy)', label: 'US-English (mm-dd-yy)' },
];
export const numberFormatSelectTwo = [
  { value: 'integer', label: 'Integer' },
  { value: 'decimal', label: 'Decimal' },
  { value: 'float', label: 'Float' },
];
export const boolSelectOptionTwo = [
  { value: true, label: 'True' },
  { value: false, label: 'False' },
  // { value: null, label: 'Null' },
];
export const dateDefaultValueList = [
  { value: 'CURRENT', label: 'Current' },
  { value: 'POSITIVE_OFFSET', label: 'Positive Offset' },
  { value: 'NEGATIVE_OFFSET', label: 'Negative Offset' },
];

export const SCOPE_MAP = {
  TICKET: 'TICKET',
  RELATION: 'RELATION',
  WORKFLOW: 'WORKFLOW',
};

export const SCOPE_TYPE_FOR_FIELD = [
  {
    label: 'Ticket',
    value: SCOPE_MAP.TICKET,
    toolTip: 'The scope of this field would be ticket level only.',
  },
  {
    label: 'Relation',
    value: SCOPE_MAP.RELATION,
    toolTip: 'The scope of this field would be relation level only.',
  },
  {
    label: 'Workflow',
    value: SCOPE_MAP.WORKFLOW,
    toolTip: 'The scope of this field would be workflow level only.',
  },
];

export const ACTOR_TYPE = [
  {
    label: 'Default',
    value: 'DEFAULT',
  },
  {
    label: 'Primary',
    value: 'PRIMARY',
  },
  {
    label: 'Requestor',
    value: 'REQUESTOR',
  },
];

export const slaOptions = [
  { value: 'number', name: 'Number' },
  { value: 'bar', name: 'Bar' },
  { value: 'badge', name: 'Badge' },
];

export const MAIL_STATUS_OPTION = [
  {
    value: 'DRAFT', label: 'Draft'
  },
  {
    value: 'PUBLISHED', label: 'Published'
  }
];
export const roleSettingForMoveAnIssue = [
  { value: roleSettingType.QUEUES, label: 'Queue', icon: Queue },
  { value: roleSettingType.GROUPS, label: 'Group', icon: Group },
  { value: roleSettingType.USERS, label: 'User', icon: User },
  { value: roleSettingType.ROLES, label: 'Role', icon: Role },
  { value: roleSettingType.ATTRIBUTE, label: 'User Attribute', icon: Group },
  /* Disabling Actors as we don't support them at the moment */
  checkForV2() && {
    value: roleSettingType.ACTORS,
    label: 'Actors',
    icon: Actors,
  },
];
export const countOption = [
  // { value: 'equals', label: 'EQUALS TO' },
  { value: 'greater than', label: 'GREATER THAN' },
  { value: 'greater than or equals to', label: 'GREATER THAN OR EQUAL TO' },
  { value: 'less than', label: 'LESS THAN' },
  { value: 'less than or equals to', label: 'LESS THAN OR EQUAL TO' },
  { value: 'all', label: 'All' }
];
